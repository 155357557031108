import { apiUrl2 } from '../../settings'

export const getAllEmployee = () => {
  return apiUrl2 + '/get-all-employee'
}
export const addExpenseCategoryUrl = () => {
  return apiUrl2 + '/add-department-expense-category'
}

export const getAllExpenseCategoryUrl = () => {
  return apiUrl2 + '/get-expense-category'
}
export const addDepartmentExpenseUrl = () => {
  return apiUrl2 + '/add-department-expense'
}

export const updateExpenseCategoryUrl = () => {
  return apiUrl2 + '/update-department-expense-category'
}

export const deleteExpenseCategoryUrl = () => {
  return apiUrl2 + '/delete-department-expense-category'
}

export const getAllDepartmentExpense   = () => {
  return apiUrl2 + '/get-department-expense'
}