import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  Select,
  Button,
  Icon, Col, Row, Tooltip, Drawer, notification, Card, Checkbox
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { hideLoader, hidePageLoad } from '../../../modules/actions'
import { connect } from 'react-redux'
import { listAllUniversities } from '../../university/actions/university'
import { listAllCountries } from '../../countries/actions/countries'
import {
  InputBox,
  departmentObj
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
  updateUser,
  listAllUsers,
  getSingleEmployee,
  updateEmployeeInfo
} from '../actions/user'
import { CountryCodeWithFlag, LoadAllCountry, LoadCity, LoadState } from '../../../components/_utils/countryUtil'


const FormItem = Form.Item
const { Option } = Select
const { TextArea } = Input

@Form.create()
class EditUserExtraInfo extends PureComponent {

  events = {
    selectAllUni: () => {
      const { form: { setFieldsValue } } = this.props
      let { universityList } = this.state
      let universities = []
      _.each(universityList, (item) => {
        if (item && item._id) {
          universities.push(item._id)
        }
      })
      setFieldsValue({
        universities: universities
      })
    },
    deSelectAllUni: () => {
      const { form: { setFieldsValue } } = this.props
      let universities = []
      setFieldsValue({
        universities: universities
      })
    },
    chooseCountry: (name) => {
      const { setFieldsValue } = this.props.form
      let countryName = _.find(this.state.allCountries, (item) => {
        return item.name == name
      })
      if (countryName) {
        setFieldsValue({
          stateName: undefined,
          cityName: undefined
        })
        this.setState({
          countryName: countryName.name ? countryName.name : '',
          stateName: '',
          cityName: '',
          allStates: countryName.id ? LoadState(countryName.id) : []
        })
      }
    },
    chooseState: (name) => {
      const { setFieldsValue } = this.props.form
      let stateName = _.find(this.state.allStates, (item) => {
        return item.name == name
      })
      setFieldsValue({
        cityName: undefined
      })
      if (stateName) {
        this.setState({
          stateName: stateName.name ? stateName.name : '',
          cityName: '',
          allCities: stateName.id ? LoadCity(stateName.id) : []
        })
      }
    },
    chooseCity: (name) => {
      this.setState({
        cityName: name ? name : ''
      })
    },
    assignUserFnx: (id) => {
      let user = _.find(this.state.usersForAgent, (item) => {
        return item._id === id
      })
      this.setState({
        createdByUserIdName: user.name,
        createdByUser: {
          name: user.name,
          userId: user._id,
          time: new Date()
        }
      })
    },
    assignAgentUser: (id) => {
      let user = _.find(this.state.usersForAgent, (item) => {
        return item._id === id
      })
      this.setState({
        createdByUserIdName: user.name,
        createdByUser: {
          name: user.name,
          userId: user._id,
          time: new Date()
        }
      })
    },
    assignManagerAgent: (id) => {
      let user = _.find(this.state.marketingManagerForAgent, (item) => {
        return item._id === id
      })
      this.setState({
        displayAgentManagers: user.name,
        selectedMarketingManager: id
      })
    }
  }

  agentEvents = {
    fetchAgent: async (name) => {
      let { dispatch } = this.props
      let obj = {
        results: 100,
        userType: 'agent',
        sortField: 'name',
        sortOrder: 'ascend',
        select: ['name', 'companyName'],
        name: name,
        block: false
      }
      let { data } = await dispatch(listAllUsers(obj))
      this.setState({
        agentList: data
      })
    }
    // removeAgent: (index) => {
    //   let cloneAgent = _.clone(this.state.agents)
    //   cloneAgent.splice(index, 1)
    //   this.setState({
    //     agents: cloneAgent
    //   })
    // },
    // chooseAgent: (data) => {
    //   let { agents, agentList } = this.state
    //   let findUser = _.find(agents, (item) => {
    //     return item._id && item._id.toString() == data
    //   })
    //   if (findUser) {
    //     this.setState({
    //       agentName: '',
    //       agentList: []
    //     })
    //     return notification.warning({
    //       message: 'Agent already exists.'
    //     })
    //   } else {
    //     let findAgent = _.find(agentList, (item) => {
    //       return item._id && item._id.toString() == data
    //     })
    //     if (findAgent) {
    //       let cloneAgents = _.clone(agents)
    //       cloneAgents.push(findAgent)
    //       this.setState({
    //         agentName: '',
    //         agentList: [],
    //         agents: cloneAgents
    //       })
    //     }
    //   }
    // }
  }
  conditionalFxn = {
    countryFieldFxn: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return (getFieldValue('userType') && ((getFieldValue('userType') == 'branchUser' && (getFieldValue('department') !== departmentObj.visaApproved && getFieldValue('department') !== departmentObj.warningDepartment) && getFieldValue('department')) || getFieldValue('userType') == 'branchManager'))
    },
    branchManagerFieldFxn: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return ((getFieldValue('userType') && getFieldValue('department')) && (getFieldValue('userType') == 'branchUser' && (getFieldValue('department') !== departmentObj.visaApproved && getFieldValue('department') !== departmentObj.warningDepartment)))
    },
    approvedAgentFieldFxn: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return (getFieldValue('userType') && (getFieldValue('userType') == 'admin' || getFieldValue('userType') == 'branchManager' || getFieldValue('userType') == 'branchUser'))
    },
    universitySelectFieldFxn: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return (getFieldValue('userType') &&
        ((getFieldValue('userType') == 'branchUser' && getFieldValue('department') && (getFieldValue('department').indexOf('Visa') < 0 && getFieldValue('department') !== departmentObj.warningDepartment)) || (getFieldValue('userType') == 'branchManager' && getFieldValue('branchManagerType') == 'universityWise'))
      )
    },
    universitySelectFieldFxnRequired: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return (getFieldValue('userType') &&
        ((getFieldValue('userType') == 'branchUser' && getFieldValue('department') && (getFieldValue('department').indexOf('Visa') < 0 && getFieldValue('department') !== departmentObj.warningDepartment)) || (getFieldValue('userType') == 'branchManager' && getFieldValue('branchManagerType') == 'universityWise'))
      )
    }

  }

  constructor(props) {
    super(props)
    this.state = {
      allBranch: [],
      universityList: [],
      userList: [],
      linkedUser: [],
      countryList: [],
      mainBranchArr: [],
      logo: {},
      uploadKey: moment(),
      oldLogo: {},
      oldContract: {},
      oldPanCard: {},
      oldPassport: {},
      oldExperienceLetter: {},
      oldHighestQualification: {},
      oldAadharCard: {},
      agentList: [],
      agents: [],
      userData: {},
      usersForAgent: [],
      marketingUsersForAgent: [],
      marketingManagerForAgent: [],
      displayAgentUsers: [],
      displayAgentManagers: [],
      createdByUserIdName: '',
      createdByUser: {},
      allCountries: LoadAllCountry(),
      allStates: [],
      allCities: [],
      selectedMarketingManager: [],
      selectedMarketingUser: [],
      countryCode: '',
      notes: '',
      departmentCountryId: ''
    }
  }

  componentDidMount() {
    this.loadCountry()
    this.getUserData()
    this.loadUserData()
    this.loadUserForEmployee()

    let { dispatch } = this.props
    dispatch({ type: 'USER_HIDELOADER' })
    dispatch(hideLoader())
    dispatch(hidePageLoad())
  }

  async loadUserData() {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    this.setState({ userData: user })
  }

  async loadUserForEmployee() {
    let { dispatch } = this.props
    let obj = {
      userType: ['branchManager', 'branchUser'],
      /* customQuery: {
         'approvedAgent': true
       },*/
      results: 100,
      select: ['name', 'email']
    }
    let { data } = await dispatch(listAllUsers(obj))
    this.setState({ userList: data })
  }


  handleSubmit = e => {
    const { dispatch, form, onClose, reloadTable } = this.props
    let { getFieldValue } = form
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let {
          address,
          notes,
          agents,
          logo,
          panCard,
          aadharCard,
          passport,
          experienceLetter,
          highestQualification,
          contract,
          createdByUser,
          selectedMarketingManager,
          selectedMarketingUser,
          countryCode,
          departmentCountryId
        } = this.state
        if (!countryCode) {
          notification.error({
            message: 'Please choose County Code'
          })
          return
        }
        if (valData.assignAgentType == 'selected' || valData.assignAgentType == 'exceptSelected') {
          if ((agents && agents.length == 0) || !agents) {
            notification.error({
              message: 'Please choose Agents'
            })
            return
          }
        }
        if (createdByUser && createdByUser.userId) {
          valData.createdByUser = createdByUser
        }

        valData.address = address
        valData.notes = notes

        valData.countryISOCode = countryCode
        let fd = new FormData()
        let selectedAgents = []
        if (getFieldValue('userType') == 'branchManager') {
          if (getFieldValue('branchManagerType') == 'agentWise') {
            valData.universities = []
            if (!agents || (agents && !agents.length)) {
              notification.warning({
                message: 'Choose Agents'
              })
              return
            }
          }
        }
        _.each(agents, (item) => {
          if (item && item._id) {
            selectedAgents.push(item._id)
          }
        })
        if (selectedAgents && selectedAgents.length) {
          valData.agents = selectedAgents
        } else {
          valData.agents = []
        }
        if (getFieldValue('branchManagerType') == 'universityWise') {
          valData.agents = []
        }
        if (selectedMarketingManager || selectedMarketingUser) {
          valData.selectedMarketingUser = selectedMarketingUser
          valData.selectedMarketingManager = selectedMarketingManager
        }

        if (departmentCountryId) {
          valData.departmentCountryId = departmentCountryId
        } else {
          valData.departmentCountryId = undefined
        }

        fd.append('obj', JSON.stringify(valData))

        if (logo && logo.name) {
          fd.append('logo', logo)
        }

        if (panCard && panCard.name) {
          fd.append('panCard', panCard)
        }

        if (aadharCard && aadharCard.name) {
          fd.append('aadharCard', aadharCard)
        }

        if (contract && contract.name) {
          fd.append('contract', contract)
        }
        if (passport && passport.name) {
          fd.append('passport', passport)
        }
        if (highestQualification && highestQualification.name) {
          fd.append('highestQualification', highestQualification)
        }

        if (experienceLetter && experienceLetter.name) {
          fd.append('experienceLetter', experienceLetter)
        }

        let data = await dispatch(updateEmployeeInfo(fd, this.state.userId))
        if (data && !data.error) {
          this.setState({
            address: '',
            notes: '',
            uploadKey: moment(),
            logo: {}
          })
          onClose()
          if (reloadTable) {
            reloadTable()
          }
        }
      }
    })
  }

  async loadCountry() {
    let { dispatch } = this.props
    let countryFilter = {
      results: 10,
      sortField: 'countryName',
      sortOrder: 'ascend',
      active: [true, false]
    }
    let { data } = await dispatch(listAllCountries(countryFilter))
    let otherData = [
      {
        _id: '',
        countryName: 'Forex'
      },
      {
        _id: '',
        countryName: 'Gic'
      },
      {
        _id: '',
        countryName: 'Unizhome'
      },
      {
        _id: '',
        countryName: 'Xtravel'
      },
      {
        _id: '',
        countryName: 'International'
      },
      {
        _id: '',
        countryName: 'Canada Private'
      },
      {
        _id: '',
        countryName: 'Other'
      }
    ]
    data.push(...otherData)
    this.setState({
      countryList: data
    })
  }


  async loadCountryUniversity(countryId) {
    let { dispatch } = this.props
    let { data } = await dispatch(listAllUniversities({
      results: 1000000, universityCountry: countryId,
      sortField: 'universityName',
      sortOrder: 'ascend'
    }))
    this.setState({
      universityList: data,
      name: '',
      userType: ''
    })
  }

  async getUserData() {
    let { pathname, dispatch, form: { setFieldsValue }, userData } = this.props
    if (userData && userData._id) {
      let resp = await dispatch(getSingleEmployee(userData._id))
      if (resp) {
        this.setState({
          userId: resp._id,
          name: resp.name,
          userType: resp.userType,
          designation: resp.designation,
          oldLogo: resp.logo,
          oldContract: resp.contract,
          oldAadharCard: resp.aadharCard,
          oldPanCard: resp.panCard,
          oldPassport: resp.passport,
          oldHighestQualification: resp.highestQualification,
          oldExperienceLetter: resp.experienceLetter,
          address: resp.address,
          notes: resp.notes,
          qualification: resp.qualification,
          agents: resp.agents,
          email: resp.email,
          linkedUser: resp.linkedUser,
          countryCode: resp.countryISOCode ? resp.countryISOCode : '',
          createdByUserIdName: resp.createdByUser ? resp.createdByUser.name : '',
          departmentCountryId: resp.departmentCountryId ? resp.departmentCountryId : ''
        })
        if (resp.userType == 'branchUser' && resp.countryId) {
          let { data } = await dispatch(listAllUsers({ userType: 'branchManager', countryId: resp.countryId }))
          this.setState({
            mainBranchArr: data
          })
        }
        if ((resp.userType == 'branchUser' || (resp.userType == 'branchManager' && resp.branchManagerType == 'universityWise')) && resp.countryId && resp.countryId._id) {
          this.loadCountryUniversity(resp.countryId._id)
        }

        if (resp.createdAt) {
          resp.createdAt = moment(resp.createdAt)
        }
        if (resp.dateOfBirth) {
          resp.dateOfBirth = moment(resp.dateOfBirth)
        }

        setFieldsValue({
          name: resp.name,
          mobile: resp.mobile,
          email: resp.email,
          userType: resp.userType,
          designation: resp.designation,
          address: resp.address,
          notes: resp.notes,
          departmentName: resp.departmentName
        })
        setTimeout(() => {
          setFieldsValue({
            companyName: resp.companyName,
            commissionType: resp.commissionType,
            department: resp.department,
            postName: resp.postName,
            allowAddingUser: resp.allowAddingUser,
            enableChanelPartner: resp.enableChanelPartner ? resp.enableChanelPartner : false,
            showAustraliaUniversity: resp.showAustraliaUniversity ? resp.showAustraliaUniversity : false,
            approvedAgent: resp.approvedAgent ? resp.approvedAgent : false,
            showCommission: resp.showCommission ? resp.showCommission : false,
            createdAt: resp.createdAt ? resp.createdAt : undefined,
            dateOfBirth: resp.dateOfBirth ? resp.dateOfBirth : undefined,
            managerViewOnly: resp.managerViewOnly ? resp.managerViewOnly : false,
            showAgent: resp.showAgent ? resp.showAgent : false,
            alternateEmails: resp.alternateEmails ? resp.alternateEmails : null,
            alternateMobileNo: resp.alternateMobileNo ? resp.alternateMobileNo : null,
            branchManagerType: resp.branchManagerType ? resp.branchManagerType : null,
            cityName: resp.cityName ? resp.cityName : undefined,
            pincode: resp.pincode ? resp.pincode : undefined,
            stateName: resp.stateName ? resp.stateName : undefined,
            countryName: resp.countryName ? resp.countryName : undefined,
            accountName: resp.accountName || undefined,
            accountNumber: resp.accountNumber || undefined,
            bankName: resp.bankName || undefined,
            IFSC: resp.IFSC || undefined,
            linkedUser: resp.linkedUser || [],
            notes: resp.notes || undefined,
            departmentCountryId: resp.departmentCountryId || undefined
          })
          setTimeout(() => {
            setFieldsValue({
              showAllUniversity: resp.showAllUniversity,
              countryId: resp.countryId ? resp.countryId._id : undefined,
              // countryId: resp.countryId && resp.countryId._id,
              branchMangerId: resp.branchMangerId,
              departmentName: resp.departmentName
            })
            setTimeout(() => {
              setFieldsValue({
                universities: resp.universities,
                assignAgentType: resp.assignAgentType ? resp.assignAgentType : 'all'
              })
            }, 200)
          }, 500)
        }, 500)
      }
    }
  }

  render() {

    const { submitting, dispatch, visible, onClose } = this.props
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue }
    } = this.props

    const {
      oldLogo,
      oldPanCard,
      oldPassport,
      oldHighestQualification,
      oldExperienceLetter,
      oldAadharCard,
      oldContract,
      agentName,
      agentList,
      agents,
      userData
    } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }

    let departmentArr = ['Forex', 'Gic', 'UnizHome', 'XTravel', 'International', 'Other']

    let inputTypes = {
      fields: [
        {
          label: 'Contact Person Name',
          key: 'name',
          required: true
        },
        {
          key: 'countryCode',
          span: 3,
          customField: (
            <div style={{ marginTop: 5 }}>
              <CountryCodeWithFlag countryCode={this.state.countryCode} chooseCode={(val) => {
                this.setState({
                  countryCode: val
                })
              }} />
            </div>
          )
        },
        {
          span: 5,
          label: 'Mobile no',
          key: 'mobile',
          type: 'number'
        },
        {
          label: 'Email',
          key: 'email',
          hidden: userData && (userData.userType === 'admin' || userData.userType === 'userManager') ? false : true
        },
        {
          label: 'Designation',
          key: 'designation'
        },
        {
          key: 'companyName',
          label: 'Company Name',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent')
        },
        {
          key: 'dateOfBirth',
          label: 'Date Of Birth',
          type: 'date'
          // onChange: x => {
          //   setFieldsValue({
          //     createdAt: x.target.value
          //   })
          // }
        },
        {
          key: 'createdAt',
          label: 'Joining Date',
          type: 'date'
          // onChange: x => {
          //   setFieldsValue({
          //     createdAt: x.target.value
          //   })
          // }
        },

        {
          key: 'stateName',
          label: 'State Name',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
          type: 'select',
          showSearch: true,
          options: this.state.allStates,
          keyAccessor: x => x.name,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            this.events.chooseState(x)
            setFieldsValue({
              stateName: x
            })
          }
        },

        {
          key: 'cityName',
          label: 'City Name',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
          type: 'select',
          showSearch: true,
          options: this.state.allCities,
          keyAccessor: x => x.name,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            setFieldsValue({
              cityName: x
            })
          }
        },
        {
          key: 'pincode',
          label: 'Pincode'
        },
        {
          key: 'linkedUser',
          label: '',
          label1: 'Link Users',
          type: 'select',
          showSearch: true,
          options: this.state.userList,
          mode: 'multiple',
          keyAccessor: x => x._id,
          valueAccessor: x => `${x.name}(${x.email})`,
          onChange: x => {
            setFieldsValue({
              linkedUser: x
            })
          }
        },
        {
          key: 'Profile Picture',
          customField: (
            <InputBox title={'Profile Picture'} className={'logoCls rowFlex'}>
              <Input type={'file'} className={'form-control'}
                     key={this.state.uploadKey} name={'logo'} id={'logo'}
                     onChange={(e) => {
                       this.setState({
                         logo: e.target.files && e.target.files.length ? e.target.files[0] : null
                       })
                     }} />
              {oldLogo && oldLogo.url ?
                <Tooltip title={oldLogo.name}> <a className={'linkBtn'} target={'_blank'} href={oldLogo.url}>
                  <Icon type={'eye'}></Icon>
                </a> </Tooltip> : ''}
            </InputBox>
          )
        },
        {
          key: 'Aadhar Card',
          customField: (
            <InputBox title={'Adhar Card'} className={'logoCls rowFlex'}>
              <Input type={'file'} className={'form-control'}
                     key={this.state.uploadKey} name={'aadharCard'} id={'aadharCard'}
                     onChange={(e) => {
                       this.setState({
                         aadharCard: e.target.files && e.target.files.length ? e.target.files[0] : null
                       })
                     }} />
              {oldAadharCard && oldAadharCard.url ?
                <Tooltip title={oldAadharCard.name}> <a className={'linkBtn'} target={'_blank'}
                                                        href={oldAadharCard.url}>
                  <Icon type={'eye'}></Icon>
                </a> </Tooltip> : ''}
            </InputBox>
          )
        },
        {
          key: 'Pan Card',
          customField: (
            <InputBox title={'Pan Card'} className={'logoCls rowFlex'}>
              <Input type={'file'} className={'form-control'}
                     key={this.state.uploadKey} name={'panCard'} id={'panCard'}
                     onChange={(e) => {
                       this.setState({
                         panCard: e.target.files && e.target.files.length ? e.target.files[0] : null
                       })
                     }} />
              {oldPanCard && oldPanCard.url ?
                <Tooltip title={oldPanCard.name}> <a className={'linkBtn'} target={'_blank'} href={oldPanCard.url}>
                  <Icon type={'eye'}></Icon>
                </a> </Tooltip> : ''}
            </InputBox>
          )
        },
        {
          key: 'Passport',
          customField: (
            <InputBox title={'Passport'} className={'logoCls rowFlex'}>
              <Input type={'file'} className={'form-control'}
                     key={this.state.uploadKey} name={'passport'} id={'passport'}
                     onChange={(e) => {
                       this.setState({
                         passport: e.target.files && e.target.files.length ? e.target.files[0] : null
                       })
                     }} />
              {oldPassport && oldPassport.url ?
                <Tooltip title={oldPassport.name}> <a className={'linkBtn'} target={'_blank'} href={oldPassport.url}>
                  <Icon type={'eye'}></Icon>
                </a> </Tooltip> : ''}
            </InputBox>
          )
        },
        {
          key: 'experienceLetter',
          customField: (
            <InputBox title={'Experience Letter'} className={'logoCls rowFlex'}>
              <Input type={'file'} className={'form-control'}
                     key={this.state.uploadKey} name={'experienceLetter'} id={'experienceLetter'}
                     onChange={(e) => {
                       this.setState({
                         experienceLetter: e.target.files && e.target.files.length ? e.target.files[0] : null
                       })
                     }} />
              {oldExperienceLetter && oldExperienceLetter.url ?
                <Tooltip title={oldExperienceLetter.name}> <a className={'linkBtn'} target={'_blank'}
                                                              href={oldExperienceLetter.url}>
                  <Icon type={'eye'}></Icon>
                </a> </Tooltip> : ''}
            </InputBox>
          )
        },
        {
          key: 'highestQualification',
          customField: (
            <InputBox title={'Highest Qualification'} className={'logoCls rowFlex'}>
              <Input type={'file'} className={'form-control'}
                     key={this.state.uploadKey} name={'highestQualification'} id={'highestQualification'}
                     onChange={(e) => {
                       this.setState({
                         highestQualification: e.target.files && e.target.files.length ? e.target.files[0] : null
                       })
                     }} />
              {oldHighestQualification && oldHighestQualification.url ?
                <Tooltip title={oldHighestQualification.name}><a className={'linkBtn'} target={'_blank'}
                                                                 href={oldHighestQualification.url}>
                  <Icon type={'eye'}></Icon>
                </a> </Tooltip> : ''}
            </InputBox>
          )
        },
        {
          key: 'Contract',
          customField: (
            <InputBox title={'Contract'} className={'logoCls rowFlex'}>
              <Input type={'file'} className={'form-control'}
                     key={this.state.uploadKey} name={'contract'} id={'contract'}
                     onChange={(e) => {
                       this.setState({
                         contract: e.target.files && e.target.files.length ? e.target.files[0] : null
                       })
                     }} />
              {oldContract && oldContract.url ?
                <Tooltip title={oldContract.name}> <a className={'linkBtn'} target={'_blank'} href={oldContract.url}>
                  <Icon type={'eye'}></Icon>
                </a> </Tooltip> : ''}
            </InputBox>
          )
        },
        {
          key: 'alternateMobileNo',
          label: (
            <span>
              Staff Member Mobile No <small style={{ color: 'black', fontWeight: 'bold' }}>( Note : Use comma for multiple mobiles no )</small>
            </span>
          ),
          placeholder: 'Staff Member Mobile No',
          span: 24,
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent')
        },
        {
          key: 'departmentName',
          label: '',
          label1: 'Department',
          type: 'select',
          showSearch: true,
          options: this.state.countryList,
          // mode: 'multiple',
          keyAccessor: x => x.countryName,
          valueAccessor: x => x.countryName,
          onChange: x => {
            setFieldsValue({
              departmentName: x
            })
            let countryData = this.state.countryList.find(country => country.countryName == x)
            if (countryData) {
              this.setState({
                departmentCountryId: countryData._id
              })
            } else {
              this.setState({
                departmentCountryId: undefined
              })
            }
          }
        },
        /*{
          key: 'CheckBoxes',
          span: 24,
          customField: (
            <InputBox title={'Department'} className={'logoCls rowFlex'}>
              <div className={'form-control'} style={{ backgroundColor: '#f1f1f1' }}>
                {departmentArr.map((department) => (
                  <Checkbox
                    key={department}
                    checked={this.state.selectedDepartment === department}  // Check if this department is selected
                    onChange={(e) => {
                      // If this checkbox is checked, set the selectedDepartment to this department, otherwise null
                      this.setState({ selectedDepartment: e.target.checked ? department : null })
                      setFieldsValue({
                        departmentCountry: ''
                      })
                    }}
                  >
                    {department}
                  </Checkbox>
                ))}
              </div>
            </InputBox>
          )
        },*/
        {
          key: 'notes',
          span: 24,
          customField: (
            <InputBox title={'Notes'}>
              <TextArea rows={4} className={'form-control'} value={this.state.notes} onChange={(e) => {
                this.setState({ notes: e.target.value })
              }}>
              </TextArea>
            </InputBox>
          )
        },
        {
          key: 'address',
          span: 24,
          customField: (
            <InputBox title={'Address'}>
              <TextArea rows={4} className={'form-control'} value={this.state.address} onChange={(e) => {
                this.setState({ address: e.target.value })
              }}>
              </TextArea>
            </InputBox>
          )
        }
      ]
    }


    return (
      <Drawer
        title={`Update Profile - ${this.state.name} (${this.state.userType})`}
        visible={visible}
        placement='right'
        closable={true}
        onClose={onClose}
        width={'80%'}>
        <div className='form-box'>
          <div className='card unizportal'>
            <Form onSubmit={this.handleSubmit}>
              <Row gutter={16} className={'wrapBox'}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      {item.customField ? !item.hidden && <Col span={item.span ? item.span : 8}>
                        {item.customField}
                      </Col> : !item.hidden &&
                        <Col span={item.span ? item.span : 8} key={key}
                             style={item.extraBox1 ? { paddingBottom: 1 } : {}}>
                          <InputBox title={item.label1 ? item.label1 : ''} extra={item.extraBox1}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />
                          </InputBox>
                        </Col>}
                    </React.Fragment>
                  )
                })}

                <Col span={24}>

                </Col>

                {/*{agents && agents.length ? <Col span={24}>*/}
                {/*  <Card title={'Agents'} size={'small'}>*/}
                {/*    <div className={'guestEmailBox'}>*/}
                {/*      <ul>*/}
                {/*        {agents.map((item, index) => {*/}
                {/*          return (*/}
                {/*            <li className={'emailTag'} key={index}>*/}
                {/*              {item.name} ({item.companyName})*/}
                {/*              <Icon type={'close'}*/}
                {/*                    onClick={() => this.agentEvents.removeAgent(index)}*/}
                {/*                    className={'emailIcon'} />*/}
                {/*            </li>*/}
                {/*          )*/}
                {/*        })}*/}
                {/*      </ul>*/}
                {/*    </div>*/}
                {/*  </Card>*/}
                {/*</Col> : null}*/}
              </Row>


              <Form.Item>
                <Button type='primary' htmlType='submit' loading={this.props.loading} className={'btn'}>
                  UPDATE
                </Button>
              </Form.Item>

            </Form>
          </div>
        </div>

      </Drawer>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  pathname: router.location.pathname,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUserExtraInfo)
