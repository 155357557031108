import React, { useEffect, useState } from 'react'
import { Tooltip, Icon, Popconfirm } from 'antd'
import { getEnquiryDocsFxn, deleteEnquiryDocFxn } from '../action'
import UploadEnquiryDocument from '../view/uploadDocumentDrawer'

const EnquiryDocumentComponent = (props) => {
  let { enquiryId, dispatch } = props
  const [documents, setDocuments] = useState([])
  const [isDocumentVisible, setIsDocumentVisible] = useState({
    visible: false,
    enquiryId: ''
  })
  useEffect(() => {
    events.getEnquiryDocs()
  }, [])
  let events = {
    showDocumentDrawer: async () => {
      setIsDocumentVisible({
        visible: true,
        enquiryId: enquiryId
      })
    },
    hideDocumentDrawer: async () => {
      setIsDocumentVisible({
        visible: false,
        enquiryId: ''
      })
      events.getEnquiryDocs()
    },
    getEnquiryDocs: async () => {
      let resp = await dispatch(getEnquiryDocsFxn({ enquiryId: enquiryId }))
      if (resp) {
        setDocuments(resp)
      }
    },
    handleDeleteDocument: async (documentId) => {
      let resp = await dispatch(deleteEnquiryDocFxn({ documentId: documentId }))
      if (resp.success) {
        events.getEnquiryDocs()
      }
    }
  }
  return (
    <div className={'document-box'}>
      <div className='attachment-box' style={{ paddingBottom: 200 }}>
        <div className='head d-flex align-items-center'>
          <h5>Attachments</h5>
          <button className='ml-auto' onClick={() => events.showDocumentDrawer()}>
            + add
          </button>
        </div>




            {documents.map((doc) => {

              const trimmedFileName = doc.fileName.length > 15 ? `${doc.fileName.slice(0, 15)}[...]` : doc.fileName
              return (
                  <div className='box'>
                    <h4>{doc.name}</h4>
                    <div className={'flexRow'}>
                <Tooltip key={doc._id} placement='topRight' title={doc.fileName}>

                  <a href={doc.path} download={doc.fileName}>
                    {trimmedFileName}
                  </a>
                  <Popconfirm
                      title="Are you sure you want to delete this document?"
                      onConfirm={() => events.handleDeleteDocument(doc._id)}
                      okText="Yes"
                      cancelText="No"
                  >
                    <a className={'cross'}>
                      <Icon type={'cross'} />
                    </a>
                  </Popconfirm>

                </Tooltip>
                      {/*<div className='upload' onClick={() => events.showDocumentDrawer()}>*/}
                      {/*  <img src='/dist/img/upload-box-1.png' alt='' />*/}


                      {/*</div>*/}
                    </div>
                  </div>

              )


            })}
          </div>


      {
        isDocumentVisible.visible ? (
          <UploadEnquiryDocument
            {...isDocumentVisible}
            onClose={() => events.hideDocumentDrawer()}

          />
        ) : null
      }
    </div>
  )
}
export default EnquiryDocumentComponent
