import React, { useEffect, useState } from 'react'
import { Modal, notification, Card, Button, Form, Row, Col, Input, Descriptions } from 'antd'
import { fixed2Digit, InputBox } from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { transactionStatusFxn } from '../actions'
import { RowTable } from '../../../components/_utils/RowTable'
import { stringToFixed } from '../reeudoUtils'

let { TextArea } = Input
const initState = {
  status: '',
  reason: '',
  depositByAgentAmount: '',
  agentBalance: 0
}

const statusList = [
  { name: 'Approved', value: 'Approved' },
  { name: 'Rejected', value: 'Rejected' }
]

const UpdateTransactionStatus = (props) => {
  const dispatch = useDispatch()
  const {
    form: { getFieldDecorator },
    visible,
    onClose,
    transaction,
    onSubmit
  } = props
  const [state, setState] = useState(initState)
  const [agentMargin, setAgentMargin] = useState(0)
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false)
  const events = {
    _updateState: (data) => {
      setState((prevState) => {
        const updatedState = { ...prevState, ...data }
        let { chargeComponent = {} } = transaction
        if (updatedState.depositByAgentAmount !== undefined) {
          updatedState.agentBalance = (updatedState.depositByAgentAmount - (chargeComponent.total || 0)) + (transaction.agentProfit ? parseFloat(transaction.agentProfit) : 0)
        }
        return updatedState
      })
    },
    updateAgentMargin: () => {
      let agentMargin = 0
      if (transaction.fxRate) {
        agentMargin = parseFloat(agentMargin) + parseFloat(transaction.fxRate)
      }
      if (transaction.exchangeMargin) {
        agentMargin = parseFloat(agentMargin) - parseFloat(transaction.exchangeMargin)
      }
      if (transaction.yourRate) {
        agentMargin = parseFloat(agentMargin) - parseFloat(transaction.yourRate)
      }
      setAgentMargin(agentMargin)
    },
    confirmOnSubmit: (e) => {
      e.preventDefault()
      if (!state.status) {
        notification.warning({ message: 'Please choose status.' })
        return
      }
      if (state.status === 'Rejected' && !state.reason) {
        notification.warning({ message: 'Enter a reason for rejection.' })
        return
      }
      if (!transaction.chargeComponent || (transaction.chargeComponent && !transaction.chargeComponent.total)) {
        notification.warning({ message: 'Payment not confirmed yet.' })
        return
      }
      setVisibleConfirmModal(true)
    },
    submitHandle: async () => {
      if (!state.status) {
        notification.warning({ message: 'Please choose status.' })
        return
      }
      if (state.status === 'Rejected' && !state.reason) {
        notification.warning({ message: 'Enter a reason for rejection.' })
        return
      }
      if (!transaction.chargeComponent || (transaction.chargeComponent && !transaction.chargeComponent.total)) {
        notification.warning({ message: 'Payment not confirmed yet.' })
        return
      }

      const resp = await dispatch(transactionStatusFxn({
        ...state,
        transactionId: transaction._id,
        depositByAgentAmount: state.depositByAgentAmount,
        agentBalance: state.agentBalance
      }))
      if (resp && resp.success) {
        notification.success({ message: resp.message })
        setVisibleConfirmModal(false)
        onSubmit()
      }
    }
  }

  useEffect(() => {
    events.updateAgentMargin()
  }, [transaction])

  return (
    <Modal
      title='Update Transaction Status'
      visible={visible}
      onCancel={onClose}
      footer={null}
      width='38%'
    >
      <>
        <div className={'tableBox striped'}>
          <RowTable title={'RAPP ID'} value={transaction.rappId} />
          <RowTable title={'Student Name'} value={<>
            {transaction.name} ({transaction.emailAddress})<br />
            Mob : {transaction.phoneNumber}
          </>} />
          <RowTable title={'Agent Name'} value={
            transaction.agentId && transaction.agentId.name ? <>
              {transaction.agentId.name} ({transaction.agentId.email})<br />
              Mob : {transaction.agentId.mobile}
            </> : null
          } />
          <RowTable title={'Booking Rate'} value={`${transaction.fxRate} ${transaction.currency}`} />
          <RowTable title={'Your Rate'}
                    value={`${stringToFixed(transaction.yourRate + transaction.exchangeMargin)} ${transaction.currency}`} />
          <RowTable title={'Agent Margin'} value={agentMargin ? fixed2Digit(agentMargin) : 0} />
          <RowTable title={'Amount'} value={`${transaction.amount} ${transaction.currency}`} />
          <RowTable title={'Amount  In INR'}
                    value={transaction.chargeComponent && transaction.chargeComponent.total ? fixed2Digit(transaction.chargeComponent.total) : 0} />
          <RowTable title={'Nostro Profit'} value={<>{transaction.nostroProfit} INR</>} />
          <RowTable title={'Bank Profit'} value={<>{transaction.bankProfit} INR</>} />
          <RowTable title={'Agent Profit'} value={<>{transaction.agentProfit} INR</>} />
          <RowTable title={'Uniz Profit'} value={<>{transaction.unizProfit} INR</>} />
          <RowTable title={'Total Profit'} value={<>{transaction.totalProfit} INR</>} />

        </div>

        <div>
          <Row gutter={24}>
            <Col span={24}>
              <InputBox title='Update Status'>
                <select
                  className='form-control'
                  onChange={({ target }) => {
                    events._updateState({ status: target.value, depositByAgentAmount: '' })
                  }}
                >
                  <option value=''>Choose Status</option>
                  {statusList.map((opt, index) => (
                    <option key={index} value={opt.value}>
                      {opt.name}
                    </option>
                  ))}
                </select>
              </InputBox>
            </Col>

            {state.status === 'Rejected' && (
              <Col span={24}>
                <InputBox title='Reason'>
                                   <textarea
                                     className='form-control'
                                     onChange={({ target }) => events._updateState({ reason: target.value })}
                                   />
                </InputBox>
              </Col>
            )}

            {state.status === 'Approved' && (
              <>
                <Col span={24}>
                  <InputBox title='Enter INR Amount deposited by agent'>
                    <Input
                      type='number'
                      className='form-control'
                      onChange={({ target }) => events._updateState({ depositByAgentAmount: parseFloat(target.value) })}
                    />
                  </InputBox>
                </Col>
                <Col span={24}>
                  <InputBox title='Agent Profit'>
                    <Input
                      type='number'
                      className='form-control'
                      value={state.agentBalance ? fixed2Digit(state.agentBalance) : ''}
                      readOnly
                    />
                  </InputBox>
                </Col>
              </>
            )}

            {transaction.chargeComponent && transaction.chargeComponent.total ?
              <Col span={24}>
                <div className={'mt10 alignRight'}>
                  <a onClick={onClose} className={'btn btn-default roundNew mr10'}>
                    Close
                  </a>
                  <a className={'btn btn-primary-dark roundNew'} onClick={events.confirmOnSubmit}>
                    Submit
                  </a>
                </div>
              </Col> :
              <Col span={24} className={'red alignCenter mt20 mb20'}>
                <h6>
                  Payment not confirmed yet
                </h6>
              </Col>}
          </Row>


          {visibleConfirmModal ?
            <Modal width={'35%'}
                   title={'Confirm and Submit Transaction'}
                   visible={visibleConfirmModal}
                   footer={false}
                   onCancel={() => setVisibleConfirmModal(false)}>
              <table className={'table table-bordered table-striped'}>
                <tr>
                  <td className={'w-50'}>Status</td>
                  <td>{state.status}</td>
                </tr>
                <tr>
                  <td>Amount In INR <small>(Amount to be Deposit)</small></td>
                  <td>{transaction.chargeComponent && transaction.chargeComponent.total ? fixed2Digit(transaction.chargeComponent.total) : 0} INR</td>
                </tr>

                {state.status == 'Rejected' ?
                  <tr>
                    <td>Reason</td>
                    <td>{state.reason}</td>
                  </tr> : null}
                {state.status == 'Approved' ?
                  <>
                    <tr>
                      <td>INR Amount deposited by agent</td>
                      <td>{state.depositByAgentAmount ? fixed2Digit(state.depositByAgentAmount) : 0} INR</td>
                    </tr>
                    <tr>
                      <td>Agent Profit</td>
                      <td>{state.agentBalance ? fixed2Digit(state.agentBalance) : 0} INR</td>
                    </tr>
                  </>
                  : null}
              </table>

              <div>
                <div className={'mt10 alignRight'}>
                  <a onClick={() => setVisibleConfirmModal(false)} className={'btn btn-default roundNew mr10'}>
                    Cancel
                  </a>
                  <a className={'btn btn-primary-dark roundNew'} onClick={events.submitHandle}>
                    Confirm & Submit
                  </a>
                </div>
              </div>

            </Modal> : null}

        </div>
      </>
    </Modal>
  )
}

export default Form.create()(UpdateTransactionStatus)
