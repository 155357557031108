import React, { useState, useEffect } from 'react'
import { Drawer, Form, Button, notification, Card, Row, Col } from 'antd'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { useDispatch, useSelector } from 'react-redux'
import { getCountry, updateCountry } from '../actions/countries'
import { createMatchSelector, goBack } from 'connected-react-router'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { DocumentTypes, FileInput } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'

const Edit = (props) => {
  const dispatch = useDispatch()
  let { visible, setVisible } = props
  const [country, setCountry] = useState({})
  const [countryId, setCountryId] = useState(null)
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props
  const [state, setState] = useState({
    flag: '',
    file: ''
  })

  let inputTypes = {
    fields: [
      { key: 'countryName', label: 'Country Name', type: 'text', required: true },
      { key: 'enrollmentBasic', label: 'Enrollment Basic' },
      { key: 'enrollmentProcedure', label: 'Enrollment Procedure' },
      { key: 'fundAcceptable', label: 'Fund Acceptable' },
      { key: 'fundSponsors', label: 'Fund Sponsors' },
      { key: 'fundEducation', label: 'Fund Education' },
      { key: 'fundVisaCheckList', label: 'Fund visa checkList' },
      { key: 'visaCategory', label: 'Visa Category' },
      {
        label: 'Interview',
        key: 'interview',
        type: 'select',
        options: ['Yes', 'No', 'Maybe'],
        onChange: x => {
          props.form.setFieldsValue({
            interview: x
          })
        }
      },
      { key: 'visaFillingBy', label: 'Visa Filling By' }
    ]
  }

  let rightInputTypes = {
    fields: [
      { key: 'currency', label: 'Currency', required: true },
      { key: 'tutionFee', label: 'Tution Fee', type: 'number' },
      { key: 'singleOHSC', label: 'Single OHSC' },
      { key: 'dualOHSC', label: 'Dual OHSC' },
      { key: 'visaFeeMainApplicant', label: 'Visa fee for main applicant', type: 'number' },
      { key: 'visaFeeSpouse', label: 'Visa fee for spouse', type: 'number' },
      { key: 'visaFeeChild', label: 'Visa fee for child', type: 'number' },
      { key: 'livingCostMainApplicant', label: 'Living cost for main applicant' },
      { key: 'livingCostSpouse', label: 'Living cost for spouse' },
      {
        label: 'Required Document',
        key: 'documents',
        type: 'select',
        mode: 'multiple',
        options: DocumentTypes,
        onChange: x => {
          props.form.setFieldsValue({
            documents: x
          })
        }
      }
    ]
  }

  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = async (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let fd = new FormData()
        fd.append('obj', JSON.stringify(valData))
        fd.append('flag', state.flag)
        await dispatch(updateCountry(fd, countryId))

        getCountryData()
        // form.resetFields()

        dispatch(goBack())
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  useEffect(() => {
    dispatch({ type: 'COUNTRY_HIDELOADER' })
    getCountryData()
  }, [])

  const getCountryData = async () => {
    let searchParams = new URLSearchParams(window.location.search)
    let countryId = searchParams.get('countryId')
    setCountryId(() => countryId)
    if (countryId) {
      let data = await dispatch(getCountry(countryId))
      if (data) {
        setCountry(() => data)
      }
    }
  }

  const events = {
    chooseDocument: (e) => {
      let { name, files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          'flag': files[0]
        })
      }
    }
  }

  let fieldArr = [
    { key: 'countryName', label: 'Country Name', type: 'text', required: true },
    { key: 'currency', label: 'Currency', required: true },
    { key: 'livingCostMainApplicant', label: 'Living cost' },
    {
      key: 'flag', label: 'Flag', name: 'flag', type: 'file', onChange: (e) => {
        events.chooseDocument(e)
      }
    },
    {
      label: 'Interview',
      key: 'interview',
      type: 'select',
      options: ['Yes', 'No', 'Maybe'],
      onChange: x => {
        props.form.setFieldsValue({
          interview: x
        })
      }
    },
    {
      label: 'Required Document',
      key: 'documents',
      type: 'select',
      mode: 'multiple',
      options: DocumentTypes,
      onChange: x => {
        props.form.setFieldsValue({
          documents: x
        })
      }
    },
    { key: 'currencyInrRate', label: 'Currency Rate', type: 'number' }
  ]


  useEffect(() => {
    props.form.setFieldsValue({
      countryName: `${country.countryName || ''}`,
      currency: `${country.currency || ''}`,
      interview: `${country.interview || ''}`,
      /*enrollmentBasic: `${country.enrollmentBasic || ''}`,
      enrollmentProcedure: `${country.enrollmentProcedure || ''}`,
      fundAcceptable: `${country.fundAcceptable || ''}`,
      fundSponsors: `${country.fundSponsors || ''}`,
      fundEducation: `${country.fundEducation || ''}`,
      fundVisaCheckList: `${country.fundVisaCheckList || ''}`,
      visaCategory: `${country.visaCategory || ''}`,
      ,
      visaFillingBy: `${country.visaFillingBy || ''}`,
      tutionFee: `${country.tutionFee || ''}`,
      singleOHSC: `${country.singleOHSC || ''}`,
      dualOHSC: `${country.dualOHSC || ''}`,
      visaFeeMainApplicant: `${country.visaFeeMainApplicant || ''}`,*/
      livingCostMainApplicant: `${country.livingCostMainApplicant || ''}`,
      flag: `${country.flag || {}}`,
      documents: country.documents,
      currencyInrRate: country.currencyInrRate
      /*  visaFeeSpouse: `${country.visaFeeSpouse || ''}`,
        visaFeeChild: `${country.visaFeeChild || ''}`,
        livingCostSpouse: `${country.livingCostSpouse || ''}`,
        documents: country.documents*/

    })
    setState({
      ...state,
      file: country.flag ? country.flag : {}
    })

  }, [country])


  return (
    <div className='row'>
      <div className='col-lg-12'>
        <Form onSubmit={handleSubmit}>
          <div className='form-box mt-4'>

            <div className='d-flex align-items-center mb-3 heading-form'>
              <h5>Add New Country</h5>
            </div>

            <div className='card unizportal'>
              <Row gutter={24}>
                {fieldArr.map((item, key) => {
                  return (
                    <Col span={8} key={key}>
                      {item.type == 'file' ?
                        <FileInput name={item.name}
                                   key={state.flag}
                                   label={item.label}
                                   chooseDocument={item.onChange} />
                        : <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout} />
                      }
                    </Col>
                  )
                })}
              </Row>
              <Form.Item>
                <Button type='primary' htmlType='submit' className='btn'>
                  SAVE
                </Button>
              </Form.Item>

            </div>
          </div>

        </Form>
      </div>
    </div>
  )
}

const EditCountry = Form.create()(Edit)
export default EditCountry
