import React from "react";
import { Modal, Row, Col, Tabs, Tooltip, Table, Popconfirm, notification } from "antd";
import {  updateSinglePropertyFxn } from "../action";
import {useDispatch} from "react-redux";

const { TabPane } = Tabs;

const PropertyDetailsModal = (props) => {
    let { visible, onClose, singleProperty, activeChildren, enquiry } = props;
    const dispatch = useDispatch()

    const capitalizeUnitType = (unitType) => {
        return unitType
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const handleUpdateClick = async (record) => {
        const payload = {
            enquiryId: enquiry._id,
            roomType: record.roomType,
            leaseDuration: record.duration,
            moveInDate: record.moveIn,
            moveOutDate: record.moveOut,
            price: record.rent,
        };

        try {
            const resp = await dispatch(updateSinglePropertyFxn(payload));
            if (resp && resp.success) {
                notification.success({message: resp.message})
                onClose();

            }
        } catch (error) {

        }
    };


    const columns = [
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
        },
        {
            title: 'Move-In',
            dataIndex: 'moveIn',
            key: 'moveIn',
        },
        {
            title: 'Move-Out',
            dataIndex: 'moveOut',
            key: 'moveOut',
        },
        {
            title: 'Rent',
            dataIndex: 'rent',
            key: 'rent',
            width: 130,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record, index) => {

                return (
                    <Popconfirm
                        title="Are you sure you want to update this property?"
                        onConfirm={() => handleUpdateClick(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Tooltip title="Select Room">
                            <label className="label label-success" style={{ cursor: "pointer" }}>
                                Select Room
                            </label>
                        </Tooltip>
                    </Popconfirm>
                );
            },
        },
    ];

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={`Property Details`}
            width={"55%"}
            footer={null}
        >
            <div className="mt-1 single-pro-data">
                {singleProperty.meta && singleProperty.meta.unit_types && singleProperty.meta.unit_types.length > 0 && (
                    <div>
                        <Row>
                            <h5><b>Choose Your Room</b></h5>
                        </Row>
                        <Tabs defaultActiveKey="1">
                            {singleProperty.meta.unit_types.map((unitType, index) => {
                                const filteredChildren = activeChildren.filter(
                                    (child) => child.meta && child.meta.unit_type === unitType
                                );

                                if (filteredChildren.length === 0) return null;

                                return (
                                    <TabPane tab={capitalizeUnitType(unitType)} key={index + 1}>
                                        <Row span={24}>
                                            <Col span={24}>
                                                {filteredChildren.map((child, childIndex) => {

                                                    const tableData = child.active_children
                                                        .filter((activeChild) => activeChild.available)
                                                        .map((activeChild, idx) => ({
                                                            key: idx,
                                                            duration: `${activeChild.meta.lease_duration} weeks`,
                                                            moveIn: activeChild.meta.available_from_formatted || 'Flexible',
                                                            moveOut: activeChild.meta.available_to_formatted,
                                                            rent: `${activeChild.weekly_price}`,
                                                            roomType: `${activeChild.name}`
                                                        }));


                                                    return (
                                                        <div key={childIndex} className={'mt-3'}>
                                                            <Row>
                                                                <Col span={7}>
                                                                    <img
                                                                        src={child.images && child.images[0] ? child.images[0].path : ''}
                                                                        alt={"img"}
                                                                        style={{ maxWidth: "100%" }}
                                                                    />
                                                                </Col>
                                                                <Col span={10} className={"property-location"}>
                                                                    <h6><b>{child.name}</b></h6>
                                                                    <p>{child.pricing.price}/{child.pricing.duration}</p>
                                                                </Col>
                                                            </Row>
                                                            <Row className={'mt-3'}>
                                                                <Col span={24}>
                                                                    {tableData.length > 0 ? (
                                                                        <Table
                                                                            dataSource={tableData}
                                                                            columns={columns}
                                                                            pagination={false}
                                                                        />
                                                                    ) : (
                                                                       <Table
                                                                       columns={columns}
                                                                       />
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    );
                                                })}
                                            </Col>
                                        </Row>
                                    </TabPane>
                                );
                            })}
                        </Tabs>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default PropertyDetailsModal;
