import { hidePageLoad, showPageLoad } from '../../modules/actions'
import { customAxios as axios, getToken } from '../../request'
import { notification } from 'antd'
import { addCommentUrl, addToDoUrl, commentListUrl, toDoListUrl, updateToDoTaskUrl } from './api'
import { instituteStudentListUrl } from '../institutes/api'
import { updateTaskUrl } from '../task/apis/taskManager'

export const addToDo = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addToDoUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const addCommentFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addCommentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const toDoListFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  let { data } = await axios.get(toDoListUrl(), config)
  dispatch(hidePageLoad())
  return data
}


export const commentListFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(commentListUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const updateToDoTask = (id, { status, priority, region }) => async (dispatch) => {
  try {
    const requestData = { taskId: id, status, priority, region }
    const response = await axios.post(updateToDoTaskUrl(), requestData, getToken())
    if (!response.data.error) {
      notification.success({
        message: response.data.message || 'Success'
      })
    } else {
      notification.error({
        message: response.data.message || 'Error'
      })
    }
    return response.data
  } catch (error) {
    console.error('Error updating task:', error)
    throw error
  }
}
