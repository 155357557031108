import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Col, Row, Modal, notification } from "antd";
import _ from "lodash";
import GetEachFormFields from "../../../components/_utils/appFormUtils";

const AddPropertyRoom = (props) => {
    let { visible, onClose, form: { getFieldDecorator, setFieldsValue }, propertyId} = props;
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();

    const inputFields = {
        fields: [
            { key: "id", label: "Room ID", required: true, placeholder: "Enter ID", type: "text" },
            { key: "source", label: "Source", required: true, placeholder: "Enter Source", type: "text" },
            { key: "source_id", label: "Source ID", required: true, placeholder: "Enter Source ID", type: "text" },
            { key: "name", label: "Name", required: true, placeholder: "Enter Name", type: "text" },
            {
                key: "pricing.price",
                label: "Price",
                required: true,
                placeholder: "Enter Price",
                type: "number",
            },
            {
                key: "pricing.deposit",
                label: "Deposit",
                required: true,
                placeholder: "Enter Deposit",
                type: "number",
            },
            {
                key: "pricing.currency",
                label: "Currency",
                required: true,
                placeholder: "Select Currency",
                type: "select",
                options: [],
            },
            {
                key: "owner.emails[0].type",
                label: "Owner Email",
                required: true,
                placeholder: "Enter Email",
                type: "email",
            },
            {
                key: "owner.phones[0].type",
                label: "Owner Phone",
                required: true,
                placeholder: "Enter Phone",
                type: "text",
            },
            {
                key: "meta.floor",
                label: "Floor",
                placeholder: "Enter Floor",
                type: "text",
            },
            {
                key: "meta.area_unit",
                label: "Area Unit",
                placeholder: "Enter Area Unit",
                type: "text",
            },
            {
                key: "meta.available_from",
                label: "Available From",
                placeholder: "Enter Date",
                type: "date",
            },
        ],
    };


    const handleSubmit = (e) => {
        e.preventDefault();

    };

    return (
        <Modal
            visible={visible}
            title="Add New Room"
            onCancel={onClose}
            footer={null}
            width="50%"
        >
            <Form onSubmit={handleSubmit}>
                <Row gutter={24}>
                    {inputFields.fields.map((field, idx) => (
                        <Col key={idx} span={12}>
                            <GetEachFormFields
                                item={field}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={{ labelCol: { xs: { span: 0 }, sm: { span: 0 } }, wrapperCol: { xs: { span: 24 } } }}

                            />
                        </Col>
                    ))}
                </Row>
                <div style={{ textAlign: "right", marginTop: "20px" }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <Button style={{ marginLeft: "10px" }} onClick={onClose}>
                        Cancel
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default Form.create()(AddPropertyRoom);
