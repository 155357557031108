import { hidePageLoad, showPageLoad } from '../../modules/actions'
import { customAxios as axios, getToken } from '../../request'
import { counsellorBonusListUrl } from './api'

export const allCounsellorBonusList = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...getToken()
  }
  let { data } = await axios.get(counsellorBonusListUrl(), config)
  dispatch(hidePageLoad())
  return data
}
