import React, { useState, useEffect } from "react";
import { Modal, Checkbox, Form, Button, Row, Col, Collapse } from "antd";
import { commonAmenitiesFxn } from "../action";
import { useDispatch } from "react-redux";

const { Panel } = Collapse;

const AmenitiesModal = (props) => {
    const { visible, onClose, onSubmit, amenities } = props;
    const [checkedItems, setCheckedItems] = useState({});
    const [AmenitiesType, setAmenitiesType] = useState([]);
    const dispatch = useDispatch();

    const handleCheckboxChange = (category, value) => {
        setCheckedItems((prevState) => {
            const categoryItems = prevState[category] || {};
            return {
                ...prevState,
                [category]: {
                    ...categoryItems,
                    [value]: !categoryItems[value],
                },
            };
        });
    };

    const getAmenities = async () => {
        let resp = await dispatch(commonAmenitiesFxn());
        setAmenitiesType(resp.data[0].features);
    };

    useEffect(() => {
        if (amenities && amenities.length > 0) {
            const initialCheckedItems = {};
            amenities.forEach((category) => {
                initialCheckedItems[category.type] = {};
                category.values.forEach((item) => {
                    AmenitiesType.forEach((categoryItem) => {
                        categoryItem.values.forEach((value) => {
                            if (value.name === item.name) {
                                initialCheckedItems[category.type] = {
                                    ...initialCheckedItems[category.type],
                                    [value.type]: true,
                                };
                            }
                        });
                    });
                });
            });
            setCheckedItems(initialCheckedItems);
        }
    }, [amenities, AmenitiesType]);

    useEffect(() => {
        getAmenities();
    }, []);

    const handleSubmit = () => {
        console.log("Selected Amenities:", checkedItems);
        onSubmit(checkedItems);
        onClose();
    };

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title="Amenities"
            width={"65%"}
            footer={[
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Submit
                </Button>,
            ]}
        >
            <Form layout="vertical">
                <Collapse accordion>
                    {AmenitiesType.map((category) => (
                        <Panel header={category.name} key={category.type}>
                            <Row gutter={[16, 16]}>
                                {category.values.map((item) => (
                                    <Col span={8} key={item.type}>
                                        <Checkbox
                                            checked={
                                                (checkedItems[category.type] &&
                                                    checkedItems[category.type][item.type]) ||
                                                false
                                            }
                                            onChange={() => handleCheckboxChange(category.type, item.type)}
                                        >
                                            {item.name}
                                        </Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        </Panel>
                    ))}
                </Collapse>
            </Form>
        </Modal>
    );
};

export default AmenitiesModal;
