import { apiUrl2 } from '../../settings'

export const addToDoUrl = () => {
  return apiUrl2 + '/toDoList/add-todo'
}
export const addCommentUrl = () => {
  return apiUrl2 + '/toDoList/add-comment'
}

export const toDoListUrl = () => {
  return apiUrl2 + '/toDoList/list'
}
export const commentListUrl = () => {
  return apiUrl2 + '/toDoList/comment-list'
}
export const updateToDoTaskUrl = () => {
  return apiUrl2 + '/toDoList/update'
}
