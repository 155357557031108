import React, { useEffect, useState, useRef } from "react";
import {
    Button,
    Col,
    Form,
    notification,
    Row,
    Select,
    Input,
    Tabs,
    Icon,
    Upload,
    Table,
    Tooltip,
    Checkbox,
    Popconfirm,
    Pagination
} from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import {
    unizhomePropertyFxn,
    updateUnizhomePropertyFxn,
    deleteUnizhomeFaqFxn,
    deleteUnizhomePolicyFxn,
    deleteUnizhomeRoomFxn
} from "../action";
import {FileInput} from "../../../components/_utils/appUtils";
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {CountryCodeJson as countryCodes} from "../../../components/_utils/countryUtil";
import _ from 'lodash'
import EditPropertyFaqModal from "../modal/editPropertyFaq";
import AmenitiesModal from "./addPropertyAmenities";
import EditPropertyImage from "../modal/editPropertyImage";
import {getPushPathWrapper} from "../../../routes";
import AddPropertyFaqModal from "../modal/addPropertyFaq";
import EditPaymentPolicyModal from "../modal/editPaymentPolicy";
import '../style.css'
import EditPropertyRoomModal from "../modal/editRoomTypes";
import AddPropertyRoom from "../modal/addPropertyRoom";

const { Option } = Select;
const { TabPane } = Tabs;

const EditPropertyModal = (props) => {
    let dispatch = useDispatch();
    let tableRef = useRef()
    let history = useRef()
    let { form: { getFieldDecorator, setFieldsValue } } = props;
    const [activeTab, setActiveTab] = useState("basicDetails");
    const [faqs, setFaqs] = useState([]);
    const [features, setFeatures] = useState([]);
    const [designatedCountry, setDesignatedCountry] = useState([]);
    const [paymentPolicies, setPaymentPolicies] = useState({ applicable: [], notApplicable: [] });
    const [roomTypes, setRoomTypes] = useState([])
    const [images, setImages] = useState([])
    const [videos, setVideos] = useState([])
    const [meta, setMeta] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [openFaq, setOpenFaq] = useState({
        visible: false,
        faq: {},
        propertyId: ''
    })
    const [openPolicy, setOpenPolicy] = useState({
        visible: false,
        policy: {},
        propertyId: ''
    })
    const [faqVisible, setFaqVisible] = useState({
        visible: false,
        propertyId: ''
    })
    const [imagesVisible, setImagesVisible] = useState({
        visible: false,
        imageData: {},
        propertyId: ''
    })
    const [openAmenities, setOpenAmenities] = useState({
        visible: false,
        amenities: {},
        propertyId: ''
    })
    const [editRoomVisible, setEditRoomVisible] = useState({
        visible: false,
        room : {},
        propertyId: ''

    })
    const [addRoomVisible, setAddRoomVisible] = useState({
        visible: false,
        propertyId: ''

    })
    const [propertyData, setPropertyData] = useState([])
    const [ownerDetails, setOwnerDetails] = useState([]);
    const [activeRoomTab, setActiveRoomTab] = useState( "");
    const searchParams = new URLSearchParams(window.location.search);

    const propertyId = searchParams.get('propertyId');
    console.log(propertyId, "id------")
    const apiRequest = async () => {
        let resp = await dispatch(unizhomePropertyFxn({propertyId: propertyId}));
        if (resp) {
            const { faqs, location, features, description, pricing, owner, children , images, videos, meta} = resp;
            console.log(children, "--------11")
            setFaqs(faqs);
            setFeatures(features);
            setRoomTypes(children)
            setImages(images)
            setVideos(videos)
            setOwnerDetails(owner);
            setMeta(meta)
            console.log(meta,  "--------")
            if (description) {
                const applicablePolicies = description.filter(
                    (policy) => policy.policy_applicable
                );
                const notApplicablePolicies = description.filter(
                    (policy) => !policy.policy_applicable
                );

                setPaymentPolicies({
                    applicable: applicablePolicies,
                    notApplicable: notApplicablePolicies,
                });
            }
            setFieldsValue({
                name: resp.name,
                country: location && location.country && location.country.long_name,
                countryShort: location && location.country && location.country.short_name,
                state: location && location.state && location.state.long_name,
                route: location && location.route && location.route.long_name,
                district: location && location.district && location.district.long_name,
                locality: location && location.locality && location.locality.long_name,
                postalCode: location && location.postal_code && location.postal_code.long_name,
                streetNumber: location && location.street_number && location.street_number.long_name,
                // ownerEmails: owner && owner.emails,
                // ownerPhones: owner && owner.phones,
                currency: pricing && pricing.currency,
                duration: pricing && pricing.duration,
                min_price: pricing && pricing.min_price,
                max_price: pricing && pricing.max_price,
                refundable_token: pricing && pricing.refundable_token,
                max_available_price: pricing && pricing.max_available_price,
                min_available_price : pricing && location.min_available_price,

        });
        }
    };

    const paginatedRoomTypes = roomTypes.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const loadCountry = () => {
        const designatedArr = countryCodes.map((item) => ({
            label: item.name,
            value: item.name,
            short_name: item.code
        }));
        setDesignatedCountry(designatedArr);
    };

    useEffect(() => {
        apiRequest()
        loadCountry()
    }, [])

    const events = {
        showFaqModal: async (faq) => {

            setOpenFaq({
                visible: true,
                faq: faq,
                propertyId: propertyId
            })

        },
        hideFaqModal: async () => {
            setOpenFaq({
                visible: false,
                faq: {},
                propertyId: ''
            })
           apiRequest()
        },
        showAddFaqModal: async () => {
            setFaqVisible({
                visible: true,
                propertyId: propertyId
            })

        },
        hideAddFaqModal: async () => {
            setFaqVisible({
                visible: false,
                propertyId: ''
            })
            apiRequest()
        },
        showImagesModal: async (imageData) => {
            setImagesVisible({
                visible: true,
                imageData: imageData,
                propertyId: propertyId
            })

        },
        hideImagesModal: async () => {
            setImagesVisible({
                visible: false,
                imageData: {},
                propertyId: ''
            })
            apiRequest()
        },
        showAmenitiesModal: async (amenities) => {
            setOpenAmenities({
                visible: true,
                amenities: amenities,
                propertyId: propertyId
            })

        },
        hideAmenitiesModal: async () => {
            setOpenAmenities({
                visible: false,
                amenities: {},
                propertyId: ''
            })
            apiRequest()
        },
        showPolicyModal: async (policy) => {
            setOpenPolicy({
                visible: true,
                policy: policy,
                propertyId: propertyId
            })

        },
        hidePolicyModal: async () => {
            setOpenPolicy({
                visible: false,
                policy: {},
                propertyId: ''
            })
            apiRequest()
        },
        showRoomModal: async (room) => {
            setEditRoomVisible({
                visible: true,
                room: room,
                propertyId: propertyId
            })
        },
        hideRoomModal: async () => {
            setEditRoomVisible({
                visible: false,
                room: {},
                propertyId: ''
            })
            apiRequest()
        },
        showAddRoomModal: async () => {
            setAddRoomVisible({
                visible: true,
                propertyId: propertyId
            })
        },
        hideAddRoomModal: async () => {
            setAddRoomVisible({
                visible: false,
                propertyId: ''
            })
            apiRequest()
        },
    }
    const tabsConfig = {
        basicDetails: [
            { key: "name", label: "Name", type: "text" },
            {
                key: 'country',
                label: 'Country',
                placeholder: 'Select Country',
                required: true,
                showSearch: true,
                type: 'select',
                options: designatedCountry,
                keyAccessor: (x) => `${x.short_name}`,
                valueAccessor: (x) => `${x.value}`,
                onChange: (value, option) => {
                    console.log(value, option)
                    console.log(option.props, "Selected Option");
                    setFieldsValue({
                        country: option.props.children,
                        countryShort: option.props.value,
                    });
                }
            },


            { key: "state", label: "State", type: "text" },
            { key: "route", label: "Route", type: "text" },
            { key: "district", label: "District", type: "text" },
            { key: "locality", label: "Locality", type: "text" },
            { key: "streetNumber", label: "Street Number", type: "text" },
            { key: "postalCode", label: "Postal Code", type: "text" },
            { key: "currency", label: "Currency", type: "text"},
            { key: "duration", label: "Duration", type: "text"},
            { key: "min_price", label: "Minimum Price", type: "number"},
            { key: "max_price", label: "Maximum Price", type: "number"},
            { key: "refundable_token", label: "Refundable Token", type: "number"},
            { key: "max_available_price", label: "Maximum Available Price", type: "number"},
            { key: "min_available_price", label: "Minimum Available Price", type: "number"}

        ],
        amenities: [
            ...features.map(feature => ({
                key: feature._id,
                label: (
                    <div>
                        <span>{feature.name}</span>
                    </div>
                ),
                type: 'checkboxGroup',
                options: feature.values.map(value => ({
                    label: value.name,
                    value: value._id,
                }))
            }))
        ],
        media: [

        ],
        faqs: [

        ],
        paymentPolicy: [

        ],
        roomTypes: []
    };

    const deleteFaq = async (faqId) => {
       let resp = await dispatch(deleteUnizhomeFaqFxn({propertyId: propertyId, faqId: faqId}))
        if(resp.success) {
            notification.success({message: resp.message})
            apiRequest()
        } else  {
            notification.error({message: resp.message})
        }
    }
    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (val, record, index) => {
                return index + 1
            }
        },
        {
            title: 'Question',
            dataIndex: 'question',
            key: 'question',
            width: 330
        },
        {
            title: 'Answer',
            dataIndex: 'answer',
            key: 'answer',
            width: 740,
            render: (text) => <div dangerouslySetInnerHTML={{ __html: text }} />
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Tooltip title='Edit Faq'>
                        <a
                            className={'btn'}
                            onClick={() => events.showFaqModal(record)}
                        >
                            <Icon type={'edit'} />
                        </a>
                    </Tooltip>
                    <Tooltip title='Delete Faq'>
                        <Popconfirm title={'Are you sure, you want to delete this query?'}
                                    onConfirm={() => deleteFaq(record._id)}
                        >
                            <a
                                className={'btn'}
                            >
                                <Icon type={'delete'} />
                            </a>
                        </Popconfirm>

                    </Tooltip>

                </>

            )
        }
    ];

    const roomColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (val, record, index) => {
                return index + 1
            }
        },
        {
            title: 'Room',
            dataIndex: 'inventory_featured_image_path',
            key: 'inventory_featured_image_path',
            searchTextName: 'propertyName',
            render: (val, record) => {
                const firstImagePath = record.images && record.images.length > 0 ? record.images[0].path : '';
                return (
                    <p>
                        <img src={firstImagePath} height='90px' width='100px' />
                    </p>
                )
            }
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            searchTextName: 'id'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            searchTextName: 'name'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            searchTextName: 'status'
        },
        {
            title: 'Action',
            key: '_id',
            dataIndex: '_id',
            width: 150,
            render: (val, record) => {
                return (
                    <React.Fragment>
                        <Tooltip title='Edit Room'>
                            <a
                                className={'linkAA'}

                            >
                                <Icon type={'edit'} />
                            </a>
                        </Tooltip>
                    </React.Fragment>
                )
            }
        }

    ]

    const imagesColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (val, record, index) => {
                return index + 1
            }
        },
        {
            title: 'Image',
            dataIndex: 'path',
            key: 'path',
            render: (val, record) => {
                return (
                    <p>
                        <img src={record.path} height='100px' width='120px' />
                    </p>
                )
            }
        },
        {
            title: 'Caption',
            dataIndex: 'caption',
            key: 'caption',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Action',
            key: '_id',
            dataIndex: '_id',
            width: 150,
            render: (val, record) => {
                return (
                    <React.Fragment>
                        <Tooltip title='Edit Image'>
                            <a
                                className={'linkAA'}
                                onClick={() => events.showImagesModal(record)}
                            >
                                <Icon type={'edit'} />
                            </a>
                        </Tooltip>
                    </React.Fragment>
                )
            }
        }

    ]
    const activeRoomColumns = [
        {
            title: 'Duration',
            key: 'meta',
            dataIndex: 'meta',


            render: (item) => {
                return (
                    <div>
                        {item && item.lease_duration ? <div>{item.lease_duration}</div> : ''}

                    </div>
                )
            }
        },
        {
            title: 'Move In',
            key: 'meta',
            dataIndex: 'meta',

            render: (item) => {
                return (
                    <div>
                        {item && item.available_from_formatted ? <div>{item.available_from_formatted}</div> : 'Flexible'}

                    </div>
                )
            }
        },
        {
            title: 'Move Out',
            key: 'meta',
            dataIndex: 'meta',

            render: (item) => {
                return (
                    <div>
                        {item && item.available_to_formatted ? <div>{item.available_to_formatted}</div> : ''}

                    </div>
                )
            }
        },
        {
            title: 'Rent',
            dataIndex: 'weekly_price',
            key: 'weekly_price',

        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <>
                    {/*<Tooltip title='Edit'>*/}
                    {/*    <a*/}
                    {/*        className={'btn'}*/}
                    {/*        onClick={() => events.showFaqModal(record)}*/}
                    {/*    >*/}
                    {/*        <Icon type={'edit'} />*/}
                    {/*    </a>*/}
                    {/*</Tooltip>*/}
                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure, you want to delete this record?"
                            onConfirm={() => deleteActiveRoom(record._id)}
                        >
                            <a
                                className={'btn'}
                            >
                                <Icon type={'delete'} />
                            </a>
                        </Popconfirm>
                    </Tooltip>
                </>

            ),
        },
    ];
    const videosColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (val, record, index) => {
                return index + 1
            }
        },
        {
            title: 'Video',
            dataIndex: 'thumbnail_url',
            key: 'thumbnail_url',
            render: (val, record) => {
                return (
                    <a href={record.thumbnail_url}>
                        <img src={record.thumbnail_url} height='100px' width='120px'/>
                    </a>
                )
            }
        },
        {
            title: 'Caption',
            dataIndex: 'caption',
            key: 'caption',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Upload Date',
            dataIndex: 'upload_date',
            key: 'upload_date',
        },
        {
            title: 'Action',
            key: '_id',
            dataIndex: '_id',
            width: 150,
            render: (val, record) => {
                return (
                    <React.Fragment>
                        <Tooltip title='Edit Image'>
                            <a
                                className={'linkAA'}
                            >
                                <Icon type={'edit'} />
                            </a>
                        </Tooltip>
                    </React.Fragment>
                )
            }
        }

    ]
    const deletePolicy = async (policyId) => {
        let resp = await dispatch(deleteUnizhomePolicyFxn({propertyId: propertyId, policyId: policyId}))
        if(resp.success) {
            notification.success({message: resp.message})
            apiRequest()
        } else  {
            notification.error({message: resp.message})
        }

    };
    const deleteRoom = async (roomId) => {
        let resp = await dispatch(deleteUnizhomeRoomFxn({propertyId: propertyId, roomId: roomId}))
        if(resp.success) {
            notification.success({message: resp.message})
            apiRequest()
        } else  {
            notification.error({message: resp.message})
        }

    };

    const deleteActiveRoom = async (roomId) => {
        let resp = await dispatch(deleteUnizhomeRoomFxn({propertyId: propertyId, roomId: roomId}))
        if(resp.success) {
            notification.success({message: resp.message})
            apiRequest()
        } else  {
            notification.error({message: resp.message})
        }

    };

    const policyColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (val, record, index) => {
                return index + 1
            }
        },
        {
            title: "Display Name",
            dataIndex: "display_name",
            key: "display_name",

        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",

        },
        {
            title: "Description",
            dataIndex: "value",
            key: "value",
            render: (text) => (
                <span>{text && text.length > 180 ? `${text.slice(0, 180)}...` : text}</span>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <>
                    <Tooltip title='Edit'>
                        <a
                            className={'btn'}
                            onClick={() => events.showFaqModal(record)}
                        >
                            <Icon type={'edit'} />
                        </a>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure, you want to delete this record?"
                            onConfirm={() => deletePolicy(record._id)}
                        >
                            <a
                                className={'btn'}
                            >
                                <Icon type={'delete'} />
                            </a>
                        </Popconfirm>
                    </Tooltip>
                </>

            ),
        },
    ];
    const formItemLayout = {
        labelCol: {
            xs: { span: 0 },
            sm: { span: 0 },
            md: { span: 0 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { form } = props;

        form.validateFieldsAndScroll(async (err, valData) => {
            console.log(valData, "valddtatta")
            if (!err) {

                const location = {
                        country: {
                            long_name: valData.country,
                            short_name: valData.countryShort,
                        },
                    state: { long_name: valData.state },
                    route: { long_name: valData.route },
                    district: { long_name: valData.district },
                    locality: { long_name: valData.locality },
                    postal_code: { long_name: valData.postalCode },
                    street_number: { long_name: valData.streetNumber },
                };
                const pricing = {
                    currency: valData.currency,
                    duration: valData.duration,
                    min_price: valData.min_price,
                    max_price: valData.max_price,
                    refundable_token: valData.refundable_token,
                    max_available_price: valData.max_available_price,
                    min_available_price: valData.min_available_price,
                };

                const { country, state, route, district, locality, postalCode, streetNumber, ...otherFields } = valData;


                const updateFields = {
                    ...otherFields,
                    location,
                    pricing
                };
                console.log(updateFields, "updateettt")
                const resp = await dispatch(updateUnizhomePropertyFxn(propertyId, { updateFields }));

                if (resp && resp.success) {
                    notification.success({ message: 'Property Updated Successfully'});
                }
            } else {
                notification.warning({ message: "Validation Error" });
            }
        });
    };
    useEffect(() => {
        if (activeTab === "roomTypes" && meta && meta.unit_types && meta.unit_types.length > 0) {
            const availableTabs = meta.unit_types.filter((unitType) => unitType !== "student_accommodation");

            if (activeRoomTab === "") {
                setActiveRoomTab(availableTabs[0]);
            }
        }
    }, [activeTab, meta, activeRoomTab]);


    return (
        <>

                <div className="form-box commissionBox">
                    <div className="card unizportal">
                        <Form onSubmit={handleSubmit}>
                            <Tabs activeKey={activeTab} onChange={setActiveTab}>
                                {Object.keys(tabsConfig).map((tabKey) => (
                                    <TabPane tab={tabKey.replace(/([A-Z])/g, ' $1').toUpperCase()} key={tabKey}>
                                        <Row gutter={24}>
                                            {tabKey === "paymentPolicy" ? (

                                                <>
                                                    <div className='card'>
                                                        <div className='table-head d-flex align-items-center'>
                                                            <h5>Payment Policies</h5>
                                                        </div>
                                                        <div className={'card-body table-responsive'}>
                                                            <Table
                                                                dataSource={paymentPolicies.applicable}
                                                                columns={policyColumns}
                                                                rowKey="_id"
                                                                bordered={true}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className='card mt-2'>
                                                        <div className='table-head d-flex align-items-center'>
                                                            <h5>Descriptions</h5>
                                                        </div>
                                                        <div className={'card-body table-responsive'}>
                                                            <Table
                                                                dataSource={paymentPolicies.notApplicable}
                                                                columns={policyColumns}
                                                                rowKey="_id"
                                                                bordered={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </>



                                                ) : tabKey === "faqs" ? (
                                                <div className={'card-body'}>
                                                    <Button className={'roundBtn mb-4'}
                                                            style={{width: '120px', float: 'right'}}
                                                            onClick={() => events.showAddFaqModal()}
                                                    >+ Add Faq</Button>
                                                <div className={'card-body table-responsive'}>

                                                    <Table
                                                        dataSource={faqs}
                                                        columns={columns}
                                                        rowKey="_id"
                                                        bordered={true}
                                                    />
                                                </div>
                                                </div>
                                            ) : tabKey === "media" ? (
                                                <>
                                                    <div className='card'>
                                                        <div className='table-head d-flex align-items-center'>
                                                            <h5>Images</h5>
                                                        </div>
                                                        <div className={'card-body table-responsive'}>
                                                            <Table
                                                                dataSource={images}
                                                                columns={imagesColumns}
                                                                rowKey="_id"
                                                                bordered={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='card mt-2'>
                                                        <div className='table-head d-flex align-items-center'>
                                                            <h5>Videos</h5>
                                                        </div>
                                                        <div className={'card-body table-responsive'}>
                                                            <Table
                                                                dataSource={videos}
                                                                columns={videosColumns}
                                                                rowKey="_id"
                                                                bordered={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            ) :   tabKey === "roomTypes" ? (
                                                <div className={'card'}>
                                                    <Row span={24}>
                                                        <Col span={24}>
                                                            <Row span={24}>
                                                                <Col span={20}>
                                                                    <h6><b>Choose Your Room</b></h6>

                                                                </Col>
                                                                <Col span={3}>
                                                                    <Button size="small"
                                                                            onClick={() => events.showAddRoomModal()}
                                                                    >+ Add Room</Button>
                                                                </Col>


                                                            </Row>

                                                            <hr />
                                                            {meta.unit_types && meta.unit_types.length > 0 && (
                                                                <Tabs
                                                                    defaultActiveKey={
                                                                        meta.unit_types && meta.unit_types.length > 0
                                                                            ? meta.unit_types
                                                                                .filter((unitType) => unitType !== "student_accommodation")[0] // Set the first available tab (excluding student_accommodation)
                                                                            : ""
                                                                    }
                                                                    activeKey={activeRoomTab}
                                                                    onChange={setActiveRoomTab}
                                                                >
                                                                    {meta.unit_types && meta.unit_types.length > 0 ? (
                                                                        meta.unit_types
                                                                            .filter((unitType) => unitType !== "student_accommodation") // Exclude student_accommodation
                                                                            .map((unitType, index) => (
                                                                                <TabPane tab={unitType.toUpperCase()} key={unitType}>
                                                                                    <ul>
                                                                                        {roomTypes
                                                                                            .filter((room) => room.meta.unit_type === unitType)
                                                                                            .map((room, idx) => (
                                                                                                <li key={idx} className="property-item mt-3">
                                                                                                    <Row span={24}>
                                                                                                        <Col span={4}>
                                                                                                            <div className="property-info2">
                                                                                                                <div className="image-box-update">
                                                                                                                    <div className="image-container2 img-container3">
                                                                                                                        <img
                                                                                                                            src={
                                                                                                                                room.images && room.images[0]
                                                                                                                                    ? room.images[0].path
                                                                                                                                    : ""
                                                                                                                            }
                                                                                                                            alt={room.name}
                                                                                                                            className="suggest-pro-img"
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                        <Col span={10} className="property-location mt-3 ml-3">
                                                                                                            <h6>{room.name}</h6>
                                                                                                            <p>
                                                                                                                <label className={'label label-property'}>

                                                                                                                   Available From:  {room.meta.available_from_formatted}

                                                                                                                </label>

                                                                                                                    <b className={'ml-4'}>
                                                                                                                       Starting From: £{room.pricing.deposit} / {room.pricing.duration}
                                                                                                                    </b>


                                                                                                            </p>
                                                                                                        </Col>
                                                                                                        <Col span={3}>
                                                                                                            <div>
                                                                                                                <Button size="small"
                                                                                                                        onClick={() => events.showRoomModal(room)}
                                                                                                                >+ Edit Room</Button>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                        <Col span={3}>
                                                                                                            <Popconfirm title={'Are you sure, you want to delete this room?'}
                                                                                                                        onConfirm={() => deleteRoom(room._id)}
                                                                                                            >
                                                                                                                <Button size="small"

                                                                                                                >+ Delete Room</Button>
                                                                                                            </Popconfirm>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                    <div className={'card-body table-responsive'}>
                                                                                                        <Table
                                                                                                            dataSource={room.active_children}
                                                                                                            columns={activeRoomColumns}
                                                                                                            rowKey="_id"
                                                                                                            bordered={true}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <hr />
                                                                                                </li>

                                                                                            ))}
                                                                                    </ul>
                                                                                </TabPane>
                                                                            ))
                                                                    ) : (
                                                                        <div>No Unit Types Available</div>
                                                                    )}
                                                                </Tabs>

                                                            )}
                                                            {/*/!* Pagination *!/*/}
                                                            {/*<Pagination*/}
                                                            {/*    current={currentPage}*/}
                                                            {/*    pageSize={itemsPerPage}*/}
                                                            {/*    total={roomTypes.length}*/}
                                                            {/*    onChange={handlePageChange}*/}
                                                            {/*    showSizeChanger={false}*/}
                                                            {/*    showQuickJumper*/}
                                                            {/*/>*/}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ) :  (
                                                tabsConfig[tabKey].map((item, key) => (
                                                    <Col span={item.type === "checkboxGroup" ? 24 : 8} key={key}>
                                                        {item.type === "file" ? (
                                                            <FileInput
                                                                name={item.fileName}
                                                                label={item.label}
                                                                className="mt20"
                                                                file={{ url: "" }}
                                                                chooseDocument={item.onChange}
                                                            />
                                                        ) :  item.type === "checkboxGroup" ? (
                                                            <div className={'card-body table-responsive'}>
                                                                {key === 0 && (
                                                                    <Button size="small" className={'edit-amenities'}
                                                                            onClick={() => events.showAmenitiesModal(features)}

                                                                    >+ Edit Amenities</Button>
                                                                )}

                                                            <div>
                                                                    <h6 className={'amenities-label'}>{item.label}{item.name}</h6>
                                                                <hr/>

                                                                </div>
                                                                        <div>
                                                                            <Row>
                                                                                {item.options.map((option, index) => (
                                                                                    <Col span={12} key={index}>
                                                                                        <div>
                                                                                            <Icon type={'check-circle'}
                                                                                                  className={'mr-3'}
                                                                                            />{option.label}</div>
                                                                                    </Col>
                                                                                ))}
                                                                            </Row>
                                                                        </div>

                                                            </div>
                                                        ) : item.customField ? (
                                                            <>{item.customField}</>
                                                        ) : (
                                                            <GetEachFormFields
                                                                item={item}
                                                                getFieldDecorator={getFieldDecorator}
                                                                formItemLayout={{
                                                                    labelCol: { xs: { span: 0 }, sm: { span: 0 } },
                                                                    wrapperCol: { xs: { span: 24 } },
                                                                }}
                                                            />
                                                        )}
                                                    </Col>
                                                ))
                                            )}
                                        </Row>
                                    </TabPane>
                                ))}
                            </Tabs>

                            <Row justify="end">
                                <Col>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            { openFaq.visible ?
                <EditPropertyFaqModal
                    {...openFaq}
                    onClose={() => events.hideFaqModal()}
                    /> : null
            }
            {openAmenities.visible && (
                <AmenitiesModal
                    {...openAmenities}
                    onClose={() => events.hideAmenitiesModal()}
                    onSubmit={() => events.hideAmenitiesModal()}
                />
            )}
            {imagesVisible.visible && (
                <EditPropertyImage
                    {...imagesVisible}
                    onClose={() => events.hideImagesModal()}

                />
            )}
            {faqVisible.visible && (
                <AddPropertyFaqModal
                    {...faqVisible}
                    onClose={() => events.hideAddFaqModal()}

                />
            )}
            {openPolicy.visible && (
                <EditPaymentPolicyModal
                {...openPolicy}
                onClose={() => events.hidePolicyModal()}

                />
                )}
            {editRoomVisible.visible && (
                <EditPropertyRoomModal
                    {...editRoomVisible}
                    onClose={() => events.hideRoomModal()}

                />
            )}
            {addRoomVisible.visible && (
                <AddPropertyRoom
                    {...addRoomVisible}
                    onClose={() => events.hideAddRoomModal()}

                />
            )}
        </>
    );
};

export default Form.create()(EditPropertyModal)
