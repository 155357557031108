import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Popconfirm } from 'antd'
import { TableComp } from 'sz-react-utils-lite'
import { DefaultTablePagination, displayDate } from '../../../components/_utils/appUtils'
import {
  requestedCallBackListFxn,
  updateCallBackStatusFxn,
  requestCallbackFeedbackFxn,
  canadaOnshoreManagerFxn
} from '../actions'
import { Icon, Modal, notification, Tooltip } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { addCallbackFeedbackFxn } from '../../dashboard/actions/dashboardAction'
import { useLocation } from 'react-router'

import { CheckUserRight } from '../../dashboard/views/DashboardUserWise'
import RequestACallBack from './requestACallBack'

const RenderComment = props => {
  let { item } = props
  let [hideV, setHideV] = useState(true)
  let text = item.details || item.reason
  return (
    item && (
      <div>
        <div className={hideV ? 'appCommentDotsReplace' : ''}>
          {text ? text : ''}
        </div>
        {text && text.length && text.length > 50 ? (
          <div className={'alignRight'}>
            {hideV ? (
              <a
                onClick={() => {
                  setHideV(false)
                }}>
                Show More
              </a>
            ) : (
              <a
                onClick={() => {
                  setHideV(true)
                }}>
                Show Less
              </a>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  )
}


const RequestACallBackList = (props) => {
  let { user } = props

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const callbackId = query.get('callbackId')
  const tableRef = useRef()
  let [total, setTotal] = useState(0)
  let [details, setDetails] = useState('')
  let [reason, setReason] = useState('')
  const [satisfaction, setSatisfaction] = useState('satisfied')
  let [detailsModal, setDetailsModal] = useState({
    visible: false,
    id: ''
  })
  let [RequestACallVisible, setRequestACallModal] = useState(false)
  let [managerData, setManagerData] = useState({})

  let [cancelModal, setCancelModal] = useState({
    visible: false,
    id: ''
  })
  const [feedbackModal, setFeedbackModal] = useState({
    visible: false,
    id: ''
  })

  const [comment, setComment] = useState('')
  const dispatch = useDispatch()

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      if (callbackId) {
        params._id = callbackId
      }
      let resp = await dispatch(requestedCallBackListFxn({
        ...params,
        regExFilters: ['name', 'email', 'mobile', 'timezone']
      }))
      setTotal(resp.total)
      resolve(resp)
    })
  }

  const updateCallBackStatus = async (id, status) => {
    if (status == 'cancel' && !reason) {
      notification.warning({ message: 'Enter reason' })
      return
    }
    if (status == 'done' && !details) {
      notification.warning({ message: 'Enter details' })
      return
    }
    let data = await dispatch(updateCallBackStatusFxn({ id, status, details, reason }))
    if (data) {
      setDetailsModal({ visible: false, id: '' })
      setCancelModal({ visible: false, id: '' })
      tableRef.current.reload()
    }
  }

  const handleFeedbackSubmit = async (callbackId) => {
    if (!satisfaction) {
      notification.warning({ message: 'Select satisfaction' })
      return
    }

    if (satisfaction === 'notSatisfied' && !comment) {
      notification.warning({ message: 'Enter a comment for improvement' })
      return
    }

    const feedbackData = {
      callbackId,
      feedbackStatus: satisfaction === 'satisfied' ? 'Satisfied' : 'Not Satisfied',
      comment: comment
    }

    const data = await dispatch(requestCallbackFeedbackFxn(feedbackData))

    if (data) {
      setFeedbackModal({ visible: false, id: '' })
      setSatisfaction('')
      setComment('')
      tableRef.current.reload()
    } else {
      notification.error({ message: 'Failed to submit feedback. Please try again.' })
    }
  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (item, record, index) => {
        return index + 1
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 100
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 100,
      render: (item, record) => {
        let countryCode = record.countryCode.split('_').pop()
        return (
          <>
            {countryCode} {item}
          </>
        )
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 100

    },
    {
      title: 'Timezone',
      dataIndex: 'timezone',
      key: 'timezone',
      width: 100

    },
    {
      title: 'Callback Date',
      dataIndex: 'date',
      key: 'date',
      width: 100,
      render: (item) => {
        return item ? displayDate(item) : ''
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item == 'done' ? 'Done' : item == 'cancel' ? 'Cancel' : item}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Details/Reason',
      key: 'details',
      dataIndex: 'details',
      width: 150,
      render: (item, record) => {
        return <RenderComment item={record} />
      }
    },
    {
      title: 'Feedback Status',
      key: 'feedbackStatus',
      dataIndex: 'feedback',
      width: 150,
      render: (feedback, record) => {
        if (feedback && feedback.feedbackStatus) {
          const satisfaction = feedback.feedbackStatus === 'Satisfied' ? 'Satisfied 🙂' : 'Not Satisfied ☹'
          const className = satisfaction === 'Satisfied 🙂' ? 'label label-success-green' : 'label label-danger'
          return (
            <label className={className}>
              {satisfaction}
            </label>
          )
        } else {
          return null
        }
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 70,
      render: (text, record) => {
        let { feedback } = record
        return <React.Fragment>
          <CheckUserRight user={user} rightUserType={['admin', 'branchUser']}>
            {record.status == 'Pending' ?
              <>
                <Tooltip title='Done'>
                  <button
                    className={'btn'}
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      setDetailsModal({ visible: true, id: record._id })
                    }}>
                    <Icon type={'check'} />
                  </button>
                </Tooltip>
                <Tooltip title='Cancel'>
                  <button
                    className={'btn'}
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      setCancelModal({ visible: true, id: record._id })
                    }}>
                    <Icon type={'close'} />
                  </button>
                </Tooltip>
              </> : null}
          </CheckUserRight>

          <CheckUserRight user={user} rightUserType={['agent']}>
            {record.status == 'done' && (!feedback || (feedback && !feedback.feedbackStatus)) ?
              <>
                <Tooltip title='Give Feedback'>
                  <label
                    type='primary'
                    onClick={() => {
                      setFeedbackModal({ visible: true, id: record._id })
                    }}
                    style={{
                      background: 'linear-gradient(45deg, #0f52ba, #2e86c1)'
                    }}
                    className={'label'}
                  >
                    <Icon type='message' /> Give Feedback
                  </label>
                </Tooltip>
              </> : null}
          </CheckUserRight>
        </React.Fragment>

      }
    }
  ]

  let loadCanadaOnshoreManager = async () => {
    let { data, success } = await canadaOnshoreManagerFxn()
    setManagerData(data)
  }

  useEffect(() => {
    loadCanadaOnshoreManager()
  }, [])

  return (
    <div>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>All Request {total || 0}</h5>
              <div className='search-box-table'>

              </div>

              <div className='sort-box-table mark-btn'>
                {user.userType == 'agent' ? (
                  <a className={'btn btn-default roundNew mt10'}
                     onClick={() => setRequestACallModal(true)}>
                    <Icon type={'phone'} />
                    &nbsp; Request A Call Back
                  </a>
                ) : null}
              </div>
            </div>
            <div className='card-body table-responsive'>
              <TableComp columns={columns}
                         apiRequest={apiRequest}
                         ref={tableRef}
                         pagination={DefaultTablePagination()}
              />
            </div>
            {
              detailsModal.visible ? (
                <Modal
                  title='Enter Detail'
                  visible={detailsModal.visible}
                  onOk={() => {
                    updateCallBackStatus(detailsModal.id, 'done')
                  }}
                  onCancel={() => {
                    setDetailsModal({ ...detailsModal, visible: false })
                  }}
                >
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    value={details}
                    onChange={(e) => setDetails(e.target.value)}
                    placeholder='Enter Details..'
                  />
                </Modal>
              ) : null
            }
            {
              cancelModal.visible ? (
                <Modal
                  title='Enter Reason'
                  visible={cancelModal.visible}
                  onOk={() => {
                    updateCallBackStatus(cancelModal.id, 'cancel')
                  }}
                  onCancel={() => {
                    setCancelModal({ ...cancelModal, visible: false })
                  }}
                >
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder='Enter reason for Cancel'
                  />
                </Modal>
              ) : null
            }

            {feedbackModal.visible && (
              <Modal
                width={'30%'}
                title='Give Feedback'
                visible={feedbackModal.visible}
                onCancel={() => {
                  setFeedbackModal({ ...feedbackModal, visible: false })
                }}
                footer={null}
              >
                <div className='form-box'>
                  <div className='card unizportal' style={{ textAlign: 'center' }}>
                    <div className='message'>
                      <p><b>Please provide your feedback: </b></p>
                    </div>
                    <div className={'row'}>
                      <button
                        className={`btn btn-success-green border round lg ml-2 feedback-button ${satisfaction === 'satisfied' ? 'selected' : ''}`}
                        onClick={() => {
                          setSatisfaction('satisfied')
                        }}
                      >
                        Satisfied 🙂
                      </button>

                      <button
                        className={`btn btn-danger border round lg ml-2 feedback-button ${satisfaction === 'notSatisfied' ? 'selected' : ''}`}
                        onClick={() => {
                          setSatisfaction('notSatisfied')
                        }}
                      >
                        Not Satisfied ☹️
                      </button>
                    </div>
                    {satisfaction && (
                      <div className='mt-4'>
                        {satisfaction === 'satisfied' ? (
                          <>
                            <label>Please provide additional comments:</label>
                            <TextArea
                              rows={5}
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                              placeholder='Enter additional comments...'
                            />
                          </>
                        ) : (
                          <>
                            <label>Please put a note so that we can improve our services regarding this
                              complaint:</label>
                            <TextArea
                              rows={5}
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                              placeholder='Enter your note...'
                              required
                            />
                          </>
                        )}
                      </div>
                    )}
                    <Button className={'roundBtn mt10'} type={'primary'}
                            onClick={() => handleFeedbackSubmit(feedbackModal.id)}>
                      Submit
                    </Button>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
      </div>

      {RequestACallVisible ? (
        <RequestACallBack
          visible={RequestACallVisible}
          manager={managerData}
          currentUser={user}
          onClose={() => {
            setRequestACallModal(false)
            tableRef.current.reload()
          }}
        />
      ) : null}
    </div>
  )
}

export default RequestACallBackList
