import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Form,
    Col,
    Row,
    Modal, notification, Card
} from 'antd';
import _ from 'lodash';
import GetEachFormFields from '../../../components/_utils/appFormUtils';
import {singleQuotationFxn, getAgentsFxn, getFinalChangesInInrFxn, reeudoRatesFxn} from "../actions";
import {rupeeSymbol, fixed2Digit} from "../../../components/_utils/appUtils";
import {hidePageLoad, showPageLoad} from "../../../modules/actions";

const RenderRow = (props) => {
    let {value, label, span} = props;
    return (
        <Col span={span}>
            {label}
            <h5 className={'font-weight-600'}>{value}</h5>
        </Col>
    )
}
const AddTxnStudent = (props) => {
    const {
        form: {getFieldDecorator, setFieldsValue},
        onClose,
        visible,
        onSubmit,
        currencyList,
        sourceId,
        quotationId,
        referenceUserList, currentUser,
        agentId,
        isForexOff,
        exchangeMargin,
        orgExchangeMargin,
        finalChangesState,
        formDataState,
        refreshRateFxn = () => null
    } = props;
    let [selectedCurrencyObj, setSelectedCurrencyObj] = useState({});
    let [quotationData, setQuotationData] = useState(null);
    let [finalData, setFinalData] = useState({});
    let dispatch = useDispatch();

    useEffect(() => {
        //events.setDefaultAgent();
        events.apiRequest();
        events.setDefaultValue();
    }, []);


    const events = {
        // setDefaultAgent: () => {
        //     if (currentUser && currentUser.userType == "agent") {
        //         setAgentId(currentUser._id)
        //     } else {
        //         setAgentId(ryanAgentId)
        //     }
        // },
        setDefaultValue: () => {
            setTimeout(() => {
                setFieldsValue({
                    referencedBy: "NA",
                    exchangeMargin: exchangeMargin
                })
            }, 500)
        },

        chooseCurrency: (currency) => {
            let findDoc = _.find(currencyList, (item) => {
                return item.currency == currency;
            });
            if (findDoc) {
                setSelectedCurrencyObj(findDoc);
            }
        },
        getFinalCalculateRate: () => {
            return new Promise(async (resolve) => {
                let {data, success} = await dispatch(getFinalChangesInInrFxn(formDataState))
                if (success && data && data.charges) {
                    resolve({success: true, data: data.charges})
                } else {
                    resolve({success: false, data: {}})
                }
            })
        },
        refreshModal: () => {
            Modal.info({
                title: 'IBR Rate change, please refresh rate.',
                okText: "Refresh Now",
                onOk: () => {
                    refreshRateFxn()
                },
            });
        },
        apiRequest: () => {
            return new Promise(async (resolve) => {
                let resp = await dispatch(singleQuotationFxn({quotationId}));
                const response = resp.quotation;

                if (response) {
                    setQuotationData(response);

                    setFieldsValue({
                        currency: response.fromCurrency,
                        amount: response.amount,
                        fxRate: response.fxRate,
                    });
                }
                resolve({data: response});
            });
        },
    };


    const inputTypes = {
        fields: [
            {
                key: "studentName",
                label: "Student Name",
                required: true,
                placeholder: "Student Name",
                type: "text",
            },
            {
                key: "phoneNumber",
                label: "Student Mobile Number",
                required: true,
                placeholder: "Student Mobile Number",
                type: "text",
            },
            {
                key: "emailAddress",
                label: "Student Email Id",
                required: true,
                placeholder: "Student Email Id",
                type: "text",
            },
            {
                key: "panNumber",
                label: "Pan Number",
                // required: true,
                placeholder: "Pan Number",
                type: "text",
            },
            {
                key: 'referencedBy',
                label: 'Reference By',
                placeholder: 'Reference By', type: 'select',
                options: referenceUserList,
                keyAccessor: x => x._id,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    // console.log(x);
                    setFieldsValue({referencedBy: x})
                }
            },
            ...(currentUser.userType === "admin" || currentUser.userType == "reeudoManager" ? [
                {
                    key: "exchangeMargin", label: "Exchange Margin", placeholder: "Exchange Margin", type: "text",

                }
            ] : [])
        ],
    };

    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    };

    const forexOffModal = () => {
        Modal.warning({
            title: 'Forex is closed',
            content: (
                <div>
                    <p>Forex is Closed, Please try again between 8 a.m. and 4 p.m.</p>
                </div>
            ),
            onOk() {
            },
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isForexOff) {
            forexOffModal()
        } else {
            let {form} = props;
            dispatch(showPageLoad())
            let {success, data} = await events.getFinalCalculateRate()
            dispatch(hidePageLoad())
            if (success) {
                if (data.yourRate == finalChangesState.yourRate) {
                    nextProcess()
                } else {
                    events.refreshModal()
                }
            } else {
                events.refreshModal()
            }

            function nextProcess() {
                form.validateFields((err, values) => {
                    if (!err) {
                        if (values.exchangeMargin < orgExchangeMargin) {
                            notification.warning({message: `Sry can't enter less then ${orgExchangeMargin}`})
                            return
                        }
                        const submitValues = {
                            ...values,
                            sourceId: props.sourceId,
                            agentId: agentId
                        };
                        onSubmit(submitValues);
                    }
                });
            }
        }

    };

    return (
        <Modal
            visible={visible}
            title={"Add Quotation"}
            onCancel={onClose}
            width={"45%"}
            footer={null}>
            <>
                <div className="card unizportal">
                    <Form onSubmit={handleSubmit}>
                        <Row gutter={24}>
                            {inputTypes.fields.map((item, key) => {
                                return (
                                    <Col span={item.span ? item.span : 12}
                                         key={key}>
                                        <GetEachFormFields
                                            item={item}
                                            className={'form-control'}
                                            getFieldDecorator={getFieldDecorator}
                                            formItemLayout={formItemLayout}/>
                                    </Col>
                                );
                            })}
                        </Row>

                        <div>
                            <Button htmlType="submit" className={"roundBtn"}>
                                Submit
                            </Button>
                        </div>
                    </Form>
                </div>
            </>
        </Modal>
    );
};

export default Form.create()(AddTxnStudent);
