import React, { useEffect, useState } from 'react'
import {
  Form,
  Row,
  Col,
  Button,
  Input,
  notification,
  Popover, Checkbox, Alert, Icon, Card, Select
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import {
  qualificationOptions,
  DocumentTypes,
  EnglishExamTypes, DirectUniversityCheck, countryIds, streamsList, CornerStoneCollege, ConditionOptions
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { listAllCountries } from '../../countries/actions/countries'
import { addStudent, checkAddStudentInAustraliaFxn } from '../../student/actions/student'
import _ from 'lodash'
import moment from 'moment'
import { InputBox } from '../../../components/_utils/appUtils'
import { CountryCodeWithFlag, CountryJson, LoadState } from '../../../components/_utils/countryUtil'
import { listQuickSearchUniversities } from '../../university/actions/university'
import { listAllCourse, loadCourseIntakesFxn } from '../../course/actions/courseAction'
import { listCourseCampus } from '../../campus/actions/campus'
import { eduBoardAndUniversity } from '../../student/actions/student'
import { Debounce } from '../../../components/_utils/debounce'
import { EligibilityIcon } from '../../university/drawers/universityEligibility'
import { push } from 'connected-react-router'
import { getUser } from '../../users/actions/user'


let initialState = {
  currentStep: 0,
  fileList: [],
  uploadKey: moment(),
  allCountries: CountryJson,
  allStates: [],
  allCities: [],
  countryName: '',
  stateName: '',
  cityName: '',
  // studentShore: 'OffShore',
  disabledEducation: false,
  disabledEnglish: false
}

let requiredDocument = [
  'Passport',
  'IELTS',
  '10th',
  '12th'
]

let canadaOnShoreRequiredDocument = [
  'Study Permit'
]


const AgreementAlerts = (props) => {
  let { user } = props
  const dispatch = useDispatch()
  let [userData, setUserData] = useState({})
  useEffect(() => {
    loadCurrentUser()
  }, [])
  let loadCurrentUser = async () => {
    let data = await dispatch(getUser(user._id))
    setUserData(data)
  }
  return (
    <>
      {userData && userData.userType === 'agent' ?
        <div className={'mb20'}>
          {userData && userData.agreementSigned ? null :
            <Alert style={{ marginTop: 10 }}
                   icon={<Icon type={'warning'} style={{ fontSize: 16 }} />}
                   message={<p style={{ margin: 0 }}>Our Agreement is still not signed. Please go to <a
                     style={{ color: 'blue' }} onClick={() => dispatch(push('/agreement'))}>Agreement
                     page</a> and
                     click sign now to accept our Terms and conditions.</p>}
                   type='warning' />}
        </div> : null}

    </>
  )
}

const { Option } = Select

const AddStudent = (props) => {
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

  const {
    form: { getFieldDecorator, setFieldsValue, getFieldValue },
    callBackFxn,
    pageType,
    currentUser
  } = props
  let { enabledStudentLogin } = currentUser
  const dispatch = useDispatch()
  _.each(DocumentTypes, (item) => {
    initialState[item] = ''
  })
  const [state, setState] = useState(initialState)
  const { allCountries, allStates } = state

  let [allCountry, setAllCountry] = useState([])
  let [countryUniversity, setCountryUniversity] = useState([])
  let [universityCourse, setUniversityCourse] = useState([])
  let [selectedCourse, setSelectedCourse] = useState({})
  let [intakesList, setIntakesList] = useState([])
  let [intake, setIntake] = useState({})
  let [campusList, setCampusList] = useState([])
  let [eduList, setEduList] = useState([])
  let [countryCode, setCountryCode] = useState('')
  let [isDirectUniversity, setIsDirectUniversity] = useState(false)


  let [isCounselorBonusChecked, setIsCounselorBonusChecked] = useState(undefined)
  let [counselorName, setCounselorName] = useState('')
  let [counselorDesignation, setCounselorDesignation] = useState('')
  let [allCountryGroups, setAllCountryGroups] = useState([])
  let [countryId, setCountryId] = useState('')
  const [isOnshoreConditionChecked, setIsOnShoreConditionChecked] = useState(false)
  const handleOnshoreCheckboxChange = (e) => {
    setIsOnShoreConditionChecked(e.target.checked)
  }
  const handleCheckboxChange = (e) => {
    setIsCounselorBonusChecked(e)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const setDefaultCountry = () => {
    let defaultCountry = 'India'
    props.form.setFieldsValue({
      countryName: defaultCountry
      // studentShore: 'Offshore'
    })
    events.chooseCountry(defaultCountry)

  }

  const appEvents = {
    loadCountryGroups: async () => {
      let filters = {
        sortField: 'countryName', sortOrder: 'ascend',
        results: 100,
        count: 100

      }
      let { data } = await dispatch(listAllCountries(filters))
      const uniqueData = []
      let groupedData = _.groupBy(data, (item) => {
        if (!item.countryGroup) {
          item.countryGroup = item.countryName
        }
        return item.countryGroup
      })
      _.each(groupedData, (item, key) => {
        uniqueData.push({
          countryGroup: key,
          countryArr: item,
          countryId: item[0]._id
        })
      })
      setAllCountryGroups(uniqueData)
    },
    loadCountry: async (groupName) => {
      let finded = _.find(allCountryGroups, (item) => {
        return item.countryGroup == groupName
      })
      if (finded && finded.countryArr.length == 1) {
        setCountryId(finded.countryId)
      }
      if (finded && finded.countryArr.length) {
        setAllCountry(finded.countryArr)
      }
    },
    loadUniversity: async (item) => {
      let obj = {
        results: 1000000,
        universityCountry: item,
        sortField: 'universityName',
        sortOrder: 'ascend'
      }
      let data = await dispatch(listQuickSearchUniversities(obj))
      setCountryUniversity(data)
    },
    loadCourse: async (item) => {
      let obj = {
        results: 1000000,
        courseUniversity: item,
        sortField: 'courseName',
        sortOrder: 'ascend'
      }
      let { data } = await dispatch(listAllCourse(obj))
      setUniversityCourse(data)
    },
    loadCampus: async (item) => {
      let obj = {
        results: 1000000,
        courseId: item
      }
      let { data } = await dispatch(listCourseCampus(obj))
      setCampusList(data)
      if (isDirectUniversity && data && data.length) {
        setFieldsValue({
          campusId: data[0]._id
        })
      }

    },
    loadIntakes: async () => {
      let courseId = getFieldValue('courseId')
      let campusId = getFieldValue('campusId')
      let resp = await dispatch(loadCourseIntakesFxn({ courseId, campusId }))
      let data = []
      _.each(resp, (item) => {
        if (item) {
          item.value = `${item.intake}-${item.year}`
          item.label = `${item.intake}, ${item.year}`
          data.push(item)
        }
      })
      setIntakesList(data)
      if (isDirectUniversity && resp && resp.length) {
        setFieldsValue({
          intakeTemp: data[0].value
        })
        let intake = {
          month: resp[0].intake,
          year: resp[0].year
        }
        setIntake(intake)
      }
    }
  }

  const loadBoard = async (x) => {
    let obj = {
      qualification: getFieldValue('education.qualification'),
      board: x
    }
    let { data } = await eduBoardAndUniversity(obj)
    setEduList(data)
  }

  const ExamConditions = (!getFieldValue('englishRequirments.examType') || (getFieldValue('englishRequirments.examType') && getFieldValue('englishRequirments.examType') == 'I don\'t have this'))
  const Exam12ThConditions = (!getFieldValue('englishRequirments.examType') || (getFieldValue('englishRequirments.examType') && getFieldValue('englishRequirments.examType') !== 'I don\'t have this'))
  const testLoginShow = (
    (getFieldValue('englishRequirments.examType') == 'PTE' || getFieldValue('englishRequirments.examType') == 'IELTS') && countryId == countryIds.australia
  )
  const showStreamField = (
    (getFieldValue('education.qualification') && (getFieldValue('education.qualification') == 'XII' || getFieldValue('education.qualification') == 'XII + Diploma'))
  )
  let formFields = {
    generateInputs: [
      { key: 'name', label: 'Student Name', required: true, placeholder: 'Enter Name' },

      {
        key: 'dateOfBirth', label: 'Date Of Birth',
        required: true, type: 'date',
        placeholder: 'Choose Date of Birth'
      },
      {
        key: 'countryOfCitizenShip',
        label: 'Country of Citizenship',
        required: true,
        type: 'select',
        showSearch: true,
        options: allCountries,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            countryOfCitizenShip: x
          })
        }
      },
      { key: 'passportNumber', label: 'Passport Number', placeholder: 'Enter Passport Number' },
      {
        key: 'gender', label: 'Gender',
        type: 'select',
        options: ['Male', 'Female'],
        required: true, onChange: x => {
          props.form.setFieldsValue({
            gender: x
          })
        }
      },
      {
        key: 'maritalStatus', label: 'Marital Status',
        type: 'select', options: ['Single', 'Married'],
        required: true, onChange: x => {
          props.form.setFieldsValue({
            maritalStatus: x
          })
        }
      }
    ],
    addressInput: [
      { key: 'address', label: 'Address', required: true, span: 16, placeholder: 'Enter Address' },
      {
        key: 'countryName',
        label: 'Country',
        required: true,
        type: 'select',
        showSearch: true,
        options: allCountries,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            countryName: x
          })
          events.chooseCountry(x)
        }
      },
      {
        key: 'stateName',
        label: 'Province/State',
        required: true,
        type: 'select',
        showSearch: true,
        options: allStates,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            stateName: x
          })
          events.chooseState(x)
        }
      },
      {
        key: 'cityName',
        label: 'City/Town',
        required: true,
        // type: 'select',
        // showSearch: true,
        // options: allCities,
        // keyAccessor: x => x.name,
        // valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            cityName: x.target.value
          })
          events.chooseCity(x.target.value)
        }
      },
      {
        key: 'postalCode',
        label: 'Postal/Zip Code',
        // type: 'number',
        placeholder: 'Postal/Zip Code',
        required: true
      },
      { key: 'email', label: 'Email', type: 'email', placeholder: 'Enter Email' },
      {
        key: 'countryCode',
        span: 3,
        customField: (
          <div style={{ marginTop: -1 }}>
            <CountryCodeWithFlag
              countryCode={countryCode}
              chooseCode={(val) => {
                setCountryCode(val)
              }} />
          </div>
        )
      },
      { key: 'mobile', span: 5, label: 'Phone', required: true, type: 'number', placeholder: 'Enter Phone No' }
    ],
    educationInput: [
      {
        key: 'education.qualification',
        label: 'Qualification',
        type: 'select',
        allowClear: true,
        required: getFieldValue('countryName') == 'India',
        options: qualificationOptions,
        onChange: x => {
          let obj = {
            qualification: x,
            passingYear: '',
            percentage: '',
            maths: '',
            english: '',
            board: ''
          }
          props.form.setFieldsValue({
            education: obj
          }, () => {
            loadBoard()
          })
        },
        span: 6
      },
      {
        key: 'education.board',
        label: 'Board/University',
        showSearch: true,
        type: 'select',
        onSearch: (v) => {
          Debounce(() => loadBoard(v), 500)
        },
        required: getFieldValue('education.qualification') && getFieldValue('education.qualification') !== 'X + 3 Year Diploma',
        hidden: !(getFieldValue('education.qualification') && getFieldValue('education.qualification') !== 'X + 3 Year Diploma'),
        options: eduList,
        onChange: x => {
          props.form.setFieldsValue({
            'education.board': x
          })
        },
        span: 10
      },
      {
        key: 'education.stream',
        span: 8,
        label: 'Streams',
        type: 'select',
        keyAccessor: x => x,
        valueAccessor: x => `${x}`,
        options: streamsList,
        required: showStreamField,
        hidden: !showStreamField,
        onChange: x => {
          props.form.setFieldsValue({
            'education.stream': x
          })
        }
      }
    ],
    educationInputField: [
      {
        key: 'education.passingYear',
        label: 'Passing Year',
        type: 'number',
        span: 6,
        required: getFieldValue('education.qualification'),
        minimum: 0
      },
      {
        key: 'education.percentage',
        label: 'Percentage',
        type: 'number',
        span: 6,
        required: getFieldValue('education.qualification')
      },
      {
        key: 'education.maths',
        label: 'Math Score',
        type: 'number',
        span: 6,
        hidden: props.form.getFieldValue('education.qualification') !== 'XII',
        required: props.form.getFieldValue('education.qualification') == 'XII'
      },
      {
        key: 'education.english',
        label: 'English Score',
        type: 'number',
        span: 6,
        hidden: props.form.getFieldValue('education.qualification') !== 'XII',
        required: props.form.getFieldValue('education.qualification') == 'XII'
      }
    ],
    testInput: [
      {
        key: 'englishRequirments.examType',
        label: 'English Exam Type',
        type: 'select',
        allowClear: true,
        required: getFieldValue('countryName') == 'India',
        showSearch: true,
        options: EnglishExamTypes,
        onChange: x => {
          let obj = {
            examType: x,
            overall: '',
            listening: '',
            reading: '',
            writing: '',
            speaking: '',
            english12ThMarks: ''
          }
          props.form.setFieldsValue({
            englishRequirments: obj
          })
        },
        span: 6
      },
      {
        key: 'englishRequirments.english12ThMarks',
        label: 'English 12th Marks',
        type: 'number',
        placeholder: 'English 12th Marks',
        hidden: Exam12ThConditions,
        required: !Exam12ThConditions,
        span: 6
      },
      {
        key: 'englishRequirments.examinationDate',
        label: 'Examination Date',
        placeholder: 'Examination Date',
        type: 'date',
        hidden: ExamConditions,
        required: !ExamConditions,
        span: 6
      },
      {
        key: 'englishRequirments.overall',
        label: 'Overall',
        type: 'number',
        span: 6,
        hidden: ExamConditions,
        required: !ExamConditions,
        placeholder: 'Overall'
      }
    ],
    testInputFields: [
      {
        key: 'englishRequirments.listening',
        label: 'Listening',
        type: 'number',
        span: 6,
        hidden: ExamConditions,
        required: !ExamConditions,
        placeholder: 'Listening'
      },
      {
        key: 'englishRequirments.reading',
        label: 'Reading',
        type: 'number',
        span: 6,
        hidden: ExamConditions,
        required: !ExamConditions,
        placeholder: 'Reading'
      },
      {
        key: 'englishRequirments.writing',
        label: 'Writing',
        type: 'number',
        span: 6,
        hidden: ExamConditions,
        required: !ExamConditions,
        placeholder: 'Writing'
      },
      {
        key: 'englishRequirments.speaking',
        label: 'Speaking',
        type: 'number',
        span: 6,
        hidden: ExamConditions,
        required: !ExamConditions,
        placeholder: 'Speaking'
      }
    ],
    backgroundInfoInput: [
      {
        key: 'refusedVisa',
        label: 'Have you been refused a visa from Canada, the USA, the United Kingdom, New Zealand or Australia?',
        type: 'radioGroup',
        options: ['Yes', 'No'],
        span: 24,
        onChange: x => {
          props.form.setFieldsValue({
            refusedVisa: x.target.value
          })
        }
      },
      {
        key: 'details',
        label: 'If you answered "Yes" to any of the following questions above, please provide more details below:',
        placeholder: 'Provide details... ',
        type: 'textArea',
        span: 24,
        rows: 7
      }
    ],
    applicationFields: [
      {
        key: 'countryGroup',
        label: 'Country',
        required: true,
        type: 'select',
        showSearch: true,
        options: allCountryGroups,
        keyAccessor: x => x.countryGroup,
        valueAccessor: x => `${x.countryGroup}`,
        onChange: x => {
          props.form.setFieldsValue({
            countryGroup: x,
            countryId: undefined,
            universityId: undefined,
            courseId: undefined,
            intakeTemp: undefined
          })
          setCountryId('')
          setIntake({})
          setCountryUniversity([])
          setState({
            ...state,
            disabledEducation: false,
            disabledEnglish: false
          })
        }
      },
      {
        key: 'countryId',
        label: `${
          getFieldValue('countryGroup') == 'Europe' ? 'Choose Sub Countries'
            : 'Student Current Location'}`,
        required: true,
        type: 'select',
        showSearch: true,
        options: allCountry,
        hidden: allCountry.length === 1,
        keyAccessor: x => x._id,
        valueAccessor: x => `${
          x.countryName == 'Canada Onshore' ? 'Inside Canada'
            : x.countryName == 'Canada' ? 'Outside Canada'
            : x.countryName
        }`,
        onChange: x => {
          props.form.setFieldsValue({
            countryId: x,
            universityId: undefined,
            courseId: undefined,
            intakeTemp: undefined
          })
          setCountryId(x)
          appEvents.loadUniversity(x)
          setIntake({})
          setState({
            ...state,
            disabledEducation: false,
            disabledEnglish: false
          })
        }
      },
      {
        key: 'universityId',
        label: 'University',
        required: true,
        type: 'select',
        showSearch: true,
        options: countryUniversity,
        customExtra: (
          <span className={'customExtra'}>
                        <EligibilityIcon universityId={getFieldValue('universityId')}
                                         countryId={countryId} />
                    </span>
        ),
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.universityName}`,
        onChange: x => {
          props.form.setFieldsValue({
            universityId: x,
            courseId: undefined,
            intakeTemp: undefined,
            campusId: undefined
          })
          appEvents.loadCourse(x)
          setIntake({})
          setIsDirectUniversity(DirectUniversityCheck(x))
        }
      },
      {
        key: 'courseId',
        label: 'Course',
        required: true,
        type: 'select',
        showSearch: true,
        options: universityCourse,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.courseName}`,
        onChange: x => {
          props.form.setFieldsValue({
            courseId: x,
            intakeTemp: undefined,
            campusId: undefined
          })
          appEvents.loadCampus(x)
          let selectedCourse = _.find(universityCourse, (item) => {
            return item._id == x
          })
          setSelectedCourse(selectedCourse)
          setIntake({})

        }
      },
      {
        key: 'campusId',
        label: 'Campus',
        type: 'select',
        required: isDirectUniversity,
        // showSearch: true,
        options: campusList,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.campusName}`,
        onChange: x => {
          props.form.setFieldsValue({
            campusId: x
          })
        }
      },
      {
        key: 'intakeTemp',
        label: 'Intake',
        type: 'select',
        required: isDirectUniversity,
        // showSearch: true,
        options: intakesList,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.label}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            intakeTemp: x
          })
          if (x) {
            let intakeVal = x.split('-')
            let intake = {
              month: intakeVal[0],
              year: intakeVal[1]
            }
            setIntake(intake)

          } else {
            setIntake({})
          }
        }
      },
      /*   {
             key: 'studentShore',
             label: 'Student Shore',
             type: 'select',
             showSearch: true,
             hidden: !(getFieldValue('countryId') == countryIds.canada),
             required: (getFieldValue('countryId') == countryIds.canada),
             options: onShoreOptions,
             keyAccessor: x => x.value,
             valueAccessor: x => x.name,
             onChange: x => {
                 props.form.setFieldsValue({
                     studentShore: x
                 })
             }
         },*/
      {
        key: 'testUserName',
        label: `${getFieldValue('englishRequirments.examType')} Username`,
        hidden: !testLoginShow,
        required: testLoginShow,
        placeholder: `Enter ${getFieldValue('englishRequirments.examType')} Username`
      },
      {
        key: 'testPassword',
        label: `${getFieldValue('englishRequirments.examType')} Password`,
        hidden: !testLoginShow,
        required: testLoginShow,
        placeholder: `Enter ${getFieldValue('englishRequirments.examType')} Password`
      }

    ],
    counsellingFields: [
      {
        key: 'countryGroup',
        label: 'Country',
        required: true,
        type: 'select',
        showSearch: true,
        hidden: !((getFieldValue('universityId') == CornerStoneCollege && countryId == countryIds.canada) || countryId == countryIds.uk),
        options: ConditionOptions,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            isCounselorBonusChecked: x
          })
        }
      },

      {
        key: 'counselorName',
        label: `Counselor Name`,
        required: true,
        placeholder: `Counselor Name`
      },
      {
        key: 'counselorDesignation',
        label: `Counselor Designation`,
        required: counselorDesignation,
        placeholder: `Counselor Designation`
      }

    ]
  }

  useEffect(() => {
    dispatch({ type: 'STUDENT_HIDE_LOADER' })
    dispatch(listAllCountries({ results: 1000000 }))
    appEvents.loadCountryGroups()
    setDefaultCountry()
    loadBoard()
    events.loadParams()
  }, [])


  useEffect(() => {
    if (getFieldValue('countryGroup')) {
      appEvents.loadCountry(getFieldValue('countryGroup'))
    }
  }, [getFieldValue('countryGroup'), allCountryGroups])

  useEffect(() => {
    appEvents.loadIntakes()
  }, [getFieldValue('courseId'), getFieldValue('campusId')])


  const events = {
    stepChange: (val = 0) => {
      let activeId = ''
      setState({
        ...state
        // currentStep: val
      })
      switch (val) {
        case 0:
          activeId = 'generalInformation'
          break
        case 1:
          activeId = 'educationHistory'
          break
        case 2:
          activeId = 'testScore'
          break
        case 3:
          activeId = 'backgroundInformation'
          break
        case 4:
          activeId = 'applications'
          break
        case 5:
          activeId = 'uploadDocument'
          break
        default:
          activeId = 'generalInformation'
      }
      // console.log(activeId)
      document.getElementById(activeId).scrollIntoView({
        behavior: 'smooth'
      })
    },
    chooseDocument: (e) => {
      let { name, files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          [name]: files[0]
        })
      }
    },
    handleSubmit: (e) => {
      const { form } = props
      e.preventDefault()
      form.validateFieldsAndScroll(async (err, valData) => {
        if (!err) {
          let { education, englishRequirments } = valData
          let { countryName, stateName, cityName, disabledEducation, disabledEnglish } = state
          if (state.fileList && state.fileList.length) {
            valData.document = state.fileList
          }

          if (education) {
            if (education.percentage < 1) {
              notification.warning({
                message: 'Enter greater then 0 Percentage'
              })
              return
            }
            if (education.qualification == 'XII') {
              if (education.maths < 1) {
                notification.warning({
                  message: 'Enter greater then 0 Math score'
                })
                return
              }
              if (education.english < 1) {
                notification.warning({
                  message: 'Enter greater then 0 English score'
                })
                return
              }
            }
          }
          if (englishRequirments && englishRequirments.examType) {
            if (englishRequirments.examType == 'I don\'t have this') {
              if (englishRequirments.english12ThMarks <= 0) {
                notification.warning({
                  message: 'Enter greater then 0 English 12Th marks'
                })
                return
              }
            } else {
              if (englishRequirments.overall <= 0) {
                notification.warning({
                  message: 'Enter greater then 0 Overall Rank'
                })
                return
              }
              if (englishRequirments.listening <= 0) {
                notification.warning({
                  message: 'Enter greater then 0 Listening Rank'
                })
                return
              }
              if (englishRequirments.reading <= 0) {
                notification.warning({
                  message: 'Enter greater then 0 Reading Rank'
                })
                return
              }
              if (englishRequirments.writing <= 0) {
                notification.warning({
                  message: 'Enter greater then 0 Writing Rank'
                })
                return
              }
              if (englishRequirments.speaking <= 0) {
                notification.warning({
                  message: 'Enter greater then 0 Speaking Rank'
                })
                return
              }
            }
          }
          if (!countryCode) {
            notification.error({
              message: 'Please choose Country Code'
            })
            return
          } else {
            valData.countryISOCode = countryCode
          }
          valData = { ...valData, countryName, stateName, cityName, intake, disabledEducation, disabledEnglish }
          valData.countryId = countryId

          if (valData.countryId == countryIds.uk) {
            let findDoc = _.find(requiredDocument, (item) => {
              if (!state[item] || (state[item] && !state[item].name)) {
                return true
              }
            })
            if (findDoc) {
              notification.warning({
                message: `Choose ${findDoc} document.`
              })
              return
            }
          }
          if (valData.countryId == countryIds.canadaOnshore) {
            let findDoc = _.find(canadaOnShoreRequiredDocument, (item) => {
              if (!state[item] || (state[item] && !state[item].name)) {
                return true
              }
            })
            if (findDoc) {
              notification.warning({
                message: `Choose ${findDoc} document.`
              })
              return
            }
          }

          if (isCounselorBonusChecked === true) {
            if (!counselorName || !counselorDesignation) {
              notification.warning({
                message: 'Please Enter Counselor Name and Designation'
              })
              return
            } else {
              valData.isCounselorBonusChecked = isCounselorBonusChecked
              valData.counsellorName = counselorName
              valData.counsellorDesignation = counselorDesignation
            }
          }
          if (countryId == countryIds.canadaOnshore && isOnshoreConditionChecked == false) {
            notification.warning({
              message: 'Please Confirm Before Submit'
            })
            return
          }

          events.checkCourseBranchUser(valData).then(async (resp) => {
            if (resp && resp.type) {
              valData.application = [resp.application]
              let fd = new FormData()
              fd.append('obj', JSON.stringify(valData))
              _.each(DocumentTypes, (item) => {
                if (state[item]) {
                  fd.append([item], state[item])
                }
              })

              let data = await dispatch(addStudent(fd, pageType == 'direct'))
              if (data && !data.error) {
                setIntake({})
                form.resetFields()
                _.each(DocumentTypes, (item) => {
                  initialState[item] = ''
                })
                setState({
                  ...initialState,
                  uploadKey: moment()
                })
                setIsOnShoreConditionChecked(false)
              }

              callBackFxn(data.data)
            } else {
              notification.warn({ message: resp.message })
            }
          })
        } else {
          notification.warning({
            message: 'Fill All Required Fields'
          })
        }
      })
    },
    checkCourseBranchUser: (valData) => {
      return new Promise(async (resolve, reject) => {
        delete selectedCourse['englishRequirments']

        valData = { ...valData, ...selectedCourse }

        valData.courseUniversity = valData.universityId
        valData.universityCountry = valData.countryId
        valData.country = _.find(allCountry, (item) => {
          return item._id == valData.countryId
        })
        if (valData.country && valData.country.countryName) {
          valData.country = valData.country.countryName
        }
        valData.university = _.find(countryUniversity, (item) => {
          return item._id == valData.universityId
        })
        if (valData.university && valData.university.universityName) {
          valData.applicationFee = valData.university.applicationFee
          valData.university = valData.university.universityName
        }

        let { success, message } = await dispatch(checkAddStudentInAustraliaFxn(valData))
        if (success) {
          resolve({ type: true, application: valData })
        } else {
          resolve({ type: false, message })
        }
      })
    },
    uploadDocument: (v) => {
      setState({
        ...state,
        fileList: v.fileList
      })
    },
    chooseCountry: (name) => {
      let countryName = _.find(allCountries, (item) => {
        return item.name == name
      })
      if (countryName) {
        setState({
          ...state,
          countryName: countryName.name ? countryName.name : '',
          allStates: countryName.id ? LoadState(countryName.id) : []
        })
      }
    },
    chooseState: (name) => {
      let stateName = _.find(allStates, (item) => {
        return item.name == name
      })
      if (stateName) {
        setState({
          ...state,
          stateName: stateName.name ? stateName.name : ''
          // allCities: stateName.id ? LoadCity(stateName.id) : []
        })
      }
    },
    chooseCity: (name) => {
      setState({
        ...state,
        cityName: name ? name : ''
      })
    },
    loadParams: () => {
      let searchParams = new URLSearchParams(window.location.search)
      let university = searchParams.get('university')
      let country = searchParams.get('country')
      let countryGroup = searchParams.get('countryGroup')
      if (country) {
        appEvents.loadUniversity(country)
        props.form.setFieldsValue({
          countryGroup: countryGroup,
          countryId: country
        })
        setCountryId(country)

      }
      if (university) {
        setTimeout(() => {
          props.form.setFieldsValue({
            universityId: university
          })
          appEvents.loadCourse(university)
        }, 200)
      }
    }
  }


  const customDot = (dot, { status, index }) => (
    <Popover
      content={
        <span>
          step {index} status: {status}
        </span>
      }>
      {dot}
    </Popover>
  )

  useEffect(() => {


    let header = document.getElementById('header')

  })

  const scrollToIds = (element) => {
    document.getElementById(element).scrollIntoView({
      behavior: 'smooth'
    })
  }

  const columns = [
    {
      title: 'Course',
      key: 'courseName',
      dataIndex: 'courseName'
    },
    {
      title: 'University',
      key: 'university',
      dataIndex: 'university'
    },
    {
      title: 'Country',
      key: 'country',
      dataIndex: 'country'
    },
    {
      title: 'Intake',
      key: 'intake',
      dataIndex: 'intake',
      render: (item) => {
        return (
          <div>
            {item && item.year ? `${item.month}, ${item.year}` : null}
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: '_id',
      dataIndex: '_id',
      render: (item, record) => {
        return (
          <Button shape={'circle'} icon={'delete'} onClick={() => events.removeApplication(record)}></Button>
        )
      }
    }

  ]


  useEffect(() => {
    if (allCountry.length === 1 && allCountry[0]._id) {
      setFieldsValue({
        countryId: allCountry[0]._id
      })
      setCountryId(allCountry[0]._id)
    }
  }, [allCountry.length === 1 && allCountry[0]._id])


  useEffect(() => {
    if (countryId) {
      appEvents.loadUniversity(countryId)
    }
  }, [countryId])


  const ApplicationBox = (
    <div>
      <div className='heading-form stuHeader  d-flex align-items-center mt-4 mb-3'
           id={'manageApplication'}>
        <h5><span className='img'><img src='/dist/img/check.svg' alt='' /></span> Apply for an application
        </h5>
      </div>
      <div className='card-body table-responsive p-0'>
        <div className='card unizportal'>
          <Row gutter={24} className={'rowWrap'}>
            {formFields.applicationFields.map((item, key) => {
              return (
                !item.hidden &&
                <Col span={8} md={8} sm={12} xs={24} key={key} className={'mb10'}>
                  <GetEachFormFields
                    item={item}
                    getFieldDecorator={getFieldDecorator}
                    formItemLayout={formItemLayout} />
                </Col>
              )
            })}
          </Row>
          {
            (
              (getFieldValue('universityId') == CornerStoneCollege && countryId == countryIds.canada) ||
              countryId == countryIds.uk
            ) &&
            <Card className={'mt10'}>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label='Do you want to add counsellor bonus?'>
                    {getFieldDecorator('isCounselorBonusChecked', {
                      rules: [{
                        required: true,
                        message: 'Please choose counsellor bonus?'
                      }]
                    })(
                      <Select value={isCounselorBonusChecked} onChange={handleCheckboxChange}
                              placeholder={'Counselor Bonus'}
                              required={true}>
                        {ConditionOptions.map((item) => {
                          return (
                            <Option value={item.value} key={item.name}>{item.name}</Option>
                          )
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                {isCounselorBonusChecked && (
                  <>
                    <Col span={8}>
                      <Form.Item label='Counselor Name'>
                        {getFieldDecorator('counselorName', {
                          rules: [
                            {
                              required: isCounselorBonusChecked,
                              message: 'Please enter counselor name'
                            }
                          ]
                        })(
                          <Input
                            value={counselorName}
                            placeholder={'Counselor name'}
                            className={'form-control'}
                            onChange={(e) => setCounselorName(e.target.value)}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label='Counselor Designation'>
                        {getFieldDecorator('counselorDesignation', {
                          rules: [
                            {
                              required: isCounselorBonusChecked,
                              message: 'Please enter counselor designation'
                            }
                          ]
                        })(
                          <Input
                            value={counselorDesignation}
                            className={'form-control'}
                            placeholder={'Counselor designation'}
                            onChange={(e) => setCounselorDesignation(e.target.value)}
                          />
                        )}
                      </Form.Item>
                    </Col>

                  </>
                )}

              </Row>

            </Card>
          }

        </div>

      </div>
    </div>
  )


  return (
    <div className={'mt-4'}>
      {user && user.userType == 'agent' ? <AgreementAlerts user={user} /> : null}
      <div className='row'>
        <div className='col-lg-12'>
          <Form className={'vertical-form'} autoComplete='off'>
            <div className='student-form-tab card'>
              <ul className={'pointerUl'}>
                <li onClick={() => scrollToIds('generalInformation')}>
                  <span className='img'>
                    <img src={'../dist/icons/checkBlue.png'} className={'stuIcon'} />
                  </span> general information
                </li>
                <li onClick={() => scrollToIds('education')}>
                  <span className='img'>
                    <img src={'../dist/icons/checkBlue.png'} className={'stuIcon'} />
                  </span> education
                </li>
                <li onClick={() => scrollToIds('testScore')}>
                  <span className='img'>
                    <img src={'../dist/icons/checkBlue.png'} className={'stuIcon'} />
                  </span> test scores
                </li>
                <li onClick={() => scrollToIds('backgroundInformation')}>
                  <span className='img'>
                    <img src={'../dist/icons/checkBlue.png'} className={'stuIcon'} />
                  </span> background information
                </li>
                <li onClick={() => scrollToIds('manageApplication')}>
                  <span className='img'>
                    <img src={'../dist/icons/checkBlue.png'} className={'stuIcon'} />
                  </span> application
                </li>
                <li onClick={() => scrollToIds('uploadDocuments')}>
                  <span className='img'>
                    <img src={'../dist/icons/checkBlue.png'} className={'stuIcon'} />
                  </span> upload documents
                </li>
              </ul>
            </div>

            <div className='form-box mt-4'>
              <>
                <div className='heading-form stuHeader  d-flex align-items-center mb-3'
                     id={'generalInformation'}>
                  <h5><span className='img'><img src='/dist/img/check.svg' alt='' /></span> general
                    information</h5>
                </div>
                <div className='card unizportal'>
                  {/*general*/}
                  <Row gutter={24} className={'rowWrap'}>

                    {formFields.generateInputs.map((item, key) => {
                      return (
                        <Col span={8} md={8} sm={12} xs={24} key={key} className={'mb10'}>
                          <GetEachFormFields
                            item={item}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout} />
                        </Col>
                      )
                    })}
                    {formFields.addressInput.map((item, key) => {
                      return (
                        // <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={12} xs={24} key={key}
                        //      className={'mb10'}>
                        //   <GetEachFormFields
                        //     item={item}
                        //     getFieldDecorator={getFieldDecorator}
                        //     formItemLayout={formItemLayout} />
                        // </Col>
                        <React.Fragment key={key}>
                          {item.customField ? <Col span={item.span ? item.span : 8}>
                            {item.customField}
                          </Col> : !item.hidden &&
                            <Col span={item.span ? item.span : 8} key={key}
                                 className={'mb10'}>
                              <InputBox title={item.label1 ? item.label1 : ''}>
                                <GetEachFormFields
                                  item={item}
                                  getFieldDecorator={getFieldDecorator}
                                  formItemLayout={formItemLayout} />
                              </InputBox>
                            </Col>}
                        </React.Fragment>
                      )
                    })}

                  </Row>
                </div>
              </>

              <>
                {/*application box show for australia L1 country*/}
                {enabledStudentLogin ? ApplicationBox : null}
              </>

              <>
                <div className='heading-form stuHeader  d-flex align-items-center mt-4 mb-3'
                     id={'education'}>
                  <h5><span className='img'><img src='/dist/img/check.svg' alt='' /></span> education
                  </h5>
                </div>
                <div className='card unizportal'>
                  <div className='inner-form'>

                    {enabledStudentLogin && countryId == countryIds.australia ?
                      <Row gutter={24}>
                        <Col span={24}>
                          <label>
                            <Checkbox
                              onChange={() => {
                                setState({
                                  ...state,
                                  disabledEducation: !state.disabledEducation
                                })
                              }}
                              name={'disabledEducation'}
                              value={state.disabledEducation} />
                            <span className={'ml10'}>
                                                The student doesn't need to provide this information.
                                                </span>
                          </label>
                        </Col>
                      </Row> : null}

                    {!state.disabledEducation && <>
                      <Row gutter={24}>
                        {formFields.educationInput.map((item, key) => {
                          return (
                            <Col span={item.span ? item.span : 8}
                                 md={item.span ? item.span : 8}
                                 sm={12} xs={24} key={key}
                                 className={'mb10'}>
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout} />
                            </Col>
                          )
                        })}
                      </Row>
                      <Row gutter={24}>
                        {formFields.educationInputField.map((item, key) => {
                          return (
                            <Col span={item.span ? item.span : 8}
                                 md={item.span ? item.span : 8}
                                 sm={12} xs={24} key={key}
                                 className={'mb10'}>
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout} />
                            </Col>
                          )
                        })}
                      </Row>
                    </>}
                  </div>
                </div>
              </>
              <>
                <div className='heading-form stuHeader  d-flex align-items-center mt-4 mb-3'
                     id={'testScore'}>
                  <h5><span className='img'><img src='/dist/img/check.svg' alt='' /></span> test scores
                  </h5>
                </div>

                <div className='card unizportal'>
                  <div className='inner-form'>
                    {/*test score*/}
                    {enabledStudentLogin && countryId == countryIds.australia ?
                      <Row gutter={24}>
                        <Col span={24}>
                          <label>
                            <Checkbox
                              onChange={() => {
                                setState({
                                  ...state,
                                  disabledEnglish: !state.disabledEnglish
                                })
                              }}
                              name={'disabledEnglish'}
                              value={state.disabledEnglish} />
                            <span className={'ml10'}>
                                                    The student doesn't need English Eligibility.
                                                </span>
                          </label>
                        </Col>
                      </Row> : null}

                    {!state.disabledEnglish && <>
                      <Row gutter={24}>
                        {formFields.testInput.map((item, key) => {
                          return (
                            !item.hidden &&
                            <Col span={item.span ? item.span : 8}
                                 md={item.span ? item.span : 8}
                                 sm={12} xs={24} key={key}
                                 className={'mb10'}>
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout} />
                            </Col>
                          )
                        })}
                      </Row>
                      <Row gutter={24}>
                        {formFields.testInputFields.map((item, key) => {
                          return (
                            <Col span={item.span ? item.span : 8}
                                 md={item.span ? item.span : 8}
                                 sm={12} xs={24} key={key}
                                 className={'mb10'}>
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout} />
                            </Col>
                          )
                        })}
                      </Row>
                    </>}
                  </div>
                </div>
              </>
              <>
                <div className='heading-form stuHeader  d-flex align-items-center mt-4 mb-3'
                     id={'backgroundInformation'}>
                  <h5><span className='img'><img src='/dist/img/check.svg' alt='' /></span> background
                    information</h5>
                </div>

                <div className='card unizportal'>
                  <div className='inner-form'>
                    {/*background information*/}
                    <Row gutter={24}>
                      {formFields.backgroundInfoInput.map((item, key) => {
                        return (
                          <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8}
                               sm={12} xs={24} key={key}
                               className={'mb10'}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />
                          </Col>
                        )
                      })}
                    </Row>
                  </div>
                </div>
              </>

              <>
                {/*application box show for rest of australia L1 country*/}
                {!enabledStudentLogin ? ApplicationBox : null}
              </>
              <>
                <div className='heading-form stuHeader  d-flex align-items-center mt-4 mb-3'
                     id={'uploadDocuments'}>
                  <h5><span className='img'><img src='/dist/img/check.svg' alt='' /></span> upload
                    documents</h5>
                </div>

                <div className='card unizportal'>
                  <div className='inner-form'>
                    <Row gutter={24} key={state.uploadKey}>
                      {DocumentTypes.map((item, key) => {
                        let requiredDoc = false
                        if (countryId == countryIds.uk) {
                          requiredDoc = requiredDocument.includes(item) && countryId == countryIds.uk
                        }
                        if (countryId == countryIds.canadaOnshore) {
                          requiredDoc = canadaOnShoreRequiredDocument.includes(item)
                        }
                        let title = `${requiredDoc ? '* ' : ''}${item} Document`
                        return (
                          <Col span={8} md={8} sm={12} xs={24} key={key}>
                            <InputBox title={title}>
                              <Input type={'file'} name={item} id={item}
                                     className={'form-control'}
                                     onChange={(e) => {
                                       events.chooseDocument(e)
                                     }} />
                            </InputBox>
                          </Col>
                        )
                      })}
                    </Row>
                    {
                      countryId == countryIds.canadaOnshore &&
                      <Row className={'mt20'}>
                        <Col>
                          <Checkbox
                            checked={isOnshoreConditionChecked}
                            onChange={handleOnshoreCheckboxChange}
                          >
                            <strong style={{ color: 'black' }}>
                              You have selected the student's current location as inside Canada.
                              Therefore, this application will be processed by the Uniz Portal Canadian onshore team.
                              Please confirm to proceed.
                            </strong>
                          </Checkbox>
                        </Col>
                      </Row>
                    }

                    <button className='btn' type='submit' onClick={events.handleSubmit}>save
                    </button>
                  </div>
                </div>
              </>

            </div>
          </Form>
        </div>
      </div>

    </div>
  )
}


const WrappedAddStudent = Form.create()(AddStudent)

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})

export default connect(
  mapStateToProps,
  null
)(WrappedAddStudent)
