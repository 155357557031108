import {
    unizHomeEnquiryUrl,
    enquiryListUrl,
    singleEnquiryUrl,
    addEnquiryNoteUrl,
    enquiryNotesUrl,
    enquiryActivitiesUrl,
    addEnquiryCommentsUrl,
    enquiryDocumentsUrl,
    getEnquiryDocsUrl,
    enquiryStatusUrl, emergencyDetailsUrl,
    guarantorDetailsUrl,
    singlePropertyUrl,
    propertyListUrl,
    searchPropertyUrl,
    updateSinglePropertyUrl,
    suggestedPropertyUrl, getSuggestPropertyUrl,
    deleteEnquiryDocUrl,
    enquiryActivityUrl,
    addUnizhomePropertyUrl,
    savePropertyUrl,
    getPropertyListUrl,
    unizhomePropertyUrl,
    updateUnizhomePropertyUrl,
    updatePropertyFaqUrl,
    updatePropertyFeatureUrl,
    deleteUnizhomeFaqUrl,
    addUnizhomeFaqUrl,
    deleteUnizhomePolicyUrl,
    addUnizhomeRoomUrl,
    editUnizhomeRoomUrl,
    deleteUnizhomeRoomUrl,
    commonAmenitiesUrl
} from '../api'
import {hidePageLoad, showPageLoad} from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import { notification } from 'antd'
import {addEnquiryUrl} from "../../../unizHome/apis";


export const unizHomeEnquiryFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.get(unizHomeEnquiryUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const enquiryListFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(enquiryListUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const singleEnquiryFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(singleEnquiryUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const addEnquiryNoteFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addEnquiryNoteUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}

export const enquiryNotesFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(enquiryNotesUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const enquiryActivitiesFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(enquiryActivitiesUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const addEnquiryCommentsFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addEnquiryCommentsUrl(), valData, getToken())
    dispatch(hidePageLoad())

    return data
}

export const enquiryDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(enquiryDocumentsUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}

export const getEnquiryDocsFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getEnquiryDocsUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}



export const enquiryStatusFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(enquiryStatusUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}
export const emergencyDetailsFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(emergencyDetailsUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        // notification.success({
        //     message: data.message || 'Success'
        // })
    }
    return data
}
export const guarantorDetailsFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(guarantorDetailsUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        // notification.success({
        //     message: data.message || 'Success'
        // })
    }
    return data
}

export const singlePropertyFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(singlePropertyUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const propertyListFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(propertyListUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const searchPropertyFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(searchPropertyUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const updateSinglePropertyFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateSinglePropertyUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}

export const addEnquiryFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let { data } = await axios.post(addEnquiryUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const suggestedPropertyFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let { data } = await axios.post(suggestedPropertyUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const getSuggestPropertyFxn = filters => async dispatch => {

    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getSuggestPropertyUrl(), config)
    dispatch(hidePageLoad())

    return data.data
}


export const deleteEnquiryDocFxn = (filters) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let { data } = await axios.delete(deleteEnquiryDocUrl(), config)
    dispatch(hidePageLoad())
    return data
}

export const enquiryActivityFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let { data } = await axios.post(enquiryActivityUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}


export const addUnizhomePropertyFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let { data } = await axios.post(addUnizhomePropertyUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}
export const savePropertyFxn = filters => async dispatch => {

    // dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(savePropertyUrl(), config)
    // dispatch(hidePageLoad())

    return data.data
}

export const getPropertyListFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getPropertyListUrl(), config)
    dispatch(hidePageLoad())

    return data.data
}

export const unizhomePropertyFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(unizhomePropertyUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}


export const updateUnizhomePropertyFxn = (propertyId, valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        params: { propertyId },
        ...(await getToken())
    }
    dispatch(showPageLoad())
    let { data } = await axios.put(updateUnizhomePropertyUrl(), valData, config)
    dispatch(hidePageLoad())
    return data
}

export const updatePropertyFaqFxn = (propertyId, valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        params: { propertyId },
        ...(await getToken())
    }
    dispatch(showPageLoad())
    let { data } = await axios.put(updatePropertyFaqUrl(), valData, config)
    dispatch(hidePageLoad())
    return data
}

export const updatePropertyFeatureFxn = (propertyId, valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        params: { propertyId },
        ...(await getToken())
    }
    dispatch(showPageLoad())
    let { data } = await axios.put(updatePropertyFeatureUrl(), valData, config)
    dispatch(hidePageLoad())
    return data
}

export const deleteUnizhomeFaqFxn = (filters) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let { data } = await axios.delete(deleteUnizhomeFaqUrl(), config)
    dispatch(hidePageLoad())
    return data
}

export const addUnizhomeFaqFxn = (propertyId, valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        params: { propertyId },
        ...(await getToken())
    }
    dispatch(showPageLoad())
    let { data } = await axios.put(addUnizhomeFaqUrl(), valData, config)
    dispatch(hidePageLoad())
    return data
}

export const deleteUnizhomePolicyFxn = (filters) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let { data } = await axios.delete(deleteUnizhomePolicyUrl(), config)
    dispatch(hidePageLoad())
    return data
}

export const addUnizhomeRoomFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let { data } = await axios.post(addUnizhomeRoomUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const editUnizhomeRoomFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let { data } = await axios.post(editUnizhomeRoomUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const deleteUnizhomeRoomFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let { data } = await axios.post(deleteUnizhomeRoomUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const commonAmenitiesFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(commonAmenitiesUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}