import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Form, notification, Row, Select, Tooltip, Icon, Table, Popconfirm, Checkbox,
Collapse} from "antd";
import { connect } from "react-redux";
import { StatusTypes } from "../propertyUtils";
import { useDispatch } from "react-redux";
import {editUnizhomeRoomFxn} from "../action";
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import moment from "moment";
import AmenitiesModal from "../view/addPropertyAmenities";
import EditPropertyImage from "./editPropertyImage";

const { Option } = Select;
const { Panel } = Collapse;

const EditPropertyRoom = (props) => {
    let dispatch = useDispatch();
    let { visible, onClose, form: { getFieldDecorator, setFieldsValue }, propertyId, room} = props;
    const [amenities, setAmenities] = useState({})
    const [openAmenities, setOpenAmenities] = useState({
        visible: false,
        amenities: {},
    })
    const [imagesVisible, setImagesVisible] = useState({
        visible: false,
        imageData: {},
        propertyId: ''
    })

    const setDefaultValues = () => {
        setTimeout(() => {
                setFieldsValue({
                name: room.name,
                lease_duration: room.meta && room.meta.lease_duration || '',
                lease_duration_days: room.meta && room.meta.lease_duration_days || '',
                lease_duration_unit: room.meta && room.meta.lease_duration_unit || '',
                roomStatus: room.roomStatus || ''
        });
        }, 100)
    }
    let inputTypes = {
        fields: [
            {
                key: 'name',
                label: 'Room Name',
                placeholder: 'Edit Room',
                type: 'text',
                required: true,
            },
            {
                key: 'lease_duration',
                label: 'Lease Duration',
                placeholder: 'Enter lease duration',
                type: 'number',
                required: true,
            },
            {
                key: 'lease_duration_days',
                label: 'Lease Duration (Days)',
                placeholder: 'Enter lease duration in days',
                type: 'number',
                required: true,
            },
            {
                key: 'lease_duration_unit',
                label: 'Lease Duration Unit',
                placeholder: 'Enter lease duration unit',
                type: 'text',
                required: true,
            },
            // {
            //     key: 'status',
            //     label: 'Status',
            //     placeholder: 'Edit status',
            //     type: 'text',
            //     required: true,
            // },
            {   key: 'roomStatus',
                label: 'Status',
                placeholder: 'Edit Status',
                type: 'select',
                options: StatusTypes,
                keyAccessor: x => `${x.name}`,
                valueAccessor: x => `${x.value}`,
                onChange: x => {
                    setFieldsValue({
                        roomStatus: x
                    });
                }
            }
        ]
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { form } = props;
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                const updatedRoom = {
                    _id: room._id,
                    name: valData.name,
                    roomStatus: valData.roomStatus,
                    meta: {
                        lease_duration: valData.lease_duration,
                        lease_duration_days: valData.lease_duration_days,
                        lease_duration_unit: valData.lease_duration_unit,
                    },
                };

                const resp = await dispatch(editUnizhomeRoomFxn( { propertyId : propertyId, room: [updatedRoom] }));
                if (resp && resp.success) {
                    notification.success({ message: resp.message });
                    onClose();
                }
            } else {
                notification.warning({
                    message: 'Please fill in all required fields'
                });
            }
        });
    };

    const handleUpdateFeatures = async (amenities) => {
        console.log(amenities, "ammennties")
        const updatedFeatures = {
            ...amenities,
            _id: room._id,
        }
        let resp = await dispatch(editUnizhomeRoomFxn({ propertyId : propertyId, room: [updatedFeatures] }))
        console.log(resp, "respppp")
        if (resp.success) {
            notification.success({message: resp.message})
        } else {
            notification.error({message: resp.message})
        }
    }
    const imagesColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (val, record, index) => {
                return index + 1
            }
        },
        {
            title: 'Image',
            dataIndex: 'path',
            key: 'path',
            render: (val, record) => {
                return (
                    <p>
                        <img src={record.path} height='100px' width='120px' />
                    </p>
                )
            }
        },
        {
            title: 'Caption',
            dataIndex: 'caption',
            key: 'caption',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Action',
            key: '_id',
            dataIndex: '_id',
            width: 150,
            render: (val, record) => {
                return (
                    <React.Fragment>
                        <Tooltip title='Edit Image'>
                            <a
                                className={'linkAA'}
                                onClick={() => events.showImagesModal(record)}
                            >
                                <Icon type={'edit'} />
                            </a>
                        </Tooltip>
                    </React.Fragment>
                )
            }
        }

    ]
    const videosColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (val, record, index) => {
                return index + 1
            }
        },
        {
            title: 'Video',
            dataIndex: 'thumbnail_url',
            key: 'thumbnail_url',
            render: (val, record) => {
                return (
                    <a href={record.thumbnail_url}>
                        <img src={record.thumbnail_url} height='100px' width='120px'/>
                    </a>
                )
            }
        },
        {
            title: 'Caption',
            dataIndex: 'caption',
            key: 'caption',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Upload Date',
            dataIndex: 'upload_date',
            key: 'upload_date',
        },
        {
            title: 'Action',
            key: '_id',
            dataIndex: '_id',
            width: 150,
            render: (val, record) => {
                return (
                    <React.Fragment>
                        <Tooltip title='Edit Image'>
                            <a
                                className={'linkAA'}
                            >
                                <Icon type={'edit'} />
                            </a>
                        </Tooltip>
                    </React.Fragment>
                )
            }
        }

    ]
    const policyColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (val, record, index) => {
                return index + 1
            }
        },
        {
            title: "Display Name",
            dataIndex: "display_name",
            key: "display_name",

        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",

        },
        {
            title: "Description",
            dataIndex: "value",
            key: "value",
            render: (text) => (
                <span>{text && text.length > 180 ? `${text.slice(0, 180)}...` : text}</span>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <>
                    <Tooltip title='Edit'>
                        <a
                            className={'btn'}
                            // onClick={() => events.showFaqModal(record)}
                        >
                            <Icon type={'edit'} />
                        </a>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure, you want to delete this record?"
                            // onConfirm={() => deletePolicy(record._id)}
                        >
                            <a
                                className={'btn'}
                            >
                                <Icon type={'delete'} />
                            </a>
                        </Popconfirm>
                    </Tooltip>
                </>

            ),
        },
    ];
    useEffect(() => {
        setDefaultValues()
    }, [])

    const formItemLayout = {
        labelCol: {
            xs: { span: 0 },
            sm: { span: 0 },
            md: { span: 0 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 }
        }
    }

    const events = {
        showAmenitiesModal: async (amenities) => {
            setOpenAmenities({
                visible: true,
                amenities: amenities,

            })

        },
        hideAmenitiesModal: async () => {
            setOpenAmenities({
                visible: false,
                amenities: {},

            })

        },
        showImagesModal: async (imageData) => {
            setImagesVisible({
                visible: true,
                imageData: imageData,
                propertyId: propertyId
            })

        },
        hideImagesModal: async () => {
            setImagesVisible({
                visible: false,
                imageData: {},
                propertyId: ''
            })
        },
    }
    return (
        <>
            <Modal
                visible={visible}
                onCancel={onClose}
                title={`Edit`}
                width={'60%'}
                footer={null}
            >

                        <Collapse defaultActiveKey={['1']} accordion>
                            <Panel header="Basic Details" key="1">
                                <Form onSubmit={handleSubmit}>
                                    <Row gutter={24} className="wrapBox">
                                        {inputTypes.fields.map((item, key) => {
                                            return (
                                                <React.Fragment key={key}>
                                                    {item.customField ? (
                                                        <Col span={12}>
                                                            {item.customField}
                                                        </Col>
                                                    ) : ((item.hidden == undefined || (!item.hidden)) && (
                                                        <Col span={12}>
                                                            <GetEachFormFields
                                                                item={item}
                                                                getFieldDecorator={getFieldDecorator}
                                                                formItemLayout={formItemLayout}
                                                            />
                                                        </Col>
                                                    ))}
                                                </React.Fragment>
                                            );
                                        })}
                                    </Row>
                                    <Row>
                                        <Col span={4}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" className="btn mt40">
                                                    Update
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Panel>
                            <Panel header="Images" key="2">
                                <Table
                                    dataSource={room.images}
                                    columns={imagesColumns}
                                    rowKey="_id"
                                    bordered
                                />
                            </Panel>
                            <Panel header="Videos" key="3">
                                <Table
                                    dataSource={room.videos}
                                    columns={videosColumns}
                                    rowKey="_id"
                                    bordered
                                />
                            </Panel>
                            <Panel header="Description" key="4">
                                <Table
                                    dataSource={room.description}
                                    columns={policyColumns}
                                    rowKey="_id"
                                    bordered
                                />
                            </Panel>
                                <Panel
                                    key="5"
                                    header={
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <span>Amenities</span>
                                            <Tooltip title="Edit Amenities">
                                                <Icon
                                                    type="edit"
                                                    style={{ fontSize: "16px", cursor: "pointer" }}
                                                    onClick={() => {events.showAmenitiesModal(room.features)}}
                                                />
                                            </Tooltip>
                                        </div>
                                    }
                                >
                                    <div>
                                        <Row gutter={24}>
                                            {room.features && room.features.map((feature, index) => (
                                                <Col span={12} key={feature._id}>
                                                    <div>
                                                        <strong className={'amenities-label'}>{feature.name}</strong>
                                                        <hr/>
                                                        <div
                                                            onChange={checkedValues => {
                                                                console.log(`Updated values for ${feature.name}:`, checkedValues);
                                                            }}
                                                        >
                                                            <Row gutter={12}>
                                                                {feature.values.map(value => (
                                                                    <Col span={12} key={value._id}>
                                                                        <p value={value._id}>
                                                                            <Icon type={'check-circle'}
                                                                                  className={'mr-3'}/>   {value.name}
                                                                        </p>
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </div>
                                                    </div>
                                                    <br />
                                                </Col>
                                            ))}
                                        </Row>

                                    </div>


                            </Panel>
                        </Collapse>
                {openAmenities.visible && (
                    <AmenitiesModal
                        {...openAmenities}
                        onClose={() => events.hideAmenitiesModal()}
                        onSubmit={(updatedAmenities) => {
                            setAmenities(updatedAmenities);
                            handleUpdateFeatures(updatedAmenities);
                        }}
                    />
                )}
                {imagesVisible.visible && (
                    <EditPropertyImage
                        {...imagesVisible}
                        onClose={() => events.hideImagesModal()}

                    />
                )}
            </Modal>
        </>
    );
};

const EditPropertyRoomModal = Form.create()(EditPropertyRoom);

const mapStateToProps = ({ global, router }) => ({
    currentUser: global.currentUser
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditPropertyRoomModal);
