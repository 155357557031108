import React, {useEffect, useState} from "react";
import { Button, Col, Modal, Form, notification, Row, Input } from "antd";
import { connect } from "react-redux";
import moment from 'moment';
import _ from 'lodash';
import { useDispatch } from "react-redux";
import {emergencyDetailsFxn, singleEnquiryFxn} from "../action";
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import {CountryCodeJson as countryCodes, CountryCodeWithFlag} from "../../../components/_utils/countryUtil";

const EmergencyDetailsModal = (props) => {
    const dispatch = useDispatch();
    const { visible, onClose, form: { getFieldDecorator, setFieldsValue}, enquiry } = props;
    const [designatedCountry, setDesignatedCountry] = useState([]);
    const [dialCode, setDialCode] = useState(enquiry.emergencyDialCode || '');
    const setDefaultValues = () => {

        setTimeout(() => {
            setFieldsValue({
                title: enquiry.emergencyTitle,
                fullName: enquiry.emergencyFullName,
                dateOfBirth: enquiry.emergencyDateOfBirth ? moment(enquiry.emergencyDateOfBirth) : null,
                email: enquiry.emergencyEmail,
                dialCode: enquiry.emergencyDialCode,
                mobileNumber: enquiry.emergencyMobileNumber,
                relation: enquiry.emergencyRelation,
                address: enquiry.emergencyAddress,
                address2: enquiry.emergencyAddressLine2,
                city: enquiry.emergencyCity,
                stateName: enquiry.emergencyStateName,
                country: enquiry.emergencyCountry,

            })
        }, 100)
    }

    let loadCountry = () => {
        let designatedArr = [];
        _.each(countryCodes, (item) => {
            designatedArr.push({
                name: `${item.code} - ${item.name}`,
                value: item.name
            });
        });
        setDesignatedCountry(designatedArr);
    };

    const inputFields = [
        { key: 'title', label: 'Title', placeholder: 'Enter Title'},
        { key: 'fullName', label: 'Full Name', placeholder: 'Enter Full Name', required: true },
        { key: 'dateOfBirth', label: 'Date of Birth',type: 'date', placeholder: 'Enter Date of Birth' },
        { key: 'email', label: 'Email', placeholder: 'Enter Email', required: true },
        {
            key: 'dialCode',
            span: 12,
            customField: (
                <div style={{ marginTop: 12 }}>
                    <CountryCodeWithFlag
                        countryCode={dialCode || enquiry.emergencyDialCode}
                        chooseCode={(val) => {
                            setDialCode(val);
                            setFieldsValue({ dialCode: val });
                        }}
                    />
                </div>
            ),
        },
        { key: 'mobileNumber', label: 'Mobile Number', placeholder: 'Enter Mobile Number', required: true },
        { key: 'relation', label: 'Relation', placeholder: 'Enter Relation', required: true },
        { key: 'address', label: 'Address', placeholder: 'Enter Address' },
        { key: 'address2', label: 'Alternate Address', placeholder: 'Enter Alternate Address' },
        {
            key: 'country',
            label: 'Country',
            placeholder: 'Select Country',
            required: true,
            showSearch: true,
            type: 'select',
            options: designatedCountry,
            keyAccessor: x => `${x.name}`,
            valueAccessor: x => `${x.value}`,
            onChange: x => {
                setFieldsValue({
                    country: x
                });
            }
        },

        { key: 'stateName', label: 'State', placeholder: 'Enter State' },
        { key: 'city', label: 'City', placeholder: 'Enter City' },


    ];
    const formItemLayout = {
        labelCol: {
            xs: { span: 0 },
            sm: { span: 0 },
            md: { span: 0 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 }
        }
    }
    const handleSubmit = (e) => {
        const { form } = props;
        e.preventDefault();
        form.validateFieldsAndScroll(async (err, values) => {
            const formValues = { ...values, dialCode };
            if (!err) {
                const payload = { enquiryId: enquiry._id, emergencyDetails: formValues };
                const resp = await dispatch(emergencyDetailsFxn(payload));
                if (resp && resp.success) {
                    notification.success({ message: resp.message });
                    onClose();
                } else {
                    notification.error({ message: resp.message || "Error updating emergency details" });
                }
            } else {
                notification.warning({
                    message: 'Please fill all required fields',
                });
            }
        });
    };

    useEffect(() => {
        setDefaultValues()
        loadCountry();
    },[])

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title="Update Emergency Details"
            width="50%"
            footer={null}
        >
            <div className="form-box emergencyBox">
                <div className="card unizportal">
                    <Form onSubmit={handleSubmit}>
                            <Row gutter={24} className={'rowWrap'}>
                                {inputFields.map((item, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            {item.customField ? (
                                                <Col span={12}>
                                                    {item.customField}
                                                </Col>
                                            ) : ((item.hidden == undefined || (!item.hidden)) && (
                                                <Col span={12}>
                                                    <GetEachFormFields
                                                        item={item}
                                                        getFieldDecorator={getFieldDecorator}
                                                        formItemLayout={formItemLayout}
                                                    />
                                                </Col>
                                            ))}
                                        </React.Fragment>
                                    );
                                })}

                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Update Emergency Details
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

const EmergencyDetailsUpdate = Form.create()(EmergencyDetailsModal);

const mapStateToProps = ({ global }) => ({
    currentUser: global.currentUser
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyDetailsUpdate);
