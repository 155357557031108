import React, { useState } from 'react'
import { Button, Drawer, Form, Input, notification } from 'antd'
import { connect, useDispatch } from 'react-redux'
import { updateExpenseCategoryFxn } from './action'


const UpdateExpenseCategoryDrawer = (props) => {
  let { visible, onClose, record } = props
  const dispatch = useDispatch()
  const [updateExpenseCategoryName, setUpdateExpenseCategoryName] = useState(record.name)

  const updateExpenseCategory = async () => {
    let valData = {}
    if (updateExpenseCategoryName) {
      valData.name = updateExpenseCategoryName
    } else {
      notification.warning({
        message: 'Please enter category name'
      })
      return
    }
    if (record._id) {
      valData.expenseCategoryId = record._id
    }
    let updateExpense = await dispatch(updateExpenseCategoryFxn(valData))
    if (updateExpense.success) {
      onClose()
      setUpdateExpenseCategoryName('')
    }
  }

  return (
    <React.Fragment>
      <Drawer visible={visible}
              width={'40%'}
              closable={true}
              maskClosable={true}
              onClose={onClose}
      >
        <div className='row'>
          <div className='col-lg-12'>
            <div className='form-box mt-4'>
              <div className='d-flex align-items-center mb-3 heading-form'>
                <h5>Update Expense Category</h5>
              </div>
              <div className='card unizportal'>
                <div className={'row'}>
                  <Input
                    placeholder='Enter Expense Category'
                    value={updateExpenseCategoryName}
                    onChange={(e) => setUpdateExpenseCategoryName(e.target.value)}
                    className='form-control'
                  />

                </div>
                <div className={'row mt10'}>
                  <div className={'col-md-12 alignRight'}>
                    <Button
                      type='primary'
                      onClick={updateExpenseCategory}
                      className={'btn btn-success roundNew md'}>
                      Save
                    </Button>
                    <Button
                      className={'btn btn-default roundNew md ml5'}
                      onClick={() => {
                        setUpdateExpenseCategoryName('')
                      }}>
                      Clear
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  )
}

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(UpdateExpenseCategoryDrawer)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)
