import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Form,
  Input,
  notification, Tooltip, Icon, Modal, List, Select
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import { addToDo, toDoListFxn, updateToDoTask } from './action'
import moment from 'moment'
import {
  DefaultTablePagination
} from '../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'
import CommentDrawerToDoList from './commentDrawer'

const { TextArea } = Input
const { Option } = Select

const ToDoList = (props) => {
  let { currentUser } = props
  const [newTodo, setNewTodo] = useState('')
  let [commentState, setCommentState] = useState({
    visible: false,
    taskId: '',
    task: ''
  })
  const [priority, setPriority] = useState('Moderate')
  const [status, setStatus] = useState()
  const [region, setRegion] = useState()
  const [priorityFilter, setPriorityFilter] = useState()
  const [statusFilter, setStatusFilter] = useState()
  const [regionFilter, setRegionFilter] = useState()
  let [priorityState, setPriorityState] = useState({
    visible: false,
    taskId: ''
  })

  const dispatch = useDispatch()
  const tableRef = useRef()
  const tableStyles = {
    boxShadow: '0 8px 12px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px'
  }

  const taskColumns = [
    {
      key: '_id',
      title: '#',
      dataIndex: '_id',
      width: 50,
      render: (item, record, index) => {
        return (
          <>
            {pageState.count * (pageState.page - 1) + (index + 1)}
          </>
        )
      }
    },
    {
      title: 'TO DO',
      dataIndex: 'toDoTask',
      key: 'toDoTask',
      width: 400,
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div className={''}>
              {text}
              {/*<RenderTask text={text} data={record} refreshTable={toDoListFxn()} />*/}
            </div>
          </div>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 180,
      render: (status, record) => {
        return (
          <Select
            defaultValue={status}
            className={'small-select'}
            onChange={(value) => events.handleStatusChange(record._id, value)}
          >
            {statusOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                <span className={'statusCss'} style={{ backgroundColor: option.color }} />
                {option.label}
              </Option>
            ))}
          </Select>
        )
      }
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      width: 180,
      render: (region, record) => {
        return (
          <Select
            defaultValue={region}
            className={'small-select'}
            onChange={(value) => events.handleRegionChange(record._id, value)}
          >
            {regionOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                <img className={'regionFlagCss'} src={`./../dist/img/${option.flag}`} />
                {option.label}
              </Option>
            ))}
          </Select>
        )
      }
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      render: (taskPriority, record) => {
        const priorityColor = events.getPriorityColor(taskPriority)
        return (
          <Button type='link' onClick={() => {
            events.handleOpenPriorityModal(record._id)
          }}>
            <Icon type={'flag'} theme={'filled'}
                  style={{ color: priorityColor, fontSize: '14px' }} />
            {taskPriority && <span style={{ marginLeft: '8px', fontSize: '13px' }}>{taskPriority}</span>}
          </Button>
        )
      }
    },
    {
      title: 'Last Comment Date',
      dataIndex: 'lastCommentDate',
      key: 'lastCommentDate',
      render: (text, record) => {
        let { noOfComments } = record
        return (
          <div>
            <Tooltip title={'View Comments'}>
              <a className={'btn btn-default roundNew xs'} onClick={() => {
                events.showCommentDrawer(record._id, record.toDoTask)
              }}>{noOfComments || 0} Comments</a>
            </Tooltip>
            <div>
              {text ? moment(text).format('DD MMM YY,  HH:MM') : ''}
            </div>
          </div>
        )
      }
    },
    {
      title: 'Date/Added by',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date, record) => {
        let { userId } = record
        return (
          <>
            {moment(date).format('DD MMM YY, HH:MM')}<br />
            {userId && userId.name ? <div className={'font11 textCap'}>({userId.name})</div> : ''}
          </>
        )
      }
    }
  ]
  const priorityOptions = [
    {
      key: 'High',
      label: 'High',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#eca500', fontSize: '16px' }} />
    },
    {
      key: 'Moderate',
      label: 'Moderate',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#4466ff', fontSize: '16px' }} />
    },
    {
      key: 'Low',
      label: 'Low',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: 'gray', fontSize: '16px' }} />
    }
  ]
  const regionOptions = [
    { value: 'uk', label: 'UK', flag: 'united-kingdomFlag.png' },
    { value: 'australia', label: 'Australia', flag: 'australiaFlag.png' },
    { value: 'ireland', label: 'Ireland', flag: 'irelandFlag.png' },
    { value: 'canada', label: 'Canada', flag: 'canadaFlag.png' },
    { value: 'usa', label: 'USA', flag: 'usaFlag.png' },
    { value: 'germany', label: 'Germany', flag: 'germanyFlag.png' }
  ]
  const statusOptions = [
    { value: 'contacted', label: 'Contacted', color: '#FFA500' },
    { value: 'inProgress', label: 'In Progress', color: '#007BFF' },
    { value: 'opportunity', label: 'Opportunity', color: '#28A745' },
    { value: 'onboarded', label: 'Onboarded', color: '#6C757D' }
  ]

  const fetchAllTasks = async (params) => {
    try {
      if (priorityFilter) {
        params['priority'] = priorityFilter
      }
      if (statusFilter) {
        params['status'] = statusFilter
      }
      if (regionFilter) {
        params['region'] = regionFilter
      }
      const { data } = await dispatch(toDoListFxn(params))
      return data
    } catch (error) {
      console.error('Error fetching tasks:', error)
    }
  }

  const addTodo = async () => {
    if (!newTodo) {
      notification.warning({ message: 'Task cannot be empty' })
      return
    }
    if (!priority) {
      notification.warning({ message: 'Please Select Priority' })
      return
    }
    if (!status) {
      notification.warning({ message: 'Please Select Status' })
      return
    }
    if (!region) {
      notification.warning({ message: 'Please Select Region' })
      return
    }
    let formData = { toDoTask: newTodo, priority: priority, status: status, region: region }

    await dispatch(addToDo(formData))
    fetchAllTasks()
    tableRef.current.reload()
    setNewTodo('')
  }

  useEffect(() => {
    fetchAllTasks()
  }, [])
  let [pageState, setPageState] = useState({ count: 10, page: 1, groupCount: 10, groupPage: 1 })

  const events = {
    showCommentDrawer: (taskId, task) => {
      setCommentState({
        taskId: taskId,
        visible: true,
        task: task
      })
    },
    hideCommentDrawer: () => {
      setCommentState({
        taskId: '',
        visible: false,
        task: ''
      })
    },
    handleOpenPriorityModal: (taskId = '') => {
      setPriorityState({
        taskId: taskId,
        visible: true
      })
    },
    handleClosePriorityModal: () => {
      setPriorityState({
        taskId: '',
        visible: false
      })
    },
    handleSelectPriority: async (priority) => {
      events.handleClosePriorityModal()
      if (priorityState.taskId) {
        try {
          const result = await dispatch(updateToDoTask(priorityState.taskId, { priority }))
          if (!result.error) {
            events.reloadGroup()
          } else {
          }
        } catch (error) {
          console.error('Error updating task priority:', error)
        }
      } else {
        setPriority(priority)
      }
    },
    getPriorityColor: (priority) => {
      const priorityOption = priorityOptions.find((option) => option.key === priority)
      return priorityOption ? priorityOption.icon.props.style.color : ''
    },
    reloadGroup: () => {
      if (tableRef.current && tableRef.current.reload) {
        tableRef.current.reload()
      }
    },
    handleStatusChange: async (taskId, status) => {
      const result = await dispatch(updateToDoTask(taskId, { status }))
      if (!result.error) {
        events.reloadGroup()
      }
    },
    handleRegionChange: async (taskId, region) => {
      const result = await dispatch(updateToDoTask(taskId, { region }))
      if (!result.error) {
        events.reloadGroup()
      }
    }
  }

  useEffect(() => {
    if (priorityFilter !== undefined || statusFilter !== undefined || regionFilter !== undefined) {
      events.reloadGroup()
    }
  }, [regionFilter, priorityFilter, statusFilter])


  return (
    <div className='row'>

      <div className='col-lg-12'>
        <div className='form-box mt-4'>
          <div className='d-flex align-items-center mb-3 heading-form'>
            <h5>To Do List Unizhome</h5>
          </div>
          <div className='card unizportal'>
            <div className={'row'}>
              <div className={'col-md-10'}>
                <TextArea
                  placeholder='Enter new TO-DO'
                  value={newTodo}
                  style={{ minHeight: 170 }}
                  onChange={(e) => setNewTodo(e.target.value)}
                  className='input-hover'
                />
              </div>
              <div className={'col-md-2'}>
                <div className={'row'}>
                  <div
                    onClick={() => {
                      events.handleOpenPriorityModal()
                    }}
                    className='col-md-12'
                  >
                    <Input
                      placeholder='Priority'
                      value={priority}
                      // readOnly={true}
                      onChange={(e) => setPriority(e.target.value)}
                      suffix={<Icon type='flag' style={{ color: '#eca500', fontSize: '16px' }} />}
                      className={'custom-disabled-input'}
                    />
                  </div>
                  <div className={'col-md-12 mt10'}>
                    <Select placeholder={'Filter by Status'}
                            className={'md-select'}
                            allowClear={true}
                            value={status}
                            onChange={(value) => {
                              if (value) {
                                setStatus(value)
                              } else {
                                setStatus(null)
                              }
                            }}>
                      <Option disabled={true} value={''}>Select Status</Option>
                      {statusOptions.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className={'col-md-12 mt10'}>
                    <Select placeholder={'Select Region'}
                            className={'md-select'}
                            allowClear={true}
                            value={region}
                            onChange={(value) => {
                              if (value) {
                                setRegion(value)
                              } else {
                                setRegion(null)
                              }
                            }}>
                      <Option disabled={true} value={''}>Select Region</Option>
                      {regionOptions.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className={'row mt10'}>
                    <div className={'col-md-12 alignRight'}>
                      <Button
                        type='primary'
                        onClick={addTodo}
                        className={'btn btn-success roundNew md ml5'}>
                        Add TO-DO
                      </Button>
                      <Button
                        className={'btn btn-default roundNew md ml5'}
                        onClick={() => {
                          setNewTodo('')
                        }}
                      >
                        Clear
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='col-lg-12'>
        <div className='form-box'>
          <div className='card unizportal'>
            <div className={'row mt5'}>
              <div className={'col-md-2'}>
                <Select placeholder={'Filter by Status'}
                        className={'md-select'}
                        allowClear={true}
                        value={statusFilter}
                        onChange={(value) => {
                          if (value) {
                            setStatusFilter(value)
                          } else {
                            setStatusFilter(null)
                          }
                        }}>
                  {statusOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>

              </div>
              <div className={'col-md-2'}>
                <Select placeholder={'Filter by Priority'}
                        className={'md-select'}
                        allowClear={true}
                        value={priorityFilter}
                        onChange={(value) => {
                          if (value) {
                            setPriorityFilter(value)
                          } else {
                            setPriorityFilter(null)
                          }
                        }}>
                  {priorityOptions.map((option) => (
                    <Option key={option.key} value={option.label}>
                      {option.label}
                    </Option>
                  ))}
                </Select>

              </div>
              <div className={'col-md-2'}>
                <Select placeholder={'Filter by Region'}
                        className={'md-select'}
                        allowClear={true}
                        value={regionFilter}
                        onChange={(value) => {
                          if (value) {
                            setRegionFilter(value)
                          } else {
                            setRegionFilter(null)
                          }
                        }}>
                  {regionOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>

              </div>
            </div>

            <div className={'row'}>
              <div className={'col-md-12'}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: 10
                }}>
                </div>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-md-12'}>
                <TableComp columns={taskColumns} style={tableStyles}
                           apiRequest={fetchAllTasks}
                           pagination={DefaultTablePagination()}
                           ref={tableRef}
                           extraProps={{ scroll: { x: 1200 } }}
                           rowKey='_id' />
              </div>
            </div>
          </div>
        </div>
      </div>
      {commentState.visible ?
        <CommentDrawerToDoList
          {...commentState}
          onClose={() => {
            events.hideCommentDrawer()
          }}
          onSubmit={() => {
            tableRef.current.reload()
          }}
          currentUser={currentUser} /> : ''}
      {priorityState.visible ? <Modal
        title='Priority'
        visible={priorityState.visible}
        onCancel={events.handleClosePriorityModal}
        footer={null}
        width={200}>
        <List dataSource={priorityOptions}
              renderItem={(item, index) => (
                <List.Item
                  onClick={() => events.handleSelectPriority(item.key)}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 0',
                    borderBottom: index === 3 ? '1px solid #d9d9d9' : 'none',
                    backgroundColor: priority === item.key ? '#e6f7ff' : 'inherit'
                  }}>
                  {item.icon && <span style={{ marginRight: '8px' }}>{item.icon}</span>}
                  <div style={{
                    textAlign: 'center',
                    fontSize: '14px',
                    color: '#000'
                  }}>{item.label}</div>
                </List.Item>
              )}
        />

      </Modal> : null}
    </div>
  )
}

const mapStateToProps = ({ global }) => ({
  currentUserRights: global.currentUserRights
})

const mapDispatchToProps = (dispatch) => ({ dispatch })

const ToDoListWrappedComponent = Form.create()(ToDoList)

export default connect(mapStateToProps, mapDispatchToProps)(ToDoListWrappedComponent)
