import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Tooltip, Icon, Avatar } from 'antd'
import { withdrawalFxn } from '../actions'
import { useDispatch, connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { countryIds, DefaultTablePagination } from '../../../../../components/_utils/appUtils'
import WithDrawCommission from '../../withDrawCommission'
import moment from 'moment'
import { transactionListFxn } from '../../gicCommission/action'
import { getUser, getUserFxn, listAllUsers } from '../../../../users/actions/user'
import RequestCallback from '../../../drawers/requestCallback'

let GicBranchManagerBox = () => {
  let dispatch = useDispatch()
  let [managerData, setManagerData] = useState({})
  useEffect(() => {
    getBranchManager()
  }, [])
  const getBranchManager = async () => {
    let { data } = await dispatch(getUserFxn({
      userType: 'assistantMarketingManager',
      select: ['name', 'email', 'displayPassword', 'countryCode', 'mobile', 'logo']
    }))
    setManagerData(data)
  }
  return (
    <>
      <div className={'marketingUserBox managerBoxCanada'}>
        <div className={'jcsb'}>
          <div className={'managerHead'}>
            <span style={{ marginLeft: 'auto', fontSize: 14 }}>DESIGNATED MANAGER</span>
          </div>

        </div>
        <div className={'managerBody'}>
          <div className={'center'}
               style={{ paddingRight: 10 }}>
            <Avatar
              size={60}
              style={{
                border: `3px solid #ad2125`,
                marginLeft: 15,
                marginRight: 15,
                left: 0
              }}
              src={
                managerData && managerData.logo &&
                managerData.logo.url ? managerData.logo.url
                  : '../dist/img/user.png'
              }
            />
          </div>
          { managerData && (
              <div className={'jcsb'}>
                <div
                    style={{
                      textAlign: 'left',
                      padding: 5,
                      fontSize: 12,
                      color: '#585858'
                    }}>
                  <div>
                    <div className={'mt5'}>
                      <Icon type={'user'} />&nbsp;
                      {managerData.name}
                    </div>
                    <Icon
                        type={'mail'} />&nbsp; {managerData.email}
                    <br />
                    <Avatar
                        size={15}
                        src={'../dist/img/whatsapp.png'}
                    />&nbsp;
                    +91 {managerData.mobile}
                  </div>
                </div>

              </div>

          )}

        </div>
      </div>

    </>
  )
}

const WithdrawalList = () => {
  let [visibleWithdrawal, setVisibleWithdrawal] = useState(false)
  let [currentBalance, setCurrentBalance] = useState(0)
  let [managerData, setManagerData] = useState({})
  let [requestACallVisible, setRequestACallModal] = useState({
    visible: false,
    assignedTo: '',
    countryId: '',
    requestType: ''
  })
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

  const dispatch = useDispatch()
  let tableRef = useRef()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let { data } = await dispatch(withdrawalFxn({
        ...params,
        regExFilters: ['name']
      }))
      resolve(data)
    })
  }

  useEffect(() => {
    getBranchManager()
  }, [])
  const getBranchManager = async () => {
    let { data } = await dispatch(getUserFxn({
      userType: 'assistantMarketingManager',
      select: ['name', 'email', 'displayPassword', 'countryCode', 'mobile', 'logo']
    }))
    setManagerData(data)
  }


  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (item, record) => {
        let { agentId } = record
        return agentId && agentId.name ? agentId.name : ''
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (item, record) => {
        let { agentId } = record
        return agentId && agentId.email ? agentId.email : ''
      }
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (item, record) => {
        let { agentId } = record
        return agentId && agentId.companyName ? agentId.companyName : ''
      }
    },


    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (item, record) => {
        return (
          <>
            {item == 'Pending' ? <label className='label label-warning sm'>Pending</label> : null}
            {item == 'Approved' ? <label className='label label-success-green sm'>Approved</label> : null}
            {item == 'Cancel' ? <label className='label label-danger sm'>Cancelled</label> : null}
          </>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (item, record) => {
        return (
          <>

          </>
        )
      }
    }

  ]
  useEffect(() => {
    events.loadBalance()
  }, [])
  const events = {
    loadBalance: async () => {
      let data = await dispatch(getUser(user._id))
      if (data && data.gicWallet) {
        setCurrentBalance(data.gicWallet)
      }
    },
    showWithdrawal: () => {
      setVisibleWithdrawal(true)
    },
    hideWithdrawal: () => {
      setVisibleWithdrawal(false)
      events.loadBalance()
    },
    showRequestACall: async (data) => {
      setRequestACallModal({
        visible: true,
        assignedTo: managerData._id,
        countryId: countryIds.canada,
        requestType: 'gicCallback'
      })
    },
    hideRequestACall: async () => {
      setRequestACallModal({
        visible: false,
        assignedTo: '',
        countryId: '',
        requestType: 'gicCallback'
      })
    }
  }


  return (
    <>
      <div className='row  mt-4'>
        <div className='col-lg-12'>
          <div className='card gic-list'>
            <div className='table-head d-flex align-items-center'>
              <h5>Withdrawal List</h5>
              <div className='search-box-table' style={{ padding: 0, width: '36%' }}>
                {user.userType == 'agent' ?
                  <GicBranchManagerBox
                    events={events}
                  /> : null}
              </div>
              <div className='search-box-table'></div>
              <div className='sort-box-table mark-btn'>
                <Tooltip title={'Export Excel'}>
                  <Button className={'roundBtn'} onClick={events.showWithdrawal}>
                    Withdraw Commission &nbsp;
                    <strong>(Curr. Bal &nbsp;<span> ${currentBalance.toFixed(2)} CAD</span>)</strong>
                  </Button>
                </Tooltip> <br />
                <a className={'btn btn-default roundNew2 mt10 w-10'} style={{ width: '70%' }}
                   onClick={() => events.showRequestACall(managerData)}>
                  <Icon type={'phone'} />&nbsp; Request A Call Back</a>


              </div>

            </div>

            <div className='card-body table-responsive'>
              <TableComp
                apiRequest={apiRequest}
                columns={columns}
                ref={tableRef}
                pagination={DefaultTablePagination()}
              />
            </div>
          </div>
        </div>

        {visibleWithdrawal ? (
          <WithDrawCommission
            user={user}
            visible={visibleWithdrawal}
            onClose={events.hideWithdrawal}
            onSubmit={() => {
              events.hideWithdrawal()
              tableRef.current.reload()
            }}
            currentBalance={currentBalance}
          />
        ) : null}

        {requestACallVisible.visible ?
          <RequestCallback
            {...requestACallVisible}
            onClose={events.hideRequestACall}
          /> : null}

      </div>
    </>
  )
}
const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithdrawalList)
