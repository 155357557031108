import {apiUrl, apiUrl2} from '../../../settings'

export const unizHomeEnquiryUrl = () => {
    return apiUrl + '/uniz-home/booking-list'
}
export const enquiryListUrl = () => {
    return apiUrl2 + '/unizhome/enquiry-list'
    // return apiUrl + '/singleUserEnquiry'
}
export const singleEnquiryUrl = () => {
    return apiUrl2 + '/unizhome/single-enquiry'
}

export const addEnquiryNoteUrl = () => {
    return apiUrl2 + '/unizhome/add-note'
}


export const enquiryNotesUrl = () => {
    return apiUrl2 + '/unizhome/get-notes'
}

export const enquiryActivitiesUrl = () => {
    return apiUrl2 + '/unizhome/get-activities'
}
export const enquiryActivityUrl = () => {
    return apiUrl2 + '/unizhome/add-activity'
}


export const enquiryCommentsUrl = () => {
    return apiUrl2 + '/unizhome/get-activities'
}
export const addEnquiryCommentsUrl = () => {
    return apiUrl2 + '/unizhome/add-comment'
}

export const enquiryDocumentsUrl = () => {
    return apiUrl2 + '/unizhome/enquiry-document'
}

export const getEnquiryDocsUrl = () => {
    return apiUrl2 + '/unizhome/get-enquiry-doc'
}

export const enquiryStatusUrl = () => {
    return apiUrl2 + '/unizhome/update-enquiry-status'
}

export const emergencyDetailsUrl = () => {
    return apiUrl2 + '/unizhome/update-emergency-details'
}

export const guarantorDetailsUrl = () => {
    return apiUrl2 + '/unizhome/update-guarantor-details'
}

export const singlePropertyUrl = () => {
    return apiUrl2 + '/single-property'
}

export const propertyListUrl = () => {
    return apiUrl2 + '/property-list'
}
export const searchPropertyUrl = () => {
    return apiUrl2 + '/search-data'
}

export const updateSinglePropertyUrl = () => {
    return apiUrl2 + '/unizhome/update-single_property'
}

export const addEnquiryUrl = () => {
    return apiUrl2 + '/unizhome/add-enquiry'
}

export const suggestedPropertyUrl = () => {
    return apiUrl2 + '/unizhome/add-suggested-property'
}

export const getSuggestPropertyUrl = () => {
    return apiUrl2 + '/unizhome/get-suggested-properties'
}

export const deleteEnquiryDocUrl = () => {
    return apiUrl2 + '/unizhome/delete-enquiry-doc'
}

export const addUnizhomePropertyUrl = () => {
    return apiUrl2 + '/unizhome/add-property'
}

export const savePropertyUrl = () => {
    return apiUrl2 + '/fetch-and-save-properties'
}

export const getPropertyListUrl = () => {
    return apiUrl2 + '/get-property-list'
}

export const unizhomePropertyUrl = () => {
    return apiUrl2 + '/unizhome/single-property'
}

export const updateUnizhomePropertyUrl = () => {
    return apiUrl2 + '/unizhome/update-property'
}

export const updatePropertyFaqUrl = () => {
    return apiUrl2 + '/unizhome/update-faqs'
}

export const updatePropertyFeatureUrl = () => {
    return apiUrl2 + '/unizhome/update-features'
}

export const deleteUnizhomeFaqUrl = () => {
    return apiUrl2 + '/unizhome/delete-faq'
}
export const addUnizhomeFaqUrl = () => {
    return apiUrl2 + '/unizhome/add-faq'
}

export const deleteUnizhomePolicyUrl = () => {
    return apiUrl2 + '/unizhome/delete-policy'
}

export const addUnizhomeRoomUrl = () => {
    return apiUrl2 + '/unizhome/add-room'
}

export const editUnizhomeRoomUrl = () => {
    return apiUrl2 + '/unizhome/edit-room'
}

export const deleteUnizhomeRoomUrl = () => {
    return apiUrl2 + '/unizhome/delete-room'
}

export const commonAmenitiesUrl = () => {
    return apiUrl2 + '/get-common-amenities'
}




