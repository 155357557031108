import React, { useRef } from 'react'
import { Drawer } from 'antd'
import { useDispatch } from 'react-redux'
import { listWithdrawalDataFxn } from '../../withdrawal/actions/withdrawalAction'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import { fixed2Digit, newFormatDisplayDate, rupeeSymbol } from '../../../components/_utils/appUtils'

const WithdrawalDetails = (props) => {
  let dispatch = useDispatch()
  const tableRef = useRef()

  let { onClose, visible, withdrawalId, withdrawalType, withdrawalDataModal } = props

  const loadWithdrawalData = async () => {
    if (withdrawalId && withdrawalType) {
      let params = {
        withdrawalId: withdrawalId,
        withdrawalType: withdrawalType
      }
      let { data } = await dispatch(listWithdrawalDataFxn(params))
      console.log(data, 'data')
      if (data && data.data.length) {
        return data
      }
    }
  }


  const columns = [

    {
      title: 'S.No',
      dataIndex: 'sno',
      key: 'sno',
      render: (item, record, index) => {
        return <a className={'linkPointer'}>
          {index + 1}
        </a>
      }
    },
    {
      title: 'Student',
      dataIndex: 'studentId',
      key: 'studentId',
      hidden: ['Counselor Forex Bonus', 'Forex Exchange Commission'].includes(withdrawalType),
      render: (item, record, index) => {
        return <div>
          {record &&
          record.studentId &&
          record.studentId.name &&
          record.studentId.name}
        </div>
      }
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      hidden: ['Counselor Forex Bonus', 'Forex Exchange Commission'].includes(withdrawalType),
      render: (item, record, index) => {
        return <div>
          {record &&
          record.countryId &&
          record.countryId.currency &&
          record.countryId.currency}
          {item}
        </div>
      }
    },
    {
      title: 'Intake',
      hidden: ['Counselor Forex Bonus', 'Forex Exchange Commission'].includes(withdrawalType),
      dataIndex: 'intake',
      key: 'intake'
    },
    {
      title: 'University',
      dataIndex: 'universityId',
      key: 'universityId',
      width: 250,
      hidden: ['Counselor Forex Bonus', 'Forex Exchange Commission'].includes(withdrawalType),
      render: (item, record, index) => {
        return <div>
          {record &&
          record.universityId &&
          record.universityId.universityName &&
          record.universityId.universityName}
        </div>
      }
    },
    {
      title: 'Course',
      hidden: ['Counselor Forex Bonus', 'Forex Exchange Commission'].includes(withdrawalType),
      dataIndex: 'courseName',
      key: 'courseName',
      width: 250
    },
    {
      title: 'Counsellor Details',
      dataIndex: 'reeudoTransactionId',
      hidden: ['Counselor Forex Bonus', 'Forex Exchange Commission'].includes(withdrawalType),
      render: (text, record) => (
        <div>
          {
            record &&
            record.counsellorName &&
            record.counsellorDesignation && (
              <>
                <strong>Name: </strong>{record && record.counsellorName && record.counsellorName}<br />
                <strong>Designation: </strong>{record && record.counsellorDesignation && record.counsellorDesignation}<br />
              </>
            )
          }
        </div>
      )
    },
    {
      title: 'Rap Id',
      dataIndex: 'reeudoTransactionId',
      key: 'reeudoTransactionId',
      hidden: ['Counselor Student Bonus'].includes(withdrawalType),
      render: (text, record) => (
        <div>
          {record &&
          record.reeudoTransactionId &&
          record.reeudoTransactionId.rappId &&
          record.reeudoTransactionId.rappId}
        </div>
      )
    },
    {
      title: 'Student Details',
      dataIndex: 'reeudoTransactionId',
      hidden: ['Counselor Student Bonus'].includes(withdrawalType),
      render: (text, record) => (
        <div>
          {<div>{record &&
          record.reeudoTransactionId &&
          record.reeudoTransactionId.name &&
          record.reeudoTransactionId.name}</div>}
          {record &&
          record.reeudoTransactionId &&
          record.reeudoTransactionId.phoneNumber ?
            <div><b>Mobile-</b>{record.reeudoTransactionId.phoneNumber}</div> : ''}
          {record &&
          record.reeudoTransactionId &&
          record.reeudoTransactionId.emailAddress ?
            <div><b>Email-</b>{record.reeudoTransactionId.emailAddress}</div> : ''}
        </div>
      )
    },
    {
      title: 'Counsellor Details',
      dataIndex: 'reeudoTransactionId',
      hidden: ['Counselor Student Bonus'].includes(withdrawalType),
      render: (text, record) => (
        <div>
          {
            record.reeudoTransactionId &&
            record.reeudoTransactionId.counselorName &&
            record.reeudoTransactionId.counselorDesignation && (
              <>
                <strong>Name: </strong>{record.reeudoTransactionId && record.reeudoTransactionId.counselorName && record.reeudoTransactionId.counselorName}<br />
                <strong>Designation: </strong>{record.reeudoTransactionId && record.reeudoTransactionId.counselorDesignation && record.reeudoTransactionId.counselorDesignation}<br />
              </>
            )
          }
        </div>
      )
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      hidden: ['Counselor Student Bonus'].includes(withdrawalType)
    },
    {
      title: 'Forex',
      dataIndex: 'reeudoTransactionId',
      key: 'reeudoTransactionId',
      hidden: ['Counselor Student Bonus'].includes(withdrawalType),
      render: (text, record) => (
        <>
          {record.reeudoTransactionId && (
            <>
              {record.reeudoTransactionId.amount && (
                <div>
                  Amount: {record.reeudoTransactionId.amount} {record.reeudoTransactionId.currency}
                </div>
              )}
              {record.reeudoTransactionId.yourRate && record.reeudoTransactionId.exchangeMargin !== undefined && (
                <div>
                  Your
                  Rate: {(record.reeudoTransactionId.yourRate + record.reeudoTransactionId.exchangeMargin).toFixed(2)}
                </div>
              )}
              {record.reeudoTransactionId.fxRate && (
                <div>
                  Booking Rate: {record.reeudoTransactionId.fxRate}
                </div>
              )}
            </>
          )}
        </>
      )
    },
    {
      title: 'Profit',
      dataIndex: 'reeudoTransactionId',
      key: 'reeudoTransactionId',
      hidden: ['Counselor Student Bonus'].includes(withdrawalType),
      render: (text, record) => (
        <>
          {record.reeudoTransactionId && (
            <>
              {record.reeudoTransactionId.agentProfit && (
                <div>
                  Agent Profit: {record.reeudoTransactionId.agentProfit}
                </div>
              )}
              {record.reeudoTransactionId.unizProfit && (
                <div>
                  Uniz Profit: {record.reeudoTransactionId.unizProfit}
                </div>
              )}
              {record.reeudoTransactionId.totalProfit && (
                <div>
                  Total Profit: {record.reeudoTransactionId.totalProfit}
                </div>
              )}
            </>
          )}
        </>
      )
    },

    {
      title: 'Date',
      dataIndex: 'reeudoTransactionId',
      key: 'reeudoTransactionId',
      hidden: ['Counselor Student Bonus'].includes(withdrawalType),
      render: (item, record, index) => {
        return <div>
          {record &&
          record.reeudoTransactionId &&
          record.reeudoTransactionId.createdAt &&
          newFormatDisplayDate(record.reeudoTransactionId.createdAt)}
        </div>
      }
    }
  ]
  const getColumns = () => {
    let newColumns = []
    _.each(columns, (item) => {
      if (!item.hidden) {
        newColumns.push(item)
      }
    })
    return newColumns
  }

  return (
    <Drawer
      visible={visible}
      title={`${withdrawalType === 'Forex Exchange Commission' ? 'Agent ' : ''}${withdrawalType || ''}`}
      onClose={onClose}
      width={'80%'}
    >
      <div className='card-body table-responsive'>
        {
          withdrawalDataModal && withdrawalDataModal.agentName &&
          <>
            <strong>Name: </strong>{withdrawalDataModal && withdrawalDataModal.agentName && withdrawalDataModal.agentName} &nbsp;
            <strong>Company: </strong>{withdrawalDataModal && withdrawalDataModal.agentCompanyName && withdrawalDataModal.agentCompanyName}<br />
          </>
        }
        <TableComp
          columns={getColumns()}
          apiRequest={loadWithdrawalData}
          ref={tableRef}
        />
      </div>
    </Drawer>
  )
}
export default WithdrawalDetails
