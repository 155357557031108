import React, {useEffect, useState} from "react";
import { connect, useDispatch } from "react-redux";
import {Button, Col, Form, Row, Icon, Select, notification, Modal, Pagination, DatePicker, Slider, Checkbox, Menu, Dropdown} from "antd";
import { propertyListFxn, suggestedPropertyFxn, getSuggestPropertyFxn, enquiryActivityFxn,savePropertyFxn } from "../action";
import {InputBox} from "../../../components/_utils/appUtils";
import {CountryCodeJson as countryCodes, CountryCodeWithFlag} from "../../../components/_utils/countryUtil";
import _ from 'lodash';
import moment from 'moment'

const { Option } = Select;
const { Group } = Checkbox;
const { MonthPicker} = DatePicker;

const SuggestedProperty = (props) => {
    const { form: { getFieldDecorator, validateFieldsAndScroll }, visible, onClose, enquiry, currentUser } = props;
    const [paginationMeta, setPaginationMeta] = useState({ current_page: 1, count: 0, pages: [] });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [filters, setFilters] = useState({
        sort_key: "",
        sort_order: "",
        range_available_price: [0, 5000],
        move_in_month: null,
        meta_types: [],
        meta_unit_types: [],
    });

    const [filterDialogVisible, setFilterDialogVisible] = useState({
        sortBy: false,
        price: false,
        moveInDate: false,
        roomType: false,
        accommodationType: false,
    });
    const [allCountries, setAllCountries] = useState([])

    const dispatch = useDispatch();

    const staticCities = [
        {
            city_slug: "london",
            city_name: "London",
            path: "https://prod-assets.amberstudent.com/regions/14/2c73a731.jpg?w=330&h=330"
        },
        {
            city_slug: "leicester",
            city_name: "Leicester",
            path: "https://prod-assets.amberstudent.com/regions/75/4845618d.jpg?w=330&h=330"
        },
        {
            city_slug: "liverpool",
            city_name: "Liverpool",
            path: "https://prod-assets.amberstudent.com/regions/55/e0e0ad9a.jpg?w=330&h=330"
        },
        {
            city_slug: "coventry",
            city_name: "Coventry",
            path: "https://prod-assets.amberstudent.com/regions/70/ea20bec0.jpg?w=330&h=330"
        },
        {
            city_slug: "sheffield",
            city_name: "Sheffield",
            path: "https://prod-assets.amberstudent.com/regions/37/00f85e0f.jpg?w=330&h=330"
        },
        {
            city_slug: "leeds",
            city_name: "Leeds",
            path: "https://prod-assets.amberstudent.com/regions/43/22808500.jpg?w=330&h=330"
        },
        {
            city_slug: "newcastle-Upon-Tyne",
            city_name: "Newcastle Upon Tyne",
            path: "https://prod-assets.amberstudent.com/regions/32/22441ccc.jpg?w=330&h=330"
        },

        {
            city_slug: "manchester",
            city_name: "Manchester",
            path: "https://prod-assets.amberstudent.com/regions/56/65e8b9b2.jpg?w=330&h=330"
        },
        {
            city_slug: "cardiff",
            city_name: "Cardiff",
            path: "https://prod-assets.amberstudent.com/regions/69/6745eea3.jpg?w=330&h=330"
        },
        {
            city_slug: "swansea",
            city_name: "Swansea",
            path: "https://prod-assets.amberstudent.com/regions/64/686ce1a6.jpg?w=330&h=330"
        },
        {
            city_slug: "edinburgh",
            city_name: "Edinburgh",
            path: "https://prod-assets.amberstudent.com/regions/71/e1aae58d.jpg?w=330&h=330"
        },
        {
            city_slug: "salford",
            city_name: "Salford",
            path: "https://prod-assets.amberstudent.com/regions/76/e8313418.jpg?w=330&h=330"
        },
        // { city_slug: "chester", city_name: "Chester" ,  path: "https://prod-assets.amberstudent.com/regions/46/b411c412.jpg?w=330&h=330" },
        // { city_slug: "aberdeen", city_name: "Aberdeen" ,  path: "https://prod-assets.amberstudent.com/regions/19/3ce7048d.jpg?w=330&h=330" },
        // { city_slug: "plymouth", city_name: "Plymouth" ,  path: "https://prod-assets.amberstudent.com/regions/74/33565644.jpg?w=330&h=330" },
        // { city_slug: "lincoln", city_name: "Lincoln" ,  path: "https://prod-assets.amberstudent.com/regions/2515/5d1d7840.jpg?w=330&h=330" },
        // { city_slug: "exeter", city_name: "Exeter" ,  path: "https://prod-assets.amberstudent.com/regions/50/b8cc2387.jpg?w=330&h=330" },

    ];

    const [propertyList, setPropertyList] = useState([])
    const [suggestedList, setSuggestedList] = useState([])
    const [visibleDropdown, setVisibleDropdown] = useState(null); // Manage dropdown visibility

    const handleFilterChange = (filterName, value) => {
        setFilters((prev) => ({ ...prev, [filterName]: value }));
    };

    const toggleFilterDialog = (filterName) => {
        setFilterDialogVisible((prevState) => ({
            ...prevState,
            [filterName]: !prevState[filterName],
        }));
    };

    useEffect(() => {
        setSuggestedList([])
    },[])
    const handleSubmit = (e, page = 1) => {
        e.preventDefault();

        console.log(filters, "filters")

        validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                const { city } = values;

                const resp = await dispatch(
                    propertyListFxn({
                        location_place_name: city,
                        p: page,
                        limit: itemsPerPage,
                        sort_key: filters.sort_key,
                        sort_order: filters.sort_order,
                        range_available_price: filters.range_available_price.join(","),
                        move_in_month: filters.move_in_month,
                        meta_types: filters.meta_types.join(","),
                        meta_unit_types: filters.meta_unit_types.join(","),
                    })
                );
                if (resp) {
                    setPropertyList(resp);
                    setPaginationMeta(resp.meta);
                    setCurrentPage(page);
                }
                // getProperty(city, page);
            } else {
                notification.warning({ message: "Please select a city." });
            }
        });
    };
    // const getProperty = async (city, page) => {
    //     const resp = await dispatch(
    //         savePropertyFxn({
    //             location_place_name: city,
    //             p: page,
    //             limit: 50,
    //             sort_key: filters.sort_key,
    //             sort_order: filters.sort_order,
    //             range_available_price: filters.range_available_price.join(","),
    //             move_in_month: filters.move_in_month,
    //             meta_types: filters.meta_types.join(","),
    //             meta_unit_types: filters.meta_unit_types.join(","),
    //         })
    //     );
    //     console.log(resp, "respppp")
    // }
    const clearFilters = (e) => {
        e.preventDefault();
        setFilters({
            sort_key: "",
            sort_order: "",
            range_available_price: [0, 5000],
            move_in_month: "",
            meta_types: [],
            meta_unit_types: [],
        });

        handleSubmit({ preventDefault: () => {} }, 1);
    };

    let loadCountry = () => {
        let designatedArr = [];
        _.each(countryCodes, (item) => {
            designatedArr.push({
                name: `${item.code} - ${item.name}`,
                value: item.name
            });
        });
        setAllCountries(designatedArr);
    };
    const addSuggestedProperty = (property) => {

        if (suggestedList.some(p => p.propertyId === property.id)) {
            notification.warning({ message: "This property is already added in the list." });
            return;
        }


        if (suggestedList.length >= 3) {
            notification.warning({ message: "You cannot add more than 3 properties to the suggestion." });
            return;
        }


        const newSuggestedList = [
            ...suggestedList,
            {
                propertyId: property.id,
                propertyName: property.name,
                propertyImage: property.images && property.images[0] ? property.images[0].path : "",
                propertyRent: property.pricing.min_available_price,
                location: { primary: property.location.primary, secondary: property.location.secondary }
            }
        ];


        setSuggestedList(newSuggestedList);

        notification.success({ message: "Property added in the suggestion." });


        if (property) {
            dispatch(enquiryActivityFxn({
                enquiryId: enquiry._id,
                activityType: 'Property Suggested to agent',
                message: `Property - ${property.name} suggested to agent`,
                user: currentUser._id,
                oldData: {},
                newData: { suggestedProperties: newSuggestedList }
            }));
        }
    };

    const removeSuggestedProperty = (propertyId) => {
        const newSuggestedList = suggestedList.filter((property) => property.propertyId !== propertyId);
        setSuggestedList(newSuggestedList);

        notification.success({ message: "Property removed from the suggestion list." });
    };


    const onPageChange = async (page) => {
        if (propertyList && propertyList.result.length > 0) {
            handleSubmit({ preventDefault: () => {} }, page);
        }
    };

    // const getSuggestedProperty = async () => {
    //     let resp = await dispatch(getSuggestPropertyFxn({enquiryId: enquiry._id}))
    //     setSuggestedList(resp)
    //
    // }
    useEffect(() => {
        getSuggestedPropertyList();
        loadCountry();
    }, []);

    const getSuggestedPropertyList = () => {
        setSuggestedList([]);
    };

    useEffect(() => {
        setSuggestedList([])
    },[])
    const renderDropdownMenu = (filterName) => {
        switch (filterName) {
            case 'Sort By':
                return (
                    <Menu className={'menu-items-property'}>
                        <Menu.Item>
                            <select
                                className="form-control"
                                onChange={(e) => handleFilterChange('sort_key', e.target.value)}
                                value={filters.sort_key}
                            >
                                <option value="">Select Sorting</option>
                                <option value="available_price asc">Price (Low to High)</option>
                                <option value="available_price desc">Price (High to Low)</option>
                                <option value="created_at desc">Newly Added</option>
                                <option value="distance asc">Nearest</option>
                            </select>
                        </Menu.Item>
                    </Menu>
                );
            case 'Budget':
                return (
                    <Menu className={'menu-items-property'}>
                        <Menu.Item>
                            <Slider
                                range
                                min={0}
                                max={5000}
                                value={filters.range_available_price}
                                step={1}
                                marks={{ 0: "0", 5000: "5k" }}
                                tooltip={{ formatter: (value) => `${value}` }}
                                onChange={(value) => handleFilterChange('range_available_price', value)}
                            />
                        </Menu.Item>
                    </Menu>
                );
            case 'Move In Date':
                return (
                    <Menu className={'menu-items-property'}>
                        <Menu.Item>
                            <MonthPicker
                                className="form-control"
                                value={filters.move_in_month ? moment(filters.move_in_month, 'MMM-YYYY') : null}
                                onChange={(date, dateString) => handleFilterChange('move_in_month', dateString)}
                                format="MMM-YYYY"
                            />
                        </Menu.Item>
                    </Menu>
                );
            case 'Room Type':
                return (
                    <Menu className={'menu-items-property'}>
                        <Menu.Item>
                            <Checkbox.Group
                                value={filters.meta_types}
                                onChange={(values) => handleFilterChange('meta_types', values)}
                            >
                                <Checkbox value="shared_room">Shared Room</Checkbox>
                                <br />
                                <Checkbox value="private_room">Private Room</Checkbox>
                            </Checkbox.Group>
                        </Menu.Item>
                    </Menu>
                );
            case 'Accommodation':
                return (
                    <Menu className={'menu-items-property'}>
                        <Menu.Item>
                            <Checkbox.Group
                                value={filters.meta_unit_types}
                                onChange={(values) => handleFilterChange('meta_unit_types', values)}
                            >
                                <Checkbox value="student_accommodation">Student Accommodation</Checkbox>
                                <br />
                                <Checkbox value="independent_house">Independent House</Checkbox>
                            </Checkbox.Group>
                        </Menu.Item>
                    </Menu>
                );
            default:
                return null;
        }
    };

    const handleDropdownVisibleChange = (filterName, visible) => {
        setVisibleDropdown(visible ? filterName : null); // Ensure only one dropdown is visible
    };
    return (
        <Modal
            title="Suggested Properties"
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={'72%'}
        >
            <div className="form-box commissionBox">
                <div className="card unizportal enquiryCard">
                <Form onSubmit={handleSubmit}>
                    <Row gutter={24}>
                        <Col span={10}>
                            <Form.Item label="Country">
                                {getFieldDecorator("country", {
                                    initialValue: "United Kingdom",
                                })(
                                    <Select disabled={true}>
                                        {allCountries.map((country) => (
                                            <Option key={country.value} value={country.value}>
                                                {country.name}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>


                        </Col>
                        <Col span={10}>
                            <Form.Item label="City">
                                {getFieldDecorator("city", {
                                    rules: [{ required: true, message: "Please select a city." }],
                                })(
                                    <Select placeholder="Select a city">
                                        {staticCities.map((city) => (
                                            <Option key={city.city_slug} value={city.city_name}>
                                                {city.city_name}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>

                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Button
                                    type="primary" htmlType="submit" className={'btn mt40'}>
                                    Search
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>


                </div>
                {propertyList && propertyList.result && (
                    <>
                        <Row span={24} className="mt-3 property-filters-btn">

                            <div style={{ marginTop: '10px' }}>
                                {['Sort By', 'Budget', 'Move In Date', 'Room Type', 'Accommodation'].map((filterName) => (
                                   <Col span={4}>
                                    <Dropdown
                                        key={filterName}
                                        overlay={renderDropdownMenu(filterName)}
                                        visible={visibleDropdown === filterName}
                                        onVisibleChange={(visible) => handleDropdownVisibleChange(filterName, visible)}
                                        trigger={['click']}
                                    >
                                        <Button className="roundBtn">{filterName.replace(/([A-Z])/g, ' $1')}</Button>
                                    </Dropdown>
                                   </Col>
                                ))}
                            </div>

                            <Col span={2}>
                                <Button className="roundBtn" type="primary" onClick={handleSubmit}>
                                    Search
                                </Button>
                            </Col>
                            <Col span={2}>
                                <Button className="roundBtn" onClick={clearFilters}>
                                    Clear
                                </Button>
                            </Col>
                        </Row>
                    <Row span={24} className={'mt-4'}>
                        <Col span={12}>

                                <h6><b>Property Details</b></h6>
                            <hr/>
                            <ul>
                                {propertyList.result.map((property, index) => (
                                    <>
                                    <li key={index} className="property-item mt-3">
                                        <Row span={24}>
                                            <Col span={9}>
                                                <div className={'property-info2'}>
                                                    <div className={'image-box-update'}>
                                                        <div className="image-container2 img-container3">
                                                            <img
                                                                src={property.images && property.images[0] ? property.images[0].path : ""}
                                                                alt={property.name}
                                                                className={'suggest-pro-img'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col span={14} className={'property-location mt-3 ml-3'}>
                                                <h6>{property.name}</h6>
                                                <p>
                                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                                    {' '}{property.location.primary}, {property.location.secondary}
                                                </p>
                                                <Row span={24}>
                                                    <Col span={12}>
                                                        <p style={{color: '#5CB85C'}}><b>£{property.pricing.min_available_price} / Weeks</b></p>

                                                    </Col>
                                                    <Col span={12}>
                                                        <div>
                                                            <Button size={'small'}
                                                                    onClick={() => addSuggestedProperty(property)}>+ Add Property</Button>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                    </li>
                                    <hr/>
                                    </>
                                ))}
                            </ul>
                            <Pagination
                                current={paginationMeta.current_page}
                                pageSize={itemsPerPage}
                                onChange={onPageChange}
                                total={paginationMeta.count}
                                showSizeChanger={false}
                            />
                        </Col>
                        <Col span={2}></Col>
                        <Col span={10}>

                                {suggestedList && suggestedList.length > 0 && (
                                    <div className={'ml-2'}>
                                        <h6><b>Suggested Properties List</b></h6>
                                        <hr/>
                                    <ul>
                                        {suggestedList.map((property, index) => (
                                            <li key={index} className="property-item mt-3 card ">
                                                <div className="remove-icon" onClick={() => removeSuggestedProperty(property.propertyId)}>
                                                    <Icon type="close" />
                                                </div>

                                                <Row span={24} >

                                                    <Col span={14} className={'property-location mt-3 ml-3'}>
                                                        <h6 style={{fontSize: '15px'}}>{property.propertyName}</h6>
                                                        <p style={{fontSize: '12px'}}>
                                                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                                                            {' '} {property.location.primary}, {property.location.secondary}
                                                        </p>
                                                        <p style={{fontSize: '12px', color: '#5CB85C'}}>
                                                              <b>£{property.propertyRent} / Weeks</b>
                                                        </p>
                                                    </Col>


                                                    <Col span={8}>
                                                        <div className={'image-box-update'}>
                                                            <div className="image-container2 img-container3">
                                                                <img
                                                                    src={property.propertyImage}
                                                                    alt={property.propertyName}
                                                                    className={'suggest-pro-img'}
                                                                />
                                                            </div>
                                                        </div>

                                                    </Col>
                                                </Row>
                                            </li>
                                        ))}
                                    </ul>
                                        {/*<Button type="primary" htmlType="submit" className={'btn'}>*/}
                                        {/*    Send Suggestion To Student </Button>*/}
                                    </div>
                                )}

                        </Col>
                    </Row>
                    </>
                )}
            </div>
        </Modal>
    );
};

const SuggestedPropertyList = Form.create()(SuggestedProperty);

const mapStateToProps = ({ global, router }) => ({
    currentUser: global.currentUser
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SuggestedPropertyList);
