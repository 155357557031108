import React, { useEffect, useState } from 'react';
import {Modal, Form, Button, Row, Col, notification} from 'antd';
import GetEachFormFields from '../../../components/_utils/appFormUtils';
import { CountryCodeJson as countryCodes } from "../../../components/_utils/countryUtil";
import { useDispatch } from 'react-redux';
import FileInput from "../../../components/_utils/fileInput";
import _ from 'lodash';
import { addUnizhomePropertyFxn, savePropertyFxn, getPropertyListFxn} from "../action";
import AmenitiesModal from "./addPropertyAmenities";
import FAQsModal from "./addPropertyFaqs";
import {getPropertyListUrl} from "../api";

const amenitiesData = [
    {
        name: "Common Amenities",
        type: "common_amenities",
        values: [
            { name: "On-site Gym", type: "Gym" },
            { name: "Fully Furnished Flats", type: "Fully Furnished" },
            { name: "Prime Liverpool University Location", type: "Location Benefit" },
            { name: "Spacious living area", type: "Common Social Area" },
            { name: "Complimentary WiFi & Netflix", type: "Wifi" },
            { name: "X1 Loyalty Card", type: "Others" },
        ],
    },
    {
        name: "Bills Included",
        type: "bills_included",
        values: [
            { name: "Wi-fi", type: "Wifi" },
            { name: "Water", type: "Basic Essentials" },
            { name: "Electricity", type: "Electronic Payments" },
            { name: "Gas", type: "Gas Stove" },
        ],
    },
    {
        name: "Safety and Security",
        type: "safety_security",
        values: [
            { name: "Overnight Security (8 PM – 6 AM)", type: "Others" },
            { name: "Dedicated Account Manager", type: "Reception & Staff" },
        ],
    },
];

const AddPropertyForm = (props) => {
    const { form } = props;
    const { getFieldDecorator, setFieldsValue, resetFields } = form;
    const [allCountries, setAllCountries] = useState([]);
    const [openAmenities, setOpenAmenities] = useState(false)
    const [amenities, setAmenities] = useState({})
    const [openFaqs, setOpenFaqs] = useState(false)
    const [images, setImages] = useState([{ path: '', type: '', caption: '', base_path: '' }]);
    const dispatch = useDispatch();

    console.log(amenities, "amjj")

    const getPropertyList = async() => {
        const resp = await dispatch(getPropertyListFxn())
        console.log(resp, "respppp")
    }


    useEffect(() => {
        getPropertyList();
    },[])
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     form.validateFields(async (err, values) => {
    //         if (!err) {
    //             const formattedFeatures = Object.keys(amenities).map((categoryKey) => {
    //                 const selectedItems = Object.keys(amenities[categoryKey]).filter(
    //                     (item) => amenities[categoryKey][item]
    //                 );
    //                 const category = amenitiesData.find((item) => item.type === categoryKey);
    //
    //                 return {
    //                     name: category.name,
    //                     type: category.type,
    //                     value: category.values
    //                         .filter((item) => selectedItems.includes(item.type))
    //                         .map((item) => ({
    //                             name: item.name,
    //                             type: item.type,
    //                         })),
    //                 };
    //             });
    //
    //             // Prepare FormData
    //             const formData = new FormData();
    //             formData.append("name", values.propertyName);
    //             formData.append("canonical_name", values.propertyName);
    //             formData.append("location", JSON.stringify({
    //                 name: values.propertyName,
    //                 primary: values.state,
    //                 secondary: values.city,
    //                 country: {
    //                     long_name: values.country,
    //                     short_name: values.country,
    //                 },
    //                 district: values.district,
    //                 locality: values.city,
    //                 state: values.state,
    //                 postalCode: values.postalCode,
    //                 route: values.address,
    //             }));
    //             formData.append("pricing", JSON.stringify({
    //                 currency: values.currency,
    //                 duration: 'weeks',
    //                 max_available_price: values.price,
    //                 min_available_price: values.price,
    //             }));
    //             formData.append("meta", JSON.stringify({ available_from: values.moveIn }));
    //             formData.append("features", JSON.stringify(formattedFeatures));
    //             formData.append("description", JSON.stringify([{ display_name: 'About', value: values.aboutProperty, name: 'about' }]));
    //             formData.append("distances", JSON.stringify([{ place: values.propertyName, distance: values.distanceCityCenter }]));
    //             formData.append("videos", JSON.stringify(values.videos ? [{ path: values.videos, type: 'video', upload_date: new Date() }] : []));
    //
    //             // console.log(images, "images00000")
    //             // images.forEach((image, index) => {
    //             //     if (image.file instanceof File || image.file instanceof Blob) {
    //             //         formData.append('images', image.file, image.caption || `image_${index}.jpg`);
    //             //         formData.append('captions', image.caption || '');
    //             //         formData.append('types', image.type || '');
    //             //     } else {
    //             //         console.error(`Invalid file at index ${index}:`, image.file);
    //             //     }
    //             // });
    //
    //
    //             const resp = await dispatch(addUnizhomePropertyFxn(formData));
    //             if (resp.success) {
    //                 notification.success({ message: resp.message });
    //                 form.resetFields();
    //             } else {
    //                 notification.error({ message: resp.message });
    //             }
    //         }
    //     });
    // };
    const handleSubmit = (e) => {
        e.preventDefault();

        form.validateFields(async (err, values) => {
            if (!err) {

                const formattedFeatures = Object.keys(amenities).map((categoryKey) => {
                    const selectedItems = Object.keys(amenities[categoryKey]).filter(
                        (item) => amenities[categoryKey][item]
                    );
                    const category = amenitiesData.find((item) => item.type === categoryKey);

                    return {
                        name: category.name,
                        type: category.type,
                        value: category.values
                            .filter((item) => selectedItems.includes(item.type))
                            .map((item) => ({
                                name: item.name,
                                type: item.type,
                            })),
                    };
                });


                const formData = new FormData();


                Object.keys(values).forEach((key) => {
                    if (key !== 'images' && key !== 'videos' && key !== 'amenities') {
                        formData.append(key, values[key]);
                    }
                });


                formData.append('features', JSON.stringify(formattedFeatures));


                if (images && images.length > 0) {
                    images.forEach((image) => {
                        formData.append('images', JSON.stringify({
                            base_path: image.base_path,
                            caption: image.caption,
                            featured: false,
                            path: image.path,
                            type: image.type,
                        }));
                    });
                }

                if (values.videos) {
                    formData.append('videos', JSON.stringify(values.videos));
                }


                const resp = await dispatch(addUnizhomePropertyFxn(formData));

                if (resp.success) {
                    notification.success({ message: resp.message });
                    form.resetFields();
                } else {
                    notification.error({ message: resp.message });
                }
            }
        });
    };


    const loadCountry = () => {
        let designatedArr = [];
        _.each(countryCodes, (item) => {
            designatedArr.push({
                name: `${item.code} - ${item.name}`,
                value: item.name,
            });
        });
        setAllCountries(designatedArr);
    };

    useEffect(() => {
        loadCountry();
    }, []);

    const inputFields = [
        { key: 'name', label: 'Name', placeholder: 'Name', required: true },
        { key: 'source', label: 'Source', placeholder: 'Source'},
        // { key: 'source_id', label: 'Source Id', placeholder: 'Source Id'},
        { key: 'source_link', label: 'Source Link', placeholder: 'Source Link'},
        { key: 'partner_link', label: 'Partner Link', placeholder: 'Partner Link'},


        {
            key: 'country',
            label: 'Country',
            placeholder: 'Select Country',
            required: true,
            showSearch: true,
            type: 'select',
            options: allCountries,
            keyAccessor: x => `${x.name}`,
            valueAccessor: x => `${x.value}`,
            onChange: x => {
                setFieldsValue({
                    country: x
                });
            }
        },
        { key: 'route', label: 'Route', placeholder: 'Route'},
        { key: 'state', label: 'State', placeholder: 'State', required: true },
        { key: 'locality', label: 'Locality', placeholder: 'Locality'},
        { key: 'district', label: 'District', placeholder: 'District' },
        { key: 'street_number', label: 'State', placeholder: 'State', required: true },
        { key: 'postal_code', label: 'Postal Code', placeholder: 'Postal Code'},
        { key: 'propertyName', label: 'Property Name', placeholder: 'Property Name', required: true },
        { key: 'address', label: 'Address', placeholder: 'Address', required: true },
        { key: 'distanceCityCenter', label: 'Distance from City Center', placeholder: 'Distance (km)' },
        { key: 'distanceBus', label: 'Distance by Bus', placeholder: 'Distance (mins)' },
        { key: 'distanceWalk', label: 'Distance by Walk', placeholder: 'Distance (mins)' },
        { key: 'distanceCar', label: 'Distance by Car', placeholder: 'Distance (mins)' },
        { key: 'aboutProperty', label: 'About Property', type: 'textArea' },
        { key: 'roomType', label: 'Room Type', placeholder: 'Room Type', required: true },
        // { key: 'inventory', label: 'Inventory No', placeholder: 'Inventory No' },
        // { key: 'amenities', label: 'Amenities', placeholder: 'Amenities' },
        { key: 'moveIn', label: 'Move-In Date', placeholder: 'Select date', type: 'date', required: true },
        { key: 'moveOut', label: 'Move-Out Date', placeholder: 'Select date', type: 'date', required: true },
        { key: 'duration', label: 'Duration', placeholder: 'Duration' },
        { key: 'currency', label: 'Currency', placeholder: 'Currency' },
        { key: 'max_price', label: 'Max Price', placeholder: 'Max Price' },
        { key: 'min_price', label: 'Min Price', placeholder: 'Min Price' },
        { key: 'refundable_token', label: 'Refundable Token', placeholder: 'Refundable Token' },
        { key: 'propertyImage', label: 'Property Image', placeholder: 'Upload image', type: 'file' },
        // { key: 'faqs', label: 'FAQs', type: 'textArea' },
        // { key: 'videos', label: 'Videos', placeholder: 'Video' },
    ];

    const formItemLayout = {
        labelCol: { xs: { span: 0 }, sm: { span: 0 }, md: { span: 0 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 } }
    };
    const handleImageChange = (index, field, value) => {
        const updatedImages = [...images];
        updatedImages[index][field] = value;
        setImages(updatedImages);
    };


    const handleAddImage = () => {
        setImages([...images, { path: '', type: '', caption: '', base_path: '' }]);
    };

    const handleRemoveImage = (index) => {
        const updatedImages = images.filter((_, i) => i !== index);
        setImages(updatedImages);
    };

    return (
        <div>
            <Form layout="vertical" onSubmit={handleSubmit}>
                <div className="form-box mt-4">
                    <div className="d-flex align-items-center mb-3 heading-form">
                        <h5>Add Property</h5>
                    </div>

                    {/* Main Input Fields Card */}
                    <div className="card unizportal">
                        <Row gutter={24}>
                            {inputFields.map((item, index) => (
                                <Col span={item.span || 8} md={item.span || 8} sm={24} xs={24} key={index}>
                                    {item.type === 'file' ? null : (  // No image inputs here
                                        <GetEachFormFields
                                            item={item}
                                            getFieldDecorator={getFieldDecorator}
                                            formItemLayout={formItemLayout}
                                        />
                                    )}
                                </Col>
                            ))}

                        <Col span={24}>
                            <Row span={24}>
                                <Col span={4}>
                                    <Form.Item>
                                        <a onClick={() => setOpenAmenities(true)}>
                                            Add Amenities
                                        </a>
                                    </Form.Item>

                                </Col>
                                <Col span={4}>
                                    <Form.Item>
                                        <a onClick={() => setOpenFaqs(true)}>
                                            Add Faqs
                                        </a>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        </Row>

                    </div>


                    <div className="card unizportal mt-4">
                        <h6>Property Images</h6>
                        {images.map((image, imageIndex) => (
                            <div key={imageIndex} className="image-card">
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <FileInput
                                            name={`image_${imageIndex}`}
                                            label={`Image ${imageIndex + 1}`}
                                            chooseDocument={(file) => handleImageChange(imageIndex, 'file', file)}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <GetEachFormFields
                                            item={{
                                                key: `caption_${imageIndex}`,
                                                label: `Caption for Image ${imageIndex + 1}`,
                                                placeholder: 'Image Caption',
                                                required: true,
                                                type: 'text',
                                            }}
                                            getFieldDecorator={getFieldDecorator}
                                            formItemLayout={formItemLayout}
                                            onChange={(e) => handleImageChange(imageIndex, 'caption', e.target.value)}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <GetEachFormFields
                                            item={{
                                                key: `type_${imageIndex}`,
                                                label: `Type for Image ${imageIndex + 1}`,
                                                placeholder: 'Image Type',
                                                required: true,
                                                type: 'text',
                                            }}
                                            getFieldDecorator={getFieldDecorator}
                                            formItemLayout={formItemLayout}
                                            onChange={(e) => handleImageChange(imageIndex, 'type', e.target.value)}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        {imageIndex > 0 && (
                                            <Button
                                                type="danger"
                                                onClick={() => handleRemoveImage(imageIndex)}
                                                className="remove-image-btn"
                                            >
                                                Remove Image
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        ))}

                        {/* Add More Images button moved outside the map */}
                        <Row>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="link" onClick={handleAddImage}>
                                        Add More Images
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>


                    {/* Submit Button */}
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </div>
            </Form>


            {openAmenities && (
                <AmenitiesModal
                    visible={true}
                    onClose={() => setOpenAmenities(false)}
                    onSubmit={setAmenities}
                />
            )}
            {openFaqs && (
                <FAQsModal
                    visible={true}
                    onClose={() => setOpenFaqs(false)}
                    // onSubmit={setAmenities}
                />
            )}
        </div>
    );
};

export default Form.create()(AddPropertyForm);
