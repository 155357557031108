import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Form, notification, Row, Select, Tabs, Icon, Table, Popconfirm, Tooltip} from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import {enquiryStatusFxn, singlePropertyFxn, propertyListFxn, updateSinglePropertyFxn} from "../action";
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import { TableComp} from "sz-react-utils-lite";
import moment from "moment";
import PropertyDetailsModal from "./singlePropertyModal";

const { Option } = Select;
const { TabPane} = Tabs
const UpdatePropertyModal = (props) => {
    let dispatch = useDispatch();
    let { visible, onClose, form: { getFieldDecorator, setFieldsValue }, enquiry } = props;
    let [singleProperty, setSingleProperty] = useState({})
    let [activeChildren, setActiveChildren] = useState([])
    let [ propertyUpdate, setPropertyUpdate] = useState({
        show: false,
        property: {}
    })
    let [detailsModalVisible, setDetailsModalVisible] = useState(false);
    const [iconClicked, setIconClicked] = useState(false);

    const capitalizeUnitType = (unitType) => {
        return unitType
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const handleUpdateClick = async (record) => {
        const payload = {
            enquiryId: enquiry._id,
            roomType: record.roomType,
            leaseDuration: record.duration,
            moveInDate: record.moveIn,
            moveOutDate: record.moveOut,
            price: record.rent,
        };

        try {
            const resp = await dispatch(updateSinglePropertyFxn(payload));
            if (resp && resp.success) {
                notification.success({message: resp.message})
                onClose();

            }
        } catch (error) {

        }
    };


    const columns = [
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
        },
        {
            title: 'Move-In',
            dataIndex: 'moveIn',
            key: 'moveIn',
        },
        {
            title: 'Move-Out',
            dataIndex: 'moveOut',
            key: 'moveOut',
        },
        {
            title: 'Rent',
            dataIndex: 'rent',
            key: 'rent',
            width: 130,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record, index) => {

                return (
                    <Popconfirm
                        title="Are you sure you want to update this property?"
                        onConfirm={() => handleUpdateClick(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Tooltip title="Select Room">
                            <label className="label label-success" style={{ cursor: "pointer" }}>
                                Select Room
                            </label>
                        </Tooltip>
                    </Popconfirm>
                );
            },
        },
    ];
    const handleIconClick = () => {
        handleImageClick();
        setIconClicked(true);

    };
    const handleImageClick = () => {
        setDetailsModalVisible(true);
    };

    const closeDetailsModal = () => {
        setDetailsModalVisible(false);
        onClose()
    };

    const events = {
        showPropertyModal: async (property) => {
            setSingleProperty({
                show: true,
                property: property
            })
        },
        hidePropertyModal: async () => {
            setSingleProperty({
                show: false,
                property: {}
            })
        }
    }
    const setDefaultValues = () => {

        setTimeout(() => {
            setFieldsValue({
               id : enquiry.propertyId

            })
        }, 100)
    }
    const apiRequest = async () => {
        let resp = await dispatch(propertyListFxn())

    }

    let inputTypes = {
        fields: [
            {
                key: 'id',
                label: 'Enter Property Id',
                placeholder: 'Enter Property Id',
                type: 'text',
                required: true,

            }
        ]
    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 0 },
            sm: { span: 0 },
            md: { span: 0 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 }
        }
    };

    const handleSubmit = (e) => {
        const { form } = props;
        e.preventDefault();
        form.validateFieldsAndScroll(async (err, valData) => {

            if (!err) {

                const resp = await dispatch(singlePropertyFxn(valData));
                setSingleProperty(resp)
                setActiveChildren(resp.active_children)

                // if (resp && resp.success) {
                //     notification.success({message: resp.message})
                //     onClose();
                // }

            } else {

            }
        });
    };


    useEffect(() => {
        setDefaultValues()
        // apiRequest()
    },[])

    return (
        <>
            <Modal
                visible={visible}
                onCancel={onClose}
                title={`Update Property`}
                width={'52%'}
                footer={null}
            >
                <div className="form-box commissionBox">
                    <div className="card unizportal enquiryCard">
                        <Form onSubmit={handleSubmit}>
                            <Row gutter={24} className={'rowWrap'}>
                            {inputTypes.fields.map((item, key) => {
                                return item.hidden == undefined ||
                                (item.hidden !== undefined && !item.hidden) ? (
                                    <Col span={14} key={key}>
                                        <GetEachFormFields
                                            item={item}
                                            getFieldDecorator={getFieldDecorator}
                                            formItemLayout={formItemLayout}
                                        />
                                    </Col>
                                ) : null
                            })}
                                <Col span={8}>
                                    <Form.Item>
                                        <Button
                                            type="primary" htmlType="submit" className={'btn mt40'}>
                                            Search
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                {Object.keys(singleProperty).length > 0 && (
                    <div className={'mt-3'}>
                        <h5><b>Property Details</b></h5>
                        <Row span={24}>
                            <Col span={7}>
                                <div className={'property-info2'}>
                                    <div className={'image-box-update'}>
                                        <div className="image-container2" style={{ position: 'relative', display: 'inline-block' }}>
                                            <img
                                                src={singleProperty.inventory_featured_image_path}
                                                alt={'img'}
                                                className="room-image-enquiry"
                                                style={{ maxWidth: '100%' }}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={10} className={'property-location mt-3 ml-3'}>
                                <h6
                                    onClick={() => events.showPropertyModal(singleProperty)}>{singleProperty.name}</h6>

                                    <p>
                                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        {singleProperty.location && singleProperty.location.primary},{singleProperty.location && singleProperty.location.secondary} </p>



                            </Col>
                        </Row>
                        <hr/>
                        <div className="mt-1 single-pro-data">
                            {singleProperty.meta && singleProperty.meta.unit_types && singleProperty.meta.unit_types.length > 0 && (
                                <div>
                                    <Row>
                                        <h5><b>Choose Your Room</b></h5>
                                    </Row>
                                    <Tabs defaultActiveKey="1">
                                        {singleProperty.meta.unit_types.map((unitType, index) => {
                                            const filteredChildren = activeChildren.filter(
                                                (child) => child.meta && child.meta.unit_type === unitType
                                            );

                                            if (filteredChildren.length === 0) return null;

                                            return (
                                                <TabPane tab={capitalizeUnitType(unitType)} key={index + 1}>
                                                    <Row span={24}>
                                                        <Col span={24}>
                                                            {filteredChildren.map((child, childIndex) => {

                                                                const tableData = child.active_children
                                                                    .filter((activeChild) => activeChild.available)
                                                                    .map((activeChild, idx) => ({
                                                                        key: idx,
                                                                        duration: `${activeChild.meta.lease_duration} weeks`,
                                                                        moveIn: activeChild.meta.available_from_formatted || 'Flexible',
                                                                        moveOut: activeChild.meta.available_to_formatted,
                                                                        rent: `£${activeChild.weekly_price}`,
                                                                        roomType: `${activeChild.name}`,
                                                                    }));


                                                                if (tableData.length === 0) return null;

                                                                return (
                                                                    <div key={childIndex} className={'mt-3'}>
                                                                        <Row span={24}>
                                                                            <Col span={8} className={"property-location"}>
                                                                                <h6><b>{child.name}</b></h6>
                                                                                <p>£{child.pricing.price} / weeks</p>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row span={24}>
                                                                            <Col span={5}>

                                                                                <img
                                                                                    src={child.images && child.images[0] ? child.images[0].path : ''}
                                                                                    alt={"img"}
                                                                                    className={'table-pro-img'}
                                                                                />
                                                                            </Col>


                                                                            <Col span={19}>
                                                                                <Table
                                                                                    dataSource={tableData}
                                                                                    columns={columns}
                                                                                    pagination={false}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <hr/>
                                                                    </div>
                                                                );
                                                            })}
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            );
                                        })}
                                    </Tabs>
                                </div>
                            )}
                        </div>

                        {/*<PropertyDetailsModal*/}
                        {/*    visible={detailsModalVisible}*/}
                        {/*    onClose={closeDetailsModal}*/}
                        {/*    singleProperty={singleProperty}*/}
                        {/*    activeChildren={activeChildren}*/}
                        {/*    enquiry={enquiry}*/}

                        {/*/>*/}
                    </div>
                )}

            </Modal>

        </>
    );
};

const UpdatePropertyForm = Form.create()(UpdatePropertyModal);

const mapStateToProps = ({ global, router }) => ({
    currentUser: global.currentUser
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdatePropertyForm);
