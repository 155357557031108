import {
  Avatar,
  Button,
  Form,
  Select
} from 'antd'
import lodash from 'lodash'
import React, { useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  defaultLogoList,
  newFormatDisplayDate
} from '../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../routes'
import { allCounsellorBonusList } from './action'

const CounsellorBonusList = () => {
  const tableAppRef = useRef()
  const [totalApplication, setTotalApplication] = useState(0)
  let [tableSearch, setTableSearch] = useState(false)
  // const [name, setName] = useState('')
  // const [universityList, setUniversityList] = useState([])
  // const [appUniversityId, setAppUniversityId] = useState('')
  // let [filters, setFilters] = useState({})
  // let [intake, setIntake] = useState('')
  // let [isCallStatusDrawerOpen, setIsCallStatusDrawerOpen] = useState({
  //   visible: false,
  //   record: ''
  // })
  const dispatch = useDispatch()
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

  let apiRequest = params => {
    return new Promise(async resolve => {
      // let resp = await getParamsForApplicationList()
      // params = { ...params, ...resp }
      params = { ...params }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }
      if (tableSearch) {
        params.page = 1
      }
      try {
        // setFilters(params)
        let { data } = await dispatch(allCounsellorBonusList({
          ...params, regExFilters: ['name']
        }))
        console.log(data, 'resp')
        setTotalApplication(data.total)
        setTableSearch(false)
        resolve(data)
      } catch (e) {
      }
    })
  }


  // useEffect(() => {
  //   setFieldByParams()
  //   loadUniversityNameList()
  // }, [])

  // const setFieldByParams = async () => {
  //   let resp = await getParamsForApplicationList()
  //   if (resp.name) {
  //     setName(resp.name)
  //   }
  //   if (resp.appUniversityId) {
  //     setAppUniversityId(resp.appUniversityId)
  //   }
  //   if (resp.intake) {
  //     setIntake(resp.intake)
  //   }
  //   if (resp.gicCallStatus) {
  //     setGicCallStatus(resp.gicCallStatus)
  //   }
  // }

  // const loadUniversityNameList = async () => {
  //   let params = {
  //     results: 5000,
  //     select: ['universityName'],
  //     sortField: 'universityName',
  //     sortOrder: 'ascend',
  //     regExFilters: ['universityName'],
  //     showUniversity: [true, false]
  //   }
  //   params.universityCountry = countryIds.canada
  //   let { data } = await dispatch(listAllUniversities(params))
  //   setAppUniversityId('')
  //   if (data && data.length) {
  //     setUniversityList(data)
  //   }
  // }


  const events = {
    reloadFxn: () => {
      tableAppRef.current.reload()
    }
  }

  // const getParamsForApplicationList = () => {
  //   return new Promise(resolve => {
  //     let searchParams = new URLSearchParams(window.location.search)
  //     let agentId = searchParams.get('agentId')
  //     let appUniversityId = searchParams.get('appUniversityId')
  //     let name = searchParams.get('name')
  //     let intake = searchParams.get('intake')
  //     let gicCallStatus = searchParams.get('gicCallStatus')
  //     let obj = {}
  //     if (agentId) {
  //       obj.agentId = agentId
  //     }
  //     if (appUniversityId) {
  //       obj.appUniversityId = appUniversityId
  //     }
  //     if (name) {
  //       obj.name = name
  //     }
  //     if (intake) {
  //       obj.intake = intake
  //     }
  //     if (gicCallStatus) {
  //       obj.gicCallStatus = gicCallStatus
  //     }
  //     resolve(obj)
  //   })
  // }

  // const searchFxn = async () => {
  //   let obj = {}
  //
  //   if (name) {
  //     obj.name = name
  //   }
  //   if (appUniversityId) {
  //     obj.appUniversityId = appUniversityId
  //   }
  //   if (intake) {
  //     obj.intake = intake
  //   }
  //   if (gicCallStatus) {
  //     obj.gicCallStatus = gicCallStatus
  //   }
  //   dispatch(
  //     getUrlPushWrapper('canadaOfferList', {
  //       ...obj
  //     })
  //   )
  //   setTableSearch(true)
  //   setTimeout(() => {
  //     if (tableAppRef && tableAppRef.current) {
  //       tableAppRef.current.reload()
  //     }
  //   }, 200)
  // }
  // const clearFxn = async () => {
  //   let resp = await getParamsForApplicationList()
  //   let obj = {}
  //   setAppUniversityId('')
  //   setName('')
  //   dispatch(
  //     getUrlPushWrapper('canadaOfferList', {
  //       ...obj
  //     })
  //   )
  //   setTableSearch(true)
  //   setTimeout(() => {
  //     tableAppRef.current.reload()
  //   }, 200)
  // }

  const columns = [
    {
      title: 'App#',
      key: 'applicationId',
      dataIndex: 'applicationId',
      searchTextName: 'applicationId',
      render: (item, record) => {
        return <div style={{ width: 80 }}>{item}</div>
      }
    },
    {
      title: 'Name',
      key: 'studentId',
      dataIndex: 'studentId',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.name ? item.name : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Email',
      key: 'studentId',
      dataIndex: 'studentId',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.email ? item.email : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'DOB',
      dataIndex: 'studentId',
      key: 'studentId',
      render: (item, record) => {
        return <div style={{ width: 90 }}>{newFormatDisplayDate(item && item.dateOfBirth)}</div>
      }
    },
    {
      title: 'Counsellor',
      key: 'counsellorName',
      render: (item, record) => {
        return (
          <React.Fragment>
            { item &&
              <div style={{ width: 120 }}>
                {record && record.counsellorName ? record.counsellorName : ''} / <br />
                {record && record.counsellorDesignation ? record.counsellorDesignation : ''}
              </div>
            }
          </React.Fragment>
        )
      }
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      render: (item, record) => {
        return (
          <React.Fragment>
            {
              item &&
              <>
                {record && record.countryId && record.countryId.currency && record.countryId.currency}
                {item && item}
              </>
            }
          </React.Fragment>
        )
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status'
    },
    {
      title: 'Agent',
      dataIndex: 'agentId',
      key: 'agentId',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.name ? item.name : ''} / <br />
            {item && item.companyName ? item.companyName : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Course Name',
      key: 'courseName',
      dataIndex: 'courseName',
      render: item => {
        return <div>{item}</div>
      }
    },
    {
      title: 'University',
      key: 'universityId',
      dataIndex: 'universityId',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.logo && item.logo.url ? (
              <Avatar src={item.logo.url} size={20} />
            ) : (
              <Avatar src={defaultLogoList.defaultUniversityLogo} size={20} />
            )}
            {item && item.universityName ? `  ${item.universityName}` : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Country',
      key: 'countryId',
      dataIndex: 'countryId',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item.countryName ? `  ${item.countryName}` : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Intake',
      key: 'intake',
      dataIndex: 'intake'
    }
  ]


  const loadColumns = () => {
    let columnArr = []
    lodash.each(columns, item => {
      if (item.hidden == undefined) {
        columnArr.push(item)
      } else {
        if (!item.hidden) {
          columnArr.push(item)
        }
      }
    })
    return columnArr
  }

  /*const filterBlock = (
    <Row gutter={16}>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='search-box-table round ml0'>
          <input
            className='form-control form-control'
            type='search'
            placeholder='Name...'
            value={name}
            onChange={e => {
              events.enterName(e.target.value)
            }}
          />
          <img src='/dist/img/search.png' alt='' />
        </div>
      </Col>

      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Search By University'
            onChange={item => setAppUniversityId(item)}
            filterOption={filterOption}
            className={'antSelect'}
            allowClear={true}
            showSearch={true}
            placeholder={'University'}
            value={appUniversityId || undefined}>
            {universityList && universityList.length
              ? universityList.map((item, key) => {
                return (
                  <Option value={item._id} key={key}>
                    {item.universityName}
                  </Option>
                )
              })
              : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Intake'
            onChange={item => setIntake(item)}
            filterOption={filterOption}
            className={'antSelect'}
            allowClear={true}
            showSearch={true}
            placeholder={'Intake'}
            value={intake || undefined}>
            {Intakes && Intakes.length
              ? Intakes.map((item, key) => {
                return (
                  <Option value={item} key={key}>
                    {item}
                  </Option>
                )
              })
              : null}
          </Select>
        </div>
      </Col>

      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Status'
            onChange={setGicCallStatus}
            filterOption={filterOption}
            className='antSelect'
            allowClear={true}
            showSearch={true}
            placeholder='Status'
            value={gicCallStatus}
          >
            <Option value='Done'>Done</Option>
            <Option value='Not Done'>Not Done</Option>
            <Option value='Pending'>Pending</Option>
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <Button onClick={() => searchFxn()} className={'roundBtn'}>
          Search
        </Button>
        <Button onClick={() => clearFxn()} className={'roundBtn'}>
          Clear
        </Button>
      </Col>
    </Row>
  )*/
  return (
    <React.Fragment>
      <div>
        <div className='row mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div
                className='table-head d-flex align-items-center all-student-search'
                style={{ height: 60 }}>
                <Button
                  onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                  className={'roundGoBackBtn'}
                  icon={'arrow-left'}>
                  Back
                </Button>
                <h5>Paid Commissions On Student Counselling</h5>

                <div className='sort-box-table mark-btn'>
                  ( Total : {totalApplication} )
                </div>
              </div>
              <div className='card-body table-responsive'>
                {/*{filterBlock}*/}
                <TableComp
                  columns={loadColumns()}
                  rowKey={(item, index) => {
                    return index
                  }}
                  apiRequest={apiRequest}
                  pagination={{
                    position: 'top',
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '50'],
                    defaultPageSize: 10
                    // current: filters.page ? filters.page : 1
                  }}
                  ref={tableAppRef}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = ({ global }) => ({
  currentUserRights: global.currentUserRights
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(CounsellorBonusList)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)
