import React, { useState } from 'react'
import { displayDate, longDisplayDate, nameAvatar } from '../../../components/_utils/appUtils'
import { Tooltip, Icon, Avatar } from 'antd'
import EnquiryStatusUpdate from '../view/updateStatusModal'
import EmergencyDetailsUpdate from '../view/emergencyDetailsModal'
import GuarantorDetailsUpdate from '../view/gurantorDetailsModal'
import UpdatePropertyForm from '../view/updatePropertyModal'
import SuggestedPropertyList from "../view/suggestPropertyList";

const InfoColumn = (props) => {
  let { title, value, icon } = props
  return (
    <div className={'grid-column'}>
      <i className={`fa fa-${icon}`}></i>
      <div>
        <h6>{title}:</h6>
        <p>{value}</p>
      </div>
    </div>
  )
}

const EnquiryInfoComponent = (props) => {
  let { enquiryData, enquiryEvents, enquiryId } = props

  const [updateStatus, setUpdateStatus] = useState({
    visible: false,
    enquiryId: ''
  })
  const [updateEmergency, setUpdateEmergency] = useState({
    visible: false,
    enquiry: {}
  })
  const [updateGuarantor, setUpdateGuarantor] = useState({
    visible: false,
    enquiry: {}
  })
  const [updateProperty, setUpdateProperty] = useState({
    visible: false,
    enquiry: {}
  })
  const [suggestProperty, setSuggestProperty] = useState({
    visible: false,
    enquiry: {}
  })
  const events = {
    showEmergencyModal: async (enquiry) => {
      setUpdateEmergency({
        visible: true,
        enquiry: enquiry
      })
    },
    showGuarantorModal: async (enquiry) => {
      setUpdateGuarantor({
        visible: true,
        enquiry: enquiry
      })
    },
    hideStatusDrawer: async () => {
      setUpdateStatus({
        visible: false,
        enquiryId: enquiryId
      })
      enquiryEvents.apiRequest()
      enquiryEvents.getActivity()
    },
    hideEmergencyModal: async () => {
      setUpdateEmergency({
        visible: false,
        enquiry: {}
      })
      enquiryEvents.apiRequest()
      enquiryEvents.getActivity()
    },
    hideGuarantorModal: async () => {
      setUpdateGuarantor({
        visible: false,
        enquiry: {}
      })
      enquiryEvents.apiRequest()
      enquiryEvents.getActivity()
    },
    showUpdateModal: async (enquiry) => {
      setUpdateProperty({
        visible: true,
        enquiry: enquiry
      })
    },
    hideUpdateModal: async () => {
      setUpdateProperty({
        visible: false,
        enquiry: {}
      })
      enquiryEvents.apiRequest()
      enquiryEvents.getActivity()
    },
    showSuggestModal: async (enquiry) => {
      setSuggestProperty({
        visible: true,
        enquiry: enquiry
      })
    },
    hideSuggestModal: async () => {
      setSuggestProperty({
        visible: false,
        enquiry: {}
      })
      enquiryEvents.apiRequest()
      enquiryEvents.getActivity()
    },
    showStatusDrawer: async () => {
      setUpdateStatus({
        visible: true,
        enquiryId: enquiryId
      })
    }
  }
  return (
    <>
      <div className={'info-box'}>
        <div>
          <div className={'base-info'}>
            <div className={'alignCenter'}>
              <Avatar size={100}>{nameAvatar(enquiryData.fullName)}</Avatar>
            </div>
            <div className={'jcsb aic mb5 mt10'}>
              <div className={'title-name'}>{enquiryData.title} {enquiryData.fullName}</div>
              <div className={'font12 aic'}>
                <Icon type={'calendar'} className={'mr5'} />
                {longDisplayDate(enquiryData.createdAt)}
              </div>
            </div>
            <div className={'basic-details'}>
              <div className={'other-info'}><Icon type={'mail'} /> {enquiryData.email}</div>
              <div className={'other-info'}>
                <Icon type={'phone'} /> {enquiryData.phoneNumber}
              </div>
              <div className={'jcsb'}>
                <div className={'other-info'}>
                  <Icon type='environment' /> {enquiryData.country}
                </div>
                <div>

                  <a className={'btn  btn-info xs '} onClick={() => events.showStatusDrawer()}>
                    Update Status
                  </a>
                  <a className={'btn  btn-success-green xs  ml5'}
                     onClick={() => events.showUpdateModal(enquiryData)}>Update Property</a>

                </div>

              </div>

            </div>
          </div>
          <a className={'btn  btn-success xs m-2'} style={{float: 'right'}}
             onClick={() => events.showSuggestModal(enquiryData)}>Add Property</a>
          <div className={'property-info2'}>

            <div className={'image-box'}>
              <img src={enquiryData.propertyImage} className='room-image-enquiry' />
              <div className={'property-rent'}>
                Rent : {enquiryData.price} /week
              </div>
            </div>

            <div className={'details'}>
              <div className={'jcsb'}>
                <div className={'property-name'}>
                  {enquiryData.propertyName}
                </div>
                <div>
                  <label className={'label label-warning sm'}>{enquiryData.status}</label>
                </div>
              </div>

              <div className={'other-info'}>
                <span>Room Type :</span>
                <span>{enquiryData.roomType}</span>
              </div>
              <div className={'other-info'}>
                <span>Duration : </span><span>{enquiryData.leaseDuration}</span>
              </div>
              <div className={'other-info'}>
                <span>Move In : </span>
                <span>
                {enquiryData.moveInDate === 'others'
                  ? displayDate(enquiryData.prefferedmoveInDate)
                  : displayDate(enquiryData.moveInDate)}
              </span>
              </div>
              <div className={'other-info'}>
                <span>Move Out : </span><span> {displayDate(enquiryData.moveOutDate)}</span>
              </div>
            </div>
          </div>

          <div className={'property-other-info'}>
            <div className='filter-box'>
              <div className='accordion' id='accordionExample'>
                {/* Emergency Details */}
                <div className='collapsible-section mt-2'>
                  <div className='card-header' id='headingOne'>
                    <h2 className='mb-0 d-flex'>
                      <button
                        className='btn btn-link d-flex btn-enquiry-collapse'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapseOne'
                        aria-expanded='false'
                        aria-controls='collapseOne'>
                        <div>
                          Emergency Details
                        </div>
                        <img
                          src='../dist/img/arrow-down-white.svg'
                          alt='Expand Property Details'
                          className='ml-auto'
                        />
                      </button>
                      <div className={'edit-btn'}>
                        <img src={'../dist/img/edit.png'} alt='' className='edit'
                             onClick={() => events.showEmergencyModal(enquiryData)}
                        />
                      </div>
                    </h2>
                  </div>
                  <div id='collapseOne'
                       className='collapse'
                       aria-labelledby='headingOne'
                       data-parent='#accordionExample'>
                    <div className={'info-grid-column'}>
                      <InfoColumn icon={'user'} title={'Name'}
                                  value={`${enquiryData.emergencyTitle} ${enquiryData.emergencyFullName}`} />
                      <InfoColumn icon={'envelope'} title={'Email'} value={enquiryData.emergencyEmail} />
                      <InfoColumn icon={'phone'} title={'Contact'} value={enquiryData.emergencyMobileNumber} />
                      <InfoColumn icon={'calendar'} title={'Country'} value={enquiryData.emergencyCountry} />
                      <InfoColumn icon={'calendar-alt'} title={'Date of Birth'}
                                  value={displayDate(enquiryData.emergencyDateOfBirth)} />
                      <InfoColumn icon={'users'} title={'Relation'} value={enquiryData.emergencyRelation} />
                      <InfoColumn icon={'map-marker-alt'} title={'Address'} value={
                        <div>{enquiryData.emergencyAddress}, {enquiryData.emergencyCity}, {enquiryData.emergencyStateName}, {enquiryData.emergencyPinCode}</div>
                      } />
                    </div>
                  </div>
                </div>

                {/* Guarantor Details */}
                <div className='collapsible-section mt-2'>
                  <div className='card-header' id='headingTwo'>
                    <h2 className='mb-0 d-flex'>
                      <button
                        className='btn btn-link d-flex btn-enquiry-collapse'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapseTwo'
                        aria-expanded='false'
                        aria-controls='collapseTwo'>
                        Guarantor Details
                        <img
                          src='/dist/img/arrow-down-white.svg'
                          alt='Expand Property Details'
                          className='ml-auto'
                        />
                      </button>
                      <div className={'edit-btn'}>
                        <img src={'../dist/img/edit.png'} alt='' className='edit'
                             onClick={() => events.showGuarantorModal(enquiryData)}
                        />
                      </div>
                    </h2>
                  </div>
                  <div
                    id='collapseTwo'
                    className='collapse'
                    aria-labelledby='headingTwo'
                    data-parent='#accordionExample'>

                    <div className={'info-grid-column'}>
                      <InfoColumn icon={'user'} title={'Name'}
                                  value={`${enquiryData.guarantorTitle} ${enquiryData.guarantorFullName}`} />
                      <InfoColumn icon={'phone'} title={'Contact'} value={enquiryData.guarantorMobileNumber} />
                      <InfoColumn icon={'envelope'} title={'Email'} value={enquiryData.guarantorEmail} />
                      <InfoColumn icon={'calendar'} title={'Country'} value={enquiryData.guarantorCountry} />
                      <InfoColumn icon={'calendar-alt'} title={'Date of Birth'}
                                  value={displayDate(enquiryData.guarantorDateOfBirth)} />
                      <InfoColumn icon={'users'} title={'Relation'} value={enquiryData.guarantorRelation} />
                      <InfoColumn icon={'map-marker-alt'} title={'Address'} value={
                        <div>  {enquiryData.guarantorAddress}, {enquiryData.guarantorCity}, {enquiryData.guarantorStateName}, {enquiryData.guarantorPinCode}</div>
                      } />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      {
        updateStatus.visible ? (
          <EnquiryStatusUpdate
            {...updateStatus}
            onClose={() => events.hideStatusDrawer()}
          />
        ) : null
      }
      {
        updateEmergency.visible ? (
          <EmergencyDetailsUpdate
            {...updateEmergency}
            onClose={() => events.hideEmergencyModal()}
          />
        ) : null
      }
      {
        updateGuarantor.visible ? (
          <GuarantorDetailsUpdate
            {...updateGuarantor}
            onClose={() => events.hideGuarantorModal()}
          />
        ) : null
      }
      {updateProperty.visible ?
        <UpdatePropertyForm
          {...updateProperty}
          onClose={() => events.hideUpdateModal()}
        /> : null
      }
      {suggestProperty.visible ?
          <SuggestedPropertyList
              {...suggestProperty}
              onClose={() => events.hideSuggestModal()}
          /> : null
      }

    </>
  )
}
export default EnquiryInfoComponent
