import { customAxios as axios, getToken } from '../../request'
import {
  addDepartmentExpenseUrl,
  addExpenseCategoryUrl,
  deleteExpenseCategoryUrl, getAllDepartmentExpense,
  getAllEmployee,
  getAllExpenseCategoryUrl, updateExpenseCategoryUrl
} from './api'
import { hidePageLoad, showPageLoad } from '../../modules/actions'
import { notification } from 'antd'

export const listAllEmployee = async (filters) => {
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  let { data } = await axios.get(getAllEmployee(), config)
  return data
}

export const getAllDepartmentExpenseFxn = async (filters) => {
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  let { data } = await axios.get(getAllDepartmentExpense(), config)
  return data
}

export const addDepartmentExpenseFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addDepartmentExpenseUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const addExpenseCategoryFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addExpenseCategoryUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const updateExpenseCategoryFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateExpenseCategoryUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const deleteExpenseCategoryFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(deleteExpenseCategoryUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getAllExpenseCategoryFxn = async (valData) => {
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  let { data } = await axios.get(getAllExpenseCategoryUrl(), config)
  return data
}
