import React from "react";
import { Modal, Collapse, Button } from "antd";

const { Panel } = Collapse;

const faqsData = [
    { question: "What is the check-in process?", answer: "Our check-in process is quick and easy. You will receive detailed instructions after booking." },
    { question: "Are pets allowed?", answer: "Unfortunately, pets are not allowed in our properties." },
    { question: "What payment methods are accepted?", answer: "We accept credit cards, debit cards, and bank transfers." },
    { question: "Can I cancel my booking?", answer: "Yes, bookings can be canceled according to our cancellation policy." },
    { question: "Is Wi-Fi available?", answer: "Yes, complimentary Wi-Fi is provided in all rooms." },
    { question: "Is there parking available?", answer: "Yes, parking is available on request with prior booking." },
];

const FAQsModal = (props) => {
    let { visible, onClose} = props;
    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title="Frequently Asked Questions (FAQs)"
            width={'45%'}
            footer={[
                <Button key="close" onClick={onClose}>
                    Close
                </Button>,
            ]}
        >
            <Collapse accordion>
                {faqsData.map((faq, index) => (
                    <Panel header={faq.question} key={index}>
                        <p>{faq.answer}</p>
                    </Panel>
                ))}
            </Collapse>
        </Modal>
    );
};

export default FAQsModal;
