import {
  Button,
  Col,
  Icon,
  Popconfirm,
  Row,
  Select,
  Tag,
  Tooltip,
  DatePicker, notification
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  Intakes,
  fcmtUniversity,
  filterOption,
  longDisplayDate,
  newFormatDisplayDate, InputBox
} from '../../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../../routes'
import { listAllCampus } from '../../campus/actions/campus'
import { listAllUsers } from '../../users/actions/user'
import DocumentDrawer from '../drawers/studentDocumentDrawer'
import { cambieOnShoreApplicationListFxn, cambieOnShoreApplicationExportFxn } from '../../fcmtApplications/actions'
import {
  cambieOnshoreEnrollmentLetterFxn,
  cambieOnshoreLoaLetterFxn,
  cambieOnshorePaymentReceiptFxn,
  generateEslCertificateFxn
} from './actions'
import GenerateCambieOnshoreReceipt from './receiptDrawer'
import GenerateCambieOnshoreLoa from './loaDrawer'
import EditStudentDrawer from './editStudentDrawer'
import { ReCreateFcmtOfferLetter } from '../actions/chooseStudent'
import { allowReCreateOffer } from '../../../components/_utils/utils'
import UploadTTComponentCambieOnshore from '../directUniDrawers/uploadTTComponentsCambieOnshore'
import EnrollmentLetterDrawer from './enrollementLetterDrawer'
import SupportingLetterDrawer from './supportingLetterDrawer'
import AddTranscriptOfMarks from './addTranscriptOfMarks'
import _ from 'lodash'
import moment from 'moment'
import DeferLoa from './deferLoaDrawer'

import { RightComponent } from '../../../components/_utils/rightsUtils'
import AllDocumentDrawer from './allDocumentDrawer'

const { RangePicker } = DatePicker

let { TotalTTComponent } = UploadTTComponentCambieOnshore
const ttVerifiedStatusList = [
  { name: 'Pending', value: 'Pending' },
  { name: 'Verified by User', value: 'Verified' },
  { name: 'Disregard', value: 'Disregard' },
  { name: 'Bank Verified', value: 'Bank Verified' }
]
const { Option } = Select


const OfferActionComponent = props => {
  let { file, record, events, user } = props
  let { applications } = record
  return (
    <div>
      {file && file.path ? (
        <Tooltip title={'Download Offer Letter'}>
          <a download={file.fileName} href={file.path}>
            <img src={'/assets/icons/cloud-computing-download.png'} />
          </a>
        </Tooltip>
      ) : null}

      <RightComponent user={user} userTypes={['cambieDirector', 'cambieManager']}>
        <div className={'sprBox'}>
          {allowReCreateOffer(applications) ?
            <Popconfirm
              title={'Are your sure, you want to Re-Create Offer Letter?'}
              onConfirm={() => events.reCreateOfferLetter(record)}
              okText='Yes'
              cancelText='No'>
              <a className={'bs-link bs-xs'}>Re-Create Offer Letter</a>
            </Popconfirm> : null}
        </div>
      </RightComponent>
    </div>
  )
}

const OnShoreCambieApplicationList = (props) => {
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  const [studentData, setStudentData] = useState({})
  const [name, setName] = useState('')
  const [allAgent, setAllAgent] = useState([])
  const [agentId, setAgentId] = useState('')
  const [totalApplication, setTotalApplication] = useState(0)
  let [tableSearch, setTableSearch] = useState(false)
  let [filters, setFilters] = useState({})
  let [campusList, setCampusList] = useState([])
  const [campusId, setCampusId] = useState('')
  const [intake, setIntake] = useState('')
  let [courseName, setCourseName] = useState('')
  let [visibleDocumentDrawer, setVisibleDocumentDrawer] = useState(false)
  let [marketingUserList, setMarketingUserList] = useState([])
  let [marketingUserId, setMarketingUserId] = useState('')
  let [ttVerifiedStatus, setTtVerifiedStatus] = useState('')
  let [lastUpdatedByUserId, setLastUpdatedByUserId] = useState('')
  let [cambieUserList, setCambieUsers] = useState([])
  let [receiptState, setReceiptState] = useState({
    visible: false,
    studentId: '',
    applicationId: '',
    date: moment()
  })
  let [enrollementState, setEnrollementState] = useState({
    visible: false,
    studentId: '',
    applicationId: '',
    date: moment()
  })
  let [supportingLetterState, setSupportingLetterState] = useState({
    visible: false,
    studentId: '',
    applicationId: '',
    date: moment()

  })
  let [studentState, setStudentState] = useState({
    visible: false,
    studentId: '',
    familyName: '',
    applicationId: '',
    courseId: '',
    application: {},
    email: ''
  })
  let [loaState, setLoaState] = useState({
    visible: false,
    studentId: '',
    applicationId: '',
    receiptAmount: '',
    feesPrepaid: undefined,
    date: moment()

  })
  let [addMarkSheetState, setAddMarkSheetState] = useState({
    visible: false,
    studentId: '',
    applicationId: '',
    record: ''
  })
  let [deferLoaState, setDeferLoaState] = useState({
    visible: false,
    studentId: '',
    applicationId: '',
    record: ''
  })

  const dispatch = useDispatch()

  useEffect(() => {
    loadAgentData()
    setFieldByParams()
    loadMarketingUsers()
    loadCampusList()
    loadCambieUsers()
    // loadUniversity()
  }, [])
  const tableAppRef = useRef()


  let apiRequest = params => {
    return new Promise(async resolve => {
      let resp = await getParamsForApplicationList()
      params = { ...params, ...resp }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }
      if (tableSearch) {
        params.page = 1
      }
      try {
        let resp = await cambieOnShoreApplicationListFxn({ ...params })
        setFilters({ ...params, totalRecords: resp.total })
        setTotalApplication(resp.total)
        setTableSearch(false)
        resolve(resp)
      } catch (e) {
      }
    })
  }

  const setFieldByParams = async () => {
    let resp = await getParamsForApplicationList()

    if (resp.campusId) {
      setCampusId(resp.campusId)
    }

    if (resp.agentId) {
      setAgentId(resp.agentId)
    }
    if (resp.intake) {
      setIntake(resp.intake)
    }
    if (resp.courseName) {
      setCourseName(resp.courseName)
    }
    if (resp.marketingUserId) {
      setMarketingUserId(resp.marketingUserId)
    }
    if (resp.ttVerifiedStatus) {
      resp.ttVerifiedStatus = resp.ttVerifiedStatus.split(',')
      setTtVerifiedStatus(resp.ttVerifiedStatus)
    }

    if (resp.name) {
      setName(resp.name)
    }

  }

  const loadAgentData = async () => {
    let params = {
      results: 10000,
      userType: 'agent',
      sortField: 'companyName',
      sortOrder: 'ascend',
      select: ['name', 'companyName']
    }
    let { data } = await dispatch(listAllUsers(params))
    if (data && data.length) {
      setAllAgent(data)
    }
  }

  const loadCampusList = async () => {
    let params = {}
    params.sortField = 'campusName'
    params.sortOrder = 'ascend'
    params.results = 50
    params.count = 50
    params.campusUniversity = fcmtUniversity
    params.select = ['campusName']
    let { data } = await dispatch(
      listAllCampus({
        ...params,
        regExFilters: ['campusAddress', 'campusName']
      })
    )
    setCampusList(data)
  }

  const events = {
    reloadFxn: () => {
      tableAppRef.current.reload()
    },

    refreshApplication: () => {
      tableAppRef.current.reload()
    },

    openDocumentDrawer: async record => {
      setStudentData(record)
      setVisibleDocumentDrawer(true)
    },
    closeDocumentDrawer: async record => {
      setStudentData({})
      setVisibleDocumentDrawer(false)
    },
    generateEnrollment: async (record) => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(cambieOnshoreEnrollmentLetterFxn(obj))
      if (resp && resp.success) {

        if (tableAppRef && tableAppRef.current) {
          tableAppRef.current.reload()
        }
      }
    },
    reCreateOfferLetter: async record => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(ReCreateFcmtOfferLetter(obj))
      if (resp && resp.success) {
        tableAppRef.current.reload()
      }
    },
    showReceiptDrawer: async (record) => {
      let { applications } = record
      setReceiptState({
        visible: true,
        studentId: record._id,
        applicationId: record.applications._id,
        receiptAmount: record.applications.cambieOnshoreReceiptAmount ? record.applications.cambieOnshoreReceiptAmount : '',
        date: applications && applications.cambieDates && applications.cambieDates.receiptDate ? moment(applications.cambieDates.receiptDate) : moment()
      })
    },
    hideReceiptDrawer: async () => {
      setReceiptState({
        visible: false,
        studentId: '',
        applicationId: '',
        receiptAmount: '',
        date: moment()
      })
    },
    hideEnrollmentDrawer: async () => {
      setEnrollementState({
        visible: false,
        studentId: '',
        applicationId: '',
        date: moment()
      })
    },
    showEnrollmentDrawer: async (record) => {
      let { applications } = record
      setEnrollementState({
        visible: true,
        studentId: record._id,
        applicationId: record.applications._id,
        date: applications && applications.cambieDates && applications.cambieDates.enrollmentDate ? moment(applications.cambieDates.enrollmentDate) : moment()
      })
    },
    showEditStudent: (record) => {
      let obj = {
        visible: true,
        studentId: record._id,
        familyName: record.applications && record.applications.familyName ? record.applications.familyName : '',
        applicationId: record.applications && record.applications._id ? record.applications._id : '',
        application: record.applications,
        email: record.email
      }
      if (record.applications && record.applications.courseId && record.applications.courseId._id) {
        obj.courseId = record.applications.courseId._id
      }
      setStudentState(obj)
    },
    hideEditStudent: () => {
      setStudentState({
        visible: false,
        studentId: '',
        familyName: '',
        applicationId: '',
        courseId: '',
        application: {},
        email: ''
      })
    },

    showLoaDrawer: async (record) => {
      let { applications } = record
      setLoaState({
        visible: true,
        studentId: record._id,
        applicationId: record.applications._id,
        receiptAmount: record.applications.cambieOnshoreReceiptAmount ? record.applications.cambieOnshoreReceiptAmount : '',
        feesPrepaid: record.applications.feesPrepaid ? record.applications.feesPrepaid : undefined,
        date: applications && applications.cambieDates && applications.cambieDates.loaDate ? moment(applications.cambieDates.loaDate) : moment()
      })
    },
    hideLoaDrawer: async () => {
      setLoaState({
        visible: false,
        studentId: '',
        applicationId: '',
        receiptAmount: '',
        feesPrepaid: undefined,
        date: moment()
      })
    },
    generateReceiptFxn: async (record) => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(cambieOnshorePaymentReceiptFxn(obj))
      if (resp && resp.success) {

        if (tableAppRef && tableAppRef.current) {
          tableAppRef.current.reload()
        }
      }
    },
    hideSupportingDrawer: async () => {
      setSupportingLetterState({
        visible: false,
        studentId: '',
        applicationId: '',
        date: moment()
      })
    },
    showSupportingDrawer: async (record) => {
      let { applications } = record
      setSupportingLetterState({
        visible: true,
        studentId: record._id,
        applicationId: record.applications._id,
        date: applications && applications.cambieDates && applications.cambieDates.eslLetterDate ? moment(applications.cambieDates.eslLetterDate) : moment()
      })
    },
    showAddMarkSheetDrawer: async (record) => {
      setAddMarkSheetState({
        visible: true,
        studentId: record._id,
        applicationId: record.applications._id,
        record: record
      })
    },
    hideMarkSheetDrawer: async () => {
      setAddMarkSheetState({
        visible: false,
        studentId: '',
        applicationId: '',
        record: ''
      })
    },
    generateEslCertificate: async record => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(generateEslCertificateFxn(obj))
      if (resp && resp.success) {
        tableAppRef.current.reload()
      }
    },
    showDeferLoaDrawer: async (record) => {
      setDeferLoaState({
        visible: true,
        studentId: record._id,
        applicationId: record.applications._id,
        record: record
      })
    },
    hideDeferLoaDrawer: async () => {
      setDeferLoaState({
        visible: false,
        studentId: '',
        applicationId: '',
        record: ''
      })
    }
  }

  const getParamsForApplicationList = () => {
    return new Promise(resolve => {
      let searchParams = new URLSearchParams(window.location.search)
      let name = searchParams.get('name')
      let campusId = searchParams.get('campusId')
      let agentId = searchParams.get('agentId')
      let intake = searchParams.get('intake')
      let courseName = searchParams.get('courseName')
      let marketingUserId = searchParams.get('marketingUserId')
      let ttVerifiedStatus = searchParams.get('ttVerifiedStatus')
      let fromDate = searchParams.get('fromDate')
      let toDate = searchParams.get('toDate')
      let obj = {}

      if (name) {
        obj.name = name
      }

      if (campusId) {
        obj.campusId = campusId
      }
      if (agentId) {
        obj.agentId = agentId
      }
      if (intake) {
        obj.intake = intake
      }
      if (courseName) {
        obj.courseName = courseName
      }
      if (marketingUserId) {
        obj.marketingUserId = marketingUserId
      }
      if (ttVerifiedStatus) {
        obj.ttVerifiedStatus = ttVerifiedStatus
      }
      if (fromDate) {
        obj.fromDate = fromDate
      }
      if (toDate) {
        obj.toDate = toDate
      }
      if (lastUpdatedByUserId) {
        obj.lastUpdateByUserId = lastUpdatedByUserId
      }

      resolve(obj)
    })
  }

  const searchFxn = async () => {
    let obj = {}
    if (name) {
      obj.name = name
    }
    if (campusId) {
      obj.campusId = campusId
    }
    if (agentId) {
      obj.agentId = agentId
    }
    if (intake) {
      obj.intake = intake
    }
    if (courseName) {
      obj.courseName = courseName
    }
    if (marketingUserId) {
      obj.marketingUserId = marketingUserId
    }
    if (ttVerifiedStatus) {
      obj.ttVerifiedStatus = ttVerifiedStatus
    }
    if (fromDate && toDate) {
      obj.fromDate = fromDate
      obj.toDate = toDate
    }
    if (lastUpdatedByUserId) {
      obj.lastUpdateByUserId = lastUpdatedByUserId
    }
    dispatch(
      getUrlPushWrapper('onshoreCambieAppList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
    }, 200)
  }
  const clearFxn = async () => {
    let obj = {}
    setName('')
    setCampusId('')
    setAgentId('')
    setIntake('')
    setCourseName('')
    setMarketingUserId('')
    setTtVerifiedStatus('')
    dispatch(
      getUrlPushWrapper('onshoreCambieAppList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      tableAppRef.current.reload()
    }, 200)
  }

  const columns = [
    {
      title: 'App#',
      key: 'applications.id',
      dataIndex: 'applications.id',
      searchTextName: 'applications.id',
      width: 100,
      render: item => {
        return <div style={{ width: 60 }}>{item}</div>
      }
    },
    {
      title: 'Student ID',
      key: 'applications.studentIdNo',
      dataIndex: 'applications.studentIdNo',
      width: 100,
      render: (item, record) => {

        return <div style={{ width: 80 }}>{item}</div>
      }
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 150,
      render: (item, record) => {
        return (
          <div style={{ width: 150 }}>
            {/*{item}*/}

            <Tooltip title={'Edit Student'}>
              <a className={'linkAA'}
                 onClick={() => {
                   events.showEditStudent(record)
                 }}>
                {item} <Icon type={'edit'} />
              </a>
            </Tooltip>

            <br />
            {newFormatDisplayDate(record.dateOfBirth)}
          </div>
        )
      }
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      width: 220
    },
    {
      title: 'Program',
      key: 'applications.courseName',
      dataIndex: 'applications.courseName',
      width: 220,
      render: item => {
        return <div style={{ width: 200 }}>{item}</div>
      }
    },
    {
      title: 'Tuition Fees',
      key: 'tuitionFeeDocument',
      dataIndex: 'tuitionFeeDocument',
      width: 180,
      render: (val, record) => {
        return (
          <div style={{ width: 180 }} className={'fcmtDiv'}>
            <UploadTTComponentCambieOnshore
              student={record}
              ttVerifiedStatus={record.applications.ttVerifiedStatus ? record.applications.ttVerifiedStatus : 'Pending'}
              user={user}
              reloadTable={events.reloadFxn}
            />
          </div>
        )
      }
    },
    {
      title: 'Total TT Amount',
      key: 'applications.totalTTAmount',
      dataIndex: 'applications.totalTTAmount',
      width: 180,
      render: (item, record) => {
        return (
          <div style={{ width: 180 }} className={'fcmtDiv'}>
            <TotalTTComponent
              student={record}
              ttVerifiedStatus={record.applications.ttVerifiedStatus ? record.applications.ttVerifiedStatus : 'Pending'}
              user={user}
              reloadTable={events.reloadFxn}
            />
          </div>
        )
      }
    },
    {
      title: 'Commission Status',
      key: 'applications',
      dataIndex: 'applications',
      width: 160,
      render: (item, record) => {
        return item && item.cambieCommissionStatus && item.cambieCommissionStatus == 'notApplicable' ?
          <label className={'label label-success sm'}>
            Not Applicable
          </label>
          :
          item && item.cambieCommissionStatus && item.cambieCommissionStatus == 'pending' ?
            <>
              <div>${item.cambieCommissionAmount}</div>
              <label className={'label label-info sm'}>
                Pending
              </label>
            </>
            : null
      }
    },
    {
      title: 'Intake',
      key: 'applications.intake',
      dataIndex: 'applications.intake',
      width: 180,
      render: (item, record) => {
        return (
          <div style={{ width: 160 }}>
            {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
            <br />
            {record.applications.oldIntake &&
            record.applications.oldIntake.month &&
            record.applications.oldIntake.year ? `(Defer From - ${record.applications.oldIntake.month} , ${record.applications.oldIntake.year})` : null}
            <div>
              {
                record.applications.courseId ? <button
                  className='bs_btn bs-default mt10 bs-xs'
                  onClick={() => {
                    events.showDeferLoaDrawer(record)
                  }}
                >Defer Loa</button> : null
              }
            </div>

          </div>
        )
      }
    },
    {
      title: 'Campus',
      key: 'applications.campusId',
      dataIndex: 'applications.campusId',
      width: 120,
      render: (item) => {
        return (
          <div style={{ width: 120 }}>
            {item && item.campusName ? item.campusName : ''}
          </div>
        )
      }
    },
    {
      title: 'Current User',
      key: 'applications.assignedUserId',
      dataIndex: 'applications.assignedUserId',
      width: 130,
      render: (val, record) => {
        let { statusList } = record.applications
        return (
          <div style={{ width: 130 }}>
            {val && val.name ? val.name : ''}
            <br />
            <div>{longDisplayDate(statusList.date)}</div>
          </div>
        )
      }
    },

    {
      title: 'Conditional Offer Letter',
      key: 'applications.offerReceiveLetter',
      dataIndex: 'applications.offerReceiveLetter',
      width: 150,
      render: (val, record) => {
        return (
          <div style={{ width: 150 }} className={'fcmtDiv'}>
            <OfferActionComponent file={val} record={record} events={events} user={user} />
          </div>
        )
      }
    },
    {
      title: 'LOA',
      key: 'applications.loaLetter',
      dataIndex: 'applications.loaLetter',
      width: 180,
      render: (val, record) => {
        return (
          <div style={{ width: 180 }} className={'fcmtDiv'}>
            {val && val.path ? <>
              <Tag>
                <Icon type={'download'} /> &nbsp;
                <a href={val.path} target={'_blank'}>Download LOA</a></Tag>
              <br />

            </> : null}
            <RightComponent user={user} userTypes={['cambieDirector', 'cambieManager']}>
              <button className={`bs_btn bs-default mt10 bs-xs`} onClick={() => {
                events.showLoaDrawer(record)
              }}>
                Generate
              </button>
            </RightComponent>
          </div>
        )
      }
    },
    {
      title: 'Enrollment Letter',
      key: 'applications.enrollmentLetter',
      dataIndex: 'applications.enrollmentLetter',
      width: 200,
      render: (val, record) => {
        let { loaLetter } = record.applications
        return (
          <div style={{ width: 200 }} className={'fcmtDiv'}>
            {val && val.path ? <>
              <Tag>
                <Icon type={'download'} /> &nbsp;
                <a href={val.path} target={'_blank'}>Download Enrolment</a></Tag>
              <br />

            </> : null}
            <RightComponent user={user} userTypes={['cambieDirector', 'cambieManager']}>
              <button className={`bs_btn bs-default mt10 bs-xs`}
                      onClick={() => {
                        events.showEnrollmentDrawer(record)
                      }}>
                Generate
              </button>
            </RightComponent>
          </div>
        )
      }
    },
    {
      title: 'ESL Support Letter',
      key: 'applications.supportingLetter',
      dataIndex: 'applications.supportingLetter',
      width: 200,
      render: (val, record) => {
        let { courseId } = record.applications
        return (
          courseId && courseId.eslCourse ? <div style={{ width: 200 }} className={'fcmtDiv'}>
            {val && val.path ? <>
              <Tag>
                <Icon type={'download'} /> &nbsp;
                <a href={val.path} target={'_blank'}>Download ESL<br /> Supporting Letter</a></Tag>
              <br />

            </> : null}
            <RightComponent user={user} userTypes={['cambieDirector', 'cambieManager']}>
              <button className={`bs_btn bs-default mt10 bs-xs`}
                      onClick={() => {
                        events.showSupportingDrawer(record)
                      }}>
                Generate
              </button>
            </RightComponent>
          </div> : null
        )
      }
    },

    {
      title: 'Academic Transcript',
      width: 180,
      key: 'applications.markSheetLetter',
      dataIndex: 'applications.markSheetLetter',
      render: (val, record) => {
        let { courseId } = record.applications
        return (
          courseId && courseId.eslCourse ?
            <div style={{ width: 180 }} className={'fcmtDiv'}>
              {val && val.path ?
                <>
                  <Tooltip title={'Download Transcript'}>
                    <Tag>
                      <Icon type={'download'} /> &nbsp;
                      <a href={val.path} target={'_blank'}>Download Transcript</a></Tag>
                    <br />
                  </Tooltip>
                  <Tooltip title={'Re-Generate Transcript'}>
                    <RightComponent user={user} userTypes={['cambieDirector', 'cambieManager']}>
                      <button className={`bs_btn bs-default mt10 bs-xs`} onClick={() => {
                        events.showAddMarkSheetDrawer(record)
                      }}>
                        Re-Generate
                      </button>
                    </RightComponent>
                  </Tooltip>
                </> : <RightComponent user={user} userTypes={['cambieDirector', 'cambieManager']}>
                  <Tooltip title={'Generate Transcript'}>
                    <button className={`bs_btn bs-default mt10 bs-xs`} onClick={() => {
                      events.showAddMarkSheetDrawer(record)
                    }}>
                      Generate
                    </button>
                  </Tooltip>
                </RightComponent>}
            </div>
            : null
        )
      }
    },
    {
      title: 'Generate Esl Certificate',
      width: 200,
      key: 'applications.eslCertificate',
      dataIndex: 'applications.eslCertificate',
      render: (val, record) => {
        let { courseId } = record.applications
        return (
          courseId && courseId.eslCourse ?
            <div style={{ width: 180 }} className={'fcmtDiv'}>
              {val && val.path ?
                <>
                  <Tooltip title={'Download Esl Certificate'}>
                    <Tag>
                      <Icon type={'download'} /> &nbsp;
                      <a href={val.path} target={'_blank'}>Download</a></Tag>
                    <br />
                  </Tooltip>

                  <RightComponent user={user} userTypes={['cambieDirector', 'cambieManager']}>
                    <Tooltip title={'Re-Generate Esl Certificate'}>
                      <Popconfirm
                        title={'Are your sure, you want to Re-Generate Esl Certificate?'}
                        onConfirm={() => events.generateEslCertificate(record)}
                        okText='Yes'
                        cancelText='No'>
                        <a className={'btn btn-default sm mt-2'}>Re-Generate</a>
                      </Popconfirm>
                    </Tooltip>
                  </RightComponent>
                </> : <RightComponent user={user} userTypes={['cambieDirector', 'cambieManager']}>
                  <Tooltip title={'Generate Esl Certificate'}>
                    <Popconfirm
                      title={'Are your sure, you want to Generate Esl Certificate?'}
                      onConfirm={() => events.generateEslCertificate(record)}
                      okText='Yes'
                      cancelText='No'>
                      <a className={'btn btn-default sm'}>Generate</a>
                    </Popconfirm>
                  </Tooltip>
                </RightComponent>}
            </div> : null
        )
      }
    },
    {
      title: 'Receipt',
      key: 'applications.receipt',
      dataIndex: 'applications.receipt',
      width: 180,
      render: (val, record) => {
        return (
          <div style={{ width: 180 }} className={'fcmtDiv'}>
            {val && val.path ? <>
              <Tag>
                <Icon type={'download'} /> &nbsp;
                <a href={val.path} target={'_blank'}>Download Receipt</a></Tag>
              <br />
            </> : null}
            <RightComponent user={user} userTypes={['cambieDirector', 'cambieManager']}>
              <button
                className={`bs_btn bs-default mt10 bs-xs`}
                onClick={() => {
                  events.showReceiptDrawer(record)
                }}>
                Generate
              </button>
            </RightComponent>
          </div>
        )
      }
    },
    {
      title: 'Status',
      key: 'applications.status',
      dataIndex: 'applications.status',
      width: 140,
      render: (item, record) => {
        return (
          <div className={'fcmtDiv'} style={{ width: 140 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Referral Code',
      key: 'referralCode',
      dataIndex: 'referralCode',
      width: 120,
      render: (item, record) => {
        return (
          <div className={'fcmtDiv'} style={{ width: 120 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Added By',
      key: 'cambieAgentId',
      dataIndex: 'cambieAgentId',
      width: 150,
      render: (item, record) => {
        return (
          <div className={'fcmtDiv'} style={{ width: 120 }}>
            {item && item.name ? item.name : ''}<br />
            {item && item.email ? `(${item.email})` : ''}
          </div>
        )
      }
    },
    {
      title: 'Updated By',
      key: 'applications.lastUpdatedBy',
      dataIndex: 'applications.lastUpdatedBy',
      width: 150,
      render: (item, record) => {
        return (
          <div className={'fcmtDiv'} style={{ width: 120 }}>
            {item && item.name ? item.name : ''}<br />
            {item && item.email ? `(${item.email})` : ''}
          </div>
        )
      }
    },
    {
      title: 'Marketing User',
      key: 'applications.marketingUserId',
      dataIndex: 'applications.marketingUserId',
      width: 120,
      render: (item, record) => {
        return (
          <div className={'fcmtDiv'} style={{ width: 120 }}>
            {item && item.name ? item.name : ''}
          </div>
        )
      }
    },
    {
      title: 'Documents',
      key: 'document',
      dataIndex: 'document',
      width: 140,
      render: (val, record) => {
        return (
          <div className={'actionBtnGroup'}>
            <Tooltip title={'View Documents'}>
              <a className={`btn btn-default roundNew xs`}
                 onClick={() => events.openDocumentDrawer(record)}>
                View Documents
              </a>
            </Tooltip>
            <br />
            <Tooltip title={'View Application'}>
              <a className={`btn btn-default roundNew xs`}
                 href={`/application/view?appId=${record.applications._id}&studentId=${record._id}`}>
                View Application
              </a>
            </Tooltip>
          </div>
        )
      }
    }
  ]


  const loadMarketingUsers = async () => {
    let obj = {
      userType: 'cambieBranchUser',
      results: 100,
      select: ['name']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setMarketingUserList(data)
  }
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const DateRangeSelector = (date, dateString) => {
    setFromDate(dateString[0])
    setToDate(dateString[1])
  }
  const loadCambieUsers = async () => {
    let obj = {
      userType: ['cambieManager', 'cambieBranchUser', 'cambieDirector', 'cambieAccoutant', 'cambieReception'],
      results: 100,
      select: ['name']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setCambieUsers(data)
  }

  const exportAppList = async () => {
    let { success, message } = await dispatch(cambieOnShoreApplicationExportFxn(filters))
    if (success) {
      notification.success({ message: message })
    } else {
      notification.error({ message: message })
    }
  }

  const extra = (
    <div className={'mt10 filter_box'}>
      <Row gutter={12}>
        <Col md={5} sm={5} xs={8} lg={5}>
          <InputBox title={'Search by name'}>
            <input
              type='search'
              placeholder='Search by name...'
              value={name}
              onChange={e => {
                setName(e.target.value)
              }}
            />
          </InputBox>
        </Col>
        <Col md={5} sm={5} xs={8} lg={5}>
          <InputBox title={'Search by course name'}>
            <input
              type='search'
              placeholder='course name...'
              value={courseName}
              onChange={e => {
                setCourseName(e.target.value)
              }}
            />
          </InputBox>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <InputBox title={'Search by Campus'}>
            <Select
              name='Status'
              onChange={item => setCampusId(item)}
              placeholder={'Campus'}
              allowClear={true}
              value={campusId || undefined}>
              {campusList && campusList.length
                ? campusList.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.campusName}
                    </Option>
                  )
                })
                : null}
            </Select>
          </InputBox>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <InputBox title={'Search by Intake'}>
            <Select
              name='Intake'
              onChange={item => setIntake(item)}
              filterOption={filterOption}
              allowClear={true}
              showSearch={true}
              placeholder={'Intake'}
              value={intake || undefined}>
              {Intakes && Intakes.length
                ? Intakes.map((item, key) => {
                  return (
                    <Option value={item} key={key}>
                      {item}
                    </Option>
                  )
                })
                : null}
            </Select>
          </InputBox>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <InputBox title={'Search by Marketing User'}>
            <Select
              name='Search By Marketing User'
              filterOption={filterOption}
              allowClear={true}
              placeholder={'Marketing User'}
              onChange={item => setMarketingUserId(item)}
              value={marketingUserId || undefined}>
              {marketingUserList && marketingUserList.length
                ? marketingUserList.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </InputBox>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col md={5} sm={5} xs={24} lg={5}>
          <InputBox title={'Search By TT Status'}>
            <Select
              name='Search By TT Status'
              filterOption={filterOption}
              allowClear={true}
              className={'round-selector'}
              mode={'multiple'}
              placeholder={'TT Status'}
              onChange={item => setTtVerifiedStatus(item)}
              value={ttVerifiedStatus || undefined}>
              {ttVerifiedStatusList && ttVerifiedStatusList.length
                ? ttVerifiedStatusList.map((item, key) => {
                  return (
                    <Option value={item.value} key={item.value}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </InputBox>
        </Col>
        <Col md={5} sm={5} xs={12} lg={5}>
          <InputBox title={'Select Date'}>
            <RangePicker onChange={DateRangeSelector} />
          </InputBox>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <InputBox title={'Search by Last Updated'}>
            <Select
              name='Search by Last Updated'
              filterOption={filterOption}
              allowClear={true}
              placeholder={'Last Updated By Users'}
              onChange={item => setLastUpdatedByUserId(item)}
              value={lastUpdatedByUserId || undefined}>
              {cambieUserList && cambieUserList.length
                ? cambieUserList.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </InputBox>
        </Col>

        <Col md={14} sm={14} xs={24} lg={14}>
          <div className={'mt30'}>
            <Button onClick={() => searchFxn()} className={'roundBtn'}>
              Search
            </Button>
            <Button onClick={() => clearFxn()} className={'roundBtn'}>
              Clear
            </Button>
            <RightComponent user={user} userTypes={['cambieManager']}>
              <Button onClick={() => exportAppList()} className={'roundBtn'}>
                Export
              </Button>
            </RightComponent>
          </div>
        </Col>
      </Row>
    </div>
  )

  const getColumns = () => {
    let newCol = _.filter(columns, (item) => {
      return !item.hidden
    })
    return newCol
  }

  return (
    <React.Fragment>
      <div>
        <div className='row mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div
                className='table-head d-flex align-items-center all-student-search'
                style={{ height: 60 }}>
                <Button
                  onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                  className={'roundGoBackBtn'}
                  icon={'arrow-left'}>
                  Back
                </Button>
                <h5>Application List {totalApplication && `(Total - ${totalApplication})`}</h5>
              </div>
              <div className='card-body table-responsive'>
                {extra}
                <TableComp
                  columns={getColumns()}
                  rowKey={(item, index) => {
                    return index
                  }}
                  apiRequest={apiRequest}
                  pagination={{
                    position: 'top',
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    defaultPageSize: 10,
                    current: filters.page ? filters.page : 1
                  }}
                  extraProps={{ scroll: { x: 3000 } }}
                  ref={tableAppRef}
                />
              </div>
            </div>
          </div>
        </div>

        {visibleDocumentDrawer &&
        studentData.applications &&
        studentData.applications._id ? (
          <AllDocumentDrawer
            onClose={events.closeDocumentDrawer}
            reloadTable={events.reloadFxn}
            studentData={studentData}
            applicationId={studentData.applications._id}
            studentId={studentData._id}
            visible={visibleDocumentDrawer}
          />
        ) : null}
      </div>
      {receiptState.visible ?
        <GenerateCambieOnshoreReceipt
          {...receiptState}
          onClose={events.hideReceiptDrawer}
          onSubmit={() => {
            events.hideReceiptDrawer()
            events.reloadFxn()
          }}
        /> : null}

      {enrollementState.visible ?
        <EnrollmentLetterDrawer
          {...enrollementState}
          onClose={events.hideEnrollmentDrawer}
          onSubmit={() => {
            events.hideEnrollmentDrawer()
            events.reloadFxn()
          }}
        /> : null}

      {supportingLetterState.visible ?
        <SupportingLetterDrawer
          {...supportingLetterState}
          onClose={events.hideSupportingDrawer}
          onSubmit={() => {
            events.hideSupportingDrawer()
            events.reloadFxn()
          }}
        /> : null}

      {loaState.visible ?
        <GenerateCambieOnshoreLoa
          {...loaState}
          onClose={events.hideLoaDrawer}
          onSubmit={() => {
            events.hideLoaDrawer()
            events.reloadFxn()
          }}
        /> : null}

      {studentState.visible ?
        <EditStudentDrawer
          {...studentState}
          onClose={events.hideEditStudent}
          onSubmit={() => {
            events.hideEditStudent()
            events.reloadFxn()
          }}
        /> : ''}

      {addMarkSheetState.visible ?
        <AddTranscriptOfMarks
          {...addMarkSheetState}
          onClose={() => {
            events.hideMarkSheetDrawer()
          }}
          onSubmit={() => {
            events.hideMarkSheetDrawer()
            events.reloadFxn()
          }}
        /> : ''}

      {deferLoaState.visible ?
        <DeferLoa
          {...deferLoaState}
          onClose={() => {
            events.hideDeferLoaDrawer()
          }}
          onSubmit={() => {
            events.hideDeferLoaDrawer()
            events.reloadFxn()
          }}
        /> : ''}


    </React.Fragment>
  )
}

export default OnShoreCambieApplicationList
