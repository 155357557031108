import React, {useEffect, useState} from 'react'
import {Icon, Avatar, Row, Col, Card, Collapse, Button, Tooltip} from 'antd'
import {useHistory} from 'react-router-dom'
import ProfileImg from '../../../../assets/profile-user.png'
import {icefEventListFxn} from '../../actions'
import './style.css'
import {useDispatch} from 'react-redux'
import {displayDate, getDirectUniversityName} from '../../../../components/_utils/appUtils'
import moment from 'moment'
import {getUrlPushWrapper} from '../../../../routes'

const {Panel} = Collapse
let getDate = (eventData) => {
    let starDay = moment(eventData.startDate).format('MMMM D')
    let endDay = moment(eventData.endDate).format('D')
    let endYear = moment(eventData.endDate).format('YYYY')
    return `${starDay} - ${endDay}, ${endYear}`
}
let SingleEventComponent = (props) => {
    let {eventData, dispatch, _handleParticipants} = props
    let addPart = () => {
        dispatch(
            getUrlPushWrapper('addParticipant', {
                id: eventData._id
            }))
    }
    return (
        <>
            <Col xs={24} sm={12} md={6} key={eventData._id}>
                <div className='card-wrapper event-card event-card-icef w-100'>
                    <Card
                        bordered={true}
                        className={`card-container`}
                        style={
                            eventData && eventData.countryPhoto && eventData.countryPhoto.path
                                ? {backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.55)), url(${eventData.countryPhoto.path})`}
                                : null
                        }>

                        <div className='card-content  event-card-box'>
                            <Tooltip title={'Click to Your Participants'}>
                                <a onClick={() => {
                                    _handleParticipants(eventData._id)
                                }}>
                                    <h2>{eventData.eventName}</h2>
                                    <h3>{getDate(eventData)}</h3>
                                    <h3>{eventData.state}, {eventData.country}</h3>
                                </a>
                            </Tooltip>
                            <div className='event-info'>
                                <h5><strong>IN PERSON</strong></h5>
                                <Tooltip title={'Click to Add Participants'}>
                                    <Icon type={'plus'} className='add-event-btn' onClick={() => {
                                        addPart()
                                    }}/>
                                </Tooltip>
                            </div>
                        </div>
                    </Card>
                </div>
            </Col>

        </>
    )
}
const icefMeeting = () => {
    const [upcomingEvents, setUpcomingEvents] = useState([])
    const [eventData, setEventData] = useState([])
    const history = useHistory()
    const dispatch = useDispatch()
    let currentDate = new Date()
    let events = {
        loadUpcomingEvents: async () => {
            let resp = await dispatch(icefEventListFxn({
                customQuery: {startDate: {$gte: moment().startOf('day')}},
                regExFilters: ['contactPersonName']
            }))
            setUpcomingEvents(resp.data)
        },
        loadOldEvents: async () => {
            let resp = await dispatch(icefEventListFxn({
                results: 50,
                count: 50,
                customQuery: {startDate: {$lt: moment().startOf('day')}},
                regExFilters: ['contactPersonName']
            }))
            setEventData(resp.data)
        }
    }

    useEffect(() => {
        events.loadUpcomingEvents()
        events.loadOldEvents()
    }, [])

    const handleParticipants = (eventId) => {
        history.push(`/participants/${eventId}`)
    }

    const addParticipantExtra = (eventId) => {
        return (
            <span className={'aic'} onClick={(e) => {
                dispatch(getUrlPushWrapper('addParticipant', {id: eventId}))
                e.stopPropagation();
            }}>
                <Icon type={'plus'} className='add-event-btn'/>&nbsp;Add Participant
            </span>
        )
    }


    return (
        <div className={''}>
            <div className='card unizportal bgWhite'>
                <div>
                    <div className={'icefManager'}>
                        <div className={'avatar_box'}>
                            <img src={'../assets/image/businessman.png'}/>
                        </div>
                        <div className={'manager_info_box'}>
                            <h6 className='head-title'><strong>ICEF Manager</strong></h6>
                            <div className={'manager_details'}>
                                <div className={'inline_a'}>
                                    <Icon type={'user'}/>&nbsp; <strong>Hitanshu Bhardwaj</strong>
                                </div>
                                <div className={'inline_a'}>
                                    <Icon
                                        type={'mail'}/>&nbsp; hitanshu@unizportal.com
                                </div>
                                <div className={'inline_a'}>
                                    <Icon type={'phone'}/>&nbsp; +91 9999990803
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <Row gutter={[24, 24]} style={{margin: '0px'}}>
                    <Col span={24} className={'jcsb'} style={{alignItems: 'flex-start'}}>
                        <h4 className='upcoming-events-title'>
                            <strong>My Upcoming Events</strong>
                        </h4>
                        <Tooltip title={'Add Event'}>
                            <a className='btn btn-sm btn-default' onClick={() => {
                                dispatch(getUrlPushWrapper('addIcefEvents'))
                            }}><Icon type={'plus'}/> Add Event</a>
                        </Tooltip>
                    </Col>

                    {upcomingEvents.map((eventData, index) => {
                        return (
                            <SingleEventComponent
                                eventData={eventData} dispatch={dispatch}
                                _handleParticipants={handleParticipants}/>
                        )
                    })}
                </Row>

                <Row gutter={[24, 24]} className='past-events-row' style={{margin: '0px'}}>
                    <h4 className='past-events-title'><strong>My past events</strong></h4>
                    <Collapse className='collapsible mt5'>
                        {eventData.map((event, index) => (
                            <Panel key={index}
                                   header={`${event.eventName} -  (${getDate(event)}) — Representing unizportal`}
                                   extra={addParticipantExtra(event._id)}
                                   className='custom-panel'>
                                <Collapse>
                                    <Panel header='View Participants' key='1' className='custom-panel'>
                                        <Button type='link' onClick={() => {
                                            handleParticipants(event._id)
                                        }}>Click to view participants</Button>
                                    </Panel>
                                    {/* <Panel header='Colleagues at this event' key='2' className='custom-panel'>
                    Content of Event 2
                  </Panel>*/}
                                </Collapse>
                            </Panel>
                        ))}
                    </Collapse>
                </Row>
            </div>
        </div>
    )
}

export default icefMeeting

