import React, { PureComponent, Suspense } from 'react'
import { Button, Col, Layout, Menu, Modal, Row, Tag } from 'antd'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import styles from './index.less'
import PageLoading from '../PageLoading'
import { getDefaultCollapsedSubMenus } from './SiderMenuUtils'
import { CheckUserRight } from '../../containers/dashboard/views/DashboardUserWise'
import { push } from 'connected-react-router'
import {
  getUser,
  getAgentCertificate,
  getSingleAgentCertificates,
  downloadCertificateFxn, activeCertificateFxn
} from '../../containers/users/actions/user'
import AddCounsellingComponent from '../../containers/ReceptionDashboard/view/addCounsellingComponent'
import { connect } from 'react-redux'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { downloadAgentProfileFxn, resetLatestUpdateCountFxn } from '../../containers/latestUpdate/actions'

const BaseMenu = React.lazy(() => import('./BaseMenu'))
const { Sider } = Layout

class SiderMenu extends PureComponent {
  events = {
    showModal: async () => {
      let { user } = this.props
      // let { data } = await getSingleAgentCertificates(user._id);
      let { data } = await activeCertificateFxn()
      /*let certificates = {
        agentCertificate: data.agentCertificate,
        // agentCambieCertificate: data.agentCambieCertificate,
        // agentFCMTCertificate: data.agentFCMTCertificate,
        agentKASACertificate: data.agentKASACertificate,
        agentCiraCertificate: data.agentCiraCertificate,
        agentCornerStoneCertificate: data.agentCornerStoneCertificate
      }*/
      this.setState({ visible: true, certificateList: data })
    },
    handleCancel: () => {
      this.setState({ visible: false, certificates: {} })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      openKeys: getDefaultCollapsedSubMenus(props),
      user: {},
      visible: false,
      certificates: {},
      certificateList: []
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { pathname } = state
    if (props.location.pathname !== pathname) {
      return {
        pathname: props.location.pathname,
        openKeys: getDefaultCollapsedSubMenus(props)
      }
    }
    return null
  }

  isMainMenu = key => {
    const { menuData } = this.props
    return menuData.some(item => {
      if (key) {
        return item.key === key || item.path === key
      }
      return false
    })
  }

  handleOpenChange = openKeys => {
    const moreThanOne = openKeys.filter(openKey => this.isMainMenu(openKey)).length > 1
    this.setState({
      openKeys: moreThanOne ? [openKeys.pop()] : [...openKeys]
    })
  }

  componentDidMount() {
    // this.loadLoginUser()
  }

  openLink = (link) => {
    window.scrollTo(0, 0)
    let { dispatch } = this.props
    dispatch(push(link))
  }
  openLatestUpdates = async (link) => {
    let { dispatch } = this.props
    resetLatestUpdateCountFxn().then((resp) => {
      if (resp && resp.success) {
        dispatch({ type: 'UNREAD_LATEST_UPDATE', unreadUpdates: resp.unreadUpdates || 0 })
      }
    })
    window.scrollTo(0, 0)
    dispatch(push(link))
  }
  downloadSelfProfile = async () => {
    let { dispatch } = this.props
    let resp = await dispatch(downloadAgentProfileFxn())
    if (resp && resp.data) {
      window.open(resp.data, 'Download')
    }
  }

  loadLoginUser = async () => {
    let { dispatch, user } = this.props
    let resp = await dispatch(getUser(user._id))
    this.setState({ user: resp })
  }

  render() {
    const {
      logo,
      collapsed,
      onCollapse,
      fixSiderbar,
      theme,
      smallLogo,
      currentUser,
      currentUserRights,
      unreadLatestUpdate
    } = this.props
    const { openKeys, certificates, visible, certificateList } = this.state
    const defaultProps = collapsed ? {} : { openKeys }


    const siderClassName = classNames(styles.sider, {
      [styles.fixSiderbar]: fixSiderbar,
      [styles.light]: theme === 'light'
    })

    const agentCertificate = async () => {

      let { dispatch } = this.props
      let { data } = await dispatch(getAgentCertificate())
      if (data && data.url) {
        openPage(data)
      }
    }
    const downloadUserCertificateFxn = async (certificateName) => {
      let { dispatch } = this.props
      let { data } = await dispatch(downloadCertificateFxn({ certificateName }))
      if (data && data.path) {
        window.open(`${data.path}`, '_blank')
      }
    }
    const openPage = (data) => {
      window.open(`${data.url}`, '_blank')
    }

    const showComplaintToUsers = () => {
      let { userType } = currentUser
      if (userType == 'admin' || userType == 'branchUser' || userType == 'onBoardManager' ||
        (userType == 'branchManager')
      ) {
        return true
      } else {
        return false
      }
    }

    return (
      <Sider
        trigger={null}
        defaultCollapsed={true}
        collapsible
        collapsed={collapsed}
        breakpoint='lg'
        onCollapse={onCollapse}
        width={256}
        theme={theme}
        className={siderClassName}
      >
        <div className={styles.logo} id='logo'>
          <Link to='/dashboard' style={collapsed ? { width: 110 } : { width: 256 }}>
            {!collapsed ? <LazyLoadImage src={logo} alt='logo' /> : null}
            {/*{collapsed ? null : <h1>{this.props.title}</h1>}*/}
          </Link>
        </div>

        <div className='sidebar' key={currentUser.userType}>
          <nav className='mt-4'>
            {currentUser && currentUser.userType && currentUser.userType == 'reception' ?
              <AddCounsellingComponent /> : null}
            {currentUser && currentUser._id ?
              <Suspense fallback={<PageLoading />}>
                <BaseMenu
                  {...this.props}
                  mode='inline'
                  user={currentUser}
                  currentUserRights={currentUserRights}
                  handleOpenChange={this.handleOpenChange}
                  onOpenChange={this.handleOpenChange}
                  style={{ width: '100%' }}
                  {...defaultProps}
                />
              </Suspense> : null}


            <ul className='nav nav-pills nav-sidebar flex-column' data-widget='treeview' role='menu'
                data-accordion='false' style={{ paddingTop: 0 }}>
              <hr className='divide' />
              {/*{currentUser && currentUser.userType && currentUser.userType == 'agent' ?
                <li className='nav-item'>
                  <a onClick={() => this.downloadSelfProfile()} className='nav-link nav-link1'>
                    <p>Download Profile</p>
                    <i className={styles.newLink}>New</i>
                  </a>
                </li>
                : null}*/}


              {currentUser && currentUser.userType !== 'student' ?
                <li className='nav-item'>
                  <a onClick={() => this.openLatestUpdates('/latest-updates')} className='nav-link nav-link1'>
                    <p>Latest Updates
                      {unreadLatestUpdate ? <i className={styles.newLink}>{unreadLatestUpdate}</i> : null}
                    </p>
                  </a>
                </li> : null}

              <CheckUserRight user={currentUser} rightUserType={['agent']}>
                <li className='nav-item'>
                  <a onClick={() => this.openLink('/agreement')} className='nav-link nav-link1'>
                    <p>Agreement</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a onClick={() => this.openLink('/advance-commission-contract')} className='nav-link nav-link1'>
                    <p>Advance Commission Contract</p>
                  </a>
                </li>

                {/* <li className='nav-item'>
                  <a onClick={() => this.openLink('/all-commission')} className='nav-link'>
                    <p>Commissions</p>
                  </a>
                </li>*/}
                <li className='nav-item'>
                  <a className='nav-link nav-link1' onClick={() => {
                    this.events.showModal()
                  }}>
                    <p>Certificates</p>
                  </a>
                </li>
              </CheckUserRight>


              {currentUser && (currentUser.userType == 'admin' || currentUser.email == 'accounts@ryanconsultants.com') ? <>
                <li className='nav-item'>
                  <a onClick={() => this.openLink('/agent-commission')}
                     className='nav-link nav-link1'>
                    <p>Commissions</p>
                  </a>
                </li>
              </> : null}

              {currentUser && (currentUser.userType == 'agent') ? <li className='nav-item'>
                <a onClick={() => this.openLink('/supporting-team')} className='nav-link nav-link1'>
                  <p>Supporting Team</p>
                </a>
              </li> : null}
              {currentUser && (currentUser.userType == 'agent') ? <li className='nav-item'>
                <a onClick={() => this.openLink('/raise-a-complaint')} className='nav-link nav-link1'>
                  <p>Raise a Complaint</p>
                  <i className={styles.newLink}>New</i>
                </a>
              </li> : null}
              {currentUser && showComplaintToUsers() ?
                <li className='nav-item'>
                  <a onClick={() => this.openLink('/raise-a-complaint-list')}
                     className='nav-link nav-link1'>
                    <p>Raise a Complaint List</p>
                    <i className={styles.newLink}>New</i>
                  </a>
                </li> : null}

            </ul>
            <div className='box-side'>
              <LazyLoadImage src='/dist/img/share-friend.png' alt=''
                             style={{
                               width: '100%',
                               margin: ' 30px auto 0',
                               display: 'block',
                               padding: '0 45px'
                             }} />
              <div className='social sideMenuSoical'>
                <ul>
                  <li><a href='https://www.linkedin.com/company/unizportal'><LazyLoadImage
                    src='/dist/img/linkedin.svg' alt='' /></a></li>
                  <li><a href='https://www.facebook.com/unizportal'><LazyLoadImage
                    src='/dist/img/facebook.svg'
                    alt='' /></a></li>
                  <li><a href='https://www.instagram.com/unizportalofficial/'><LazyLoadImage
                    src='/dist/img/instagram.png'
                    alt='' /></a></li>
                  <li><a
                    href='https://www.youtube.com/channel/UCfBEmsjpc1ZkHjPBgrrhJlA'><LazyLoadImage
                    src='/dist/img/youtube.png' alt='' /></a></li>
                </ul>
              </div>
            </div>


          </nav>
        </div>

        {visible ? <Modal
          title='Certificates'
          visible={visible}
          footer={null}
          onOk={this.events.handleOk}
          onCancel={this.events.handleCancel}>
          <div>
            <div className={'certificate_box'}>
              {certificateList && certificateList.length ? <>
                <Row gutter={16}>
                  {certificateList.map((item) => {
                    return (
                      <Col span={12} key={item.key}>
                        <a className={'btn btn-default btn-sm'}
                           onClick={() => {
                             downloadUserCertificateFxn(item.key)
                           }}>
                          {item.name}
                        </a>
                      </Col>
                    )
                  })}
                </Row>


              </> : null}

            </div>

          </div>
        </Modal> : null}

      </Sider>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  menuRefresh: global.menuRefresh,
  unreadLatestUpdate: global.unreadLatestUpdate,
  currentUserRights: global.currentUserRights

})
export default connect(
  mapStateToProps,
  null
)(SiderMenu)
