import React from 'react'
import { Button, Col, Modal, Form, notification, Row, Select } from 'antd'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { enquiryStatusFxn } from '../action'
import { unizHomeStatusList } from '../../../components/_utils/appUtils'

const { Option } = Select

const EnquiryStatusModal = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, form: { getFieldDecorator }, enquiryId } = props
  let inputTypes = {
    fields: [
      {
        key: 'status',
        label: 'Status',
        placeholder: 'Select Status',
        type: 'select',
        required: true,
        options: unizHomeStatusList
      }
    ]
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {

        valData.enquiryId = enquiryId
        const resp = await dispatch(enquiryStatusFxn(valData))
        if (resp && resp.success) {
          notification.success({ message: resp.message })
          onClose()
        }

      } else {
        notification.warning({
          message: 'Please select a status'
        })
      }
    })
  }

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onClose}
        title={`Update Status`}
        width={'35%'}
        footer={null}>
        <div className='card'>
          <Form onSubmit={handleSubmit}>
            <Row gutter={16} className={'wrapBox'}>
              {inputTypes.fields.map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    <Col span={24} key={key}>
                      <Form.Item label={item.label}>
                        {getFieldDecorator(item.key, {
                          rules: [{ required: item.required, message: `Please select ${item.label.toLowerCase()}` }]
                        })(
                          <Select placeholder={item.placeholder}>
                            {item.options.map((status, idx) => (
                              <Option key={idx} value={status}>
                                {status}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </React.Fragment>
                )
              })}
              <Col span={4}>
                <Form.Item>
                  <Button
                    type='primary' htmlType='submit' className={'btn'}>
                    Update Status
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  )
}

const EnquiryStatusUpdate = Form.create()(EnquiryStatusModal)

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnquiryStatusUpdate)
