import {apiUrl2, reeudoForexApi} from '../../../settings'

export const reeudoRatesUrl = () => {
    return reeudoForexApi + '/reeudo/rates'
}
export const reeudoSourceUrl = () => {
    return reeudoForexApi + '/reeudo/source'
}
export const getFinalChangesInInrUrl = () => {
    return reeudoForexApi + '/reeudo/final-changes-inr'
}
export const createAgentUrl = () => {
    return apiUrl2 + '/reeudo/create-agent'
}

export const createQuotationUrl = () => {
    return apiUrl2 + '/reeudo/create-quotation'
}
export const createAgentTestUrl = () => {
    return apiUrl2 + '/reeudo/create-agent-test'
}

export const singleAgentMarginUrl = () => {
    return apiUrl2 + '/reeudo/getAgentMargin'
}

export const updateAgentMarginUrl = () => {
    return apiUrl2 + '/reeudo/updateAgentMargin'
}

export const allQuotationUrl = () => {
    return apiUrl2 + '/reeudo/quotation'
}
export const singleQuotationUrl = () => {
    return apiUrl2 + '/reeudo/singleQuotation'
}
export const getTransactionUrl = () => {
    return apiUrl2 + '/reeudo/getTransactions'
}
export const transactionUrl = () => {
    return apiUrl2 + '/reeudo/transaction'
}

export const getMetaInfoUrl = () => {
    return reeudoForexApi + '/meta-information'
}

export const updateStudentUrl = () => {
    return apiUrl2 + '/reeudo/update-student'
}

export const updateStudentDocUrl = () => {
    return apiUrl2 + '/reeudo/update-docs'
}

export const downloadStudentDocUrl = () => {
    return apiUrl2 + '/download-docs'
}

export const createSenderUrl = () => {
    return apiUrl2 + '/sender-details'
}

export const updateSenderDocUrl = () => {
    return apiUrl2 + '/update-sender-docs'
}

export const downloadSenderDocUrl = () => {
    return apiUrl2 + '/download-sender-docs'
}

export const getDocumentsByIdUrl = () => {
    return apiUrl2 + '/reeudo/get-docs'
}
export const createPaymentUrl = () => {
    return reeudoForexApi + '/create-payment'
}

export const createBeneficiaryUrl = () => {
    return reeudoForexApi + '/create-beneficiary'
}

export const paymentInstructionUrl = () => {
    return apiUrl2 + '/get-payment-instruction'
}


export const getFinalPaymentChangesUrl = () => {
    return apiUrl2 + '/get-final-charges'
}

export const updateStudentDataUrl = () => {
    return apiUrl2 + '/reeudo/update-student-data'
}

export const updateKycStatusUrl = () => {
    return apiUrl2 + '/update-kyc-status'
}

export const getA2FormUrl = () => {
    return apiUrl2 + '/get-a2-form'
}

export const uploadA2FormUrl = () => {
    return apiUrl2 + '/upload-signed-a2-form'
}

export const updateSenderUrl = () => {
    return apiUrl2 + '/update-sender-details'
}

export const getSenderDocsUrl = () => {
    return apiUrl2 + '/reeudo/get-sender-docs'
}
export const getReferenceUsersUrl = () => {
    return apiUrl2 + '/reeudo/get-reference'
}

export const sendPaymentEmailUrl = () => {
    return apiUrl2 + '/send-payment-instruction'
}

export const getSingleTransactionUrl = () => {
    return apiUrl2 + '/reeudo/singleTransaction'
}

export const getDocumentDataUrl = () => {
    return apiUrl2 + '/reeudo/get-student-data'
}

export const editStudentDocUrl = () => {
    return apiUrl2 + '/reeudo/edit-student-data'
}

export const transferAgentUrl = () => {
    return apiUrl2 + '/reeudo/transfer-agent'
}

export const getAgentsUrl = () => {
    return apiUrl2 + '/reeudo/get-agents'
}

export const updateForexStatusUrl = () => {
    return apiUrl2 + '/update-forex-status'
}

export const getForexStatusUrl = () => {
    return apiUrl2 + '/get-forex-status'
}

export const currentForexStatusUrl = () => {
    return apiUrl2 + '/current-forex-status'
}

export const singleExchangeMarginUrl = () => {
    return apiUrl2 + '/reeudo/getExchangeMargin'
}

export const updateExchangeMarginUrl = () => {
    return apiUrl2 + '/reeudo/updateExchangeMargin'
}

export const deleteForexTransactionUrl = () => {
    return apiUrl2 + '/delete-forex-transaction'
}

export const sendOtpUrl = () => {
    return apiUrl2 + '/otp-delete-transaction'
}

export const verifyDeleteTransactionUrl = () => {
    return apiUrl2 + '/otp-verify-delete-transaction'
}

export const quotationReadStatusUrl = () => {
    return apiUrl2 + '/reeudo/update-read-status'
}

export const deleteForexStatusUrl = () => {
    return apiUrl2 + '/delete-forex-status'
}

export const updateTransactionUrl = () => {
    return apiUrl2 + '/create-transaction'
}

export const transferAssignUserUrl = () => {
    return apiUrl2 + '/transfer-assigned-user'
}

export const transactionStatusUrl = () => {
    return apiUrl2 + '/updateTransactionStatus'
}

export const getReeudoDocumentsByIdUrl = () => {
    return apiUrl2 + '/reeudo/get-reeudo-docs'
}

export const readTransactionUrl = () => {
    return apiUrl2 + '/update-read-transaction'
}

export const getReeudoManagerUrl = () => {
    return apiUrl2 + '/get-reeudo-managers'
}

export const getTransactionCountUrl = () => {
    return apiUrl2 + '/getTransactionCount'
}

export const quotationCountUrl = () => {
    return apiUrl2 + '/quotationCount'
}

export const transferQuotationUrl = () => {
    return apiUrl2 + '/transfer-quotation-user'
}

export const getTxnCounsellorUrl = () => {
    return apiUrl2 + '/reeudo/getCounselors'
}

export const updateQuotationUrl = () => {
    return apiUrl2 + '/update-quotation'
}

export const singleReeudoAgentUrl = () => {
    return apiUrl2 + '/reeudo/get-agent'
}

export const agentUnizMarginUrl = () => {
    return apiUrl2 + '/getAgentUnizMargin'
}

export const updateAgentUnizMarginUrl = () => {
    return apiUrl2 + '/updateAgentUnizMargin'
}

export const agentTransactionsListUrl = () => {
    return apiUrl2 + '/getAgentTransactions'
}
export const getSingleQuotationById = () => {
    return apiUrl2 + '/reeudo/get-single-quotation'
}
