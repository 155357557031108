import { departmentObj } from './appUtils'

const marketingRoutes = (user) => {
  let commonRoute = ['travelPlanList', 'addTravelPlan', 'tasksList', 'followupList', 'searchCourse', 'canadaOpenCloseCourse', 'ukOpenCloseCourse',
    'usaOpenCloseCourse', 'approvedAgents', 'dailyExpenseListForMarketingUser']
  let unizPortalMarketing = ['dashboard', 'oldMarketingUserDashboard', 'addStudent', 'allStudent', 'marketingUserAssignAgentList', 'commissionStructure', 'universityWiseAssignedAgents', 'liveReporting', 'liveReportingList', 'applicationRecord', 'diwaliGiftDistribution', 'txnList', 'callbackRequestList']
  let forexMarketing = ['dashboard', 'txnList']
  let unizHomeMarketing = ['dashboard', 'singleEnquiryDetails', 'agentEnquiryList']

  if (user.department == departmentObj.marketing) {
    if (user.marketingFor == 'unizportal') return [...unizPortalMarketing, ...commonRoute]
    if (user.marketingFor == 'forex') return [...forexMarketing, ...commonRoute]
    if (user.marketingFor == 'unizhome') return [...unizHomeMarketing, ...commonRoute]
  } else {
    return []
  }
}

const unizHomeRoutes = (user) => {

}

const evaluateRoutes = ['searchCourse', 'universitySearchCourse']

export { marketingRoutes, evaluateRoutes }
