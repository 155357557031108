import { Button, Card, Drawer, Input, Form, notification, Modal } from 'antd';
import { InputBox } from '../../../components/_utils/appUtils';
import React, { useState } from 'react';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import {enquiryDocumentFxn} from "../action";

const initialState = {
    documentDrawerVisible: false,
    name: '',
    document: {},
    documentKey: moment(),
};

const { TextArea } = Input;

const UploadEnquiryDocument = (props) => {
    const dispatch = useDispatch();
    let [state, setState] = useState(initialState);
    let { enquiryId, visible, onClose } = props;

    const events = {
        chooseDocument: (e) => {
            let { files } = e.target;
            if (files && files.length) {
                setState({
                    ...state,
                    document: files[0],
                });
            }
        },
        handleSubmit: async () => {
            let { document, name} = state;
            if (!document || (document && !document.name)) {
                return notification.error({
                    message: 'Please choose a document',
                });
            }

            let obj = {
                enquiryId,
                name,
            };

            let fd = new FormData();
            fd.append('enquiryId', enquiryId);
            fd.append('name', name);
            fd.append('document', document);

            let data = await dispatch(enquiryDocumentFxn(fd));
            if (data && !data.error) {
                setState({
                    ...state,
                    name: '',
                    document: '',
                    documentKey: moment(),
                });
                 onClose()
                notification.success({
                    message: 'Document uploaded successfully',
                });

            }
        },
    };

    return (
        <Modal
            width={700}
            title="Upload Enquiry Document"
            // placement="right"
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <Card>
                <Form>
                    <InputBox title="Document Name">
                        <TextArea
                            rows={4}
                            placeholder="Document Name"
                            value={state.name}
                            className="form-control"
                            onChange={(e) => {
                                setState({
                                    ...state,
                                    name: e.target.value,
                                });
                            }}
                        />
                    </InputBox>

                    <InputBox title="Choose Document">
                        <Input
                            type="file"
                            key={state.documentKey}
                            className="form-control"
                            onChange={(e) => events.chooseDocument(e)}
                        />
                    </InputBox>


                    <Form.Item className="mt20">
                        <Button
                            type="primary"
                            onClick={events.handleSubmit}
                        >
                            SUBMIT
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Modal>
    );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
    dispatch,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadEnquiryDocument);
