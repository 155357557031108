import React from "react"
import {Card, Drawer, Icon, Tooltip} from "antd";
import {RowTable} from "../../../components/_utils/RowTable";
import {newFormatDisplayDate} from "../../../components/_utils/appUtils";

const ExpenseInfo = (props) => {
    let {data, visible, onClose} = props;
    let {fromPlace, toPlace, agentName, expenseType} = data;

    return (
        <>
            <Drawer title={'Expense Info'} width={"50%"} onClose={onClose} visible={visible}>
                <Card>
                    <div className={'tableBox striped'}>
                        <RowTable title={'Date'} value={newFormatDisplayDate(data.date)}/>
                        <RowTable title={'Expense'} value={data.expenseType}/>
                        <RowTable title={'User'} value={data.userId && data.userId.name ? data.userId.name : ""}/>
                        <RowTable title={'Details'} value={
                            <>
                                {(expenseType == 'Hotel' || expenseType == 'Food') ? <div>
                                    {data.name}
                                    <br/>
                                    {data.city}({data.state})
                                </div> : null}
                                {expenseType == 'Car' ?
                                    <div>
                                        Place
                                        - {data.fromPlace ? `${data.fromPlace}, ` : ''}{data.city ? `${data.city}, ` : ''}{data.state}
                                        <br />
                                        To
                                        - {data.toPlace ? `${data.toPlace}, ` : ''}{data.toCity ? `${data.toCity}, ` : ''}{data.toState}
                                    </div>
                                    : null}
                                {expenseType == 'Other Expense' ? <div>
                                    {'Gift To Agent'} <br/>
                                    {agentName}
                                </div> : null}
                            </>
                        }/>
                        <RowTable title={'Amount'} value={
                            <>
                                {(expenseType == 'Hotel' || expenseType == 'Food' || expenseType == 'Other Expense') ?
                                    <div>
                                        Rs. {(data.amount).toFixed(2)}
                                    </div> : null}
                                {expenseType == 'Car' ? <div>
                                    Total Kms : {data.totalKlms} kms<br/>
                                    Extra Kms : {data.extraKlms} kms<br/>
                                    Toll Tax : Rs. {data.tollTax ? data.tollTax : 0}<br/>
                                    Net Amount : Rs. {(data.netAmount).toFixed(2)}
                                </div> : null}
                            </>
                        }/>
                        <RowTable title={'Receipt'} value={
                            <>
                                {data.receipt && data.receipt.path ? <>
                                    <Tooltip title={data.receipt.name}>
                                        <a className={'linkText btn_link'} target={'_blank'}
                                           href={data.receipt.path}>
                                            Download
                                        </a>{' '}
                                    </Tooltip>
                                </> : null}
                            </>
                        }/>
                        <RowTable title={'Note'} value={data.note}/>
                        <RowTable title={'Status'} value={
                            <div>
                                <label
                                    className={
                                        data.status == 'Cancel'
                                            ? 'label label-danger label-sm'
                                            : data.status == 'Pending'
                                                ? 'label label-warning label-sm'
                                                : data.status == 'Approved'
                                                    ? 'label label-success label-sm'
                                                    : 'label'
                                    }>
                                    {data.status}
                                </label>

                                {data.reason ? <div className={'mt10'}>
                                    Reason : {data.reason}
                                </div> : null}
                            </div>
                        }/>
                    </div>
                </Card>
            </Drawer>
        </>
    )
}
export default ExpenseInfo
