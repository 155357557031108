export const DurationTypes = [
  { name: 'Weekly', value: 'weekly' },
  { name: 'Monthly', value: 'monthly' },
  { name: 'Yearly', value: 'yearly' }
]

export const StatusTypes = [
  { name: 'Available', value: 'available' },
  { name: 'Not_Available', value: 'not_available' },
  { name: 'Sold Out', value: 'sold_out' }
]

export const AmenitiesType = [
  {
    name: 'Property Rules',
    type: 'property_rules',
    values: [
      { name: 'No Smoking', type: 'no smoking' },
      { name: 'Pets Not Allowed', type: 'pets not allowed' }
    ]
  },
  {
    name: 'Safety and Security',
    type: 'safety_security',
    values: [
      { name: '24/7 CCTV', type: 'Accommodation Features' },
      { name: 'Secure Entry', type: 'Locker & Safes' },
      { name: 'Reception', type: 'Reception' },
      {
        name: 'On Site Team',
        type: 'On Site Team'
      }
    ]
  },
  {
    name: 'Common Amenities',
    type: 'common_amenities',
    values: [
      { name: 'Free High-Speed Wi-Fi', type: 'Free High-Speed Wi-Fi' },
      { name: 'Roof Terrace', type: 'Accommodation Features' },
      { name: 'Study Spaces', type: 'Library & Study Area' },
      { name: 'Bike Storage', type: 'Parking' },
      { name: 'Events Calendar', type: 'Social Events' },
      { name: 'Free On-Site Gym for Residents', type: 'Gym & Fitness' },
      { name: 'On-Site Laundry Facilities', type: 'Laundry' },
      { name: 'Games and Social Areas', type: 'Games Area' },
      {
        name: 'Bedding Packs Available for Purchase',
        type: 'Bedding Packs Available for Purchase'
      },
      { name: 'Fully Equipped Kitchen', type: 'Kitchen with Appliance' },
      { name: 'On-site Gym', type: 'On-site Gym' },
      {
        name: 'Study Spaces',
        type: 'others'
      },
      {
        name: 'Tv Snug',
        type: 'Television'
      },
      {
        name: 'Social Spaces',
        type: 'Common Social Area'
      },
      {
        name: 'Games Area',
        type: 'Games Area'
      },
      {
        name: 'Courtyard',
        type: 'Courtyard'
      },
      {
        name: 'Onsite Laundry',
        type: 'Laundry Facility'
      },
      {
        name: 'Onsite Team',
        type: 'Reception & Staff'
      },
      {
        name: 'Terrace',
        type: 'Terrace'
      },
      {
        name: 'Wifi (Up to 200 MB dual band wifi)',
        type: 'Wifi (Up to 200 MB dual band wifi)'
      }
    ]
  },
  {
    name: 'Bills Included',
    type: 'bills_included',
    values: [
      { name: 'Water', type: 'Water' },
      { name: 'Wifi', type: 'Wifi' },
      { name: 'Electricity', type: 'Electricity' },
      { name: 'Heating', type: 'Air Conditioner & Heating' }
    ]
  }
]
