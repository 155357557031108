import React, { useEffect, useState } from 'react'
import '../style.css'
import { useDispatch } from 'react-redux'
import {
  singleEnquiryFxn
} from '../action'
import EnquiryInfoComponent from '../enquiry/enquiryInfoComponent'
import EnquiryNotesComponent from '../enquiry/enquiryNotesComponent'
import EnquiryDocumentComponent from '../enquiry/enquiryDocumentComponent'
import UpdatePropertyForm from './updatePropertyModal'
import { enquiryActivitiesFxn } from '../action'

const SingleEnquiryDetails = () => {
  const [updateProperty, setUpdateProperty] = useState({
    visible: false,
    enquiry: {}
  })
  const [enquiryData, setEnquiryData] = useState({})
  let [activities, setActivities] = useState([])
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(window.location.search)
  const enquiryId = searchParams.get('enquiryId')

  const events = {
    apiRequest: () => {
      return new Promise(async resolve => {
        let resp = await dispatch(
          singleEnquiryFxn({ enquiryId: enquiryId })
        )
        setEnquiryData(resp)
        resolve(resp)
      })
    },
    getActivity: async () => {
      let resp = await dispatch(enquiryActivitiesFxn({ enquiryId: enquiryId }))
      if (resp) {
        setActivities(resp)
      }
    }

  }

  useEffect(() => {
    document.body.className = 'applicationProfile appProfile2'
    events.apiRequest()
    events.getActivity()
  }, [])
  return (
    <>

      <div className={'unizhome-enquiry-container'}>

        <EnquiryInfoComponent enquiryData={enquiryData} enquiryEvents={events} enquiryId={enquiryId} />
        <EnquiryNotesComponent enquiryData={enquiryData} enquiryEvents={events} enquiryId={enquiryId}
                               dispatch={dispatch} activities={activities} />
        <EnquiryDocumentComponent enquiryData={enquiryData} enquiryEvents={events} enquiryId={enquiryId}
                                  dispatch={dispatch} />
      </div>
      {updateProperty.visible ?
        <UpdatePropertyForm
          {...updateProperty}
          onClose={() => events.hideUpdateModal()}
        /> : null
      }
    </>
  )
}

export default SingleEnquiryDetails
