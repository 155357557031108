import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { InputBox, longDisplayDate } from '../../../components/_utils/appUtils'
import { Button, Card, Col, Form, Icon, Input, Modal, notification, Row } from 'antd'
import {
  addEnquiryCommentsFxn,
  addEnquiryNoteFxn,
  enquiryActivitiesFxn,
  enquiryNotesFxn,
  getEnquiryDocsFxn
} from '../action'

const { TextArea } = Input

const EnquiryNotesComponent = (props) => {
  let { enquiryId, enquiryEvents, dispatch, activities } = props
  let [notes, setNotes] = useState([])
  let [note, setNote] = useState('')
  // let [activities, setActivities] = useState([])
  const [comment, setComment] = useState('')
  const [isCommentVisible, setIsCommentVisible] = useState({
    visible: false,
    noteId: ''
  })
  const [isNoteVisible, setIsNoteVisible] = useState({
    visible: false,
    enquiryId: ''
  })
  useEffect(() => {
    events.getEnquiryNotes()
    enquiryEvents.getActivity()
  }, [])

  let events = {
    showNoteDrawer: async () => {
      setIsNoteVisible({
        visible: true,
        enquiryId: enquiryId
      })
    },
    hideNoteDrawer: async () => {
      setIsNoteVisible({
        visible: false,
        enquiryId: enquiryId
      })
      events.getEnquiryNotes()
      enquiryEvents.getActivity()
    },
    showCommentDrawer: async (noteId) => {
      setIsCommentVisible({
        visible: true,
        noteId: noteId
      })
    },
    hideCommentDrawer: async () => {
      setIsCommentVisible({
        visible: false,
        noteId: ''
      })
      events.getEnquiryNotes()
      enquiryEvents.getActivity()
    },
    handleNoteSubmit: async () => {
      let resp = await dispatch(addEnquiryNoteFxn({ enquiryId, note }))
      if (resp.success) {
        setNote('')
        events.hideNoteDrawer()
      }
    },
    handleCommentSubmit: async () => {
      const { noteId } = isCommentVisible
      let resp = await dispatch(addEnquiryCommentsFxn({ noteId, comment }))
      if (resp) {
        notification.success({ message: resp.message })
        setComment('')
        events.hideCommentDrawer()
      }
    },
    getEnquiryNotes: async () => {
      let resp = await dispatch(enquiryNotesFxn({ enquiryId: enquiryId }))
      if (resp) {
        setNotes(resp)
      }
    },
    // getActivity: async () => {
    //   let resp = await dispatch(enquiryActivitiesFxn({ enquiryId: enquiryId }))
    //   if (resp) {
    //     setActivities(resp)
    //   }
    // }

  }

  return (
    <>
      <div className={'tab-box'}>
        <div className={'noteBlock'}>
          <div className='student-tab' style={{ border: 'none' }}>
            <ul className='nav nav-tabs card' id='myTab' role='tablist'>
              <li className='nav-item'>
                <a
                  className='nav-link active'
                  id='Notes-tab'
                  data-toggle='tab'
                  href='#notes'
                  role='tab'
                  aria-controls='Notes'
                  aria-selected='true'
                >
                  Notes
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  id='activity-tab'
                  data-toggle='tab'
                  href='#activity'
                  role='tab'
                  aria-controls='activity'
                  aria-selected='false'
                >
                  Activity
                </a>
              </li>
            </ul>
            <div className='tab-content' id='myTabContent'>

              <div
                className='tab-pane fade show active'
                id='notes'
                role='tabpanel'
                aria-labelledby='notes-tab'
              >
                <div className={'scrollView'}>
                  <div
                    className={'dateLabel'}
                    style={{ background: 'white', textAlign: 'right' }}
                  >

                  </div>


                  <div className='box'>
                    <div className='head d-flex align-items-center'>
                      <h5>{moment().format('MMMM')}, {moment().year()}</h5>
                      <button className='btn' type='button' onClick={events.showNoteDrawer}>
                        + Create Note
                      </button>
                    </div>
                    {notes.map(note => (

                      <div className='inner card' key={note._id}>
                        <img src='/dist/img/message.png' alt='' className='edit'

                        />
                        <div className={'noteTitle'}>
                          <h5>Note</h5>
                          <span className={'date'}>
                                            {longDisplayDate(note.createdAt)}
                                                </span>
                        </div>
                        <p>
                          {note.addedBy && note.addedBy.name ?
                            <strong>{note.addedBy.name} </strong>
                            : null}

                        </p>
                        <p>{note.note}</p>

                        <span><img src='../../btnImgs/user.png'
                                   alt='' /> {note.addedBy.name} left a note.</span>

                        <div>
                          {note.comments.length > 0 ? (
                            note.comments.map((comment, index) => (
                              <div className={'commentBox'} key={index}>
                                <Row>
                                  <Col span={2} className={'avatar'}>
                                    <Icon type={'user'}></Icon>
                                  </Col>
                                  <Col span={22}>
                                    <div className={'noteTitle'}>
                                      <div>{comment.addedBy.name}</div>
                                      <span className={'date'}>
                                        <Icon type='calendar' /> {moment(comment.date).format('LL')}
                                    </span>
                                    </div>
                                    <div className={'commentText'}>{comment.comment}</div>
                                  </Col>
                                </Row>
                              </div>
                            ))
                          ) : (
                            ''
                          )}

                        </div>

                        <div className={'mt-2'}>
                          <button
                            type='button'
                            className='btn mt-3'
                            onClick={() => events.showCommentDrawer(note._id)}
                          >
                            + Add Comment
                          </button>
                        </div>

                      </div>
                    ))}


                  </div>
                </div>
              </div>

              <div
                className='tab-pane fade'
                id='activity'
                role='tabpanel'
                aria-labelledby='activity-tab'
              >
                <div className='act-container p-0'>
                  <div className='act-card'>
                    <div className='act-card-body'>
                      <div className='scrollView' style={{ height: '62vh' }}>
                        <div className='application-activity profile-activity'>
                          <div className='card-body mt0'>
                            <ul className='activity-container'>

                              {activities.map(activity => (
                                <li key={activity._id}>
                                  <div className='date'>
                                    {moment(activity.date).format('DD-MM-YYYY')}
                                    <br />
                                    {moment(activity.date).format('hh:mm A')}
                                  </div>
                                  <div className='activity_timeline'></div>
                                  <div className=''>
                                    <div className='title'><b>
                                      {activity.activityType}
                                    </b></div>
                                    <div className='information'>
                                      <div>
                                        by <i>{activity.user.name || 'Unknown'}</i>
                                      </div>
                                      <div>{activity.message}</div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <Modal
            width={'38%'}
            footer={null}
            title={'Add Note'}
            visible={isNoteVisible.visible}
            onCancel={events.hideNoteDrawer}
          >
            <Card bordered={false}>
              <Form>
                <InputBox>
                  <TextArea style={{ 'minHeight': 100 }} onChange={(e) => setNote(e.target.value)} name={'note'}
                            className={'form-control'}
                            placeholder={'Write your note here...'} value={note} />
                </InputBox>
                <Form.Item className={'mt20 alignRight'}>
                  <Button type='primary' onClick={events.handleNoteSubmit}>
                    SUBMIT
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Modal>
          <Modal
            width={'38%'}
            footer={null}
            title={'Add Comment'}

            visible={isCommentVisible.visible}
            onCancel={events.hideCommentDrawer}
          >
            <Card bordered={false}>
              <Form>
                <InputBox title={'Add Comment'}>
                  <TextArea style={{ 'minHeight': 100 }} onChange={(e) => setComment(e.target.value)}
                            name={'comment'}
                            className={'form-control'}
                            placeholder={'Write your comment here...'} value={comment} />
                </InputBox>
                <Form.Item className={'mt20 alignRight'}>
                  <Button type='primary' onClick={events.handleCommentSubmit}>
                    SUBMIT
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Modal>


        </div>
      </div>
    </>
  )
}
export default EnquiryNotesComponent
