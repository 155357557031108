import React, { useState, useEffect, useRef } from 'react'
import { Form, Button, Row, Col, notification, Icon, Input, Select, Tooltip, Popconfirm, Drawer } from 'antd'
import { useDispatch } from 'react-redux'
import { addFeedback, closeFeedbackFxn, listFeedback, singleFeedbackFxn } from '../actions/feedbackActions'
import {
  ConditionOptions, ConditionOptionsForRaiseAComplaint,
  DefaultTablePagination,
  displayDate,
  InputBox,
  longDisplayDate
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { searchedStudent } from '../../student/actions/student'
import { TableComp } from 'sz-react-utils-lite'
import AddCommentDrawer from './addCommentDrawer'
import { complaintDepartmentListFxn } from '../../ComplaintDepartement/actions'
import _ from 'lodash'

const { Option } = Select

const initialState = {
  studentList: [],
  studentId: '',
  dateOfBirth: '',
  addStuDrawer: false,
  details: ''
}
const RenderComplaint = props => {
  let { item } = props
  let { resolveBy } = item
  let [hideV, setHideV] = useState(true)
  return (
    item && item.feedback ?
      <div>
        <div className={hideV ? 'appCommentDotsReplace' : ''}>
          {item && item.feedback ? item.feedback : ''}
        </div>
        {item && item.feedback.length && item.feedback.length > 70 ? (
          <div className={'alignRight'}>
            {hideV ? (
              <a className={'btn-link'}
                 onClick={() => {
                   setHideV(false)
                 }}>
                Show More
              </a>
            ) : (
              <a className={'btn-link'}
                 onClick={() => {
                   setHideV(true)
                 }}>
                Show Less
              </a>
            )}
          </div>
        ) : null}
      </div> : null
  )
}

const RenderComment = props => {
  let { item } = props
  let { resolveBy } = item
  let [hideV, setHideV] = useState(true)
  return (
    item && item.details ?
      <div>
        {resolveBy && resolveBy.name ? (
          <div className={'alignRight colorPrimary font12'}>
            (Resolve By : {resolveBy.name} )
          </div>
        ) : null}

        <div className={'mb10'}>{longDisplayDate(resolveBy.time)}</div>

        <div className={hideV ? 'appCommentDotsReplace' : ''}>
          {item && item.details ? item.details : ''}
        </div>
        {item && item.details.length && item.details.length > 50 ? (
          <div className={'alignRight'}>
            {hideV ? (
              <a
                onClick={() => {
                  setHideV(false)
                }}>
                Show More
              </a>
            ) : (
              <a
                onClick={() => {
                  setHideV(true)
                }}>
                Show Less
              </a>
            )}
          </div>
        ) : null}
      </div> : null
  )
}
const AddFeedback = (props) => {
  const tableRef = useRef()
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  const { form: { getFieldDecorator, getFieldValue, setFieldsValue } } = props
  const dispatch = useDispatch()
  let [allCountry, setAllCountry] = useState([])
  let [allComplaintDepartment, setAllComplaintDepartment] = useState([])
  const [state, setState] = useState(initialState)
  const [studentData, setStudentData] = useState(initialState)
  let [feedbackData, setFeedbackData] = useState([])
  let [singleFeedback, setSingleFeedback] = useState({})
  let [compliantId, setComplaintId] = useState('')


  const [updateEventSlot, setUpdateEventSlot] = useState({
    visible: false,
    feedbackId: ''
  })
  let [drawerOpen, setDrawerOpen] = useState(false)
  let [resolveDrawerState, setResolveDrawerState] = useState({
    visible: false,
    feedbackId: ''
  })

  useEffect(() => {
    if (!drawerOpen) {
      tableRef.current.reload()
    }
  }, [drawerOpen])


  const getSingleComplaint = async () => {
    let resp = await dispatch(singleFeedbackFxn({ feedbackId: compliantId }))
    setSingleFeedback(resp.data)
  }

  useEffect(() => {
    if (compliantId != null) {
      getSingleComplaint()
    }
  }, [compliantId])


  const loadComplaintDepartment = async () => {
    let { data } = await dispatch(complaintDepartmentListFxn())
    setAllComplaintDepartment(data)
  }

  useEffect(() => {
    // loadCountry()
    loadComplaintDepartment()
  }, [])

  useEffect(() => {
    if (state.studentId) {
      const filteredStudent = state.studentList.find(student => student._id === state.studentId)
      setStudentData(filteredStudent)
    }
  }, [state.studentId])

  const handleSubmit = (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        valData.studentId = state.studentId
        if (valData.relatedTo == 'student' && !valData.studentId) {
          notification.warning({ message: 'Please Choose Student!' })
          return { checkError: true }
        }
        let resp = await dispatch(addFeedback(valData))
        if (resp && resp.success) {
          form.resetFields()
          setState([])
          tableRef.current.reload()

        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  const fetchStudent = async (value) => {
    let studentList = await searchedStudent({ name: value })
    setState({
      ...state,
      studentList
    })
  }

  const handleDrawerSubmit = async () => {
    if (!state.details) {
      notification.warning({ message: 'Enter details' })
      return
    }
    await dispatch(closeFeedbackFxn({ feedbackId: resolveDrawerState.feedbackId, details: state.details }))
    tableRef.current.reload()
    setDrawerOpen(false)
    setResolveDrawerState({
      visible: false,
      feedbackId: ''
    })
  }

  const chooseStudentFxn = (value) => {
    setState({
      ...state,
      studentId: value
    })
  }
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.sortField = '_id'
      params.sortOrder = 'descend'
      let resp = await dispatch(listFeedback({ ...params, regExFilters: ['feedback'] }))
      setFeedbackData(resp.data)
      resolve(resp)
    })
  }

  const events = {
    showUpdateState: (feedbackId) => {
      setUpdateEventSlot({
        visible: true,
        feedbackId: feedbackId
      })
    },
    hideUpdateState: async () => {
      tableRef.current.reload()
      setUpdateEventSlot({
        visible: false,
        feedbackId: ''
      })

    },
    submitUpdateState: () => {
      tableRef.current.reload()
      setUpdateEventSlot({
        visible: false,
        feedbackId: ''
      })
    },
    chooseDepartment: (department) => {
      let findD = _.find(allComplaintDepartment, (item) => {
        return item._id == department
      })
      if (findD && findD.countries && findD.countries.length) {
        setAllCountry(findD.countries)
      } else {
        setAllCountry([])
      }
    }
  }


  const formFields = [
    {
      key: 'department',
      label: 'Department',
      required: true,
      type: 'select',
      showSearch: true,
      options: allComplaintDepartment,
      keyAccessor: x => x._id,
      valueAccessor: x => `${x.department}`,
      onChange: x => {
        events.chooseDepartment(x)
        setFieldsValue({
          department: x
        })
      }
    },
    {
      key: 'countryId',
      label: 'Country',
      required: true,
      type: 'select',
      options: allCountry,
      hidden: allCountry.length == 0,
      keyAccessor: x => x._id,
      valueAccessor: x => `${x.countryName}`,
      onChange: x => {
        setFieldsValue({
          countryId: x
        })
      }
    },
    // {
    //   key: 'relatedStudent',
    //   label: 'Is it related to a student?',
    //   required: true,
    //   type: 'select',
    //   showSearch: true,
    //   options: ConditionOptionsForRaiseAComplaint,
    //   keyAccessor: x => x.value,
    //   valueAccessor: x => `${x.name}`,
    //   onChange: x => {
    //     setState(initialState)
    //     setFieldsValue({
    //       relatedStudent: x
    //     })
    //   }
    // },
    {
      key: 'relatedTo',
      label: 'Is it related to?',
      required: true,
      type: 'select',
      showSearch: true,
      options: ConditionOptionsForRaiseAComplaint,
      keyAccessor: x => x.value,
      valueAccessor: x => `${x.name}`,
      onChange: x => {
        setState(initialState)
        setFieldsValue({
          relatedTo: x
        })
      }
    },
    {
      key: 'studentId',
      span: 8,
      hidden: getFieldValue('relatedTo') != 'student',
      customField: (
        <InputBox title={'* Select Student'}>
          <Select
            showSearch={true}
            onSearch={debounce(fetchStudent, 500)}
            filterOption={false}
            autoClearSearchValue={true}
            allowClear={true}
            showArrow={true}
            required={getFieldValue('addSpecificPerson')}
            onChange={chooseStudentFxn}
            optionLabelProp='label'
            placeholder='Choose Student'>
            {state.studentList && state.studentList.length && state.studentList.map((item, index) => {
              return (
                <Option key={item._id} value={item._id} label={item.name}>
                  <div>
                    <div>
                      {item.studentId} : {item.name}
                    </div>
                    <div>
                      {item.email}
                    </div>
                  </div>
                </Option>
              )
            })}
          </Select>
        </InputBox>
      )
    },
    {
      key: 'addSpecificPerson',
      hidden: getFieldValue('relatedTo') == 'student' || getFieldValue('relatedTo') == undefined,
      required: getFieldValue('relatedTo') == 'complain_a_misbehaviour' || getFieldValue('relatedTo') == 'others',
      label: 'Do you want to add a specific person\'s name?',
      type: 'select',
      showSearch: true,
      options: ConditionOptions,
      keyAccessor: x => x.value,
      valueAccessor: x => `${x.name}`,
      onChange: x => {
        setFieldsValue({
          addSpecificPerson: x
        })
      }
    },
    {
      key: 'personName',
      label: 'Person Name',
      hidden: !getFieldValue('addSpecificPerson'),
      required: getFieldValue('addSpecificPerson')
    },
    {
      key: 'name',
      label: 'Your Name',
      required: true
      // span: 12
    },
    {
      key: 'designation',
      label: 'Your Designation',
      required: true
      // span: 12
    },
    {
      key: 'contactNo',
      label: 'Your Contact',
      required: true
      // span: 12
    },
    {
      key: 'feedback',
      label: 'Complaint',
      type: 'textarea',
      required: true,
      span: 24
    }
  ]
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  let columns = [
    {
      title: '#',
      key: '_id',
      dataIndex: '_id',
      width: 50,
      render: (ite, record, index) => {
        return (
          <React.Fragment>
            {index + 1}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Feedback & Complaints',
      key: 'feedback',
      dataIndex: 'feedback',
      width: '48%',
      searchTextName: 'feedback',
      render: (item, record) => {
        return (
          <RenderComplaint item={record} />
        )
      }
    },

    {
      title: 'Department',
      key: 'department',
      dataIndex: 'department',
      width: 200,
      render: (item, record) => {
        return (
          <>
            {item && item.department ?
              <div>
                {item.department}
              </div> : null}

            {record && record.countryId && record.countryId.countryName ?
              <label className={'label label-warning xs1'}>
                {record.countryId.countryName}
              </label> : null}


          </>
        )
      }
    },
    {
      title: 'Person Name',
      key: 'personName',
      dataIndex: 'personName',
      searchTextName: 'personName',
      width: 120
    },

    {
      title: 'Student',
      key: 'studentId',
      dataIndex: 'studentId',
      width: 120,
      render: (item) => {
        return item && item.name ? <>
          {item.name} (ID:{item.studentId})
        </> : ''
      }
    },

    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item ? displayDate(item) : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Added By',
      dataIndex: 'userId.name',
      key: 'userId.name',
      width: 150
    },


    {
      title: 'Detail',
      dataIndex: 'details',
      key: 'details',
      width: 250,
      render: (item, record) => {
        return (
          <>
            <RenderComment item={record} />
          </>
        )
      }
    },
    {
      title: 'Complaint By',
      dataIndex: 'complaintBy',
      key: 'complaintBy',
      width: 250,
      render: (item, record) => {
        let { name, designation, contactNo } = record
        return (
          name ? <>
            Name : {name}<br />
            Designation : {designation}<br />
            Contact No : {contactNo}
          </> : null
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (item) => {
        return (
          <>
            {item == 'Resolve' ?
              <label className={'label label-success label-sm'}>Resolved</label> :
              <label className={'label label-warning label-sm'}>Pending</label>}
          </>
        )
      }
    },
    {
      title: 'Action',
      key: '_id',
      dataIndex: '_id',
      width: 160,
      render: (val, record) => {
        return (
          <React.Fragment>
            <Tooltip title={'View Comment'}>
              <a className={'btn xs roundNew btn-default'} onClick={() => {
                events.showUpdateState(record._id)
                setComplaintId(record._id)
              }}>
                <img src='/dist/img/view.svg' alt='' /> View
              </a>
            </Tooltip>

            {record.status !== 'Resolve' && user.userType == 'agent' ?
              <a
                className={'btn xs btn-default roundNew ml5'}
                onClick={() => {
                  setResolveDrawerState({
                    visible: true,
                    feedbackId: record._id
                  })
                }}>
                Resolve
              </a> : null}

          </React.Fragment>
        )
      }
    }
  ]

  return (
    <div className={'mt-4'}>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='form-box'>
            <div className='d-flex align-items-center mb-3 heading-form'>
              <h5>Raise A Complaint</h5>
            </div>
            <div className='card unizportal'>
              <Form onSubmit={handleSubmit} className='vertical-form'>
                <Row gutter={24}>
                  {formFields.map((item, key) => {
                    return (
                      <React.Fragment>
                        {!item.hidden && item.customField ? <Col span={item.span ? item.span : 8} key={key}>
                            {item.customField}
                          </Col> :
                          !item.hidden && <Col span={item.span ? item.span : 8} key={key}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />
                          </Col>}
                      </React.Fragment>
                    )
                  })}
                </Row>
                {
                  state.studentId ?
                    <Row gutter={24}>
                      <Col span={18}>
                        <Form.Item>
                          <div className={'student-details'} style={{ lineHeight: '1' }}>
                            <h4>Student Data</h4>
                            <p className={'student-info'}><b>Name :</b> {studentData.name}</p>
                            <p className={'student-info'}><b>Student Id :</b> {studentData.studentId}</p>
                            <p className={'student-info'}><b>Date Of Birth
                              :</b> {moment(studentData.dateOfBirth).format('DD/MM/YYYY')}</p>
                            <p className={'student-info'}><b>Number :</b> {studentData.mobile}</p>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row> : null
                }
                <Row gutter={24}>
                  <Col span={18}>
                    <Form.Item>
                      <Button type='primary' htmlType='submit' className='btn'>
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className='card'>
              <div className='table-head d-flex align-items-center'>
                <h5>All Feedback & Complaints</h5>
                <div className='search-box-table'>

                </div>

                <div className='sort-box-table mark-btn'>

                </div>
              </div>
              <div className='card-body table-responsive'>
                <TableComp columns={columns}
                           pagination={DefaultTablePagination()}
                           apiRequest={apiRequest}
                           ref={tableRef}
                           extraProps={{ scroll: { x: 1800 } }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {updateEventSlot.visible ? <AddCommentDrawer
        {...updateEventSlot}
        dispatch={dispatch}
        onSubmit={events.submitUpdateState}
        onClose={() => {
          events.hideUpdateState()
        }}
        currentUser={user}
        singleFeedback={singleFeedback}
        feedbackId={compliantId}
        setComplaintId={setComplaintId}
        setState={setState}
        setDrawerOpen={setDrawerOpen}
        setUpdateEventSlot={setUpdateEventSlot}
      /> : null}


      {resolveDrawerState.visible ?
        <Drawer
          title={'Resolve Complaint'}
          visible={resolveDrawerState.visible}
          placement='right'
          closable={true}
          onClose={() => {
            setResolveDrawerState({
              visible: false,
              feedbackId: ''
            })
          }}
          width={'40%'}>
          <div className='form-box'>
            <div className='card unizportal'>
              <Form className={'vertical-form'}>
                <InputBox title={'Details'}>
                  <Form.Item name='details'>
                    <Input.TextArea
                      rows={4}
                      placeholder='Enter details'
                      value={state.details}
                      onChange={(e) => setState({ ...state, details: e.target.value })}
                    />
                  </Form.Item>
                </InputBox>
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className={'btn'}
                    onClick={handleDrawerSubmit}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Drawer> : null
      }


    </div>
  )
}

export default Form.create()(AddFeedback)
