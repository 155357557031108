import React, { useEffect, useRef, useState } from 'react'
import { Avatar, Button, Card, Col, Drawer, Icon, Input, Row, Typography } from 'antd'
import { DefaultTablePagination, displayDate, nameAvatar, taskUpdateRights } from '../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { displayTime } from '../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'
import { addCommentFxn, commentListFxn } from './action'

let { TextArea } = Input

const SingleComment = (props) => {
  let { comment } = props
  return (
    <>
      <div className='comment' key={comment._id}>
        <Avatar className={'textUpp avatar_text'} size='large'>
          {nameAvatar(comment.userId.name)}
        </Avatar>

        <div className='comment-text'>
          <p className='comment-author'>
            <span className={'name'}>{comment.userId.name}</span>
            <span className={'date'}>{displayDate(comment.time)}</span>
          </p>
          <div className={'mb5'}>
            {comment.comment}
          </div>
          <div className={'time-span'}>
            {displayTime(comment.time)}
          </div>
        </div>

      </div>
    </>
  )
}

const ComponentListDrawer = (comments) => {
  console.log(comments, 'comments')
  return (
    <>
      <div id={'dd'}>
        {comments && comments.data.length ? comments.data.map((comment, index) => {
          return (
            <SingleComment comment={comment} />
          )
        }) : null}
      </div>
    </>
  )
}


const CommentDrawerToDoList = (props) => {
  let dispatch = useDispatch()
  let { onClose, visible, taskId, task, onSubmit } = props
  let [commentList, setCommentList] = useState([])
  let [comment, setComment] = useState('')

  useEffect(() => {
    events.loadComments()
  }, [taskId])
  let events = {
    handleCommentSave: async () => {
      if (taskId) {
        let resp = await dispatch(addCommentFxn({ comment: comment, taskId: taskId }))
        if (resp && resp.success) {
          setComment('')
        }
        onSubmit()
        events.loadComments()
      }
    },
    loadComments: async () => {
      let { data } = await dispatch(commentListFxn({ taskId }))
      if (data && data.comments && data.comments.length) {
        setCommentList(data.comments)
      }
    }
  }


  return (
    <Drawer
      title={`TO DO Details - ${task && task}`}
      placement='right'
      closable={true}
      onClose={onClose}
      visible={visible}
      width='50%'
      style={{ transition: 'all 0.3s' }}
    >
      <div className={'card unizportal'} style={{ padding: '10px 20px' }}>
        {/*<Card className={'mt10'}>
          <div className={'textCap todoText'}>
            {task}
          </div>
        </Card>*/}
        <Card title={'Comments'} className={'mt10'}>
          <div className={'todo-comments-box'}>
            {commentList && commentList.length ? <ComponentListDrawer data={commentList} /> : null}
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              placeholder="Add Comment"
              className="todo-input"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              style={{ borderColor: 'blue' }}
            />

            <Button
              className={'btn btn-primary roundNew lg'}
              onClick={() => {
                events.handleCommentSave()
              }}>Send</Button>
          </div>
        </Card>

      </div>
    </Drawer>
  )
}
export default CommentDrawerToDoList
