import {
  Icon,
  Tooltip
} from 'antd'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  DefaultTablePagination
} from '../../../components/_utils/appUtils'
import { getPropertyListFxn } from '../action'
import { useHistory } from 'react-router-dom'
import { getPushPathWrapper } from '../../../routes'

const UnizhomePropertyList = props => {
  const [total, setTotal] = useState(0)
  const [editPropertyModal, setEditPropertyModal] = useState({
    visible: false,
    propertyId: ''
  })
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  const tableRef = useRef()
  const dispatch = useDispatch()
  const history = useHistory()

  const apiRequest = params => {
    return new Promise(async resolve => {
      let resp = await dispatch(
        getPropertyListFxn({
          ...params,
          select: ['inventory_featured_image_path', 'id', 'children_count', 'weekly_price', 'source', 'name']
        })
      )
      setTotal(resp.total)
      resolve(resp)
    })
  }
  const handleEdit = (propertyId) => {
    history.push(`/editUnizhomeProperty?propertyId=${propertyId}`)
  }


  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (val, record, index) => {
        return index + 1
      }
    },
    {
      title: 'Property',
      dataIndex: 'inventory_featured_image_path',
      key: 'inventory_featured_image_path',
      searchTextName: 'propertyName',
      render: (val, record) => {
        return (
          <p>
            <img src={record.inventory_featured_image_path} height='90px' width='100px' />
          </p>
        )
      }
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      searchTextName: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      searchTextName: 'name'
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      searchTextName: 'source'
    },
    {
      title: 'Price',
      dataIndex: 'weekly_price',
      key: 'weekly_price',
      searchTextName: 'weekly_price'
    },
    {
      title: 'Rooms',
      dataIndex: 'children_count',
      key: 'children_count',
      searchTextName: 'children_count'
    },
    {
      title: 'Action',
      key: '_id',
      dataIndex: '_id',
      width: 150,
      render: (val, record) => {
        return (
          <React.Fragment>
            <Tooltip title='Edit Property'>
              <a
                className={'linkAA'}
                href={`/editUnizhomeProperty?propertyId=${record._id}`}
              >
                <Icon type={'edit'} />
              </a>
            </Tooltip>
          </React.Fragment>
        )
      }
    }

  ]

  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head  align-items-center all-student-search'>
            <h6>Property List: {total || 0}</h6>
          </div>
          <div className='card-body table-responsive'>
            <TableComp
              columns={columns}
              apiRequest={apiRequest}
              ref={tableRef}
              pagination={{
                position: 'top',
                pageSizeOptions: ['20', '50', '75', '100'],
                defaultPageSize: 20
              }}
            />
          </div>
        </div>
      </div>

    </div>
  )
}

export default UnizhomePropertyList
