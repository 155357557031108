import React, { useState, useEffect } from 'react'
import { Form, Button, notification, Row, Col, Input, Icon, Card, Drawer, Checkbox, Select } from 'antd'
import { useDispatch, useSelector, connect } from 'react-redux'
import { getSingleUniversityFxn, getUniversity, updateUniversity, updateUniversityFxn } from '../actions/university'
import moment from 'moment'
import _ from 'lodash'
import { listAllCountries } from '../../countries/actions/countries'
import { listAllStates } from '../../state/actions/state'
import { listAllCities } from '../../city/actions/city'
import {
  countryIds, EligibilityList,
  Intakes, testsForUk, InputBox, indianStates, admissionFeesArray, timeDurationArray
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import AddCommission from '../drawers/addCommission'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'

const { Option } = Select


const EditUniversityRequirement = (props) => {
  const dispatch = useDispatch()
  let { TextArea } = Input
  let { universityId, visible, onClose } = props
  let [universityData, setUniversityData] = useState({})
  let [countryId, setCountryId] = useState('')
  const [university, setUniversity] = useState({})
  const [showEligibilty, setShowEligibilty] = useState(false)
  const [showCanadaEligibility, setShowCanadaEligibility] = useState(false)
  const [notes, setNotes] = useState('')
  const [notesArr, setNotesArr] = useState([])
  const [state, setState] = useState({
    mediaFiles: [],
    logo: {},
    smallLogo: {},
    oldSmallLogo: {},
    oldLogo: {},
    oldMediaFiles: [],
    oldCampusImages: [],
    oldBanner: {},
    oldUniversityContract: {}
  })
  const [commissions, setCommissions] = useState([])
  const [visibleCommissionDrawer, setVisibleCommissionDrawer] = useState(false)
  const [currencyArr, setCurrencyArr] = useState([])
  const { allCountries } = useSelector(state => ({
    loader: state.universityReducers.loader,
    allCountries: state.countryReducers.allCountries,
    allStates: state.stateReducers.allStates,
    allCities: state.cityReducers.allCities
  }))
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue } } = props

  const [englishLanguage, setEnglishLanguage] = useState(false)
  const [englishLanguageTests, setEnglishLanguageTests] = useState([])
  const [ieltsUg, setIeltsUg] = useState('')
  const [ieltsPg, setIeltsPg] = useState('')
  const [pteUg, setPteUg] = useState('')
  const [ptePg, setPtePg] = useState('')
  const [duolingoUg, setDuolingoUg] = useState('')
  const [duolingoPg, setDuolingoPg] = useState('')
  const [toeflUg, setToeflUg] = useState('')
  const [toeflPg, setToeflPg] = useState('')
  const [othersUg, setOthersUg] = useState('')
  const [othersPg, setOthersPg] = useState('')

  const [moiAcceptable, setMoiAcceptable] = useState(false)
  const [moiAcceptableState, setMoiAcceptableUniversity] = useState([])

  const [stateBoardWaiver, setStateBoardWaiver] = useState(false)
  const [stateBoardWaiverState, setStateBoardWaiverState] = useState(['Haryana', 'Punjab'])
  const [stateBoardWaiverMarks, setStateBoardWaiverMarks] = useState('')
  const [stateBoardWaiverYearUg, setStateBoardWaiverYearUg] = useState('')
  const [stateBoardWaiverYearPg, setStateBoardWaiverYearPg] = useState('')

  const [initialBeforeInterview, setInitialBeforeInterview] = useState(false)
  const [initialBeforeInterviewMinDeposit, setInitialBeforeInterviewMinDeposit] = useState('')

  const [noInitialBeforeInterview, setNoInitialBeforeInterview] = useState(false)
  const [ugOpenBoardAcceptable, setUgOpenBoardAcceptable] = useState(false)
  const [distanceLearning, setDistanceLearning] = useState(false)

  const setCurrency = (countryId) => {
    let currencyArr = []
    let findCountry = _.find(allCountries, (item) => {
      return item._id == countryId
    })
    if (findCountry && findCountry.currencyName) {
      currencyArr.push(findCountry.currencyName)
    }
    setCurrencyArr(currencyArr)
  }

  let emptyDiv = (
    <div style={{ height: 20 }}></div>
  )

  let formFields = {
    eligibility: [
      {
        key: 'minimumCasDeposit',
        type: 'Number',
        label: 'Min',
        prefixCompNew: `Minimum ${getFieldValue('universityCountry') == countryIds.uk ? 'CAS' : ''} Deposit`,
        required: showEligibilty,
        hidden: false
      },
      {
        key: 'maximumCasDeposit',
        label: 'Max',
        type: 'number',
        prefixCompNew: emptyDiv,
        hidden: false
      },
      {
        key: 'minimumTuitionFees',
        label: 'Min',
        type: 'number',
        prefixCompNew: 'Min/Max Tuition Fess',
        hidden: false,
        span: 4
      },
      {
        key: 'maximumTuitionFees',
        label: 'Max',
        type: 'number',
        hidden: false,
        prefixCompNew: emptyDiv,
        span: 4
      },
      {
        key: 'ugMinimumRequired',
        label: 'Min',
        type: 'number',
        required: showEligibilty,
        prefixCompNew: 'UG Score Requirement',
        hidden: false,
        span: 4
      },
      {
        key: 'pgMinimumRequired',
        label: 'Min',
        type: 'number',
        prefixCompNew: 'PG Score Requirement',
        required: showEligibilty,
        hidden: false,
        span: 4
      },

      /* {
           key: 'minWaiverForCbsc',
           label: 'Min',
           type: 'number',
           prefixCompNew: 'Eng Waiver for CBSE/ICSE',
           hidden: false,
           span: 8

       },*/
      {
        key: 'minWaiverForCbscUg',
        label: 'Ug',
        type: 'number',
        prefixCompNew: 'Eng Waiver for CBSE/ICSE',
        hidden: false,
        span: 4

      },
      {
        key: 'minWaiverForCbscPg',
        label: 'Pg',
        type: 'number',
        prefixCompNew: 'Eng Waiver for CBSE/ICSE',
        hidden: false,
        span: 4

      },

      {
        prefixCompNew: 'Offer Letter',
        key: 'timeMinimumDuration',
        label: 'Min',
        type: 'number',
        hidden: false,
        span: 2
      },
      {
        key: 'timeMaximumDuration',
        label: 'Max',
        prefixCompNew: 'Time Duration',
        type: 'number',
        hidden: false,
        span: 2
      },
      {
        prefixCompNew: emptyDiv,
        key: 'timeDuration',
        label: 'Period',
        type: 'select',
        hidden: false,
        options: timeDurationArray,
        span: 4,
        allowClear: true,
        onChange: (e) => {
          setFieldsValue({ timeDuration: e })
        }
      },
      {
        key: 'casTimeMinimum',
        label: 'Min',
        prefixCompNew: `${getFieldValue('universityCountry') == countryIds.uk ? 'CAS' : ''} Time (In weeks)`,
        type: 'number',
        hidden: false
      },
      {
        key: 'casTimeMaximum',
        label: 'Max',
        prefixCompNew: emptyDiv,
        type: 'number',
        hidden: false
      },
      {
        key: 'interviewType',
        type: 'select',
        options: ['Audio', 'Video', 'Walking'],
        label: 'Interview Type',
        span: 8,
        hidden: false,
        prefixCompNew: emptyDiv,
        onChange: (e) => {
          setFieldsValue({ interviewType: e })
        }
      },
      {
        key: 'admissionFee',
        type: 'select',
        options: admissionFeesArray,
        label: 'Admission Fee',
        span: 8,
        prefixCompNew: emptyDiv,
        hidden: false,
        onChange: (e) => {
          setFieldsValue({ admissionFee: e })
        }
      },
      {
        key: 'intakes',
        options: Intakes,
        type: 'select',
        label: 'Intake Available',
        mode: 'multiple',
        required: showEligibilty,
        span: 8,
        prefixCompNew: emptyDiv,
        hidden: false,
        onChange: (e) => {
          setFieldsValue({ intakes: e })
        }
      },
      {
        key: 'englishLanguage',
        label: 'English Language',
        hidden: false,
        span: 12,
        customField: (
          <div className={'checkBoxUR'}>
            <Checkbox
              checked={englishLanguage}
              onChange={(e) => {
                setEnglishLanguage(e.target.checked)
              }}
            >
              English Language
            </Checkbox>
            <br />
            {
              englishLanguage == true &&
              <>
                <InputBox title={'Select Test'}>
                  <Select
                    className={'widthLabel'}
                    style={{ width: '200px' }}
                    value={englishLanguageTests}
                    onChange={(value) => {
                      setEnglishLanguageTests(value)
                    }}
                    mode={'multiple'}
                    placeholder={'Select Test'}
                  >
                    {testsForUk.map((option, index) => (
                      <Option key={index} value={option}>{option}</Option>
                    ))}
                  </Select>
                </InputBox>
                <div key={getFieldValue('englishLanguageTests')}>
                  {
                    englishLanguageTests.includes('IELTS') &&
                    <div>
                      <h4>IELTS</h4>
                      <div className={'d-flex'}>
                        <InputBox title={'UG'}>
                          <input
                            type='text'
                            className={'form-control'}
                            style={{ width: '200px' }}
                            placeholder={'Min/Max'}
                            value={ieltsUg}
                            onChange={(e) => {
                              setIeltsUg(e.target.value)
                            }}
                            required={!ieltsPg}
                          />
                        </InputBox>
                        <InputBox title={'PG'}>
                          <input
                            type='text'
                            className={'form-control'}
                            style={{ width: '200px' }}
                            placeholder={'Min/Max'}
                            value={ieltsPg}
                            onChange={(e) => {
                              setIeltsPg(e.target.value)
                            }}
                            required={!ieltsUg}
                          />
                        </InputBox>
                      </div>
                    </div>

                  }
                  {
                    englishLanguageTests.includes('PTE') &&
                    <div>
                      <h4>PTE</h4>
                      <div className={'d-flex'}>
                        <InputBox title={'UG'}>
                          <input
                            type='text'
                            className={'form-control'}
                            style={{ width: '200px' }}
                            placeholder={'Min/Max'}
                            value={pteUg}
                            onChange={(e) => {
                              setPteUg(e.target.value)
                            }}
                            required={!ptePg}
                          />
                        </InputBox>
                        <InputBox title={'PG'}>
                          <input
                            type='text'
                            className={'form-control'}
                            style={{ width: '200px' }}
                            placeholder={'Min/Max'}
                            value={ptePg}
                            onChange={(e) => {
                              setPtePg(e.target.value)
                            }}
                            required={!pteUg}
                          />
                        </InputBox>
                      </div>
                    </div>
                  }
                  {
                    englishLanguageTests.includes('DUOLINGO') &&
                    <div>
                      <h4>DUOLINGO</h4>
                      <div className={'d-flex'}>
                        <InputBox title={'UG'}>
                          <input
                            type='text'
                            className={'form-control'}
                            style={{ width: '200px' }}
                            placeholder={'Min/Max'}
                            value={duolingoUg}
                            onChange={(e) => {
                              setDuolingoUg(e.target.value)
                            }}
                            required={!duolingoPg}
                          />
                        </InputBox>
                        <InputBox title={'PG'}>
                          <input
                            type='text'
                            className={'form-control'}
                            style={{ width: '200px' }}
                            placeholder={'Min/Max'}
                            value={duolingoPg}
                            onChange={(e) => {
                              setDuolingoPg(e.target.value)
                            }}
                            required={!duolingoUg}
                          />
                        </InputBox>
                      </div>
                    </div>
                  }
                  {
                    englishLanguageTests.includes('TOEFL') &&
                    <div>
                      <h4>TOEFL</h4>
                      <div className={'d-flex'}>
                        <InputBox title={'UG'}>
                          <input
                            type='text'
                            className={'form-control'}
                            style={{ width: '200px' }}
                            placeholder={'Min/Max'}
                            value={toeflUg}
                            onChange={(e) => {
                              setToeflUg(e.target.value)
                            }}
                            required={!toeflPg}
                          />
                        </InputBox>
                        <InputBox title={'PG'}>
                          <input
                            type='text'
                            className={'form-control'}
                            style={{ width: '200px' }}
                            placeholder={'Min/Max'}
                            value={toeflPg}
                            onChange={(e) => {
                              setToeflPg(e.target.value)
                            }}
                            required={!toeflUg}
                          />
                        </InputBox>
                      </div>
                    </div>
                  }
                  {
                    englishLanguageTests.includes('OTHERS') &&
                    <div>
                      <h4>OTHERS</h4>
                      <div className={'d-flex'}>
                        <InputBox title={'UG'}>
                          <input
                            type='text'
                            className={'form-control'}
                            style={{ width: '200px' }}
                            placeholder={'Min/Max'}
                            value={othersUg}
                            onChange={(e) => {
                              setOthersUg(e.target.value)
                            }}
                            required={!othersPg}
                          />
                        </InputBox>
                        <InputBox title={'PG'}>
                          <input
                            type='text'
                            className={'form-control'}
                            style={{ width: '200px' }}
                            placeholder={'Min/Max'}
                            value={othersPg}
                            onChange={(e) => {
                              setOthersPg(e.target.value)
                            }}
                            required={!othersUg}
                          />
                        </InputBox>
                      </div>
                    </div>
                  }
                </div>
              </>
            }
          </div>
        )
      },
      {
        key: 'stateBoardWaiver',
        label: 'State Board Waiver',
        hidden: false,
        span: 12,
        customField: (
          <div className={'checkBoxUR'}>
            <Checkbox
              checked={stateBoardWaiver}
              onChange={(e) => {
                setStateBoardWaiver(e.target.checked)
              }}
            >
              State Board Waiver
            </Checkbox>
            {
              stateBoardWaiver == true &&
              <>
                <div className={'d-flex'}>
                  <InputBox title={'Select Excepted State'}>
                    <Select
                      style={{ width: '200px' }}
                      value={stateBoardWaiverState}
                      onChange={(value) => {
                        setStateBoardWaiverState(value)
                      }}
                      showSearch={true}
                      placeholder={'Select State'}
                      mode={'multiple'}
                    >
                      {indianStates.map((option, index) => (
                        <Option key={index} value={option.name}>{option.name}</Option>
                      ))}
                    </Select>
                  </InputBox>
                  <InputBox title={'English Marks'}>
                    <Input
                      style={{ width: '140px', height: '40px' }}
                      placeholder='Enter English Marks'
                      value={stateBoardWaiverMarks}
                      type={'number'}
                      onChange={(e) => {
                        setStateBoardWaiverMarks(e.target.value)
                      }}
                    />
                  </InputBox>
                  <InputBox title={'Passing Year UG/PG'}>
                    <Input
                      type={'number'}
                      style={{ width: '80px', height: '40px' }}
                      placeholder='UG'
                      value={stateBoardWaiverYearUg}
                      onChange={(e) => {
                        setStateBoardWaiverYearUg(e.target.value)
                      }}
                    />
                    {/*</InputBox>*/}
                    {/*<InputBox title={'Passing Year PG'}>*/}
                    <Input
                      type={'number'}
                      style={{ width: '80px', height: '40px', marginLeft: '5px' }}
                      placeholder='PG'
                      value={stateBoardWaiverYearPg}
                      onChange={(e) => {
                        setStateBoardWaiverYearPg(e.target.value)
                      }}
                    />
                  </InputBox>
                </div>
              </>
            }
          </div>
        )
      },
      {
        key: 'moiAcceptable',
        label: 'MOI Acceptable',
        hidden: false,
        span: 6,
        customField: (
          <div className={'checkBoxUR'}>
            <Checkbox
              checked={moiAcceptable}
              onChange={(e) => {
                setMoiAcceptable(e.target.checked)
              }}
            >
              MOI Acceptable
            </Checkbox>
            {
              moiAcceptable &&
              <InputBox title={'Add University'}>
                <Select
                  style={{ width: '200px' }}
                  mode='tags'
                  value={moiAcceptableState}
                  onChange={(value) => {
                    setMoiAcceptableUniversity(value)
                  }}
                  placeholder='Add University'
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <div style={{ overflow: 'auto', maxHeight: '200px' }}> {/* Scrollable menu */}
                      </div>
                    </div>
                  )}
                  showSearch={true} // Enables search functionality
                >

                </Select>
              </InputBox>
            }
          </div>
        )
      },
      {
        key: 'initialBeforeInterview',
        label: 'Initial Before Interview',
        hidden: false,
        span: 6,
        customField: (
          <div className={'checkBoxUR'}>
            <Checkbox
              checked={initialBeforeInterview}
              onChange={(e) => {
                setInitialBeforeInterview(e.target.checked)
              }}
            >
              Initial Before Interview
            </Checkbox>
            {
              initialBeforeInterview == true &&
              <InputBox title={'Min. Deposite'}>
                <Input
                  style={{ width: '200px', height: '40px' }}
                  placeholder='Min Deposit'
                  value={initialBeforeInterviewMinDeposit}
                  onChange={(e) => {
                    setInitialBeforeInterviewMinDeposit(e.target.value)
                  }}
                />
              </InputBox>
            }
          </div>
        )
      },
      {
        key: 'noInitialBeforeInterview',
        label: 'No Initial Before Interview',
        hidden: false,
        span: 12,
        customField: (
          <div className={'checkBoxUR'}>
            <Checkbox
              checked={noInitialBeforeInterview}
              onChange={(e) => {
                setNoInitialBeforeInterview(e.target.checked)
              }}
            >
              No Initial Before Interview
            </Checkbox>
          </div>
        )
      },
      {
        key: 'ugOpenBoardAcceptable',
        label: 'UG Open Board Acceptable',
        hidden: false,
        span: 12,
        customField: (
          <div className={'checkBoxUR'}>
            <Checkbox
              checked={ugOpenBoardAcceptable}
              onChange={(e) => {
                setUgOpenBoardAcceptable(e.target.checked)
              }}
            >
              UG Open Board Acceptable
            </Checkbox>
          </div>
        )
      },
      {
        key: 'distanceLearning',
        label: 'Distance Learning',
        hidden: false,
        span: 12,
        customField: (
          <div className={'checkBoxUR'}>
            <Checkbox
              checked={distanceLearning}
              onChange={(e) => {
                setDistanceLearning(e.target.checked)
              }}
            >
              Distance Learning
            </Checkbox>
          </div>
        )
      },
      {
        key: 'entryRequirements',
        label: 'Eligibility For UG',
        type: 'number',
        required: showCanadaEligibility,
        prefixCompNew: 'Eligibility Year',
        hidden: false
      },
      {
        key: 'eligibilityForPg',
        label: 'Eligibility For PG',
        type: 'number',
        required: showCanadaEligibility,
        prefixCompNew: emptyDiv,
        hidden: false
      },
      {
        key: 'notes',
        label: 'Notes',
        required: showEligibilty,
        hidden: false,
        customField: (
          <div style={{ marginTop: 10 }}>
            <label>Add Notes</label>
            <div className={'justifyContent'}>
              <TextArea rows={1} className={'form-control textAreaHeightAuto'} value={notes}
                        onChange={(e) => {
                          setNotes(e.target.value)
                        }} />
              <div><Icon type={'plus-circle'} style={{ fontSize: '28px', color: 'green', marginLeft: 10 }}
                         onClick={() => {
                           addNotes(notes)
                         }} /></div>
            </div>
          </div>)
      }
    ]
  }

  const addNotes = (data) => {
    if (data === '') {
      notification.warning({
        message: 'Cannot add Empty Note'
      })
      return
    }
    setNotesArr([...notesArr, data])
    setNotes('')
  }

  const removeNotes = (index) => {
    let cloneData = _.clone(notesArr)
    cloneData.splice(index, 1)
    setNotesArr(cloneData)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  useEffect(() => {
    dispatch({ type: 'UNIVERSITY_HIDE_LOADER' })
    dispatch(listAllCountries({ results: 1000000 }))
    dispatch(listAllStates({ results: 1000000 }))
    dispatch(listAllCities({ results: 1000000 }))

  }, [])

  useEffect(() => {
    if (stateBoardWaiver) {
      setStateBoardWaiverState(['Haryana', 'Punjab'])
    } else {
      setStateBoardWaiverState([])
    }
    if (!moiAcceptable) {
      setMoiAcceptableUniversity([])
    }
  }, [stateBoardWaiver, moiAcceptable])


  const handleSubmit = async (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      valData.commissions = commissions

      if (showEligibilty) {
        valData.notes = notesArr
        let checkError = _.find(EligibilityList, (item) => {
          if (valData[item.name] !== undefined && valData[item.name] !== null && valData[item.name] < 0) {
            notification.warning({
              message: `${item.label} value cannot be Zero`
            })
            return true
          }
        })
        if (checkError) {
          return
        }
      }
      if (showCanadaEligibility) {
        valData.notes = notesArr
        let checkError = _.find(EligibilityList, (item) => {
          if (valData[item.name] !== undefined && valData[item.name] !== null && valData[item.name] < 0) {
            notification.warning({
              message: `${item.label} value cannot be NULL`
            })
            return true
          }
        })
        if (checkError) {
          return
        }
      }
      if (!err) {
        if (valData.waiverForStateBoard == 'No') {
          valData.englishWaiverForStateBoardMinimum = null
        }
        valData.mediaFiles = state.oldMediaFiles
        valData.campusImages = state.oldCampusImages

        valData.englishLanguage = englishLanguage
        if (englishLanguage && englishLanguageTests.length === 0) {
          notification.warning({
            message: `Please Select Aleast One Test`
          })
          return true
        }
        if (englishLanguageTests.includes('IELTS')) {
          if (!ieltsPg && !ieltsUg) {
            notification.warning({
              message: `Please Enter IELTS UG or PG Marks`
            })
          }
        }
        if (englishLanguageTests.includes('PTE')) {
          if (!ptePg && !pteUg) {
            notification.warning({
              message: `Please Enter PTE  UG or PG Marks`
            })
          }
        }
        if (englishLanguageTests.includes('DUOLINGO')) {
          if (!duolingoPg && !duolingoUg) {
            notification.warning({
              message: `Please Enter DUOLINGO  UG or PG Marks`
            })
          }
        }
        if (englishLanguageTests.includes('TOEFL')) {
          if (!toeflPg && !toeflUg) {
            notification.warning({
              message: `Please Enter TOEFL  UG or PG Marks`
            })
          }
        }
        if (englishLanguageTests.includes('OTHERS')) {
          if (!othersPg && !othersUg) {
            notification.warning({
              message: `Please Enter OTHERS  UG or PG Marks`
            })
          }
        }
        valData.englishLanguageTests = englishLanguageTests

        if (ieltsPg) {
          valData.ieltsPg = ieltsPg
          let a = ieltsPg.split('/')
          valData.iletsMinimumForPg = parseInt(a[0])
          valData.iletsMaximumForPg = parseInt(a[1])
        }

        if (ieltsUg) {
          valData.ieltsUg = ieltsUg
          let b = ieltsUg.split('/')
          valData.iletsMinimumForUg = parseInt(b[0])
          valData.iletsMaximumForUg = parseInt(b[1])
        }

        if (ptePg) {
          valData.ptePg = ptePg
          let c = ptePg.split('/')
          valData.pteMinimumForPg = parseInt(c[0])
          valData.pteMaximumForPg = parseInt(c[1])
        }

        if (pteUg) {
          valData.pteUg = pteUg
          let d = pteUg.split('/')
          valData.pteMinimumForUg = parseInt(d[0])
          valData.pteMaximumForUg = parseInt(d[1])
        }

        if (duolingoPg) {
          valData.duolingoPg = duolingoPg
          let d = duolingoPg.split('/')
          valData.duolingoMinimumForPg = parseInt(d[0])
          valData.duolingoMaximumForPg = parseInt(d[1])
        }
        if (duolingoUg) {
          valData.duolingoUg = duolingoUg
          let d = duolingoUg.split('/')
          valData.duolingoMinimumForUg = parseInt(d[0])
          valData.duolingoMaximumForUg = parseInt(d[1])
        }

        if (toeflPg) {
          valData.toeflPg = toeflPg
          let d = toeflPg.split('/')
          valData.toeflMinimumForPg = parseInt(d[0])
          valData.toeflMaximumForPg = parseInt(d[1])
        }
        if (toeflUg) {
          valData.toeflUg = toeflUg
          let d = toeflUg.split('/')
          valData.toeflMinimumForUg = parseInt(d[0])
          valData.toeflMaximumForUg = parseInt(d[1])
        }

        if (othersPg) {
          valData.othersPg = othersPg
          let d = othersPg.split('/')
          valData.othersMinimumForPg = parseInt(d[0])
          valData.othersMaximumForPg = parseInt(d[1])
        }
        if (othersUg) {
          valData.othersUg = othersUg
          let d = othersUg.split('/')
          valData.othersMinimumForUg = parseInt(d[0])
          valData.othersMaximumForUg = parseInt(d[1])
        }

        if (stateBoardWaiver) {
          valData.stateBoardWaiver = stateBoardWaiver
          valData.stateBoardWaiverState = stateBoardWaiverState
          valData.stateBoardWaiverEnglishMarks = stateBoardWaiverMarks
          valData.stateBoardWaiverEnglishPassingYearUg = stateBoardWaiverYearUg
          valData.stateBoardWaiverEnglishPassingYearPg = stateBoardWaiverYearPg
        }

        valData.moiAcceptable = moiAcceptable
        if (moiAcceptable && !moiAcceptableState) {
          notification.warning({
            message: `Please Choose MOI Acceptable State`
          })
          return true
        }
        if (moiAcceptable) {
          valData.moiAcceptableState = moiAcceptableState
        }

        valData.initialBeforeInterview = initialBeforeInterview
        if (initialBeforeInterview && !initialBeforeInterviewMinDeposit) {
          notification.warning({
            message: `Enter Min Deposit`
          })
          return true
        }
        valData.minimumDeposit = initialBeforeInterviewMinDeposit

        valData.noInitialBeforeInterview = noInitialBeforeInterview
        valData.ugOpenBoard = ugOpenBoardAcceptable
        valData.distanceLearning = distanceLearning

        await dispatch(updateUniversityFxn(valData, universityId))
        getUniversityData()
        setState({
          ...state,
          campusImg: [],
          mediaFiles: [],
          logo: {},
          smallLogo: {},
          universityContract: {},
          mediaKey: moment()
        })
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  useEffect(() => {
    getUniversityData()
  }, [universityId])


  const getUniversityData = async () => {
    if (universityId) {
      dispatch(showPageLoad())
      let data = await dispatch(getSingleUniversityFxn(universityId))
      if (data) {
        setUniversity(() => data)
        setCommissions(data.commissions)
        getStates(data)
      }
      setTimeout(() => {
        dispatch(hidePageLoad())
      }, 500)
    }
  }
  const getStates = (university) => {
    if (university.universityCountry)
      dispatch(listAllStates({ results: 1000000, customQuery: { country: university.universityCountry._id } }))
  }

  useEffect(() => {
    if (university.universityCountry && university.universityCountry._id) {
      setCurrency(university.universityCountry._id)
    }
    props.form.setFieldsValue({
      universityCountry: university.universityCountry ? university.universityCountry._id : undefined,
      universityState: university.universityState ? university.universityState._id : undefined,
      universityCity: university.universityCity ? university.universityCity._id : undefined,
      universityName: `${university.universityName || ''}`,
      universityWebsite: `${university.universityWebsite || ''}`,
      universityType: `${university.universityType || ''}`,
      universityPartnership: `${university.universityPartnership || ''}`,
      elicos: `${university.elicos || ''}`,
      contactAddress: `${university.contactAddress || ''}`,
      applicationFee: `${university.applicationFee || ''}`,
      contactName: `${university.contactName || ''}`,
      contactPhone: `${university.contactPhone || ''}`,
      contactEmail: `${university.contactEmail || ''}`,
      enrollmentDocuments: `${university.enrollmentDocuments || ''}`,
      enrollmentProcedure: `${university.enrollmentProcedure || ''}`,
      enrollmentAdmissionOfficer: `${university.enrollmentAdmissionOfficer || ''}`,
      enrollmentConfirmation: `${university.enrollmentConfirmation || ''}`,
      commission: `${university.commission || ''}`,
      paymentTerms: `${university.paymentTerms || ''}`,
      ugCommission: university.ugCommission,
      pgCommission: university.pgCommission,
      interview: university.interview,
      commissionType: university.commissionType,
      universityReference: university.universityReference,
      isFeatured: university.isFeatured,
      starUniversity: university.starUniversity,
      universityCommissionType: university.universityCommissionType,
      noOfUniversityCommission: university.noOfUniversityCommission,
      universityCommission: university.universityCommission,
      commissionCurrency: university.commissionCurrency
    })
    if (university.universityCountry && university.universityCountry._id) {
      let uniId = university.universityCountry._id.toString()
      setShowEligibilty(uniId && (countryIds['uk'] == uniId || countryIds['usa'] == uniId) ? true : false)
      setShowCanadaEligibility(uniId && ((countryIds['canada'] === uniId || countryIds['australia'] === uniId) ? true : false))

      setTimeout(() => {
        props.form.setFieldsValue({
          minimumCasDeposit: university.minimumCasDeposit || undefined,
          maximumCasDeposit: university.maximumCasDeposit || undefined,
          ugMinimumRequired: university.ugMinimumRequired || undefined,
          ugMaximumRequired: university.ugMaximumRequired || undefined,
          pgMinimumRequired: university.pgMinimumRequired || undefined,
          pgMaximumRequired: university.pgMaximumRequired || undefined,
          mathRequired: university.mathRequired || undefined,
          iletsMinimumForUg: university && (university.iletsMinimumForUg || university.iletsMinimumForUg == 0) ? university.iletsMinimumForUg : undefined,
          iletsMaximumForUg: university && (university.iletsMaximumForUg || university.iletsMaximumForUg == 0) ? university.iletsMaximumForUg : undefined,
          iletsMinimumForPg: university && (university.iletsMinimumForPg || university.iletsMinimumForPg == 0) ? university.iletsMinimumForPg : undefined,
          iletsMaximumForPg: university && (university.iletsMaximumForPg || university.iletsMaximumForPg == 0) ? university.iletsMaximumForPg : undefined,
          pteMinimumForUg: university && (university.pteMinimumForUg || university.pteMinimumForUg == 0) ? university.pteMinimumForUg : undefined,
          pteMaximumForUg: university && (university.pteMaximumForUg || university.pteMaximumForUg == 0) ? university.pteMaximumForUg : undefined,
          pteMinimumForPg: university && (university.pteMinimumForPg || university.pteMinimumForPg == 0) ? university.pteMinimumForPg : undefined,
          pteMaximumForPg: university && (university.pteMaximumForPg || university.pteMaximumForPg == 0) ? university.pteMaximumForPg : undefined,
          intakes: university.intakes ? university.intakes : [],
          waiverForStateBoard: university.waiverForStateBoard || undefined,
          minWaiverForCbsc: university.minWaiverForCbsc || undefined,
          maxWaiverForCbsc: university.maxWaiverForCbsc || undefined,
          interviewType: university.interviewType || undefined,
          timeMinimumDuration: university.timeMinimumDuration || undefined,
          timeMaximumDuration: university.timeMaximumDuration || undefined,
          admissionFee: university.admissionFee || undefined,
          casTimeMinimum: university.casTimeMinimum || undefined,
          casTimeMaximum: university.casTimeMaximum || undefined,
          entryRequirements: university.entryRequirements || undefined,
          eligibilityForUg: university.eligibilityForUg || undefined,
          eligibilityForPg: university.eligibilityForPg || undefined,
          minWaiverForCbscPg: university.minWaiverForCbscPg || undefined,
          minWaiverForCbscUg: university.minWaiverForCbscUg || undefined,
          timeDuration: university.timeDuration || undefined,
          minimumTuitionFees: university.minimumTuitionFees || undefined,
          maximumTuitionFees: university.maximumTuitionFees || undefined
        })
        if (university.notes) {
          setNotesArr(university.notes)
        }

        setEnglishLanguage(university.englishLanguage)
        setEnglishLanguageTests(university.englishLanguageTests)
        setIeltsPg(university.ieltsPg)
        setIeltsUg(university.ieltsUg)
        setPtePg(university.ptePg)
        setPteUg(university.pteUg)
        setDuolingoPg(university.duolingoPg)
        setDuolingoUg(university.duolingoUg)
        setToeflPg(university.toeflPg)
        setToeflUg(university.toeflUg)
        setOthersPg(university.othersPg)
        setOthersUg(university.othersUg)

        setStateBoardWaiver(university.stateBoardWaiver)
        setStateBoardWaiverState(university.stateBoardWaiverState)
        setStateBoardWaiverMarks(university.stateBoardWaiverEnglishMarks)
        setStateBoardWaiverYearUg(university.stateBoardWaiverEnglishPassingYearUg)
        setStateBoardWaiverYearPg(university.stateBoardWaiverEnglishPassingYearPg)

        setMoiAcceptable(university.moiAcceptable)
        setMoiAcceptableUniversity(university.moiAcceptableState)

        setInitialBeforeInterview(university.initialBeforeInterview)
        setInitialBeforeInterviewMinDeposit(university.minimumDeposit)

        setNoInitialBeforeInterview(university.noInitialBeforeInterview)
        setUgOpenBoardAcceptable(university.ugOpenBoard)
        setDistanceLearning(university.distanceLearning)

        setTimeout(() => {
          props.form.setFieldsValue({
            englishWaiverForStateBoardMinimum: university && university.englishWaiverForStateBoardMinimum ? university.englishWaiverForStateBoardMinimum : undefined,
            englishWaiverForStateBoardMaximum: university.englishWaiverForStateBoardMaximum || undefined
          })
        }, 200)
      }, 1000)
    }

    setState({
      ...state,
      oldMediaFiles: university.mediaFiles,
      oldCampusImages: university.campusImages,
      oldLogo: university.logo,
      oldSmallLogo: university.smallLogo,
      oldBanner: university.banner,
      oldUniversityContract: university.universityContract
    })
    if (university.agreementSigningDate) {
      props.form.setFieldsValue({
        agreementSigningDate: moment(university.agreementSigningDate)
      })
    }
    if (university.enrollmentExpiry) {
      props.form.setFieldsValue({
        enrollmentExpiry: moment(university.enrollmentExpiry)
      })
    }
    if (university.enrollmentRenewal) {
      props.form.setFieldsValue({
        enrollmentRenewal: moment(university.enrollmentRenewal)
      })
    }

  }, [university])

  const commEvents = {
    showCommissionDrawer: () => {
      setVisibleCommissionDrawer(true)
    },
    hideCommissionDrawer: () => {
      setVisibleCommissionDrawer(false)
    },
    submitCommission: (data) => {
      setCommissions((prevData) => {
        return [
          ...prevData,
          data
        ]
      })
    },
    deleteCommission: (index) => {
      let commissionArr = _.clone(commissions)
      commissionArr = _.reject(commissionArr, (item, index1) => {
        return index1 == index
      })
      setCommissions(commissionArr)
    }
  }
  useEffect(() => {
    loadUniversityData()
  }, [])
  const loadUniversityData = async () => {
    let data = await dispatch(getUniversity(universityId))
    if (data) {
      setUniversityData(data)
      if (data.universityCountry && data.universityCountry._id) {
        setCountryId(data.universityCountry._id.toString())
      }
    }
  }
  return <div>
    <Drawer visible={visible}
            title={`${universityData.universityName ? universityData.universityName : ''} - Eligibility`}
            width={'85%'}
            onClose={onClose}>
      <div className='row'>
        <div className='col-lg-12'>
          <Form onSubmit={handleSubmit}>
            <div className='form-box mt-4'>
              <div className='card unizportal'>
                {/* {showEligibilty ?
                                  : null}*/}
                <Card className={'mt-2'}>
                  <div className='inner-form'>
                    <h4>Eligibility Criteria </h4>
                    <Row gutter={18} className={'wrapBox'}>
                      {formFields.eligibility.map((item, key) => {
                        return (
                          item.customField ?
                            <Col span={item.span} md={item.span} sm={item.span} xs={24}
                                 key={item.label}
                                 className={'mt20'}>
                              {item.customField}
                            </Col> :
                            !item.hidden && <Col span={item && item.span ? item.span : 4}
                                                 key={key} className={'mt20'}>
                              <div
                                className={`widthLabel ${!item.span && 'w200'}`}>{item.prefixCompNew}</div>
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout} />
                            </Col>
                        )
                      })}
                      <Col span={24}>
                        {notesArr && notesArr.length ?
                          <Card className={'mt-4'}>
                            <div className='inner-form notesArrayStyle'>
                              <h6>All Notes</h6>
                              {notesArr.map((item, index) => {
                                return (
                                  <div className={'justifyContent'} style={{
                                    backgroundColor: 'white',
                                    padding: 5,
                                    marginBottom: 5,
                                    border: 1,
                                    borderColor: 'black',
                                    borderRadius: 5
                                  }}>
                                    <div style={{
                                      alignSelf: 'center',
                                      marginLeft: 10
                                    }}>{index + 1}.{'  '}{item}</div>
                                    <div style={{
                                      alignSelf: 'flex-start',
                                      marginRight: 10,
                                      paddingTop: 0
                                    }}><Icon type={'delete'}
                                             theme='filled'
                                             style={{
                                               fontSize: '18px',
                                               color: 'red',
                                               alignSelf: 'flex-start'
                                             }}
                                             onClick={() => removeNotes(index)} />
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </Card>
                          : null}
                      </Col>
                    </Row>
                  </div>
                </Card>
                <Form.Item>
                  <Button type='primary' htmlType='submit' className='btn'>
                    SAVE
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Drawer>

    {visibleCommissionDrawer ?
      <AddCommission
        visible={visibleCommissionDrawer}
        onClose={commEvents.hideCommissionDrawer}
        onSubmit={commEvents.submitCommission}
      /> : null}
  </div>
}

const EditUniversityRequirements = Form.create()(EditUniversityRequirement)
const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUniversityRequirements)
