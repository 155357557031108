import React, {useEffect} from "react";
import { Button, Col, Modal, Form, notification, Row, Select } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { updatePropertyFaqFxn} from "../action";
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import moment from "moment";

const { Option } = Select;

const EditPaymentPolicy = (props) => {
    let dispatch = useDispatch();
    let { visible, onClose, form: { getFieldDecorator, setFieldsValue }, propertyId, policy } = props;

    console.log(policy, "jjjjjj", propertyId, "iidd")

    const setDefaultValues = () => {

        setTimeout(() => {
            setFieldsValue({
                name: policy.name,
                display_name: policy.display_name,


            })
        }, 100)
    }
    let inputTypes = {
        fields: [
            {
                key: 'name',
                label: 'Name',
                placeholder: 'Edit Name',
                type: 'text',
                required: true,

            },
            {
                key: 'display_name',
                label: 'Display Name',
                placeholder: 'Edit Display Name',
                type: 'text',
                required: true,

            },
            {
                key: 'description',
                label: 'Description',
                placeholder: 'Edit Description',
                type: 'textarea',
                required: true,
                rows: 4,
                style: { minHeight: '200px' },
            }
        ]
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const { form } = props;
    //     form.validateFieldsAndScroll(async (err, valData) => {
    //         if (!err) {
    //             const updatedFaq = {
    //                 ...valData,
    //                 _id: policy._id,
    //             };
    //             const resp = await dispatch(updatePropertyFaqFxn(propertyId, { policies: [updatedFaq] }));
    //             if (resp && resp.success) {
    //                 notification.success({ message: resp.message });
    //                 onClose();
    //             }
    //         } else {
    //             notification.warning({
    //                 message: 'Please fill in all required fields'
    //             });
    //         }
    //     });
    // };


    useEffect(() => {
        setDefaultValues()
    }, [])

    return (
        <>
            <Modal
                visible={visible}
                onCancel={onClose}
                title={`Edit`}
                width={'40%'}
                footer={null}
            >
                <div className="form-box commissionBox">
                    <div className="card unizportal">
                        <Form>
                            <Row gutter={24} className={'wrapBox'}>
                                {inputTypes.fields.map((item, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <Col span={24} key={key}>

                                                <GetEachFormFields
                                                    item={item}
                                                    getFieldDecorator={getFieldDecorator}
                                                    formItemLayout={{ labelCol: { xs: { span: 0 }, sm: { span: 0 } }, wrapperCol: { xs: { span: 24 } } }}
                                                />

                                            </Col>
                                        </React.Fragment>
                                    );
                                })}
                                <Col span={4}>
                                    <Form.Item>
                                        <Button
                                            type="primary" htmlType="submit" className={'btn mt40'}>
                                            Update
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
};

const EditPaymentPolicyModal = Form.create()(EditPaymentPolicy);

const mapStateToProps = ({ global, router }) => ({
    currentUser: global.currentUser
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditPaymentPolicyModal);
