import React, {useEffect, useState} from "react";
import { Button, Col, Modal, Form, notification, Row, Input } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import {guarantorDetailsFxn, singleEnquiryFxn} from "../action";
import moment from "moment";
import _ from 'lodash';
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import {CountryCodeJson as countryCodes, CountryCodeWithFlag} from "../../../components/_utils/countryUtil";

const GuarantorDetailsModal = (props) => {
    const dispatch = useDispatch();
    const { visible, onClose, form: { getFieldDecorator, setFieldsValue }, enquiry } = props;
    const [designatedCountry, setDesignatedCountry] = useState([]);
    const [dialCode, setDialCode] = useState(enquiry.guarantorDialCode || '');

    const setDefaultValues = () => {

        setTimeout(() => {
            setFieldsValue({
                title: enquiry.guarantorTitle,
                fullName: enquiry.guarantorFullName,
                dateOfBirth: enquiry.guarantorDateOfBirth ? moment(enquiry.guarantorDateOfBirth) : null,
                email: enquiry.guarantorEmail,
                dialCode: enquiry.guarantorDialCode,
                mobileNumber: enquiry.guarantorMobileNumber,
                relation: enquiry.guarantorRelation,
                address: enquiry.guarantorAddress,
                address2: enquiry.guarantorAddressLine2,
                city: enquiry.guarantorCity,
                stateName: enquiry.guarantorStateName,
                country: enquiry.guarantorCountry,

            })
        }, 100)
    }
    let loadCountry = () => {
        let designatedArr = [];
        _.each(countryCodes, (item) => {
            designatedArr.push({
                name: `${item.code} - ${item.name}`,
                value: item.name
            });
        });
        setDesignatedCountry(designatedArr);
    };
    const inputFields = [
        { key: 'title', label: 'Title', placeholder: 'Enter Title' },
        { key: 'fullName', label: 'Full Name', placeholder: 'Enter Full Name', required: true },
        { key: 'dateOfBirth', label: 'Date of Birth',type: 'date', placeholder: 'Enter Date of Birth'},
        { key: 'email', label: 'Email', placeholder: 'Enter Email', required: true },
        {
            key: 'dialCode',
            span: 12,
            customField: (
                <div style={{ marginTop: 12 }}>
                    <CountryCodeWithFlag
                        countryCode={dialCode || enquiry.guarantorDialCode}
                        chooseCode={(val) => {
                            setDialCode(val);
                            setFieldsValue({ dialCode: val });
                        }}
                    />
                </div>
            ),
        },
        { key: 'mobileNumber', label: 'Mobile Number', placeholder: 'Enter Mobile Number', required: true },
        { key: 'relation', label: 'Relation', placeholder: 'Enter Relation'},
        { key: 'address', label: 'Address', placeholder: 'Enter Address' },
        { key: 'address2', label: 'Alternate Address', placeholder: 'Enter Alternate Address'},
        {
            key: 'country',
            label: 'Country',
            placeholder: 'Select Country',
            required: true,
            showSearch: true,
            type: 'select',
            options: designatedCountry,
            keyAccessor: x => `${x.name}`,
            valueAccessor: x => `${x.value}`,
            onChange: x => {
                setFieldsValue({
                    country: x
                });
            }
        },
        { key: 'stateName', label: 'State', placeholder: 'Enter State' },
        { key: 'city', label: 'City', placeholder: 'Enter City'},


    ];

    const handleSubmit = (e) => {
        const { form } = props;
        e.preventDefault();
        form.validateFieldsAndScroll(async (err, values) => {
            const formValues = { ...values, dialCode };
            if (!err) {
                const payload = { enquiryId: enquiry._id, guarantorDetails: formValues };
                const resp = await dispatch(guarantorDetailsFxn(payload));
                if (resp && resp.success) {
                    notification.success({ message: resp.message });
                    onClose();
                } else {
                    notification.error({ message: resp.message});
                }
            } else {
                notification.warning({
                    message: 'Please fill all required fields',
                });
            }
        });
    };
    const formItemLayout = {
        labelCol: {
            xs: { span: 0 },
            sm: { span: 0 },
            md: { span: 0 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 }
        }
    }

    useEffect(() => {
        setDefaultValues()
        loadCountry()
    },[])

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title="Update Guarantor Details"
            width="50%"
            footer={null}
        >
            <div className="form-box guarantorBox">
                <div className="card unizportal">
                    <Form onSubmit={handleSubmit}>
                        <Row gutter={24} className={'rowWrap'}>
                            {inputFields.map((item, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        {item.customField ? (
                                            <Col span={12}>
                                                {item.customField}
                                            </Col>
                                        ) : ((item.hidden == undefined || (!item.hidden)) && (
                                            <Col span={12}>
                                                <GetEachFormFields
                                                    item={item}
                                                    getFieldDecorator={getFieldDecorator}
                                                    formItemLayout={formItemLayout}
                                                />
                                            </Col>
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Update Guarantor Details
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

const GuarantorDetailsUpdate = Form.create()(GuarantorDetailsModal);

const mapStateToProps = ({ global }) => ({
    currentUser: global.currentUser
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(GuarantorDetailsUpdate);
