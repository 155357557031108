import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  DefaultTablePagination,
  displayDate,
  dateFilterRange
} from '../../../components/_utils/appUtils'
import { updateCallBackFxn, requestCallBackListFxn, addCallbackFeedbackFxn } from '../actions/dashboardAction'
import { Icon, Modal, notification, Tooltip, DatePicker, Row, Col, Select, Popconfirm, Button } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import moment from 'moment'
import { InputBox } from '../../../components/_utils/appUtils'
import _ from 'lodash'
import { useLocation } from 'react-router'

const { RangePicker } = DatePicker
const dateFormat = 'DD/MM/YYYY'

const initFilters = {
  fromDate: '',
  toDate: '',
  assignedTo: ''
}
const RenderComment = (props) => {
  let { item } = props
  let [hideV, setHideV] = useState(true)
  let text = item.details || item.reason
  return (
    item && (
      <div>
        <div className={hideV ? 'appCommentDotsReplace' : ''}>
          {text ? text : ''}
        </div>
        {text && text.length && text.length > 50 ? (
          <div className={'alignRight'}>
            {hideV ? (
              <a
                onClick={() => {
                  setHideV(false)
                }}>
                Show More
              </a>
            ) : (
              <a
                onClick={() => {
                  setHideV(true)
                }}>
                Show Less
              </a>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  )
}

const UnizHomeRequestCallback = () => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const callbackId = query.get('callbackId')
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  const tableRef = useRef()
  const dispatch = useDispatch()
  const [total, setTotal] = useState(0)
  const [details, setDetails] = useState('')
  const [reason, setReason] = useState('')
  const [satisfaction, setSatisfaction] = useState('satisfied')
  const [filters, setFilters] = useState(initFilters)

  const [detailsModal, setDetailsModal] = useState({
    visible: false,
    id: ''
  })
  const [cancelModal, setCancelModal] = useState({
    visible: false,
    id: ''
  })
  const [feedbackModal, setFeedbackModal] = useState({
    visible: false,
    id: ''
  })

  const [comment, setComment] = useState('')
  const updateFilter = (data) => {
    setFilters((prevState) => ({
      ...prevState,
      ...data
    }))
  }
  useEffect(() => {
    if (callbackId) {
      setFeedbackModal({ visible: true, id: callbackId })
    }
  }, [callbackId])
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let filterResp = await getParamsForList()
      params = { ...params, ...filterResp, type: 'unizHomeCallback' }
      let { fromDate, toDate } = params

      if (fromDate) {
        let date = {}
        date.$gte = moment(fromDate).startOf('day')
        date.$lte = moment(toDate).endOf('day')
        params.date = date
        delete params.fromDate
        delete params.toDate
      }
      if (callbackId) {
        params._id = callbackId
      }
      let resp = await dispatch(requestCallBackListFxn({
        ...params,
        regExFilters: ['name', 'email', 'mobile', 'status']
      }))
      setTotal(resp.total)
      resolve(resp)
    })
  }

  const getParamsForList = () => {
    return new Promise((resolve) => {
      let obj = {}
      Object.keys(initFilters).forEach((key) => {
        if (filters[key]) {
          obj[key] = filters[key]
        }
      })

      if (obj.toDate) {
        obj.toDate = moment(obj.toDate).format('MM-DD-YYYY')
      }
      if (obj.fromDate) {
        obj.fromDate = moment(obj.fromDate).format('MM-DD-YYYY')
      }

      resolve(obj)
    })
  }

  const updateCallBackStatus = async (id, status) => {
    if (status === 'cancel' && !reason) {
      notification.warning({ message: 'Enter reason' })
      return
    }
    let data = await dispatch(updateCallBackFxn({ id, status, details, reason }))
    if (data) {
      setDetailsModal({ visible: false, id: '' })
      setCancelModal({ visible: false, id: '' })
      tableRef.current.reload()
    }
  }

  const handleFeedbackSubmit = async (callbackId) => {
    if (!satisfaction) {
      notification.warning({ message: 'Select satisfaction' })
      return
    }

    if (satisfaction === 'notSatisfied' && !comment) {
      notification.warning({ message: 'Enter a comment for improvement' })
      return
    }

    const feedbackData = {
      callbackId,
      feedbackStatus: satisfaction === 'satisfied' ? 'Satisfied' : 'Not Satisfied',
      comment: comment
    }

    const data = await dispatch(addCallbackFeedbackFxn(feedbackData))

    if (data) {
      setFeedbackModal({ visible: false, id: '' })
      setSatisfaction('')
      setComment('')
      tableRef.current.reload()
    } else {
      notification.error({ message: 'Failed to submit feedback. Please try again.' })
    }
  }


  const searchFxn = () => {
    if (tableRef.current) {
      tableRef.current.reload()
    }
  }

  const clearFxn = () => {
    setFilters(initFilters)
    setTimeout(() => {
      if (tableRef.current) {
        tableRef.current.reload()
      }
    }, 500)
  }

  const FilterComponent = () => {
    return (
      <Row gutter={12} className={'filter_box'}>
        <Col span={6}>
          <div className='filter-box'>
            <InputBox title={'Search by date'}>
              <RangePicker
                format={dateFormat}
                value={[
                  filters.fromDate ? moment(filters.fromDate, dateFormat) : null,
                  filters.toDate ? moment(filters.toDate, dateFormat) : null
                ]}
                onChange={(dates) => {
                  updateFilter({
                    fromDate: dates[0],
                    toDate: dates[1]
                  })
                }}
                ranges={dateFilterRange}
              />
            </InputBox>

          </div>
        </Col>


        <Col span={24}>
          <div className={'btn_group'}>
            <a className={'default_btn'} onClick={searchFxn}>Search</a>
            <a className={'default_btn'} onClick={clearFxn}>Clear</a>
          </div>
        </Col>
      </Row>
    )
  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (item, record, index) => {
        return index + 1
      }
    },
    {
      title: 'Name',
      dataIndex: ['userId', 'name'],
      key: 'userName',
      width: 150
    },
    {
      title: 'Email',
      dataIndex: ['userId', 'email'],
      key: 'userEmail',
      width: 150
    },
    {
      title: 'Mobile',
      dataIndex: ['userId', 'mobile'],
      key: 'mobile',
      width: 150,
      render: (item, record) => {
        let countryCode = record.userId.countryCode
        return (
          <>
            {countryCode} {item}
          </>
        )
      }
    },
    {
      title: 'Agent Name',
      dataIndex: 'name',
      key: 'name',
      searchTextName: 'name',
      width: 150
    },
    {
      title: 'Agent Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 120,
      searchTextName: 'phone',
      render: (item, record) => {
        let countryCode = record.countryCode
        return (
          <>
            {countryCode} {item}
          </>
        )
      }
    },
    {
      title: 'Agent Email',
      dataIndex: 'email',
      key: 'email',
      searchTextName: 'email',
      width: 150
    },
    {
      title: 'Assigned To',
      dataIndex: 'assignedTo',
      key: 'assignedTo',
      searchTextName: 'assignedTo',
      width: 150,
      hidden: !(user.userType == 'admin'),
      render: (item) => {
        return (
          <>
            {item && item.name ? item.name : null}
            {item && item.email ? ` (${item.email})` : null}
          </>
        )
      }
    },
    {
      title: 'Callback Date',
      dataIndex: 'date',
      key: 'date',
      width: 100,
      render: (item) => {
        return item ? displayDate(item) : ''
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      searchTextName: 'status',
      width: 100,
      render: (item, record) => {
        let className = ''
        let labelText = ''
        if (item === 'done') {
          className = 'label label-success-green label-sm'
          labelText = 'Done'
        } else if (item === 'cancel') {
          className = 'label label-danger label-sm'
          labelText = 'Cancel'
        } else {
          className = 'label label-success label-sm'
          labelText = 'Pending'
        }

        return (
          <span className={className}>
            {labelText}
          </span>
        )
      }
    },
    {
      title: 'Feedback Status',
      key: 'feedbackStatus',
      dataIndex: 'feedback',
      width: 150,
      render: (feedback, record) => {
        if (feedback && feedback.length > 0) {
          const satisfaction = feedback[0].feedbackStatus === 'Satisfied' ? 'Satisfied 🙂' : 'Not Satisfied ☹'
          const className = satisfaction === 'Satisfied' ? 'label label-success-green' : 'label label-danger'
          return (
            <label className={className}>
              {satisfaction}
            </label>
          )
        } else {
          return null
        }
      }
    },
    {
      title: 'Details/Reason',
      key: 'details',
      dataIndex: 'details',
      width: 150,
      render: (item, record) => {
        return <RenderComment item={record} />
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 80,
      render: (text, record) => {
        let { status, feedback } = record
        let { userType } = user
        return (
          <React.Fragment>
            {status === 'Pending' && (userType === 'admin' || userType === 'assistantMarketingManager') ? (
              <>
                <Tooltip title='Done'>
                  <button
                    className={'btn'}
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      setDetailsModal({ visible: true, id: record._id })
                    }}>
                    <Icon type={'check'} />
                  </button>
                </Tooltip>
                <Tooltip title='Cancel'>
                  <button
                    className={'btn'}
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      setCancelModal({ visible: true, id: record._id })
                    }}>
                    <Icon type={'close'} />
                  </button>
                </Tooltip>
              </>
            ) : (
              status === 'done' && userType === 'agent' && (!feedback || (feedback && !feedback.feedbackStatus)) && (
                <Tooltip title='Give Feedback'>
                  <label
                    type='primary'
                    onClick={() => {
                      setFeedbackModal({ visible: true, id: record._id })
                    }}
                    style={{
                      background: 'linear-gradient(45deg, #0f52ba, #2e86c1)'
                    }}
                    className={'label'}
                  >
                    <Icon type='message' /> Give Feedback
                  </label>
                </Tooltip>
              )
            )}
          </React.Fragment>
        )
      }
    }
  ]
  const getColumns = () => {
    let newCol = _.filter(columns, (item) => {
      return !item.hidden
    })
    return newCol
  }
  return (
    <div>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>All Unizhome Callback Request ({total || 0})</h5>
              <div className='search-box-table'>

              </div>

              <div className='sort-box-table mark-btn'>

              </div>
            </div>

            <div className='card-body table-responsive'>
              <FilterComponent />

              <TableComp columns={getColumns()}
                         apiRequest={apiRequest}
                         ref={tableRef}
                         pagination={DefaultTablePagination()}
                         extraProps={{ scroll: { x: 1000 } }}
              />
            </div>
            {
              detailsModal.visible ? (
                <Modal
                  title='Enter Detail'
                  visible={detailsModal.visible}
                  onOk={() => {
                    updateCallBackStatus(detailsModal.id, 'done')
                  }}
                  onCancel={() => {
                    setDetailsModal({ ...detailsModal, visible: false })
                  }}
                >
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    value={details}
                    onChange={(e) => setDetails(e.target.value)}
                    placeholder='Enter Details..'
                    required={true}
                  />
                </Modal>
              ) : null
            }
            {
              cancelModal.visible ? (
                <Modal
                  title='Enter Reason'
                  visible={cancelModal.visible}
                  onOk={() => {
                    updateCallBackStatus(cancelModal.id, 'cancel')
                  }}
                  onCancel={() => {
                    setCancelModal({ ...cancelModal, visible: false })
                  }}
                >
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder='Enter reason for Cancel'
                    required={true}
                  />
                </Modal>
              ) : null
            }
            {feedbackModal.visible && (
              <Modal
                title='Give Feedback'
                visible={feedbackModal.visible}
                onCancel={() => {
                  setFeedbackModal({ ...feedbackModal, visible: false })
                }}
                footer={null}
              >
                <div className='form-box'>
                  <div className='card unizportal' style={{ textAlign: 'center' }}>
                    <div className='message'>
                      <p><b>Please provide your feedback: </b></p>
                    </div>
                    <div className={'row'}>
                      <button
                        className={`btn btn-success-green border round lg ml-2 feedback-button ${satisfaction === 'satisfied' ? 'selected' : ''}`}
                        onClick={() => {
                          setSatisfaction('satisfied')
                        }}
                      >
                        Satisfied 🙂
                      </button>

                      <button
                        className={`btn btn-danger border round lg ml-2 feedback-button ${satisfaction === 'notSatisfied' ? 'selected' : ''}`}
                        onClick={() => {
                          setSatisfaction('notSatisfied')
                        }}>
                        Not Satisfied ☹️
                      </button>
                    </div>
                    {satisfaction && (
                      <div className='mt-4'>
                        {satisfaction === 'satisfied' ? (
                          <>
                            <label>Please provide additional comments:</label>
                            <TextArea
                              rows={5}
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                              placeholder='Enter additional comments...'
                            />
                          </>
                        ) : (
                          <>
                            <label>Please put a note so that we can improve our services regarding this
                              complaint:</label>
                            <TextArea
                              rows={5}
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                              placeholder='Enter your note...'
                              required
                            />
                          </>
                        )}
                      </div>
                    )}
                    <Button className={'roundBtn mt10'} type={'primary'}
                            onClick={() => handleFeedbackSubmit(feedbackModal.id)}>
                      Submit
                    </Button>
                  </div>
                </div>
              </Modal>
            )}

          </div>
        </div>
      </div>
    </div>
  )
}

export default UnizHomeRequestCallback
