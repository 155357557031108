import React, {useState, useRef, useEffect} from 'react'
import {
    Button, Col,
    Popconfirm, Select,
    Tooltip, Row, Tag
} from 'antd'
import {useDispatch} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {
    listWithdrawalDataFxn,
    listWithdrawalFxn,
    reGenerateInvoiceFxn
} from '../../withdrawal/actions/withdrawalAction'
import UpdateWithdrawal from '../drawers/updateWithdrawal'
import {requestForCallFxn} from '../actions'
import {listAllUsers} from '../../users/actions/user'
import { countryIds, filterOption, fixed2Digit, longDisplayDate } from '../../../components/_utils/appUtils'
import {listAllCountries} from '../../countries/actions/countries'
import {InputBox, displayDate} from '../../../components/_utils/appUtils'
import lodash from 'lodash'
import AgentTypeheadComponent from '../../../components/_utils/AgentTypeheadComponent'
import moment from 'moment'
import WithdrawalDetails from '../drawers/withdrawalDetailsModal'

let statusOptions = [
    {name: 'Pending', value: 'Pending'},
    {name: 'Approved', value: 'Approved'},
    {name: 'Cancel', value: 'Cancel'}
]
const initState = {
    withdrawalId: '',
    visible: false,
    status: ''
}
let initFilter = {
    agentId: undefined,
    status: undefined,
    referralNo: '',
    countryId: undefined,
    invoiceAddress: ''
}

const RenderStudentIds = props => {
    let {appIds, withdrawalId, record} = props
    let [appList, setAppList] = useState([])
    let [hideV, setHideV] = useState(true)
    const navToSearchPage = (appId) => {
        if (!record.withdrawalType || record.withdrawalType == "Student Commission") {
            let url = `/searchApplication/view?applicationId=${appId}&withdrawalId=${withdrawalId}`
            window.open(url, '_blank')
        }
    }
    useEffect(() => {
        let uniqueArr = lodash.uniqBy(appIds)
        setAppList(uniqueArr)
    }, [appIds])

    return (
        <div>
            <div className={hideV ? '' : ''}>
                {appList && appList.length ? appList.map((item, index) => {
                    return (
                        <a className={'btn btn-default roundNew xs mr5'}
                           onClick={() => navToSearchPage(item)}
                           key={`app-${index}`}>{item}</a>
                    )
                }) : null}
            </div>

            {/* {appList && appList.length && appList.length > 6 ? (
        <div className={'alignRight mt5'}>
          {hideV ? (
            <a className={'hyperLink'}
               onClick={() => {
                 setHideV(false)
               }}>
              Show More
            </a>
          ) : (
            <a className={'hyperLink'}
               onClick={() => {
                 setHideV(true)
               }}>
              Show Less
            </a>
          )}
        </div>
      ) : null}*/}

        </div>
    )
}


const ListAllWithdrawal = () => {
    let {Option} = Select
    const tableRef = useRef()
    const dispatch = useDispatch()
    let [total, setTotal] = useState(0)
    let [state, setState] = useState(initState)
    let [allAgent, setAllAgent] = useState([])
    let [allCountry, setAllCountry] = useState([])
    let [filterState, setFilterState] = useState(initFilter)
    let [withdrawalDataModal, setWithdrawalDataModal] = useState({
        visible : false,
        withdrawalId : '',
        withdrawalType : '',
        agentName : '',
        agentCompanyName : '',
    })
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let filterResp = await events.getParamsForApplicationList()
            params = {...params, ...filterResp}
            params.sortField = '_id'
            params.sortOrder = 'descend'
            if (!params.countryId) {
                params.countryId = {$ne: countryIds.canadaOnshore}
            }
            let resp = await dispatch(listWithdrawalFxn({...params, regExFilters: ['referralNo']}))
            setTotal(resp.total)
            resolve(resp)
        })
    }

    useEffect(() => {
        loadAgentData()
        loadAllCountry()
    }, [])

    const loadAgentData = async () => {
        let params = {
            results: 10000,
            userType: 'agent',
            sortField: 'companyName',
            sortOrder: 'ascend',
            select: ['name', 'companyName']
        }
        let {data} = await dispatch(listAllUsers(params))
        if (data && data.length) {
            setAllAgent(data)
        }
    }

    const loadAllCountry = async () => {
        let {data} = await dispatch(listAllCountries({results: 100}))
        setAllCountry(data)
    }

    const updateState = (data) => {
        setState((prevState) => {
            return {
                ...prevState,
                ...data
            }
        })
    }

    const events = {
        showDrawer: (withdrawalId, status) => {
            updateState({
                withdrawalId,
                visible: true,
                status: status
            })
        },
        hideDrawer: () => {
            updateState({
                withdrawalId: '',
                visible: false,
                status: ''
            })
            tableRef.current.reload()
        },
        sendForCallRequest: async (withdrawalId) => {
            let resp = await dispatch(requestForCallFxn({withdrawalId}))
            if (resp && resp.success) {
                tableRef.current.reload()
            }
        },
        reGenerateInvoiceFunc: async (withdrawalId) => {
            let resp = await dispatch(reGenerateInvoiceFxn({withdrawalId}))
            if (resp && resp.success) {
                tableRef.current.reload()
            }
        },
        _updateFilter: (data) => {
            setFilterState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        searchFxn: () => {
            setTimeout(() => {
                tableRef.current.reload()
            }, 1000)
        },
        clearFxn: () => {
            events._updateFilter(initFilter)
            setTimeout(() => {
                tableRef.current.reload()
            }, 1000)
        },
        getParamsForApplicationList: () => {
            return new Promise(resolve => {
                let obj = {}
                lodash.each(initFilter, (item, key) => {
                    if (filterState[key]) {
                        obj[key] = filterState[key]
                    }
                })
                resolve(obj)
            })
        },
      showWithdrawalDataModal: (data) => {
        setWithdrawalDataModal({
          visible: true,
          withdrawalId: data.withdrawalId,
          withdrawalType: data.withdrawalType,
          agentName: data.agentName,
          agentCompanyName: data.agentCompanyName
        })
      },
      hideWithdrawalDataModal: () => {
        setWithdrawalDataModal({
          visible: false,
          withdrawalId: '',
          withdrawalType: ''
        })
      }
    }

    const columns = [

        {
            title: 'S.No',
            dataIndex: 'sno',
            width: 50,
            key: 'sno',
            render: (item, record, index) => {
                return <a className={'linkPointer'}>
                    {index + 1}
                </a>
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            width: 150,
            key: 'date',
            render: (item, record) => {
                let {withdrawalType} = record;
                return (
                    <div>
                        {item ? displayDate(item) : ''}
                        <br/>
                        <label className={'label label-primary label-xs mt10 round font11'}>
                            {withdrawalType ? withdrawalType : "Student Commission"}
                        </label>
                    </div>
                )
            }
        },
        {
            title: 'Agent',
            dataIndex: 'agentId',
            width: 150,
            key: 'agentId',
            render: (item, record) => {
                return (
                    <div>
                        {item && item.name ? item.name : ''}
                        {item && item.companyName ? `(${item.companyName})` : ''}
                    </div>
                )
            }
        },
        {
            title: 'Country',
            dataIndex: 'countryId',
            width: 60,
            key: 'countryId',
            render: (item, record) => {
                return (
                    <div>
                        {item && item.countryName ? item.countryName : ''}
                    </div>
                )
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            width: 60,
            key: 'amount',
            render: (item, record) => {
                let {countryId} = record
                return (
                    <div>
                        {countryId && countryId.currency ? countryId.currency : ''} {fixed2Digit(item)}
                    </div>
                )
            }
        },
        {
            title: 'Details',
            dataIndex: 'account',
            width: 150,
            key: 'account',
            render: (item, record) => {
                return (
                    <div>
                        Acc no : {item.accountNo} ({item.bankName}) <br/>
                        IFSC: {item.ifsc} <br/>
                        Swift: {item.swiftCode} <br/>
                        Address: {item.address}<br/>
                        {item.cancelledCheque && item.cancelledCheque.path ? <>
                            <Tooltip title={'Cancelled Cheque'}>
                                <a className={'btn btn-success-green btn-xs'} href={item.cancelledCheque.path}
                                   target={'_blank'}>
                                    Cancelled Cheque
                                </a>
                            </Tooltip>
                        </> : null}
                    </div>
                )
            }
        },
        {
            title: 'Withdrawal Status',
            dataIndex: 'status',
            key: 'status',
            width: 80,
            render: (item, record) => {
                return (
                    <div>
                        {item == 'Approved' ? <label className={'label label-success-green'}>Approved</label> : null}
                        {item == 'Pending' ? <label className={'label label-default'}>Pending</label> : null}
                        {item == 'Cancel' ? <label className={'label label-danger'}>Cancel</label> : null}
                    </div>
                )
            }
        },
        {
            title: 'Payable Date',
            dataIndex: 'payableDate',
            key: 'payableDate',
            width: 175,
            render: (item, record) => {
                return (
                    <div>
                        {record && record.payableDate && moment(record.payableDate).format('DD-MM-YYYY')}
                    </div>
                )
            }
        },
        {
            title: 'Student IDs',
            dataIndex: 'AppIds',
            key: 'AppIds',
            width: 175,
            render: (item, record) => {
              return (
                <div>
                  {(record.withdrawalType == 'Student Commission' || !record.withdrawalType) ?
                    <RenderStudentIds appIds={item} withdrawalId={record._id} record={record} /> :
                    <a className={'btn btn-default roundNew xs mr5'}
                       onClick={() =>
                         events.showWithdrawalDataModal({
                           withdrawalId: record._id,
                           withdrawalType: record.withdrawalType,
                           agentName: record.agentId.name,
                           agentCompanyName: record.agentId.companyName
                         })
                       }
                       key={record.withdrawalType}>View</a>
                  }
                </div>
              )
            }
        },
        {
            title: 'Call Request',
            dataIndex: 'requestForCall',
            key: 'requestForCall',
            width: 100,
            render: (item, record) => {
                let {verified} = record
                return (
                    <div>
                        {!item ?
                            <>
                                <Popconfirm
                                    title={'Are you sure, you want to Send Request for Call?'}
                                    okText={'Yes'}
                                    cancelText={'No'}
                                    onConfirm={() => {
                                        events.sendForCallRequest(record._id)
                                    }}>
                                    <a className={'btn btn-default btn-sm font12'}>
                                        Send for Call
                                    </a>
                                </Popconfirm>
                            </> :
                            <>
                                {/* <a className={'btn btn-warning btn-sm font12'}>
                                    <i className={'fa fa-check-circle'}/> Request Pending
                                </a>*/}

                                <div className={'font12'}>
                                    {verified == 'Verified' ?
                                        <label className={'label label-success-green'}><i
                                            className={'fa fa-check-circle'}/> Verified</label> : null}
                                    {verified == 'Pending' ?
                                        <label className={'label label-default'}>Request Pending</label> : null}
                                    {verified == 'Not Verified' ?
                                        <label className={'label label-danger'}>Not Verified</label> : null}
                                </div>


                            </>}
                    </div>
                )
            }
        },
        {
            title: 'Refer No',
            dataIndex: 'referralNo',
            width: 60,
            key: 'referralNo',
            render: (item, record) => {
                return (
                    <div style={{width: 60}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Invoice Add',
            dataIndex: 'invoiceAddress',
            width: 110,
            key: 'invoiceAddress',
            render: (item, record) => {
                return (
                    <div>
                        {item}
                    </div>
                )
            }
        },

        /* {
             title: 'Call Verified',
             dataIndex: 'verified',
             key: 'verified',
             width: 100,
             render: (item, record) => {
                 let {requestForCall} = record;
                 return (
                     <div>
                         {requestForCall ?
                             <>
                                 <div>
                                     {item == 'Verified' ?
                                         <label className={'label label-success-green'}>Verified</label> : null}
                                     {item == 'Pending' ?
                                         <label className={'label label-default'}>Pending</label> : null}
                                     {item == 'Not Verified' ?
                                         <label className={'label label-danger'}>Not Verified</label> : null}
                                 </div>
                             </>
                             : null}
                     </div>
                 )
             }
         },*/
        {
            title: 'Receipt',
            dataIndex: 'receipt',
            key: 'receipt',
            width: 100,
            render: (item, record) => {
                let {invoice} = record
                return (
                    <div>
                        {item && item.url ?
                            <>
                                <a href={item.url}>Download Receipt</a>
                            </>
                            : null}
                        <br/>
                        {invoice && invoice.path ?
                            <>
                                <a href={invoice.path} target={'_blank'}>Download Invoice</a>

                                <div className={'mt10'}>
                                    <Popconfirm
                                        title={'Are you sure, you want to Re-Generate Invoice?'}
                                        okText={'Yes'}
                                        cancelText={'No'}
                                        onConfirm={() => {
                                            events.reGenerateInvoiceFunc(record._id)
                                        }}>
                                        <a className={'btn btn-info btn-xs'}>
                                            Re-generate Invoice
                                        </a>
                                    </Popconfirm>
                                </div>

                            </>
                            : null}


                    </div>
                )
            }
        },
        {
            key: 'actions',
            title: 'Actions',
            width: 80,
            render: (text, record) => {
                let {verified, status, invoice} = record
                return (
                    (status != 'Approved' && verified == 'Verified' && (invoice && invoice.path)) ?
                        <div className={'view-btn-box ml10'}>
                            <Tooltip title='Approve'>
                                <a className={'btn btn-success btn-sm mr10'}
                                   onClick={() => {
                                       events.showDrawer(record._id, 'Approved')
                                   }}
                                >
                                    Approve
                                </a>
                            </Tooltip>
                            <br/>
                            <Tooltip title='Cancel'>
                                <a className={'btn btn-danger btn-sm mt5'}
                                   onClick={() => {
                                       events.showDrawer(record._id, 'Cancel')
                                   }}>
                                    Cancel
                                </a>
                            </Tooltip>
                        </div> : null
                )
            }
        }
    ]

    const filterComponent = (
        <Row className={'filter_box'} gutter={16}>
            <Col md={4} sm={4} xs={8} lg={4}>
                <InputBox title={'Search By Country'}>
                    <Select name='Search By Country'
                            filterOption={filterOption}
                            allowClear={true}
                            placeholder={'Country'}
                            showSearch={true}
                            onChange={item => events._updateFilter({countryId: item})}
                            value={filterState.countryId || undefined}>
                        {allCountry && allCountry.length
                            ? allCountry.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>
                                        {item.countryName}
                                    </Option>
                                )
                            })
                            : null}
                    </Select>
                </InputBox>
            </Col>
            <Col md={4} sm={4} xs={8} lg={4}>
                <InputBox title={'Search By Agent'} labelCls={'labelDoc'}>
                    <AgentTypeheadComponent
                        key={filterState.agentId}
                        agentId={filterState.agentId}
                        dispatch={dispatch}
                        customStyle={''}
                        onSelect={item => {
                            events._updateFilter({agentId: item})
                        }}
                    />
                </InputBox>
            </Col>

            <Col md={4} sm={4} xs={8} lg={4}>
                <InputBox title={'Search By Status'}>
                    <Select
                        name='Search By Status'
                        filterOption={filterOption}
                        allowClear={true}
                        placeholder={'Status'}
                        onChange={item => events._updateFilter({status: item})}
                        value={filterState.status || undefined}>
                        {statusOptions && statusOptions.length
                            ? statusOptions.map((item, key) => {
                                return (
                                    <Option value={item.value} key={key}>
                                        {item.name}
                                    </Option>
                                )
                            })
                            : null}
                    </Select>
                </InputBox>
            </Col>

            <Col md={4} sm={4} xs={8} lg={4}>
                <InputBox title={'Search By Referral No'}>
                    <input type='search'
                           placeholder='Search by Referral No...'
                           value={filterState.referralNo}
                           onChange={e => {
                               events._updateFilter({referralNo: e.target.value})
                           }}
                    />
                </InputBox>
            </Col>
            <Col span={24}>
                <div className={'btn_group'}>
                    <a className={'default_btn'} onClick={events.searchFxn}>Search</a>
                    <a className={'default_btn'} onClick={events.clearFxn}>Clear</a>
                </div>
            </Col>

        </Row>
    )

    return (
        <div className='row mt-4'>
            <div className='col-lg-12'>
                <div className='card'>
                    <div className='table-head d-flex align-items-center'>
                        <Button onClick={() => events.goBack()}
                                className={'roundGoBackBtn'} icon={'arrow-left'}>
                            Back
                        </Button>
                        <h5>All Withdrawal: {total}</h5>
                        <div className='search-box-table'>
                        </div>
                        <div className='sort-box-table mark-btn'>
                        </div>
                    </div>
                    <div className='card-body table-responsive'>
                        {filterComponent}
                        <br/>
                        <TableComp columns={columns}
                                   apiRequest={apiRequest}
                                   ref={tableRef}
                                   extraProps={{scroll: {x: 2000}}}
                        />
                    </div>
                </div>
            </div>
            {state.visible ?
                <UpdateWithdrawal
                    visible={state.visible}
                    withdrawalId={state.withdrawalId}
                    status={state.status}
                    onClose={events.hideDrawer}
                /> : null}
            {withdrawalDataModal.visible ?
              <WithdrawalDetails
                visible={withdrawalDataModal.visible}
                withdrawalId={withdrawalDataModal.withdrawalId}
                withdrawalType={withdrawalDataModal.withdrawalType}
                withdrawalDataModal={withdrawalDataModal}
                onClose={() => {
                  events.hideWithdrawalDataModal()
                }}
              /> : null}
        </div>
    )
}

export default ListAllWithdrawal
