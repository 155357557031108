import {
  Button,
  Card,
  Col,
  Row,
  notification, Tooltip, Icon
} from 'antd'
import lodash from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { DefaultTablePagination } from '../../../components/_utils/appUtils'
import { handleErr } from '../../../request'
import { CheckMyRights } from '../../WebComponent/CheckUserRights'
import { CheckUserRight } from '../../dashboard/views/DashboardUserWise'
import {
  eventListExport
} from '../../event/action/event'
import { enquiryListFxn, unizHomeEnquiryFxn } from '../action'
import { getUrlPushWrapper } from '../../../routes'

const unizHomeEnquiryBy = []
const AgentEnquiryList = props => {
  const [filter, setFIlter] = useState({})
  const [total, setTotal] = useState(0)
  const [approved, setApproved] = useState(false)
  const [updateProperty, setUpdateProperty] = useState({
    visible: false,
    enquiryId: ''
  })
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = params => {
    return new Promise(async resolve => {

      let resp = await dispatch(
        enquiryListFxn({
          ...params,
          enquiryBy: 'amber'
          // userType: "agent",

        })
      )
      setTotal(resp.total)
      resolve({ data: resp.data })
    })
  }

  // const apiRequest = params => {
  //     return new Promise(async resolve => {
  //         params.sortField = 'countryName'
  //         params.sortOrder = 'ascend'
  //         let resp = await dispatch(
  //             singleUserEnquiryFxn({
  //                 ...params,
  //                 userType: "agent",
  //                 regExFilters: [
  //                     'name',
  //                     'enrollmentBasic',
  //                     'enrollmentProcedure',
  //                     'fundSponsors'
  //                 ]
  //             })
  //         )
  //         setFIlter(params)
  //         setTotal(resp.total)
  //         resolve(resp)
  //     })
  // }

  const events = {
    viewCourse: record => {
      //   setState({
      //     ...state,
      //     viewCourseDrawer: true,
      //     studentObj: record
      //   })
    },
    viewCourseClose: () => {
      //   setState({
      //     ...state,
      //     viewCourseDrawer: false,
      //     studentObj: {}
      //   })
    },
    reloadTable: () => {
      if (tableRef && tableRef.current) {
        tableRef.current.reload()
      }
    },
    searchData: async () => {
      //   let obj = {}
      //   if (name) {
      //     obj.name = name
      //   }
      //   if (countryId) {
      //     obj.countryId = countryId
      //   }
      //   if (status) {
      //     obj.status = status
      //   }
      //   if (branchUserId) {
      //     obj.branchUserId = branchUserId
      //   }
      //   if (intake) {
      //     obj.intake = intake
      //   }
      //   if (appUniversityId) {
      //     obj.appUniversityId = appUniversityId
      //   }
      //   if (agentId) {
      //     obj.agentId = agentId
      //   }
      //   if (studentShore) {
      //     obj.studentShore = studentShore
      //   }
      //   dispatch(
      //     getUrlPushWrapper('allStudent', {
      //       ...obj
      //     }))
      //   setTableSearch(true)
      //   setTimeout(() => {
      //     events.reloadTable()
      //   }, 200)
    },
    showUpdateModal: async (enquiryId) => {
      setUpdateProperty({
        visible: true,
        enquiryId: enquiryId
      })
    }

  }
  const statusFilter = [
    { name: 'Verified', value: 'Verified' },
    { name: 'Unverified', value: 'Unverified' }
  ]

  const sendData = async record => {
    let resp = await dispatch(unizHomeEnquiryFxn({ eventId: record._id }))
    if (resp && resp.success) {
      tableRef.current.reload()
    }
  }

  const columns = [
    {
      title: 'Property',
      dataIndex: 'propertyImage',
      key: 'propertyImage',
      searchTextName: 'propertyName',
      render: (val, record) => {
        return (
          <p>
            <img src={record.propertyImage} height='90px' width='100px' />
          </p>
        )
      }
    },
    {
      title: 'Property Name',
      dataIndex: 'propertyName',
      key: 'propertyName',
      searchTextName: 'propertyName',
      render: (val, record) => {
        return (
          <p>
            {record.propertyName}
          </p>
        )
      }
    },
    {
      title: 'Room Details',
      dataIndex: 'roomDetails',
      key: 'roomDetails',
      render: (item, record) => {

        return (
          <>
            Room : {record && record.roomType}
            <br />
            Start : {record && record.moveInDate}
            <br />
            End : {record && record.moveOutDate
          }
            <br />
            Price : {record && record.price}
            <br />
          </>
        )
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      searchTextName: 'name',
      render: (val, record) => {
        return (
          <p>
            {record.title} {record.fullName}
          </p>
        )
      }
    },

    {
      title: 'Nationality',
      dataIndex: 'nationality',
      key: 'nationality',
      searchTextName: 'nationality'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },

    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (val, record) => {
        return (
          <p>
            {record.dailCode} {record.phoneNumber}
          </p>
        )
      }
    },

    {
      title: 'University',
      dataIndex: 'university',
      key: 'university',
      // filters: statusFilter.map(x => ({value: x.value, text: x.name})),
      render: item => {
        return item
      }
    },
    // {
    //     title: 'Property',
    //     dataIndex: 'property',
    //     key: 'property',
    //     render: (item, record) => {
    //         return <>{item.address}</>
    //     }
    // },


    {
      title: 'Added By',
      dataIndex: 'userId',
      key: 'userId',
      render: (item, record) => {

        return (
          <>
            Name : {record.userId && record.userId.name}
            <br />
            Email : {record.userId && record.userId.email}
            <br />

          </>
        )
      }
    },

    //    {
    //         title: 'Approved',
    //         dataIndex: 'approved',
    //         key: 'approved',
    //         render: (item, record) => {

    //             return (
    //                 <>

    //                     {item ? "Approved" : <>

    //                         <Popconfirm title={'Are you sure, you want to approved ths lead?'} onConfirm={() => {
    //                             sendData(record)
    //                         }
    //                         }>
    //                             <Button size={'small'}>Approved Now</Button>
    //                         </Popconfirm>

    //                     </>}
    //                 </>
    //             )
    //         }
    //     },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 100,
      key: 'action',
      render: (item, record) => {
        return (
          <>

            <Tooltip title={'View Enquiry'}>
              <a className='btn'
                 href={`/uniz-home/view-enquiry?enquiryId=${record._id}`}
                 target={'_blank'}
              ><img src='../../dist/img/view.svg' alt='' /></a>
            </Tooltip>

                        {/*<Tooltip title="Edit">*/}
                        {/*    <button*/}
                        {/*        className={'btn'}*/}
                        {/*        style={{ marginRight: 6 }}*/}
                        {/*        onClick={() => events.showUpdateModal(record._id)}*/}
                        {/*    >*/}
                        {/*        <Icon type={'edit'}/>*/}
                        {/*    </button>*/}
                        {/*</Tooltip>*/}

          </>)
      }
    }
  ]

  const handleExport = async () => {
    let obj = {
      ...filter,
      count: total,
      regExFilters: [
        'Title',
        'Name',
        'Nationality',
        'Email',
        'Phone Number',
        'University',
        'Room Details'
      ]
    }
    let { success, filePath, message } = await dispatch(eventListExport(obj))
    if (success) {
      notification.success({ message: message })
      // window.open(`${apiUrl}${filePath}`, 'Download')
    } else {
      notification.error({ message: message })
    }
  }

  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center all-student-search'>
            <Button
              onClick={() => events.goBack()}
              className={'roundGoBackBtn'}
              icon={'arrow-left'}>
              Back
            </Button>
            <h5>All Enquiries: {total || 0}</h5>

            <div
              className='sort-box-table mark-btn'
              style={{ marginLeft: '1000px' }}>
              {/*    <AllowComponentUserWise user={user} rightUserType={['admin', 'branchManager']}>
                                <Tooltip title={'Export Excel'}>
                                    <a onClick={() => handleExport()} className='btn'>Export Excel</a>
                                </Tooltip>
                            </AllowComponentUserWise>*/}
            </div>
          </div>
          <div className='card-body table-responsive'>
            <Row gutter={16}>
              <Col span={6}>
                <div className='sort-box-table custom-sort-box-new'>
                  {/* <Select name='Search By Country' id=''
                                        filterOption={filterOption}
                                        className={'antSelect'}
                                        allowClear={true}
                                        showSearch={true}
                                        value={countryId || undefined}
                                        placeholder={'Choose Country'}
                                        onChange={(item) => setCountryId(item)} value={countryId}>
                                        {allCountry && allCountry.length ? allCountry.map((item, key) => {
                                            return (
                                                <Option value={item._id} key={key}>{item.countryName}</Option>
                                            )
                                        }) : null}
                                    </Select> */}
                </div>
              </Col>
              <Col span={6}>
                <div className='search-box-table round'>
                  {/* <input className='form-control form-control' type='search'
                                        placeholder='Search by name...' value={name} onChange={(e) => {
                                            events.enterName(e.target.value)
                                        }} />
                                    <img src='/dist/img/search.png' alt='' /> */}
                </div>
              </Col>
              <Col md={4} sm={4} xs={8} lg={4}>
                <div className='sort-box-table custom-sort-box-new'>
                  {/* <Select name='Status' onChange={(item) => setStatus(item)}
                                        filterOption={filterOption}
                                        className={'antSelect'}
                                        placeholder={'Status'}
                                        allowClear={true}
                                        showSearch={true}
                                        value={status || undefined}>
                                        {statusList && statusList.length ? statusList.map((item, key) => {
                                            return (
                                                <Option value={item.name} key={key}>{item.name}</Option>
                                            )
                                        }) : null}
                                    </Select> */}
                </div>
              </Col>
              <CheckMyRights rightList={['admin', 'branchManager']}>
                <Col md={4} sm={4} xs={8} lg={4}>
                  <div className='sort-box-table custom-sort-box-new'>
                    {/* <Select name='Current User' onChange={(item) => setBranchUserId(item)}
                                            filterOption={filterOption}
                                            className={'antSelect'}
                                            allowClear={true}
                                            showSearch={true}
                                            placeholder={'Current User'}
                                            value={branchUserId || undefined}>
                                            {branchUserList && branchUserList.length ? branchUserList.map((item, key) => {
                                                return (
                                                    <Option value={item._id} key={key}>{item.name}</Option>
                                                )
                                            }) : null}
                                        </Select> */}
                  </div>
                </Col>
              </CheckMyRights>
              <Col md={4} sm={4} xs={8} lg={4}>
                <div className='sort-box-table custom-sort-box-new'>
                  {/* <Select name='Search By University' onChange={(item) => setAppUniversityId(item)}
                                        filterOption={filterOption}
                                        className={'antSelect'}
                                        allowClear={true}
                                        showSearch={true}
                                        placeholder={'University'}
                                        value={appUniversityId || undefined}>
                                        {universityList && universityList.length ? universityList.map((item, key) => {
                                            return (
                                                <Option value={item._id} key={key}>{item.universityName}</Option>
                                            )
                                        }) : null}
                                    </Select> */}
                </div>
              </Col>
              <Col md={4} sm={4} xs={8} lg={4}>
                <div className='sort-box-table custom-sort-box-new'>
                  {/* <Select name='Intake' onChange={(item) => setIntake(item)}
                                        filterOption={filterOption}
                                        className={'antSelect'}
                                        allowClear={true}
                                        showSearch={true}
                                        placeholder={'Intake'}
                                        value={intake || undefined}>
                                        {Intakes && Intakes.length ? Intakes.map((item, key) => {
                                            return (
                                                <Option value={item} key={key}>{item}</Option>
                                            )
                                        }) : null}
                                    </Select> */}
                </div>
              </Col>
              <CheckUserRight
                user={user}
                rightUserType={['admin', 'branchManager', 'branchUser']}>
                <Col md={4} sm={4} xs={8} lg={4}>
                  <div className='sort-box-table custom-sort-box-new'>
                    {/* <Select name='Search By Agent'
                                            filterOption={filterOption}
                                            className={'antSelect'}
                                            allowClear={true}
                                            placeholder={'Agent'}
                                            showSearch={true}
                                            onChange={(item) => setAgentId(item)} value={agentId || undefined}>
                                            {branchManagerAgentList && branchManagerAgentList.length ? branchManagerAgentList.map((item, key) => {
                                                let newName = `${item.companyName} (${item.name})`
                                                return (
                                                    <Option value={item._id} key={key}>{newName}</Option>
                                                )
                                            }) : null}
                                        </Select> */}
                  </div>
                </Col>
              </CheckUserRight>

              {/*<CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>
                                 <Col md={4} sm={4} xs={8} lg={4}>
                                    <div className='sort-box-table custom-sort-box-new'>
                                        <Select name='Student Shore'
                                            filterOption={filterOption}
                                            className={'antSelect'}
                                            allowClear={true}
                                            placeholder={'Student Shore'}
                                            showSearch={true}
                                            onChange={(item) => setStudentShore(item)} value={studentShore || undefined}>
                                            <Option value={'OnShore'}>OnShore</Option>
                                            <Option value={'OffShore'}>OffShore</Option>
                                        </Select>
                                    </div>
                                </Col>
                            </CheckUserRight> */}

              {/* <Col span={6}>
                                <Button onClick={() => events.searchData()} className={'roundBtn'}>Search</Button>
                                <Button onClick={() => events.clearFxn()} className={'roundBtn'}>
                                    Clear
                                </Button>
                            </Col> */}
            </Row>

                        <TableComp
                            columns={columns}
                            apiRequest={apiRequest}
                            ref={tableRef}
                            pagination={DefaultTablePagination()}
                        />
                    </div>
                </div>
            </div>
            {/*{ updateProperty.visible ?*/}
            {/*    <UpdatePropertyForm*/}
            {/*        {...updateProperty}*/}
            {/*        onClose={() => events.hideUpdateModal()}*/}
            {/*        /> : null*/}
            {/*}*/}
        </div>
    )
}

export default AgentEnquiryList
